import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminEmailtoolController', AdminEmailtoolController);

  function AdminEmailtoolController($state, $rootScope, $scope, $filter, $translate, SessionService, MessageService, envConfig, UtilService) {
    const vm = this;
    vm.cultureOptions = [{name: 'All languages', value: ''}, {name: 'SV', value: 'SV'}, {name: 'EN', value: 'EN'}, {name: 'DE', value: 'DE'}, {
      name: 'IT',
      value: 'IT'
    }, {name: 'FR', value: 'FR'}, {name: 'NL', value: 'NL'}, {name: 'DA', value: 'DA'}];
    vm.language = vm.cultureOptions[0];
    vm.nationOptions = [{name: 'All nations', value: ''}, {name: 'SE', value: 752}, {name: 'US', value: 840}, {name: 'DE', value: 276}, {name: 'IT', value: 380}, {
      name: 'FR',
      value: 250
    }, {name: 'NL', value: 528}, {name: 'DK', value: 208}];
    vm.nation = vm.nationOptions[0];
    vm.result = '';
    let _tableState = null;

    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
    };

    vm.fetchMessages = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = 6;

      updateMessagesTable();
    };

    function updateMessagesTable() {
      const id = '00000000-0000-0000-0000-000000000000';

      MessageService.getMessages(id, pagingParameters).then(function(data) {
        vm.messages = data.rows;
        vm.nrOfMessages = data.nbrOfRows;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }
      });
    }

    vm.showContent = function(content, id) {
      if (content !== null) {
        content = content.replace(/(?:\r\n|\r|\n)/g, '<br />');
        $('#contentSpan').html(content);

        $('.fa-caret-right').each(function(i, obj) {
          $(obj).hide();
        });

        $('.emailItem').each(function(i, obj) {
          $(obj).removeClass('coalaLightGrayBackground');
        });

        $('#' + id).show();
        $('.' + id).addClass('coalaLightGrayBackground');
      }
    };

    vm.sendMessage = function() {
      const obj = {
        'subject': vm.subject,
        'language': vm.language.value,
        'nationId': vm.nation.value,
        'content': vm.message,
        'messageType': 'text',
        'entityId': '',
        'created': new Date().toString()
      };

      MessageService.sendMessageToAll(obj).then(function(response) {
        vm.showFeedback($translate.instant('message_sent_to_all_users'));
        vm.subject = '';
        vm.message = '';
        updateMessagesTable();
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
