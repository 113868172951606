import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminGroupController', AdminGroupController);

  function AdminGroupController($scope, $rootScope, ModalService, HealthcareProviderService, SessionService, UtilService, NationService, envConfig) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    let _tableState = null;
    vm.showSpinner = false;

    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    vm.fetchGroups = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);

      updateGroupsTable();
    };

    function updateGroupsTable() {
      if (vm.permissions.readHealthcareProviderGroup) {
        vm.showSpinner = true;
        HealthcareProviderService.getMyGroups(pagingParameters).then(function(data) {
          vm.groups = data.rows;
          vm.totalGroups = data.nbrOfRows;
          vm.nrOfPages = data.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
          vm.showSpinner = false;
        });
      }
    }

    vm.createNewGroupModal = function(isEditMode, item) {
      if (vm.permissions.writeHealthcareProviderGroup) {
        $rootScope.isEditMode = isEditMode;
        $rootScope.item = item;
        $rootScope.updateGroupsTable = function() {
          updateGroupsTable();
        };

        if (isEditMode) {
          ModalService.showModal({
            template: require('../views/modals/group.create.edit.modal.tpl.html').default,
            controller: 'AdminGroupCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            const t = modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateGroupsTable();
              console.log(result);
            });
          });
        } else {
          ModalService.showModal({
            template: require('../views/modals/group.create.edit.modal.tpl.html').default,
            controller: 'AdminGroupCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateGroupsTable();
              console.log(result);
            });
          });
        }
      }
    };
  }
})();
