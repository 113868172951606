import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('UserCreateEditController', UserCreateEditController);

  function UserCreateEditController($scope, $state, $filter, $rootScope, $translate, ModalService, PatientService, HealthcareProviderService, envConfig, SessionService, NationService, close) {
    const vm = this;
    vm.to = moment().utc();
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.userdata = $rootScope.userdata;
    vm.subscription = $rootScope.subscription;
    vm.userDetails = $rootScope.userDetails;
    vm.subscriptionTypes = [{name: 'subscriptiontype_basic', value: 'base'}, {
      name: 'subscriptiontype_premium',
      value: 'plus'
    }];
    vm.subscriptionType = vm.subscriptionTypes[0];
    vm.callsLeftOptions = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
    vm.freeMonthsOptions = [{name: 'freemonthsoptions_no', value: 0}, {
      name: 'freemonthsoptions_onemonths',
      value: 1
    }, {name: 'freemonthsoptions_twomonths', value: 2},
      {name: 'freemonthsoptions_threemonths', value: 3}, {
        name: 'freemonthsoptions_sixmonths',
        value: 6
      }, {name: 'freemonthsoptions_oneyear', value: 12}];
    vm.freeMonths = vm.freeMonthsOptions[0];
    vm.loginMethodsOptions = NationService.getPatientLoginOptions();
    vm.loginMethods = vm.loginMethodsOptions[vm.loginMethodsOptions.length - 1];
    vm.healthcareProvider = null;

    vm.callsLeft = vm.callsLeftOptions[0];
    vm.cancelled = true;
    vm.isEditMode = $rootScope.isEditMode;
    vm.subscriptionUpdated = false;
    vm.userProfileUpdated = false;
    vm.freeMonthsUpdated = false;
    vm.oldFreeMonths = 0;
    vm.emailExists = false;
    vm.IdentificationNoExists = false;
    vm.hasBorrowedMonitor = false;
    vm.borrowedMonitor = null;
    vm.borrowedMonitorUpdated = false;
    vm.configurations = [];
    vm.selectedConfigurations = [];
    vm.beforeSelectedConfigurations = [];
    vm.currentConfigurations = null;
    vm.providersList = [];
    vm.prescribingProfessionalList = [];
    vm.prescribingProfessional = null;

    vm.options = {
      format: 'YYYY-MM-DD',
      minDate: moment(),
      viewDate: vm.to,
      icons: {
        time: 'fa fa-time',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-dot-circle',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    };

    let closing = false;
    let emptyPatientIdentificationNo = false;
    let configurationsIsLoaded = false;

    activate();

    function activate() {
      if (!vm.isEditMode) {
        vm.freeMonthsOptions = [{name: 'freemonthsoptions_onemonths', value: 1}, {
          name: 'freemonthsoptions_twomonths',
          value: 2
        },
          {name: 'freemonthsoptions_threemonths', value: 3}, {
            name: 'freemonthsoptions_sixmonths',
            value: 6
          }, {name: 'freemonthsoptions_oneyear', value: 12}];
        vm.freeMonths = vm.freeMonthsOptions[0];
        vm.userDetails = {
          firstname: '',
          lastname: '',
          email: ''
        };
        vm.subscription = {
          email: '',
          from: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
          to: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
          subscriptionType: 'base',
          cancelled: true,
          contractEnd: moment(),
          callsLeft: 12,
        };
      } else {
        if (!vm.subscription) {
          vm.subscription = {
            email: '',
            from: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
            to: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
            subscriptionType: 'base',
            cancelled: true,
            contractEnd: moment(),
            callsLeft: 12,
          };
        }
        vm.firstname = vm.userDetails.firstname;
        vm.lastname = vm.userDetails.lastname;
        vm.testUser = vm.userDetails.testUser;
        vm.hasBorrowedMonitor = vm.userDetails.hasBorrowedMonitor;
        vm.borrowedMonitor = vm.userDetails.borrowedMonitor;
        vm.email = vm.userDetails.email;
        vm.dateOfBirth = $filter('date')(vm.userDetails.dateOfBirth, vm.formats.mediumDate);

        vm.emailExists = false;
        if (vm.userdata !== undefined) {
          vm.patientIdentificationNo = vm.userdata.patientIdentificationNo;
        }
        if (vm.patientIdentificationNo === undefined || vm.patientIdentificationNo === null) {
          vm.patientIdentificationNo = vm.userDetails.patientIdentificationNo;
        }
        vm.to = moment(vm.subscription.to);
        vm.callsLeft = vm.subscription.callsLeft;
        vm.cancelled = vm.subscription.cancelled;
        vm.subscriptionType = jQuery.grep(vm.subscriptionTypes, function(x) {
          return x.value === vm.subscription.subscriptionType;
        })[0];
        if (vm.userdata !== undefined) {
          vm.loginMethods = jQuery.grep(vm.loginMethodsOptions, function(x) {
            return x.value === vm.userdata.allowedLoginMethods;
          })[0];
        }
      }

      if (vm.permissions.representingProvider) {
        getConfigurations();
      }

      getAllHealthcareProviders();
      getPrescribingProfessionalUsers();

      vm.to = vm.to.add(1, 'month');
    }

    vm.showHealthcareProviders = function() {
      return vm.providersList !== null && vm.providersList.length > 0;
    };

    vm.showPrescribingProfessional = function() {
      return NationService.isUS();
    };

    vm.isRepresentingProvider = function() {
      if (!SessionService.isUnrestrictedOrGroupUser()) {
        return true;
      }
      return false;
    };

    function getPrescribingProfessionalUsers() {
      if (!SessionService.isUnrestrictedOrGroupUser()) {
        HealthcareProviderService.getStaffs().then(function(response) {
          vm.prescribingProfessionalList = response;
          if (vm.userDetails.userId !== undefined) {
            PatientService.getPatientPortalUser(vm.userDetails.userId).then(function(response) {
              if (response !== null && response.length > 0) {
                const found = jQuery.grep(vm.prescribingProfessionalList, function(x) {
                  return x.id == response[0].id;
                });
                if (found.length > 0) {
                  vm.prescribingProfessional = found[0];
                }
              }
            });
          }
        });
      }
    }

    vm.getStaffFullname = function(user) {
      return user.lastname + ', ' + user.firstname;
    };

    vm.isUnrestrictedUser = function() {
      return SessionService.isUnrestrictedUser();
    };

    function getAllHealthcareProviders() {
      if (SessionService.isUnrestrictedOrGroupUser()) {
        HealthcareProviderService.getAllHealthcareProviders().then(function(response) {
          vm.providersList = response;
          if (!vm.permissions.coalaAdmin && vm.providersList.length > 0) {
            vm.healthcareProvider = vm.providersList[0];
          }
        });
      }
    }

    vm.savePatient = function() {
      if (!vm.isEditMode) {
        const user = {
          firstName: vm.firstname,
          lastName: vm.lastname,
          email: vm.email,
          password: vm.password,
          confirmPassword: vm.confirmPassword,
          patientIdentificationNo: vm.patientIdentificationNo,
          allowedLoginMethods: vm.loginMethods.value,
          dateOfBirth: getDateOfBirth(),
          subscriptionEnds: vm.permissions.writePatientSubscription ? vm.to.format('YYYY-MM-DD') : null,
          subscriptionType: vm.permissions.writePatientSubscription ? vm.subscriptionType.value : null,
          subscriptionCancelled: true,
          subscriptionDibsId: 'CoalaAdminPortal',
          callsLeft: vm.callsLeft,
          testUser: vm.testUser,
          measurementConfigurationGroupIds: createMeasurementConfigurationsRequest().measurementConfigurationGroupIds,
          healthcareProviderId: vm.healthcareProvider !== null ? vm.healthcareProvider.id : null
        };
        if (vm.existsAsStaff) {
          PatientService.connect(user).then(function(response) {
            $rootScope.email = vm.email;
            $rootScope.patientIdentificationNo = vm.patientIdentificationNo;
            updatePrescribingProfessional(response);
            vm.showFeedback($translate.instant('user_connected_to_staff'));
            close('', 500);
          }, function(error) {
            vm.showError($translate.instant('user_connected_to_staff_error'));
            console.log(error);
          });
        } else {
          PatientService.createPatient(user).then(function(response) {
            $rootScope.email = vm.email;
            $rootScope.patientIdentificationNo = vm.patientIdentificationNo;
            updatePrescribingProfessional(response);
            vm.showFeedback($translate.instant('user_created'));
            closeModal();
          }, function(error) {
            let errorText = '';
            if (error.patientIdentificationNo) {
              errorText = 'Invalid patient identification number';
            }
            for (const key in error.errors) {
              if (key == 'password') {
                $scope.createPatientForm.password.$setValidity('', false);
                errorText = error.errors['password'];
              }
              if (key == 'confirmPassword') {
                $scope.createPatientForm.confirmPassword.$setValidity('', false);
                errorText = error.errors['confirmPassword'];
              }
              if (key == 'dateOfBirth') {
                $scope.createPatientForm.confirmPassword.$setValidity('', false);
                errorText = error.errors['dateOfBirth'];
              }
              if (key == 'patientIdentificationNo') {
                $scope.createPatientForm.patientIdentificationNo.$setValidity('', false);
                // errorText = error.modelState["patientIdentificationNo"];
              }
            }
            vm.showError($translate.instant('user_created_error') + ' ' + errorText);
          });
        }
      } else {
        vm.updateUserProfile();
        vm.updateEmail();
        vm.updateUserConfigurations();
        vm.updatePassword();
        updatePrescribingProfessional(vm.userDetails.userId, true);

        if (vm.permissions.writePatientSubscription) {
          vm.updateSubscription();
        }
        if (vm.permissions.manageAccount) {
          vm.updateTestUser();
          vm.updateAllowedLoginMethods();
        }
      }
    };

    vm.afterSelectItem = function(item) {
      console.log('Passed - After Select Item: ' + item.id);
      if (item.id == '') {
        vm.selectedConfigurations = [{name: $translate.instant('user_created_allconfiguration_option'), id: ''}];
      } else {
        const allIsSelected = jQuery.grep(vm.selectedConfigurations, function(x) {
          return x.id == '';
        });
        if (allIsSelected.length > 0) {
          const itemIndex = vm.selectedConfigurations.indexOf(allIsSelected[0]);
          if (itemIndex != -1) {
            vm.selectedConfigurations.splice(itemIndex, 1);
          }
        }
      }
    };

    vm.isConfigurationsAvailable = function() {
      return vm.configurations !== null && vm.configurations.length > 2;
    };

    function getConfigurations() {
      if (vm.permissions.readMeasurementConfiguration) {
        vm.configurations.push({name: $translate.instant('user_created_allconfiguration_option'), id: ''});
        PatientService.getMeasuremenConfigurations().then(function(response) {
          for (const index in response) {
            vm.configurations.push({name: response[index].groupName, id: response[index].groupId});
          }
          getUserConfigurations();
        }, function(error) {
          console.log(error);
        });
      }
    }

    function getUserConfigurations() {
      if (vm.permissions.readMeasurementConfiguration) {
        if (vm.userDetails.userId != null) {
          PatientService.getMeasurementConfigurationsForUser(vm.userDetails.userId).then(function(response) {
            for (const index in response) {
              vm.selectedConfigurations.push({name: response[index].groupName, id: response[index].groupId});
            }

            // Check if all are selected
            if (vm.selectedConfigurations.length == 0) {
              vm.selectedConfigurations = [{name: $translate.instant('user_created_allconfiguration_option'), id: ''}];
            }

            vm.currentConfigurations = vm.selectedConfigurations.map(function(el) {
              return el.id;
            }).join(',');
            vm.beforeSelectedConfigurations = vm.selectedConfigurations.map(function(el) {
              return el.id;
            }).join(',');
            configurationsIsLoaded = true;
          }, function(error) {
            console.log(error);
          });
        } else {
          configurationsIsLoaded = true;
        }
      }
    }

    function updatePrescribingProfessional(userId, showmessage) {
      if (vm.prescribingProfessional !== null) {
        const request = {patientUserId: userId, portalUserId: vm.prescribingProfessional.id};
        PatientService.setPatientPortalUser(request).then(function(response) {
          SessionService.setSearchFilterPortalUsers(null);
          if (showmessage) {
            vm.showFeedback($translate.instant('user_updated'));
            closeModal();
          }
        }, function(error) {
          console.log(error);
        });
      }
    }

    vm.updateUserConfigurations = function() {
      if (configurationsIsLoaded && vm.permissions.writeMeasurementConfiguration) {
        if (vm.currentConfigurations == null || vm.currentConfigurations !== vm.selectedConfigurations.map(function(el) {
          return el.id;
        }).join(',')) {

          const request = createMeasurementConfigurationsRequest();
          PatientService.updateUserConfiguration(request).then(function(response) {
            vm.showFeedback($translate.instant('user_updated'));
            closeModal();
          }, function(error) {
            console.log(error);
          });
        }
      }
    };

    function createMeasurementConfigurationsRequest() {
      const allConfigs = jQuery.grep(vm.selectedConfigurations, function(x) {
        return x.id == '';
      });

      const request = {
        patientUserId: vm.userDetails.userId,
        measurementConfigurationGroupIds: []
      };

      if (allConfigs.length == 0) {
        for (const index in vm.selectedConfigurations) {
          request.measurementConfigurationGroupIds.push(vm.selectedConfigurations[index].id);
        }
      }

      return request;
    }


    function getDateOfBirth() {
      let result;
      if (vm.configuration.showDateOfBirth) {
        const dt = moment(vm.dateOfBirth, vm.configuration.dateOfBirthFormat);
        result = dt.format('YYYY-MM-DD');
        console.log(result);
        return result;
      } else {
        // Get it from the patientIdentificationId (TODO validate that it is date?)
        const date = vm.patientIdentificationNo;
        const tmpdate = date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
        result = $filter('date')(tmpdate, 'yyyy-MM-dd');
        console.log(result);
        return result;
      }
    }

    vm.updateUserProfile = function() {
      if (vm.userProfileUpdated || vm.borrowedMonitorUpdated) {
        const userProfile = {
          firstname: vm.firstname,
          lastname: vm.lastname,
          phoneNumber: vm.userDetails.phoneNumber,
          patientIdentificationNo: vm.patientIdentificationNo,
          coalaAtlasAgreement: vm.userDetails.coalaAtlasAgreement,
          borrowedMonitor: vm.borrowedMonitorUpdated ? (vm.hasBorrowedMonitor ? moment() : null) : vm.borrowedMonitor,
          hasBorrowedMonitor: vm.hasBorrowedMonitor,
          dateOfBirth: getDateOfBirth()
        };
        vm.userDetails.firstname = vm.firstname;
        vm.userDetails.lastname = vm.lastname;
        vm.userDetails.patientIdentificationNo = vm.patientIdentificationNo;
        vm.userDetails.borrowedMonitor = userProfile.borrowedMonitor;
        vm.userDetails.hasBorrowedMonitor = vm.hasBorrowedMonitor;
        vm.userDetails.dateOfBirth = getDateOfBirth();

        PatientService.putUserProfile(vm.userDetails.userId, userProfile).then(function(data) {
          $rootScope.userDetails = userProfile;
          vm.showFeedback($translate.instant('user_updated'));
          closeModal();
        }, function(error) {
          const errorText = '';
          for (const key in error.modelState) {
            if (key == 'patientIdentificationNo') {
              $scope.createPatientForm.patientIdentificationNo.$setValidity('', false);
              // errorText = error.modelState["patientIdentificationNo"];
            }
          }
          vm.showError($translate.instant('user_updated_error') + errorText);
        });
      }
    };

    vm.updateSubscription = function() {
      if (vm.subscriptionUpdated || vm.freeMonthsUpdated) {
        if (vm.subscription.contractEnd === null) {
          vm.subscription.contractEnd = moment().format('YYYY-MM-DDTHH:mm:ss');
        }
        let todate = vm.subscription.to;
        if (vm.freeMonthsUpdated) {
          todate = vm.to.format('YYYY-MM-DDTHH:mm:ss');
        }

        const subscription = {
          email: vm.userDetails.email,
          from: vm.subscription.from,
          to: todate,
          subscriptionType: vm.subscriptionType.value,
          cancelled: vm.cancelled,
          contractEnd: vm.subscription.contractEnd,
          dibsId: vm.subscription.dibsId,
          callsLeft: vm.subscriptionType.value === 'plus' ? vm.callsLeft : 0,
          comment: vm.comment
        };
        vm.subscription.to = todate;
        vm.subscription.subscriptionType = vm.subscriptionType.value;
        vm.subscription.callsLeft = vm.subscriptionType.value === 'plus' ? vm.callsLeft : 0;
        vm.subscription.comment = vm.comment;
        vm.subscription.cancelled = vm.cancelled;

        PatientService.putSubscription(subscription).then(function(data) {
          $rootScope.subscription = subscription;
          vm.showFeedback($translate.instant('user_subcription_updated'));
          closeModal();
        });
      }
    };

    vm.updateEmail = function() {
      if (vm.email !== vm.userDetails.email) {
        vm.userDetails.email = vm.email;

        PatientService.postUpdateEmail(vm.userDetails.userId, vm.email).then(function(data) {
          $rootScope.userDetails.email = vm.email;
          vm.showFeedback($translate.instant('user_email_updated'));
          closeModal();
        });
      }
    };

    vm.updateTestUser = function() {
      if (vm.testUser !== vm.userDetails.testUser) {
        vm.userDetails.testUser = vm.testUser;

        PatientService.postUpdateTestUser(vm.userDetails.userId, vm.testUser).then(function(data) {
          $rootScope.userDetails.testUser = vm.testUser;
          vm.showFeedback($translate.instant('user_test_updated'));
          closeModal();
        });
      }
    };

    vm.updateAllowedLoginMethods = function() {
      if (vm.loginMethods.value !== vm.userdata.allowedLoginMethods) {
        vm.userdata.allowedLoginMethod = vm.loginMethods.value;

        PatientService.postUpdateAllowedLoginMethod(vm.userDetails.userId, vm.loginMethods.value).then(function(data) {
          $rootScope.userdata.allowedLoginMethods = vm.loginMethods.value;
          vm.showFeedback($translate.instant('user_allowed_login_updated'));
          closeModal();
        });
      }
    };

    vm.updatePassword = function() {
      if (vm.password !== null && vm.password !== undefined) {
        PatientService.putUpdateUserPassword(vm.userDetails.userId, vm.password, vm.confirmPassword).then(function(data) {
          vm.showFeedback($translate.instant('user_password_updated'));
          closeModal();
        }, function(error) {
          for (const key in error.modelState) {
            if (key == 'userRequest.Password') {
              $scope.createPatientForm.password.$setValidity('', false);
            }
            if (key == 'model.ConfirmPassword') {
              $scope.createPatientForm.confirmPassword.$setValidity('', false);
            }
          }
          vm.showError($translate.instant('user_password_error'));
        });
      }
    };

    vm.isHealthcareProviderRequired = function() {
      if (!vm.isEditMode && vm.showHealthcareProviders() && !SessionService.isUnrestrictedUser()) {
        // if (vm.healthcareProvider == null)
        return true;
      }
      return false;
    };

    vm.setToDate = function() {
      let ending;
      const fm = jQuery.grep(vm.freeMonthsOptions, function(x) {
        return x.value === vm.freeMonths.value;
      })[0].value;

      vm.to = moment(vm.subscription.to);
      if (vm.comment === undefined) {
        vm.comment = '';
      }
      if (vm.isEditMode) {
        ending = vm.oldFreeMonths === 1 ? '' : 's';
        const remove = 'Added ' + vm.oldFreeMonths + ' free month' + ending + '\n';
        vm.comment = vm.comment.replace(remove, '');
      }
      if (fm !== 0) {
        vm.freeMonthsUpdated = true;
        vm.to = vm.to.add(fm, 'month');
        if (vm.isEditMode) {
          ending = fm === 1 ? '' : 's';
          vm.comment = 'Added ' + fm + ' free month' + ending + '\n' + vm.comment;
        }
        vm.oldFreeMonths = fm;
      } else {
        vm.freeMonthsUpdated = false;
      }
    };

    vm.setSubscriptionType = function() {
      if (vm.subscription.subscriptionType !== vm.subscriptionType.value) {
        vm.subscriptionUpdated = true;
      }
    };

    vm.setCallsLeft = function() {
      if (vm.subscription.callsLeft !== vm.callsLeft) {
        vm.subscriptionUpdated = true;
      }
    };

    vm.setComment = function() {
      if (vm.subscription.comment !== vm.comment) {
        vm.subscriptionUpdated = true;
      }
    };

    vm.setCancelled = function() {
      if (vm.subscription.cancelled !== vm.cancelled) {
        vm.subscriptionUpdated = true;
      }
    };

    vm.setBorrowedMonitor = function() {
      if (vm.userDetails.hasBorrowedMonitor !== vm.hasBorrowedMonitor) {
        vm.borrowedMonitorUpdated = true;
      } else {
        vm.borrowedMonitorUpdated = false;
      }
    };

    vm.setFirstName = function() {
      if (vm.userDetails.firstname !== vm.firstname) {
        vm.userProfileUpdated = true;
      }
    };

    vm.setLastName = function() {
      if (vm.userDetails.lastname !== vm.lastname) {
        vm.userProfileUpdated = true;
      }
    };

    vm.setPassword = function() {
      $scope.createPatientForm.password.$setValidity('', true);
      $scope.createPatientForm.confirmPassword.$setValidity('', true);
    };

    vm.setDateOfBirth = function() {
      if (vm.userDetails.dateOfBirth !== vm.dateOfBirth) {
        vm.userProfileUpdated = true;
      }
    };

    vm.setIdentificationNo = function() {
      vm.IdentificationNoExists = false;
      if (vm.patientIdentificationNo !== undefined && vm.userDetails.patientIdentificationNo !== vm.patientIdentificationNo) {
        if (vm.configuration.identificationNoMustBeUnique) {
          PatientService.getIdentificationNoExists(vm.patientIdentificationNo).then(function(response) {
            if (response.exist) {
              vm.IdentificationNoExists = true;
              $scope.createPatientForm.patientIdentificationNo.$setValidity('', false);
            } else {
              vm.IdentificationNoExists = false;
              $scope.createPatientForm.patientIdentificationNo.$setValidity('', true);
            }
          });
        } else {
          $scope.createPatientForm.patientIdentificationNo.$setValidity('', true);
        }
        vm.userProfileUpdated = true;
      }
    };

    vm.checkEmail = function() {
      vm.existsAsStaff = false;
      if (vm.email !== undefined && vm.email !== vm.userDetails.email) {
        PatientService.getEmailExists(vm.email).then(function(response) {
          if (response.exist) {
            if (response.existsAsUser) {
              $scope.createPatientForm.email.$setValidity('', false);
            } else if (response.existsAsStaff) {
              vm.existsAsStaff = true;
              vm.showFeedback($translate.instant('user_connect_to_staff'));
              HealthcareProviderService.getStaff(response.userId).then(function(data) {
                vm.firstname = data.firstName;
                vm.lastname = data.lastName;
                vm.patientIdentificationNo = data.staffIdentificationNo;
                emptyPatientIdentificationNo = data.staffIdentificationNo == '';
                $scope.createPatientForm.email.$setValidity('', true);
              });
            }
            vm.emailExists = true;
          } else {
            vm.emailExists = false;
            $scope.createPatientForm.email.$setValidity('', true);
          }
        });
      }
      vm.emailExists = false;
    };

    vm.disablePatientIdentificationNo = function() {
      return vm.existsAsStaff && !emptyPatientIdentificationNo;
    };

    vm.isPatientIdRequired = function() {
      return vm.configuration.patientIdentificationNoValidationRegExp !== '';
    };

    vm.showCompleteInvestigationLink = function() {
      return vm.permissions.representingProvider && vm.userDetails.acceptedTo == null;
    };

    vm.showDisconnectPatientLink = function() {
      return vm.permissions.representingProvider && !NationService.isUS();
    };

    vm.resetPassword = function() {
      const email = vm.userdata.email;
      PatientService.resetPassword(email).then(function(response) {
        vm.showFeedback($translate.instant('user_resetpassword'));
      }, function(error) {
        vm.showError($translate.instant('user_resetpassword_error'));
      });
    };

    vm.unlockAccount = function() {
      PatientService.postUnlockUser(vm.userdata.id).then(function(response) {
        vm.showFeedback($translate.instant('user_account_unlocked'));
        PatientService.getUserData(vm.userdata.id).then(function(data) {
          vm.userdata = data;
          vm.loadedR++;
          vm.checkDoneLoading();
        });
      });
    };

    vm.showRemoveUser = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.delete.user.tpl.html').default,
        controller: 'UserCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          // closeModal();
        });
      });
    };

    vm.showRemoveUserFromHealthcareProvider = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.unlink.user.tpl.html').default,
        controller: 'UserCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          // closeModal();
        });
      });
    };

    vm.showCompleteInvestigation = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.completeinvestigation.user.tpl.html').default,
        controller: 'UserCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          // closeModal();
        });
      });
    };

    vm.removeUser = function() {
      PatientService.deletePatient(vm.userDetails.userId).then(function(response) {
        vm.showFeedback($translate.instant('user_removed'));
        $rootScope.userDetails.email = '';
        // closeModal();
        $state.go('mainAdmin.userlist', {
          startItem: 0
        });
      });
    };

    vm.removeUserFromHealthcareProvider = function() {
      PatientService.unlinkPatient(vm.userDetails.userId).then(function(response) {
        vm.showFeedback($translate.instant('user_unlinkedfromprovider'));
        $rootScope.userDetails.email = '';
        // closeModal();
        $state.go('main.patientsearch', {
          startItem: 0
        });
      });
    };

    vm.completeInvestigation = function() {
      PatientService.completeInvestigation(vm.userDetails.userId).then(function(response) {
        vm.showFeedback($translate.instant('user_investigation_completed'));
        $rootScope.userDetails.email = '';
        // closeModal();
        $state.go('main.patientsearch', {
          startItem: 0
        });
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal() {
      if (!closing) {
        close('', 500);
        $('#usermodal').modal('hide');
      }
      closing = true;
    }
  }
})();
