import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('MyProfileController', MyProfileController);

  function MyProfileController($stateParams, $state, $translate, $scope, $rootScope, $location, MeService, NationService, AuthService, ModalService, envConfig) {
    const vm = this;
    vm.formats = NationService.getDateFormats();
    vm.productsOptions = [];
    vm.selectedProduct = vm.productsOptions[0];
    vm.subscriptionTypes = [{name: 'subscriptiontype_basic', value: 'base'}, {name: 'subscriptiontype_premium', value: 'plus'}];
    vm.subscriptionType = vm.subscriptionTypes[0];
    vm.voucher = null;
    let voucherValid = false;
    vm.metaData = {};
    vm.subscriptionItems = {};
    vm.items = {};
    vm.paymentDetails = {};
    vm.paymentUpdateInfo = null;
    vm.paymentVoucherInfo = null;
    vm.acceptagreement = false;
    vm.showKlarnaCheckout = false;
    vm.showKlarnaConfirm = false;
    vm.showUpdateWithToken = false;
    vm.futurePayments = [];

    activate();

    function activate() {
      vm.userDetails = $rootScope.userDetails;
      vm.subscription = $rootScope.subscription;

    vm.subscriptionTypes = [{name: 'subscriptiontype_basic', value: 'base'}, {name: 'subscriptiontype_premium', value: 'plus'}];
      if (vm.userDetails == null) {
        getUserDetails();
      }
    }

    vm.initializeKlarnaCheckout = function(actionType) {
      vm.showKlarnaCheckout = true;
      const checkoutContainer = document.getElementById('klarna-checkout-container');
      let action = 'None';
      let productId = null;
      if (actionType !== undefined) {
        action = actionType;
      }
      if (vm.paymentInfo !== undefined) {
        productId = vm.paymentInfo.product.id;
      }
      const order = {
        productId: productId,
        // email: 'johan123@mail.com',
        userId: vm.userDetails.userId,
        voucher: vm.voucher,
        actionType: action,
        // registerUserWithOrderNumber: true
      };
      MeService.createKlarnaCheckout(order).then(function(response) {
        checkoutContainer.innerHTML = response.html_snippet;
        const scriptsTags = checkoutContainer.getElementsByTagName('script');
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (let i = 0; i < scriptsTags.length; i += 1) {
          const parentNode = scriptsTags[i].parentNode;
          const newScriptTag = document.createElement('script');
          newScriptTag.type = 'text/javascript';
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
        checkoutContainer.scrollIntoView(true);
      });
    };

    function getUserDetails() {
      vm.showSpinner = true;
      MeService.getUserDetails().then(function(response) {
        vm.userDetails = response;
        MeService.getUserSubscription().then(function(response_subs) {
          vm.subscription = response_subs;
          vm.cancelled = vm.subscription.cancelled;
          vm.subscriptionType.value = vm.subscription.subscriptionType;
          if (vm.showPayments()) {
            // getUpdatePaymentInfo();
            vm.reloadProducts(vm.subscriptionType.value);
          } else {
            vm.showSpinner = false;
          }
        }, function(response) {
          vm.reloadProducts(vm.subscriptionType.value);
          vm.showSpinner = false;
        });

        if (vm.showPayments()) {
          getActiveRecurrentPayments();
          getUpdatePaymentInfo();
        }
      });
    }

    function getActiveRecurrentPayments() {
      MeService.getActiveRecurrentPayments().then(function(response) {
        // vm.activePayments = response;
        vm.futurePayments = jQuery.grep(response, function(x) {
          return x.status === 'active';
        });
        vm.failedpayments = jQuery.grep(response, function(x) {
          return x.status === 'declined' || x.status === 'failed';
        });
        vm.currentPayments = jQuery.grep(response, function(x) {
          return x.status === 'approved';
        });
      });
    }

    function getPaymentInfo() {
      vm.showSpinner = true;
      // Payment info
      const request = {
        subscriptionType: vm.subscriptionType.value,
        productId: vm.selectedProduct.value,
        voucher: vm.voucher
      };

      MeService.getPaymentInfo(request).then(function(response) {
        vm.paymentInfo = response;
        vm.showSpinner = false;
      });
    }

    function getUpdatePaymentInfo() {
      vm.showSpinner = true;
      // Update Payment info for mondido to klarna migration
      // TODO when all mondido customers are migrated this can be removed
      MeService.getUpdatePaymentInfo().then(function(response) {
        vm.paymentUpdateInfo = response;
        vm.showSpinner = false;
      });
    }

    vm.initializeSubscriptionWithToken = function() {
      vm.showUpdateWithToken = true;
    };

    vm.cancelSubscriptionWithToken = function() {
      vm.showUpdateWithToken = false;
    };

    vm.showCancelPayment = function(paymentId) {
      vm.cancelPaymentId = paymentId;
    };

    vm.cancelPayment = function() {
      vm.showSpinner = true;
      MeService.deletePayment(vm.cancelPaymentId).then(function(response) {
        vm.showSpinner = false;
        getUserDetails();
      }, function(response) {
        vm.showSpinner = false;
      });
    };

    vm.updateSubscriptionWithToken = function() {
      vm.showSpinner = true;
      const order = {
        productId: vm.paymentInfo.product.id,
        voucher: vm.voucher
      };
      MeService.updateSubscriptionWithToken(order).then(function(response) {
        vm.showSpinner = false;
        vm.showUpdateWithToken = false;
        vm.showFeedback($translate.instant('payment_success'));
        getUserDetails();
      }, function(response) {
        vm.showSpinner = false;
        vm.showError($translate.instant('payment_error'));
      });
    };

    vm.reloadPaymentInfo = function() {
      if (vm.showUserDetails()) {
        if (vm.selectedProduct !== undefined && vm.selectedProduct.value !== '') {
          getPaymentInfo();
        } else if (vm.paymentInfo !== undefined) {
          vm.paymentInfo.amount = null;
        }
      }
    };

    vm.reloadProducts = function(type) {
      if (vm.showPayments()) {
        vm.paymentInfo = undefined;
        MeService.getProducts(type).then(function(response_products) {
          vm.productsOptions = [];
          let sortOrder = -1;
          let obj = {name: $translate.instant('payment_product_choose_product'), value: '', sortOrder: sortOrder};
          vm.productsOptions.push(obj);
          for (let i = 0; i < response_products.length; i += 1) {
            const item = response_products[i];
            sortOrder = 1;
            let desc = $translate.instant('payment_product_recurrent_nocontract');
            if (item.productSubType === 'screening') {
              sortOrder = 0;
              desc = $translate.instant('payment_product_screening');
            } else if (item.paymentType === 'recurrentWithContract') {
              sortOrder = 2;
              desc = format($translate.instant('payment_product_recurrent_withcontract'), item.contractMonths);
            } else if (item.paymentType === 'directPayment') {
              sortOrder = item.months;
              desc = format($translate.instant('payment_product_directpayment'), item.months);
            } else {
              desc = $translate.instant('payment_product_recurrent_nocontract');
            }
            obj = {name: desc, value: response_products[i].id, sortOrder: sortOrder};
            vm.productsOptions.push(obj);
          }
          vm.selectedProduct = vm.productsOptions[0];
        });
      }
    };

    vm.setCancelled = function() {
      vm.subscription.cancelled = true;
      MeService.cancelSubscription(true).then(function(data) {
        vm.showFeedback($translate.instant('user_subcription_updated'));
        getUserDetails();
      });
    };

    vm.checkVoucher = function() {
      if (vm.selectedProduct !== undefined && vm.voucher !== '') {
        const productId = vm.selectedProduct.value;
        vm.showSpinner = true;
        MeService.checkVoucher(productId, vm.voucher).then(function(response) {
          if (response != null) {
            // $scope.registrationForm.voucher.$setValidity('', true);
            vm.reloadPaymentInfo();
            voucherValid = true;
          } else {
            // $scope.registrationForm.voucher.$setValidity('', false);
            if (voucherValid) {
              voucherValid = false;
              vm.reloadPaymentInfo();
            }
          }

          vm.showSpinner = false;
        }, function(response) {
          // $scope.registrationForm.voucher.$setValidity('', false);
          vm.showSpinner = false;
        });
      } else {
        // $scope.registrationForm.voucher.$setValidity('', false);
      }
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none');
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic');
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium');
      }

      return '';
    };

    vm.usePaymentVoucher = function() {
      vm.showSpinner = true;
      MeService.usePaymentVoucher(vm.paymentVoucherInfo).then(function(response) {
        vm.showFeedback($translate.instant('user_subcription_updated'));
        vm.showSpinner = false;
        getUserDetails();
      }, function(response) {
        // vm.showFeedback($translate.instant('user_subcription_error'));
        vm.showSpinner = false;
      });
    };

    vm.checkPaymentVoucher = function() {
      if (vm.paymentvoucher !== '') {
        vm.showSpinner = true;
        MeService.checkPaymentVoucher(vm.paymentvoucher, 'update').then(function(response) {
          vm.paymentVoucherInfo = response;
          vm.showSpinner = false;
        }, function(response) {
          vm.paymentVoucherInfo = null;
          vm.showSpinner = false;
        });
      } else {
        vm.paymentVoucherInfo = null;
      }
    };

    vm.useStartDate = function() {
      if (vm.paymentInfo != undefined && vm.paymentInfo.startDate == null) {
        return true;
      } else {
        return false;
      }
    };

    vm.useStartDateDibs = function() {
      if (vm.paymentUpdateDibs != undefined && vm.paymentUpdateDibs.startDate == null) {
        return true;
      } else {
        return false;
      }
    };

    vm.hasKlarnaToken = function() {
      return false;// vm.paymentUpdateInfo !== null && vm.paymentUpdateInfo == 'updatePaymentInfo';
    };

    vm.showPaymentUpdateInfo = function() {
      return vm.paymentUpdateInfo !== null && vm.paymentUpdateInfo !== 'none';
    };

    vm.showPaymentVoucherInfo = function() {
      return vm.paymentVoucherInfo !== null;
    };

    vm.isValidPaymentVoucherType = function() {
      return vm.paymentVoucherInfo !== null && (vm.subscription === undefined || vm.paymentVoucherInfo.subscriptionType == vm.subscription.subscriptionType);
    };

    vm.isRecurrentPayment = function() {
      return vm.showPaymentInfo() && vm.paymentInfo.product.paymentType != 'directPayment';
    };

    vm.paymentType = function(product) {
      if (product.productSubType == 'screening') {
        return $translate.instant('payment_product_screening');
      }
      return product.paymentType !== 'directPayment' ? $translate.instant('payment_type_recurrent') : $translate.instant('payment_type_fixed');
    };

    vm.isUpgradedToPremium = function() {
      return vm.showPaymentInfo() && (vm.subscription !== undefined && vm.subscription.subscriptionType == 'base' && vm.subscriptionType.value == 'plus');// && vm.paymentInfo.creditedAmount > 0;
    };

    vm.showUserDetails = function() {
      return vm.userDetails !== null && vm.userDetails !== undefined;
    };

    vm.showPaymentButton = function() {
      return vm.acceptagreement === true && vm.showPaymentInfo();
    };

    vm.showPaymentInfo = function() {
      return vm.paymentInfo !== undefined && vm.paymentInfo.amount !== null && vm.paymentInfo.product !== null;
    };

    vm.showPaymentScreeningInfo = function() {
      return vm.showPaymentInfo() && vm.paymentInfo.product.productSubType === 'screening';
    };
    vm.showBuyoutInfo = function() {
      return vm.showPaymentInfo() && vm.paymentInfo.product.productType === 'buyOutMonitor';
    };
    vm.showPaymentCallbackInfo = function() {
      return vm.paymentStatus !== undefined && (vm.paymentStatus == 'success' || vm.paymentStatus == 'error');
    };

    vm.isAbleToCancel = function() {
      return vm.subscription !== undefined && vm.subscription !== null && !vm.subscription.cancelled && (vm.subscription.userId == vm.userDetails.userId);
    };

    vm.getTermsUrl = function() {
      return NationService.getCoalaUserTermsUrl();
    };

    vm.resetPassword = function() {
      AuthService.resetPassword(vm.userDetails.email).then(function(response) {
        vm.showFeedback($translate.instant('resetpassword_sentmessage'));
      }, function(response) {
        vm.showError(response.message);
      });
    };

    vm.showEndSubscription = function() {
      $rootScope.userDetails = vm.userDetails;
      $rootScope.subscription = vm.subscription;
      ModalService.showModal({
        template: require('../views/modals/confirm.endsubscription.tpl.html').default,
        controller: 'MyProfileController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          close('', 500);
          $('#usermodal').modal('hide');
        });
        $rootScope.userDetails = null;
        $rootScope.subscription = null;
      });
    };

    vm.showPayments = function() {
      return NationService.showPayments();
    };

    function format(str) {
      const args = Array.prototype.slice.call(arguments, 1);
      return str.replace(/{(\d+)}/g, function(match, number) {
        if (typeof args[number] !== 'undefined') {
          return args[number];
        } else {
          return match;
        }
      });
    }

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
