import * as angular from 'angular';

(function() {
  'use strict';

  angular.module('core')
    .directive('toggle', toggle);

  function toggle() {
    return function(scope, elem, attrs) {
      scope.$on('event:toggle', function(ev, num) {
        if (num === attrs.toggle) {
          elem.slideToggle();
        }
      });
    };
  }

  // usage: ng-click="toggle(id)"
  // element that should toggle <div toggle="id"></div>
})();
