import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminDoctorCreateEditController', AdminDoctorCreateEditController);

  function AdminDoctorCreateEditController($scope, $stateParams, $rootScope, $translate, ModalService, HealthcareProviderService, PatientService, envConfig, SessionService, NationService, close) {
    const vm = this;

    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.loginMethodsOptions = NationService.getLoginMetodOptions();
    vm.loginMethods = vm.loginMethodsOptions[vm.loginMethodsOptions.length - 1];
    vm.IdentificationNoExists = false;
    vm.emailExists = false;
    vm.staffProvidersList = [];
    vm.staffGroupsList = [];
    vm.groupList = [];
    vm.unrestrictedRolesList = [];
    let closing = false;
    let hcpRolesChanged = false;
    let groupRolesChanged = false;
    let unrestrictedRoleChanged = false;
    let hcpOrGroupIsRemovedByUser = false;
    vm.tab = 0;
    vm.storing = false;
    vm.activecalls = 0;

    vm.monitoringNotificationOptions = {
      timeZoneOptions: [
          {id: 'America/Los_Angeles', selected: true},
          {id: 'America/Phoenix', selected: false},
          {id: 'America/Chicago', selected: false},
          {id: 'America/New_York', selected: false}
      ],
      attemptNumOptions: [
          {id: 1, selected: false},
          {id: 3, selected: true}
      ],
      afterHoursContact: false,
      notificationCriteriaOptions: [
          {id: 'Standard', title: 'Standard notification criteria', selected: true},
          {id: 'Expanded', title: 'Expanded notification criteria', selected: false}
      ]
    };

    vm.eventMonitoringContact = {};
    let eventMonitoringContactOriginalAsJson = {};

    activate();

    function activate() {
      vm.isEditMode = $rootScope.isEditMode;
      vm.providersList = $rootScope.providers;
      setAvailableGroups();

      if ($rootScope.item != null && vm.isEditMode) {
        vm.doctor = $.extend(true, {}, $rootScope.item);
        vm.originalDoctor = $.extend(true, {}, $rootScope.item);
        vm.loginMethods = jQuery.grep(vm.loginMethodsOptions, function(x) {
          return x.value === vm.doctor.allowedLoginMethods;
        })[0];

        setStaffProvidersRoles();
        setStaffGroupsRoles();
      } else {
        const doctor = {
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
          staffIdentificationNo: '',
          allowedLoginMethods: 'none',
          hsaId: ''
        };
        vm.doctor = $.extend(true, {}, doctor);
        vm.originalDoctor = $.extend(true, {}, doctor);
        if (vm.providersList !== undefined && vm.providersList.length == 1) {
          addHealthcareProviderToList(vm.providersList[0]);
        }
      }
      setStaffUnrestrictedRole();
      fetchMonitoringSetting();
    }

    function setAvailableGroups(addDefault) {
      const groups = [];
      if (hasGroupAccess()) {
        HealthcareProviderService.getMyGroups().then(function(response) {
          angular.forEach(response.rows, function(item) {
            groups.push({id: item.id, name: item.name});
          });
          vm.groupList = groups;
        });
      }
    }

    function setStaffUnrestrictedRole() {
      if (hasUnrestrictedAccess()) {
        HealthcareProviderService.getUnrestrictedRoles().then(function(response) {
          const item = {id: null, roles: response};
          item.roles = populateRoles(item.roles, vm.doctor.unrestrictedRoles);
          vm.unrestrictedRolesList.push(item);
        });
      }
    }

    function setStaffGroupsRoles() {
      angular.forEach(vm.doctor.healthcareProviderGroups, function(group) {
        addGroupToList(group);
      });
    }

    function fetchMonitoringSetting() {
      vm.eventMonitoringContact.monitoringSettingOn = false;
      if (NationService.isUS()) {
        if (vm.doctor.id !== undefined) {
          HealthcareProviderService.getPortalUserMonitoringServiceContactInfo(vm.doctor.id).then(function(data) {
            if (data.length > 0) {
              vm.eventMonitoringContact = data[0];
              vm.eventMonitoringContact.monitoringSettingOn = true;
            } else {
              vm.eventMonitoringContact.attempts = 3;
              vm.eventMonitoringContact.notificationCriteria = 'Standard';
              vm.eventMonitoringContact.timeZone = 'America/Los_Angeles';
            }

            vm.monitoringNotificationOptions.attemptNumOptions.forEach((option) => {
              option.selected = option.id === vm.eventMonitoringContact.attempts;
            });

            vm.monitoringNotificationOptions.notificationCriteriaOptions.forEach((criteria) => {
                criteria.selected = criteria.id === vm.eventMonitoringContact.notificationCriteria;
            });

            vm.monitoringNotificationOptions.timeZoneOptions.forEach((zone) => {
                zone.selected = zone.id === vm.eventMonitoringContact.timeZone;
            });

            eventMonitoringContactOriginalAsJson = JSON.stringify(vm.eventMonitoringContact);
          });
        } else {
          vm.eventMonitoringContact.attempts = 3;
          vm.eventMonitoringContact.notificationCriteria = 'Standard';
          vm.eventMonitoringContact.timeZone = 'America/Los_Angeles';
          eventMonitoringContactOriginalAsJson = JSON.stringify(vm.eventMonitoringContact);
        }
      }
    }

    vm.notificationCriteriaChanged = function(selected/* : { id: string; selected: boolean; title: string } */) {
      vm.monitoringNotificationOptions.notificationCriteriaOptions.forEach((criteria) => {
          criteria.selected = criteria === selected;
      });
      vm.eventMonitoringContact.notificationCriteria = selected.id;
    };

    vm.attemptNumChanged = function(selected/* : { id: number; selected: boolean } */) {
        vm.monitoringNotificationOptions.attemptNumOptions.forEach((option) => {
            option.selected = option === selected;
        });
        vm.eventMonitoringContact.attempts = selected.id;
    };

    vm.timeZoneChanged = function(selected/* : { id: string; selected: boolean } */) {
      vm.monitoringNotificationOptions.timeZoneOptions.forEach((zone) => {
          zone.selected = zone === selected;
      });
      vm.eventMonitoringContact.timeZone = selected.id;
    };

    vm.getTimeZoneAbbreviation = function(zone/* : { id: string; selected: boolean }*/) {
      return moment.tz(zone.id).format('z');
    };

    vm.addGroupToStaff = function() {
      const group = vm.selectedGroup;
      const exists = jQuery.grep(vm.staffGroupsList, function(x) {
        return group.id == x.id;
      });
      if (exists.length == 0) {
        group.roles = [];
        addGroupToList(group);
      }
      vm.selectedGroup = null;
    };

    function addGroupToList(group) {
      HealthcareProviderService.getGroupRoles(group.id).then(function(response) {
        const item = {id: group.id, name: group.name, roles: response};
        item.roles = populateRoles(item.roles, group.roles);
        vm.staffGroupsList.push(item);
        hcpOrGroupIsRemovedByUser = false;
      });
    }

    function removeGroupFromList(group) {
      const itemIndex = vm.staffGroupsList.indexOf(group);
      if (itemIndex != -1) {
        vm.staffGroupsList.splice(itemIndex, 1);
      }
    }

    function setStaffProvidersRoles() {
      angular.forEach(vm.doctor.healthcareProviders, function(provider) {
        addHealthcareProviderToList(provider);
      });
    }

    vm.addHealthcareProviderToStaff = function() {
      const provider = vm.selectedProvider;
      const exists = jQuery.grep(vm.staffProvidersList, function(x) {
        return provider.id == x.id;
      });
      if (exists.length == 0) {
        provider.roles = [];
        addHealthcareProviderToList(provider);
      }
      vm.selectedProvider = null;
    };

    function addHealthcareProviderToList(provider) {
      HealthcareProviderService.getHealthcareProviderRoles(provider.id).then(function(response) {
        const item = {id: provider.id, name: provider.name, roles: response};
        item.roles = populateRoles(item.roles, provider.roles);
        vm.staffProvidersList.push(item);
        hcpOrGroupIsRemovedByUser = false;
      });
    }

    function removeHealthcareProviderFromList(provider) {
      const itemIndex = vm.staffProvidersList.indexOf(provider);
      if (itemIndex != -1) {
        vm.staffProvidersList.splice(itemIndex, 1);
      }
    }

    function populateRoles(roles, myRoles) {
      jQuery.grep(roles, function(role) {
        role.selected = false;
        if (myRoles !== undefined) {
          jQuery.grep(myRoles, function(selectedRole) {
            if (selectedRole.id === role.id) {
              role.selected = true;
            }
          });
        }
      });

      // Add my roles that not are available anymore
      if (myRoles !== undefined) {
        jQuery.grep(myRoles, function(providerRole) {
          const found = jQuery.grep(roles, function(role) {
            if (providerRole.id === role.id) {
              return true;
            }
          });
          if (found.length == 0) {
            roles.push({id: providerRole.id, name: providerRole.name, selected: true});
          }
        });
      }

      return roles;
    }

    vm.getToolTip = function(role) {
      const text = $translate.instant(('roles_desc_' + role.name).toLowerCase());
      if (text.indexOf('roles_desc_') > -1) {
        return '';
      }
      return text;
    };

    vm.getRoleName = function(role) {
      const text = $translate.instant(('roles_name_' + role.name).toLowerCase());
      if (text.indexOf('roles_name_') > -1) {
        return role.name;
      }
      return text;
    };

    vm.validateInput = function() {
      if (roleExists(vm.staffProvidersList)) {
        return true;
      }
      if (roleExists(vm.staffGroupsList)) {
        return true;
      }
      if (roleExists(vm.unrestrictedRolesList)) {
        return true;
      }
      if (hcpOrGroupIsRemovedByUser && vm.staffProvidersList.length == 0 && vm.staffGroupsList.length == 0 && vm.isEditMode) {
        return true;
      } else {
        return false;
      }
    };

    function roleExists(list) {
      if (list.length > 0) {
        const roles = jQuery.grep(list, function(provider) {
          const tmp = jQuery.grep(provider.roles, function(role) {
            return role.selected === true;
          });
          return tmp.length > 0;
        });

        if (roles.length > 0) {
          return true;
        }
      }
      return false;
    }

    vm.hcpRoleChanged = function() {
      hcpRolesChanged = true;
      hcpOrGroupIsRemovedByUser = false;
    };

    vm.groupRoleChanged = function() {
      groupRolesChanged = true;
      hcpOrGroupIsRemovedByUser = false;
    };

    vm.unrestrictedRoleChanged = function() {
      unrestrictedRoleChanged = true;
      hcpOrGroupIsRemovedByUser = false;
    };

    vm.removeHCPConnection = function(item) {
      hcpOrGroupIsRemovedByUser = true;
      removeHealthcareProviderFromList(item);
    };

    vm.removeGroupConnection = function(item) {
      hcpOrGroupIsRemovedByUser = true;
      removeGroupFromList(item);
    };

    vm.setTab = function(tabId) {
      this.tab = tabId;
    };

    vm.isSet = function(tabId) {
      return this.tab === tabId;
    };

    vm.showInactivateDoctor = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.inactivate.doctor.tpl.html').default,
        controller: 'AdminDoctorCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
          $rootScope.ust();
        });
      });
    };

    vm.inactivateDoctor = function() {
      const id = $rootScope.item.id;
      HealthcareProviderService.deleteDoctor(id).then(function(response) {
        console.log('deleted clinician');
        vm.showFeedback($translate.instant('doctor_create_removed'));
        closeModal();
      });
    };

    vm.setPassword = function() {
      $scope.createDoctorForm.password.$setValidity('', true);
      $scope.createDoctorForm.confirmPassword.$setValidity('', true);
    };

    vm.setIdentificationNo = function() {
      if (vm.configuration.identificationNoMustBeUnique) {
        if (vm.doctor.staffIdentificationNo !== undefined && vm.originalDoctor.staffIdentificationNo !== vm.doctor.staffIdentificationNo) {
          PatientService.getIdentificationNoExists(vm.doctor.staffIdentificationNo).then(function(response) {
            if (response.exist) {
              vm.IdentificationNoExists = true;
              $scope.createDoctorForm.staffIdentificationNo.$setValidity('', false);
            } else {
              vm.IdentificationNoExists = false;
              $scope.createDoctorForm.staffIdentificationNo.$setValidity('', true);
            }
          });
        } else {
          $scope.createDoctorForm.staffIdentificationNo.$setValidity('', true);
        }
      }
      vm.IdentificationNoExists = false;
    };

    vm.checkEmail = function() {
      if (vm.doctor.email !== undefined && vm.doctor.email !== vm.originalDoctor.email) {
        PatientService.getEmailExists(vm.doctor.email).then(function(response) {
          if (response.exist) {
            if (response.existsAsStaff) {
              $scope.createDoctorForm.email.$setValidity('', false);
            } else if (response.existsAsUser) {
              vm.existsAsUser = true;
              vm.showFeedback($translate.instant('doctor_create_appaccount_exists_message'));
              PatientService.getUserDetails(response.userId).then(function(data) {
                vm.doctor.firstname = data.firstname;
                vm.doctor.lastname = data.lastname;
                if (vm.configuration.hasStaffIdentificationNo) {
                  vm.doctor.staffIdentificationNo = data.patientIdentificationNo;
                }
                $scope.createDoctorForm.email.$setValidity('', true);
              });
            }
            vm.emailExists = true;
          } else {
            vm.emailExists = false;
            $scope.createDoctorForm.email.$setValidity('', true);
          }
        });
      }
      vm.emailExists = false;
    };

    vm.saveDoctor = function() {
      vm.activecalls = 0;
      let errorText = '';
      const doctor = {
        firstname: vm.doctor.firstname,
        lastname: vm.doctor.lastname,
        email: vm.doctor.email,
        password: vm.doctor.password,
        confirmPassword: vm.doctor.confirmPassword,
        staffIdentificationNo: vm.doctor.staffIdentificationNo,
        allowedLoginMethods: vm.loginMethods.value,
        hsaId: vm.doctor.hsaId
      };

      if (!vm.isEditMode) {
        vm.storing = true;
        if (vm.existsAsUser) {
          HealthcareProviderService.connect(doctor).then(function(response) {
            vm.activecalls += 1;
            vm.doctor.id = response.id;
            storeRoles(vm.activecalls);
            storeMonitoringSetting(vm.activecalls);
            vm.showFeedback($translate.instant('doctor_create_appaccount_connected'));
            closeModal('Saved', vm.activecalls -= 1);
          }, function(error) {
            vm.storing = false;
            vm.showError($translate.instant('doctor_create_appaccount_error'));
            console.log(error);
          });
        } else {
          HealthcareProviderService.createNewDoctor(doctor).then(function(response) {
            vm.activecalls += 1;
            vm.doctor.id = response.id;
            storeRoles(vm.activecalls);
            storeMonitoringSetting(vm.activecalls);
            vm.showFeedback($translate.instant('doctor_create_created'));
            closeModal('Saved', vm.activecalls -= 1);
          }, function(error) {
            vm.storing = false;
            vm.activecalls -= 1;
            if (error.password) {
              $scope.createDoctorForm.password.$setValidity('', false);
              errorText = error.password;
            }
            if (error.confirmPassword) {
              $scope.createDoctorForm.confirmPassword.$setValidity('', false);
              errorText = error.confirmPassword;
            }
            if (error.staffIdentificationNo) {
              $scope.createDoctorForm.staffIdentificationNo.$setValidity('', false);
              errorText = error.staffIdentificationNo;
            }
            vm.showError($translate.instant('doctor_create_error') + ' ' + errorText);
          });
        }
      } else if (vm.isEditMode) {
        if (vm.doctor.firstname !== vm.originalDoctor.firstname ||
          vm.doctor.lastname !== vm.originalDoctor.lastname ||
          vm.doctor.email !== vm.originalDoctor.email ||
          vm.doctor.staffIdentificationNo !== vm.originalDoctor.staffIdentificationNo ||
          vm.doctor.allowedLoginMethods !== vm.originalDoctor.allowedLoginMethods ||
          vm.doctor.hsaId !== vm.originalDoctor.hsaId) {
          vm.activecalls += 1;
          HealthcareProviderService.updateDoctor(vm.doctor.id, doctor).then(function(response) {
            vm.showFeedback($translate.instant('doctor_create_updated'));
            $rootScope.item = vm.doctor;
            closeModal('Updated', vm.activecalls -= 1);
          }, function(error) {
            vm.storing = false;
            vm.activecalls -= 1;
            if (error.staffIdentificationNo) {
              $scope.createDoctorForm.staffIdentificationNo.$setValidity('', false);
              errorText = error.staffIdentificationNo;
            }
            vm.showError($translate.instant('doctor_create_updated_error') + ' ' + errorText);
          });
        }

        if (vm.doctor.password !== null && vm.doctor.password !== undefined) {
          vm.activecalls += 1;
          PatientService.putUpdateUserPassword(vm.doctor.id, vm.doctor.password, vm.doctor.confirmPassword).then(function(data) {
            vm.showFeedback($translate.instant('user_password_updated'));
            closeModal('Updated', vm.activecalls -= 1);
          }, function(error) {
            vm.activecalls -= 1;
            vm.storing = false;
            if (error.newPassword) {
              $scope.createDoctorForm.password.$setValidity('', false);
              errorText = error.newPassword;
            }
            if (error.confirmPassword) {
              $scope.createDoctorForm.confirmPassword.$setValidity('', false);
              errorText = error.confirmPassword;
            }
            vm.showError($translate.instant('user_password_error') + ' ' + errorText);
          });
        }

        if (vm.loginMethods.value !== vm.originalDoctor.allowedLoginMethods) {
          vm.activecalls += 1;
          PatientService.postUpdateAllowedLoginMethod(vm.doctor.id, vm.loginMethods.value).then(function(data) {
            vm.showFeedback($translate.instant('user_allowed_login_updated'));
            closeModal('Updated', vm.activecalls -= 1);
          });
        }

        storeRoles();
        storeMonitoringSetting();
      }
    };

    function storeMonitoringSetting() {
      if (NationService.isUS()) {
        const current = JSON.stringify(vm.eventMonitoringContact);
        if (eventMonitoringContactOriginalAsJson !== current) {
          if (vm.eventMonitoringContact.monitoringSettingOn == false) {
            vm.eventMonitoringContact = {};
          }
          vm.activecalls += 1;
          HealthcareProviderService.postPortalUserMonitoringServiceContactInfo(vm.doctor.id, vm.eventMonitoringContact).then(function(response) {
            console.log('HCP roles stored');
            closeModal('Updated', vm.activecalls -= 1);
          });
        }
      }
    }

    function storeRoles() {
      if (hcpRolesChanged || hcpOrGroupIsRemovedByUser) {
        storeHCPRoles();
      }

      if (groupRolesChanged || hcpOrGroupIsRemovedByUser) {
        storeGroupRoles();
      }

      if (unrestrictedRoleChanged) {
        storeUnrestrictedRoles();
      }
    }

    function storeHCPRoles() {
      vm.activecalls += 1;
      const request = createRequestObject(vm.staffProvidersList);
      HealthcareProviderService.updateHealthcareProviderUserRoles(vm.doctor.id, request).then(function(response) {
        console.log('HCP roles stored');
        closeModal('Updated', vm.activecalls -= 1);
      });
    }

    function storeGroupRoles() {
      vm.activecalls += 1;
      const request = createRequestObject(vm.staffGroupsList);
      HealthcareProviderService.updateHealthcareProviderGroupUserRoles(vm.doctor.id, request).then(function(response) {
        console.log('Group roles stored');
        closeModal('Updated', vm.activecalls -= 1);
      });
    }

    function storeUnrestrictedRoles() {
      vm.activecalls += 1;
      const request = createRequestObject(vm.unrestrictedRolesList);
      HealthcareProviderService.updateUnrestrictedUserRoles(vm.doctor.id, request).then(function(response) {
        console.log('unrestricted roles stored');
        closeModal('Updated', vm.activecalls -= 1);
      });
    }

    function createRequestObject(list) {
      const request = jQuery.grep(list, function(item) {
        const roles = jQuery.grep(item.roles, function(role) {
          return role.selected;
        });
        item.roles = roles;
        return true;
      });
      return request;
    }

    vm.resetPassword = function() {
      const email = vm.doctor.email;
      PatientService.resetPassword(email).then(function(response) {
        vm.showFeedback($translate.instant('user_resetpassword'));
      }, function(error) {
        vm.showError($translate.instant('user_resetpassword_error'));
      });
    };

    vm.showHCPRoles = function() {
      return vm.providersList !== null && vm.providersList.length > 0;
    };

    vm.showMonitoringSetting = function() {
      return NationService.isUS();
    };

    vm.showAddHealthcareProviders = function() {
      return vm.providersList !== undefined && vm.providersList.length > 1;
    };

    function hasGroupAccess() {
      return SessionService.isUnrestrictedOrGroupUser() && (vm.permissions.group || vm.permissions.coalaAdmin);
    }

    function hasUnrestrictedAccess() {
      return SessionService.isUnrestrictedUser() && vm.permissions.coalaAdmin;
    }

    vm.showGroupRoles = function() {
      return hasGroupAccess();
    };

    vm.showAddGroupRoles = function() {
      return vm.groupList !== null && vm.groupList.length > 0;
    };

    vm.showUnrestrictedRoles = function() {
      return hasUnrestrictedAccess();
    };

    vm.hasInactivateDoctorPermission = function() {
      return vm.permissions.writeStaffUser;
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal(text, activecalls) {
      console.log('vm.activecalls:' + activecalls);
      if (activecalls == 0 || activecalls == undefined) {
        if (!closing) {
          close(text, 500);
          $('#usermodal').modal('hide');
          console.log('Closing modal...');
        }
        closing = true;
      }

    }
  }
})();
