import * as angular from 'angular';
import * as moment from 'moment';
import {NationConfiguration, NationService} from '../../../../services/NationService';

export interface InsuranceDataFormSettings {
    enabled: boolean;
    id?: string;
    primaryCompany?: string;
    primaryMemberId?: string;
    secondaryCompany?: string;
    secondaryMemberId?: string;
    dateOfBirth?: string;
    address?: string;
    city?: string;
    zipcode?: string;
    prescribingMD?: string;
    diagnosisCodes?: string;
    eventmonitoring?: boolean;
    monthlyMonitoring?: boolean;
    comments?: string;
}

// language=HTML
export const template = `
    <form name="$ctrl.insuranceForm">
        <div class="row" ng-show="$ctrl.isUS">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{ "insuranceinfo_title" | translate }}
                </div>

                <div class="col-md-12">
                    <h4>{{"insuranceinfo_desc" | translate}}</h4>
                </div>
                <div class="col-md-12">
                    <label>
                        <input ng-model="$ctrl.settings.enabled" ng-value="true" type="radio">
                        <span>{{"yes" | translate}}</span>
                    </label>
                    <label>
                        <input ng-model="$ctrl.settings.enabled" ng-value="false" type="radio">
                        <span>{{"no" | translate}}</span>
                    </label>
                </div>
                <div class="col-md-12">
                    <p>{{ "insuranceinfo_note" | translate}}</p>
                </div>
            </fieldset>
        </div>

        <div class="row" uib-collapse="!$ctrl.settings.enabled">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"insuranceinfo_details" | translate}}
                </div>
                <div class="col-md-6">
                    <label>{{"insuranceinfo_primary_insurance_company" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_primary_insurance_company' }" ng-model="$ctrl.settings.primaryCompany" ng-required="$ctrl.settings.enabled">
                </div>
                <div class="col-md-6">
                    <label>{{"insuranceinfo_primary_insurance_memberid" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_primary_insurance_memberid' }" ng-model="$ctrl.settings.primaryMemberId" ng-required="$ctrl.settings.enabled">
                </div>
                <br>
                <div class="col-md-6">
                    <label>{{"insuranceinfo_secondary_insurance_company" | translate}}</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_secondary_insurance_company' }" ng-model="$ctrl.settings.secondaryCompany">
                </div>
                <div class="col-md-6">
                    <label>{{"insuranceinfo_secondary_insurance_memberid" | translate}}</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_secondary_insurance_memberid' }" ng-model="$ctrl.settings.secondaryMemberId">
                </div>
            </fieldset>
        </div>

        <div class="row" uib-collapse="!$ctrl.settings.enabled">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"insuranceinfo_code" | translate}}
                </div>
                <div class="col-md-12">
                    <label>
                        <input type="checkbox" ng-change="$ctrl.toggleCheckboxes('eventmonitoring')" ng-model="$ctrl.settings.eventmonitoring" class="radio">
                        <span>{{ "insuranceinfo_event_monitoring" | translate }}</span>
                    </label>
                    <br>
                    <label>
                        <input type="checkbox" ng-change="$ctrl.toggleCheckboxes('monthlyMonitoring')" ng-model="$ctrl.settings.monthlyMonitoring" class="radio">
                        <span>{{ "insuranceinfo_monthly_monitoring" | translate }}</span>
                    </label>
                    <br>
                    <label><input type="checkbox" ng-change="$ctrl.toggleCheckboxes('other')" ng-model="$ctrl.hasComments" class="radio">
                        <span>{{ "insuranceinfo_other" | translate }}</span>
                    </label>
                    <input ng-disabled="!$ctrl.hasComments" autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_comments' }" ng-model="$ctrl.settings.comments" ng-required="$ctrl.hasComments">
                </div>
            </fieldset>
        </div>

        <div class="row" uib-collapse="!$ctrl.settings.enabled">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"patient_details" | translate}}
                </div>

                <div class="col-md-12">
                    <label>{{"insuranceinfo_address" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control" name="address"
                           translate-attr="{ placeholder: 'insuranceinfo_address' }" ng-model="$ctrl.settings.address" ng-required="$ctrl.settings.enabled">
                </div>

                <div class="col-md-6">
                    <label>{{"insuranceinfo_city_state" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control" name="city"
                           translate-attr="{ placeholder: 'insuranceinfo_city_state' }" ng-model="$ctrl.settings.city" ng-required="$ctrl.settings.enabled">
                </div>

                <div class="col-md-6">
                    <label>{{"insuranceinfo_zip" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control" name="zipcode"
                           translate-attr="{ placeholder: 'insuranceinfo_zip' }" ng-model="$ctrl.settings.zipcode" ng-required="$ctrl.settings.enabled">
                </div>

                <div class="col-md-12">
                    <label>{{"patient_date_of_birth" | translate}} *</label><br>
                    <input autocomplete="off" type="text" date='dd-MM-yyyy' class="input-text-lg input-medium blockInput form-control"
                           ng-pattern="$ctrl.configuration.dateOfBirthalidationRegExp" placeholder="{{$ctrl.configuration.dateOfBirthFormat}}"
                           ng-model="$ctrl.settings.dateOfBirth" ng-required="$ctrl.settings.enabled">
                </div>

                <div class="col-md-6">
                    <label>{{"insuranceinfo_prescribing_md" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_prescribing_md' }" ng-model="$ctrl.settings.prescribingMD" ng-required="$ctrl.settings.enabled">
                </div>

                <div class="col-md-6">
                    <label>{{"insuranceinfo_diagnosis_codes" | translate}} *</label><br>
                    <input autocomplete="off" type="text" class="input-text-lg input-medium blockInput form-control"
                           translate-attr="{ placeholder: 'insuranceinfo_diagnosis_codes' }" ng-model="$ctrl.settings.diagnosisCodes" ng-required="$ctrl.settings.enabled">
                </div>

            </fieldset>
        </div>
    </form>
`;

angular.module('admin')
    .component('patientInsuranceForm',
        {
            bindings: {
                isEditMode: '<',
                settings: '=',
                validated: '=',
            },
            template: template,
            controller: ['$translate', 'NationService', class PatientInsuranceForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: InsuranceDataFormSettings;
                insuranceForm: any;

                private translateService: angular.translate.ITranslateService;
                private isUS: boolean;
                private configuration: NationConfiguration;
                private hasComments = false;

                constructor($translate: angular.translate.ITranslateService, NationService: NationService) {
                    this.translateService = $translate;
                    this.isUS = NationService.isUS();
                    this.configuration = NationService.getConfiguration();
                }

                $doCheck() {
                    if (this.insuranceForm) {
                        this.validated = this.insuranceForm.$valid;
                    }
                    if (this.settings) {
                        this.hasComments = this.settings.comments != null || this.settings.comments != undefined;
                    }
                }

                toggleCheckboxes = (type: string) => {
                    if (type == 'eventmonitoring') {
                        this.settings.eventmonitoring = true;
                        this.settings.monthlyMonitoring = false;
                        this.hasComments = false;
                        delete this.settings.comments;
                    } else if (type == 'monthlyMonitoring') {
                        this.settings.eventmonitoring = false;
                        this.settings.monthlyMonitoring = true;
                        this.hasComments = false;
                        delete this.settings.comments;
                    } else if (type == 'other') {
                        this.settings.monthlyMonitoring = false;
                        this.settings.eventmonitoring = false;
                        this.hasComments = true;
                        this.settings.comments = '';
                    }
                };

                validCheckboxInput = () => {
                    return this.settings !== undefined && (this.settings.monthlyMonitoring || this.settings.eventmonitoring || this.hasComments);
                };

                getDateOfBirth(dateOfBirth: string) {
                    const dt = moment(dateOfBirth, this.configuration.dateOfBirthFormat);
                    return dt.format('YYYY-MM-DD');
                }

            }]
        }
    );
