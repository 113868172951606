import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminSerialNumberController', AdminSerialNumberController);

  function AdminSerialNumberController($rootScope, ModalService, HealthcareProviderService, PatientService, SessionService, UtilService, NationService, envConfig) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    let _tableState = null;
    vm.showSpinner = false;

    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      getAllHealthcareProviders();
    }

    vm.fetchSerialNumbers = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = vm.itemsPerPage;
      updateTable();
    };

    function updateTable() {
      if (vm.permissions.readSerialNumber) {
        vm.showSpinner = true;
        PatientService.getDevices(pagingParameters).then(function(data) {
          vm.serialnumbers = data.rows;
          vm.totalSerialNumbers = data.nbrOfRows;
          vm.nrOfPages = data.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
          vm.showSpinner = false;
        });
      }
    }

    vm.createSerialNumberModal = function(isEditMode, item) {
      if (vm.permissions.writeSerialNumber) {
        $rootScope.isEditMode = isEditMode;
        $rootScope.item = item;
        $rootScope.update = function() {
          updateTable();
        };

        if (isEditMode) {
          ModalService.showModal({
            template: require('../views/modals/serialnumber.create.edit.modal.tpl.html').default,
            controller: 'AdminSerialNumberCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            const t = modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateTable();
              console.log(result);
            });
          });
        } else {
          ModalService.showModal({
            template: require('../views/modals/serialnumber.create.edit.modal.tpl.html').default,
            controller: 'AdminSerialNumberCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateTable();
              console.log(result);
            });
          });
        }
      }
    };

    function getAllHealthcareProviders() {
      HealthcareProviderService.getAllHealthcareProviders().then(function(response) {
        $rootScope.providers = response;
        vm.providers = response;
      });
    }
  }
})();
