import * as angular from 'angular';

export interface NavStep {
    id: string;
    title: string;
    done: boolean;
    validated: boolean;
}

export class StepNavigation {
    index = 0;
    steps: NavStep[];
    activeStep: string;
    hiddenStep: any;

    constructor(steps: NavStep[]) {
        this.steps = steps;
        this.activeStep = steps[0].id;
        this.hiddenStep = this.createEmptyStep();
    }

    areAllStepsValidated = () => {
        for (const step of this.steps) {
            if (!step.validated) {
                return false;
            }
        }
        return true;
    }

    getStep = (tabId: string) => {
        const step = this.steps.filter((step) => step.id == tabId)[0];
        if (step) {
            return step;
        }
        return this.hiddenStep;
    }

    createEmptyStep() {
        var emptyStep: NavStep = {
            id: '',
            title: '',
            done: false,
            validated: false
        }; 
        return emptyStep;
    }

    setActiveStep = (index: number) => {
        this.index = index;
        this.activeStep = this.steps[index].id;
    };

    setNextActiveStep = () => {
        this.index += 1;
        this.steps[this.index].done = true;
        this.activeStep = this.steps[this.index].id;
    };

    setPrevActiveStep = () => {
        this.index -= 1;
        this.steps[this.index + 1].done = false;
        this.activeStep = this.steps[this.index].id;
    };

    isActiveStep = (tabId: string) => {
        return this.activeStep === tabId;
    };

    goToDone() {
        this.steps[this.steps.length-1].done = true;
        this.setActiveStep(this.steps.length-1);
    }

    shouldDisplayPrevButton = () => {
        const excludingDone = this.steps.filter((step) => step.id != 'done');
        return this.index > 0 && this.index < excludingDone.length;
    }

    shouldDisplayNextButton = () => {
        const excludingDone = this.steps.filter((step) => step.id != 'done');
        return this.index < excludingDone.length - 1;
    }

    shouldDisplayFinishButton = () => {
        const excludingDone = this.steps.filter((step) => step.id != 'done');
        return this.index == excludingDone.length - 1;
    }
}

angular.module('core')
    .component('navSteps',
        {
            bindings: {
                stepNavigation: '=',
            },
            template: `
                <div class="nav-num">
                    <div class="step-lines">
                        <div class="step-line" ng-repeat="step in $ctrl.stepNavigation.steps" ng-class="step.done ? 'done' : ''"
                           ng-style="{ 'width' : $index != 0 ? (100 / $ctrl.stepNavigation.steps.length) + '%' : (50 / $ctrl.stepNavigation.steps.length) + '%' }">
                        </div>
                    </div>
                    <div class="step-num">
                        <div ng-repeat="step in $ctrl.stepNavigation.steps" ng-style="{ 'width' : (100 / $ctrl.stepNavigation.steps.length) + '%'}">
                            <span ng-show="(!step.validated || !step.done) && step.id != 'done'" ng-class="step.done ? 'done' : ''">{{$index + 1}}</span>
                            <span ng-show="step.validated && step.done || step.id == 'done'" aria-hidden="true" class="fa fa-check" style="font-size: 15px" ng-class="step.done ? 'done' : ''"></span>
                        </div>
                    </div>
                    <div class="step-text">
                        <div ng-repeat="step in $ctrl.stepNavigation.steps"
                            ng-style="{ 'font-weight' : $index === $ctrl.stepNavigation.index ? 'bold' : 'normal', 'width' : (100 / $ctrl.stepNavigation.steps.length) + '%'}">
                            {{step.title}}
                        </div>
                    </div>
                </div>
            `,
            controller: class NavStepsController {
                stepNavigation?: StepNavigation;
            }
        }
    );
