import * as angular from 'angular';
import {saveAs} from 'file-saver';

(function() {
  'use strict';

  function HttpClientService($q, $state, $http, $window, envConfig, SessionService) {
    const service = {};
    const root = envConfig.baseUrl;

    service.get = function(url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;
        const separator = url.indexOf('?') === -1 ? '?' : '&';
        $http.get(url + separator + new Date().getTime(), {
          cache: false,
        })
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject(data);
          });
      });
      return deferred.promise;
    };

    service.patch = function(url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.patch(url, {
          cache: false,
        })
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject(data);
          });
      });
      return deferred.promise;
    };


    service.getPdf = function(filename, url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.get(url, {
          cache: false,
          responseType: 'arraybuffer'
        }).success(function(data, status, headers) {
          const octetStreamMime = 'application/pdf';
          headers = headers();

          if (!filename) {
            filename = headers['X-FileName'] || 'ecg.pdf';
          }

          const contentType = headers['Content-Type'] || octetStreamMime;

          const blob = new Blob([data], {type: contentType});
          saveAs(blob, filename);
          deferred.resolve(data);
        }).error(function(data) {
          console.error(data);
          deferred.reject(data);
        });
      });
      return deferred.promise;
    };

    service.getRawFile = function(filename, url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.get(url, {
          cache: false,
          responseType: 'arraybuffer'
        }).success(function(data, status, headers) {
          const octetStreamMime = 'application/octet-stream';
          headers = headers();

          const contentType = headers['Content-Type'] || octetStreamMime;

          const blob = new Blob([data], {type: contentType});
          saveAs(blob, filename);
          deferred.resolve(data);
        }).error(function(data) {
          console.error(data);
          deferred.reject(data);
        });
      });
      return deferred.promise;
    };

    service.getPdfWithBody = function(filename, body, url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.post(url, body, {
          cache: false,
          responseType: 'arraybuffer'
        }).success(function(data, status, headers) {
          const octetStreamMime = 'application/pdf';
          headers = headers();

          if (!filename) {
            filename = headers['X-FileName'] || 'ecg.pdf';
          }

          const contentType = headers['Content-Type'] || octetStreamMime;

          const blob = new Blob([data], {type: contentType});
          saveAs(blob, filename);
          deferred.resolve(data);
        }).error(function(data) {
          console.error(data);
          deferred.reject(data);
        });
      });
      return deferred.promise;
    };

    service.getWithoutAuthToken = function(url) {
      const deferred = $q.defer();

      $http.get(url, {
        cache: false,
      })
        .success(function(data) {
          deferred.resolve(data);
        })
        .error(function(data) {
          deferred.reject(data);
        });
      return deferred.promise;
    };

    service.post = function(url, obj) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.post(url, obj)
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject(data);
          });
      });
      return deferred.promise;
    };

    service.postWithoutBody = function(url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.post(url, {
          cache: false,
        })
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject(data);
          });
      });
      return deferred.promise;
    };

    service.postWithoutAuthToken = function(url, obj, contentType) {
      const deferred = $q.defer();

      $http.post(url, obj, {
        cache: false,
        headers: {'Content-Type': contentType}
      })
        .success(function(data) {
          deferred.resolve(data);
        })
        .error(function(data) {
          deferred.reject(data);
        });

      return deferred.promise;
    };

    service.postWithoutBodyAndAuthToken = function(url, obj) {
      const deferred = $q.defer();

      $http.post(url, {
        cache: false,
      })
        .success(function(data) {
          deferred.resolve(data);
        })
        .error(function(data) {
          deferred.reject(data);
        });

      return deferred.promise;
    };

    service.postWithoutBodyAndAuthToken = function(url, obj) {
      const deferred = $q.defer();

      $http.post(url, {
        cache: false,
      })
        .success(function(data) {
          deferred.resolve(data);
        })
        .error(function(data) {
          deferred.reject(data);
        });

      return deferred.promise;
    };

    service.put = function(url, obj) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.put(url, obj)
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject(data);
          });
      });
      return deferred.promise;
    };

    service.delete = function(url) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.delete(url)
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject();
          });
      });
      return deferred.promise;
    };

    service.deleteWithBody = function(url, obj) {
      const deferred = $q.defer();

      service.authToken().then(function(token) {
        $http.defaults.headers.common.Authorization = token;

        $http.delete(url, {
          data: obj,
          cache: false,
          headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function(data) {
            deferred.reject();
          });
      });
      return deferred.promise;
    };

    service.authToken = function() {
      const deferred = $q.defer();
      let isResolved = false;

      service.isInMaintenanceMode();

      const now = new Date();
      console.log('Now: ' + now);
      now.setMinutes(now.getMinutes() + 1);
      const tokenValidTo = SessionService.getAuthTokenValidTo();
      console.log('authToken - validTo: ' + tokenValidTo);
      if (tokenValidTo > now) {
        const token = SessionService.getAuthToken();
        if (token === null) {
          service.renewAuthToken().then(function(token) {
            deferred.resolve(token);
            isResolved = true;
          });
        } else {
          deferred.resolve(token);
          isResolved = true;
        }
      }
      if (!isResolved) {
        service.renewAuthToken().then(function(token) {
          deferred.resolve(token);
        });
      }

      return deferred.promise;
    };

    service.renewAuthToken = function() {
      const deferred = $q.defer();

      if (SessionService.getRefreshToken !== null) {
        console.log('AuthToken: ' + SessionService.getAuthToken());
        const url = root + '/api/v1/account/token';
        const obj = {'grant_type': 'refresh_token', 'refresh_token': SessionService.getRefreshToken()};

        service.postWithoutAuthToken(url, obj, 'application/x-www-form-urlencoded').then(function(response) {
          SessionService.setAuthToken(response.access_token);
          SessionService.setAuthTokenValidTo(response.expires);
          SessionService.setRefreshToken(response.refresh_token);
          console.log('New authToken: ' + SessionService.getAuthToken());
          console.log('New authToken - validTo: ' + SessionService.getAuthTokenValidTo());

          deferred.resolve(response.access_token);
        });
      }

      return deferred.promise;
    };

    service.logOut = function() {
      SessionService.clearAll();
      console.log('Logged out due to AuthToken was null');

      $state.go('login');
    };

    service.isInMaintenanceMode = function() {
      if (envConfig.maintenanceMode) {
        $state.go('maintenance');
      }
    };

    return service;
  }

  angular
    .module('core')
    .factory('HttpClientService', HttpClientService);

})();
