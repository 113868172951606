import * as angular from 'angular';
import * as de from '../../resources/locale-de.json';
import * as enUs from '../../resources/locale-en-us.json';
import * as en from '../../resources/locale-en.json';
import * as fr from '../../resources/locale-fr.json';
import * as it from '../../resources/locale-it.json';
import * as nl from '../../resources/locale-nl.json';
import * as sv from '../../resources/locale-sv.json';

angular.module('core', ['pascalprecht.translate', 'ngCookies'])
    .config(['$provide', '$httpProvider', '$translateProvider', function($provide: any, $httpProvider: any, $translateProvider: any) {

        $translateProvider.translations('de', de);
        $translateProvider.translations('en-us', enUs);
        $translateProvider.translations('en', en);
        $translateProvider.translations('fr', fr);
        $translateProvider.translations('it', it);
        $translateProvider.translations('nl', nl);
        $translateProvider.translations('sv', sv);
        $translateProvider.registerAvailableLanguageKeys(['en-us', 'en', 'sv', 'fr', 'it', 'de', 'nl'], {
            'en-us': 'en-us',
            'en_*': 'en',
            'sv_*': 'sv',
            'fr_*': 'fr',
            'it_*': 'it',
            'de_*': 'de',
            'nl_*': 'nl',
            '*': 'en'
        }).determinePreferredLanguage().fallbackLanguage('en');

        // $translateProvider.determinePreferredLanguage();

        $translateProvider.useSanitizeValueStrategy(null);

        $translateProvider.useCookieStorage();

        // Intercept http calls.
        $provide.factory('MyHttpInterceptor', ['$q', '$injector', function($q: any, $injector: any) {
            return {
                // On request success
                request: function(config: any) {
                    // if(!(config.data !== undefined && config.data.grant_type === 'refresh_token')){
                    const sessionService = $injector.get('SessionService');
                    sessionService.setLastActivity();
                    // }

                    // Return the config or wrap it in a promise if blank.
                    return config || $q.when(config);
                },

                // On request failure
                requestError: function(rejection: any) {
                    console.log(rejection); // Contains the data about the error on the request.

                    // Return the promise rejection.
                    return $q.reject(rejection);
                },

                // On response success
                response: function(response: any) {
                    // Return the response or promise.
                    return response || $q.when(response);
                },

                // On response failture
                responseError: function(rejection: any) {
                    console.log(rejection); // Contains the data about the error.

                    // Return the promise rejection.
                    // if(rejection.status === 401){
                    //   var authService = $injector.get("AuthService");
                    //   authService.logOut();
                    //   return;
                    // }

                    return $q.reject(rejection);
                }
            };
        }]);

        // Add the interceptor to the $httpProvider.
        $httpProvider.interceptors.push('MyHttpInterceptor');
    }]);
