// Generated by CoffeeScript 1.10.0
(function() {
  (function($) {
    var all, defaultOptions, drawHBar, drawHBars, init, initHBar, resize;
    all = function(values) {
      var i, len, v;
      for (i = 0, len = values.length; i < len; i++) {
        v = values[i];
        if (!v) {
          return false;
        }
      }
      return true;
    };
    defaultOptions = {
      hbar: {
        'class': 'jquery-flot-hbar',
        wrapperCss: {
          'position': 'absolute',
          'display': 'block',
          'margin': '0',
          'line-height': '1em',
          'background-color': '#000',
          'color': 'white',
          'padding': '2px 0',
          'font-size': 'xx-small',
          'box-sizing': 'border-box',
          'text-align': 'center',
          'white-space': 'nowrap',
          'overflow-x': 'hide'
        },
        htmlTemplate: function(text, width, height) {
          var more;
          more = height > 0 ? "height: " + height + "px;" : 'min-height: 1em;';
          return "<div class=\"" + this['class'] + "\" style=\"width: " + width + "px;" + more + "\">" + (text || '') + "</div>";
        },
        show: true,
        position: {
          offsetX: 0,
          offsetY: 0,
          x: function(x) {
            return {
              'left': x + parseFloat(this.offsetX || 0)
            };
          },
          y: function(y) {
            return {
              'top': y + parseFloat(this.offsetY || 0)
            };
          }
        }
      }
    };
    initHBar = function(plot) {
      plot.hooks.draw.push(drawHBars);
    };
    drawHBars = function(plot) {
      var hbar, hbars, i, len, options;
      hbars = plot.getOptions().hbars;
      if (!hbars) {
        return;
      }
      options = plot.getOptions().hbar || defaultOptions;
      $(plot.getPlaceholder()).find('.' + options["class"]).remove();
      if ($.isArray(hbars) && options.show) {
        for (i = 0, len = hbars.length; i < len; i++) {
          hbar = hbars[i];
          drawHBar.apply(plot, [hbar, options]);
        }
      }
    };
    drawHBar = function(hbar, options) {
      var axes, canvasX, canvasY, dX, dY, height, html, i, k, len, plot, ref, ref1, ref2, ref3, tX, text, v, width, wrapperCss, x0, x1, xaxis, y0, y1, yaxis;
      plot = this;
      axes = plot.getAxes();
      xaxis = axes.xaxis;
      yaxis = axes.yaxis;
      if (hbar.x.length !== 2 || hbar.x[1] < xaxis.min || hbar.x[0] > xaxis.max) {
        return;
      }
      dX = hbar.dX || options.dX;
      if (dX && dX < (xaxis.max - xaxis.min)) {
        return;
      }
      dY = hbar.dY || options.dY;
      if (dY && dY < (yaxis.max - yaxis.min)) {
        return;
      }
      ref = [Math.max(hbar.x[0], xaxis.min), Math.min(hbar.x[1], xaxis.max)], x0 = ref[0], x1 = ref[1];
      ref1 = [Math.max(hbar.y[0], yaxis.min), Math.min(hbar.y[1], yaxis.max)], y0 = ref1[0], y1 = ref1[1];
      width = xaxis.p2c(x1) - xaxis.p2c(x0);
      height = 12;
      tX = hbar.tX || options.tX;
      if (hbar.text && (!tX || tX < (xaxis.max - xaxis.min) || (x1 - x0) < (hbar.x[1] - hbar.x[0]) / 2)) {
        text = '&nbsp;';
      } else {
        text = hbar.text;
      }
      html = options.htmlTemplate(text, width, height);
      canvasX = xaxis.p2c(x0) + plot.getPlotOffset().left + (hbar.offsetX || 0);
      canvasY = y0;
      wrapperCss = {};
      ref2 = options.wrapperCss;
      for (k in ref2) {
        v = ref2[k];
        wrapperCss[k] = hbar[k] ? hbar[k] : v;
      }
      ref3 = ['opacity'];
      for (i = 0, len = ref3.length; i < len; i++) {
        k = ref3[i];
        if (hbar[k]) {
          wrapperCss[k] = hbar[k];
        }
      }
      $(html).css(wrapperCss).css(options.position.x(canvasX)).css(options.position.y(canvasY)).prependTo(plot.getPlaceholder());
    };
    resize = function(plot) {
      plot.resize(plot.width() * (1 - maxWidth));
      plot.setupGrid();
      plot.draw();
    };
    init = function(plot) {
      var i, len, onResize, p, ref;
      onResize = function() {
        setTimeout((function() {
          drawHBars(plot);
        }), 200);
      };
      initHBar(plot);
      plot.drawHBar = drawHBar;
      ref = $.plot.plugins;
      for (i = 0, len = ref.length; i < len; i++) {
        p = ref[i];
        if (p.name === 'resize') {
          plot.hooks.bindEvents.push(function() {
            plot.getPlaceholder().resize(onResize);
          });
          plot.hooks.shutdown.push(function() {
            plot.getPlaceholder().unbind('resize', onResize);
          });
          break;
        }
      }
    };
    $.plot.plugins.push({
      init: init,
      options: defaultOptions,
      name: 'hbars',
      version: '0.3'
    });
  })(jQuery);

}).call(this);
