import * as angular from 'angular';
import {NationService} from '../../../../services/NationService';
import Permissions from '../../../../services/models/Permissions';
import {IdNameSelectable} from '../../controllers/admin.healthcareProvider.create.edit.controller';
import HealthcareProviderResponse from '../../../../services/models/HealthcareProviderResponse';


export interface HcpDetailsFormSettings {
    healthcareProvider: HealthcareProviderResponse;
    selectedGroup?: IdNameSelectable;
    groupList: IdNameSelectable[];
    useDefaultConfigurations: boolean;
    configurations: IdNameSelectable[];
    selectedConfigurations: IdNameSelectable[];
}

// language=HTML
const template = `
    <form name="$ctrl.form">
        <div class="row">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"healthcare_details" | translate}}
                </div>
                <div class="col-md-12">
                    <label>{{"healthcare_create_name" | translate}} *</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.name"
                           required
                           translate-attr="{ placeholder: 'healthcare_create_name' }" type="text">
                </div>
                <div class="col-md-6">
                    <label>{{"healthcare_create_phone" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.phone"
                            ng-required="$ctrl.isUS"
                           translate-attr="{ placeholder: 'healthcare_create_phone' }"
                           type="text">
                </div>
                <div class="col-md-6">
                    <label>{{"healthcare_create_email" | translate}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control"
                           ng-model="$ctrl.settings.healthcareProvider.email"
                           ng-pattern="/^[-a-z0-9~!$%^&*_=+}{\\'?]+(\\.[-a-z0-9~!$%^&*_=+}{\\'?]+)*@([a-z0-9_][-a-z0-9_]*(\\.[-a-z0-9_]+)*\\.([a-z]{2,10}))?$/i"
                           translate-attr="{ placeholder: 'healthcare_create_email' }"
                           type="email">
                </div>
                <div class="col-md-12">

                    <label>{{"healthcare_create_address1" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.addressLine1"
                            ng-required="$ctrl.isUS"
                           translate-attr="{ placeholder: 'healthcare_create_address1' }"
                           type="text">
                </div>

                <div class="col-md-6">
                    <label>{{"healthcare_create_address2" | translate}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.addressLine2"
                           translate-attr="{ placeholder: 'healthcare_create_address2' }"
                           type="text">
                    <label>{{"healthcare_create_zip" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.zipcode"
                            ng-required="$ctrl.isUS"
                           translate-attr="{ placeholder: 'healthcare_create_zip' }" type="text">
                </div>
                <div class="col-md-6">
                    <label>{{"healthcare_create_city" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.city"
                            ng-required="$ctrl.isUS"
                           translate-attr="{ placeholder: 'healthcare_create_city' }"
                           type="text">
                    <label>{{"healthcare_create_region" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.region"
                        ng-required="$ctrl.isUS"
                        translate-attr="{ placeholder: 'healthcare_create_region' }" type="text">

                </div>

                <div class="col-md-6" ng-show="$ctrl.isUS">
                    <label>{{"healthcare_create_territory" | translate}} *</label><br>
                    <input class="input-text-lg input-medium blockInput form-control" ng-model="$ctrl.settings.healthcareProvider.territory"
                        ng-required="$ctrl.isUS"
                        translate-attr="{ placeholder: 'healthcare_create_territory' }"
                        type="text">
                </div>

                <div class="col-md-6" ng-show="$ctrl.showGroups()">
                    <label>{{"doctor_create_select_group" | translate}}</label><br>
                    <select class="input-text-lg input-medium blockInput form-control paddingLeft14" id="fav_show"
                            name="fav_show"
                            ng-model="$ctrl.settings.selectedGroup"
                            ng-options="provider.name for provider in $ctrl.settings.groupList track by provider.id">
                        <option ng-show="$ctrl.allowEmptyGroup()" value="">{{ "doctor_create_select_group" | translate
                            }}
                        </option>
                    </select>
                </div>
            </fieldset>
        </div>


        <!-- MEASUREMENT CONFIGURATIONS-->
        <div class="row" ng-show="$ctrl.permissions.readMeasurementConfiguration">
            <div class="col-md-12 title">
                {{ "user_create_configurations" | translate }}
            </div>
            <div class="col-md-12">
                <label><input ng-model="$ctrl.settings.useDefaultConfigurations" type="checkbox"><span>{{
                  "healthcare_create_usedefaultconfigurations" | translate }}</span></label>
            </div><!-- end row -->
            <div class="col-md-12" uib-collapse="$ctrl.settings.useDefaultConfigurations">
                <span class="coalaFont16">
                  {{ "user_create_configurations" | translate }}
                </span>
                <fieldset class="form-inline" ng-disabled="!$ctrl.permissions.writeMeasurementConfiguration">
                    <multiple-autocomplete after-select-item="$ctrl.afterSelectItem" id="userconfigurations"
                                           ng-model="$ctrl.settings.selectedConfigurations" object-property="name"
                                           suggestions-arr="$ctrl.settings.configurations">
                    </multiple-autocomplete>
                </fieldset>
            </div><!-- end row -->
        </div>

        <!-- OTHER SETTINGS-->
        <div class="row" ng-show="$ctrl.permissions.coalaAdmin || $ctrl.permissions.canSetBankIdOnMyJournal">
            <div class="col-md-12 title">
                {{ "general_other_settings" | translate }}
            </div>
            <div class="col-md-12" ng-show="$ctrl.permissions.coalaAdmin">
                <label><input ng-model="$ctrl.settings.healthcareProvider.genarallyAccepted" type="checkbox"><span>{{
                  "healthcare_create_approvedbyall" | translate }}</span></label>
            </div>
            <div class="col-md-12" ng-show="$ctrl.permissions.coalaAdmin">
                <label><input ng-model="$ctrl.settings.healthcareProvider.serialNumberFiltering" type="checkbox"><span>{{
                    "healthcare_create_serialnumberfiltering" | translate }}</span></label>
            </div>
            <div class="col-md-12" ng-show="$ctrl.permissions.canSetBankIdOnMyJournal">
                <label><input ng-model="$ctrl.settings.healthcareProvider.myJournalRequeriesAuth" type="checkbox"><span>{{
                  "healthcare_create_myjournalrequeriesauth" | translate }}</span></label>
            </div>
        </div>
    </form>
`;

angular.module('admin')
    .component('hcpDetailsForm',
        {
            bindings: {
                isEditMode: '<',
                validated: '=',
                settings: '='
            },
            template: template,
            controller: ['$translate', 'SessionService', 'NationService', class HcpDetailsForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: HcpDetailsFormSettings;
                form: any;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly permissions: Permissions;
                private readonly isUS: boolean;
                private readonly isUnrestricted: boolean;

                constructor($translate: angular.translate.ITranslateService, SessionService: any, NationService: NationService) {
                    this.translateService = $translate;
                    this.permissions = SessionService.getUserPermissions();
                    this.isUnrestricted = SessionService.isUnrestrictedUser();
                    this.isUS = NationService.isUS();
                }

                $doCheck() {
                    if (this.form) {
                        this.validated = this.form.$valid;
                    }
                }

                showGroups = () => {
                    return this.settings.groupList !== null && this.settings.groupList.length > 0;
                };

                allowEmptyGroup = () => {
                    return this.isUnrestricted;
                };

                afterSelectItem = (item: IdNameSelectable) => {
                    console.log('Passed - After Select Item: ' + item.id);
                    // @ts-ignore
                    this.settings.useDefaultConfigurations = false;
                };
            }]
        }
    );
