import * as angular from 'angular';
import * as moment from 'moment';
import {MonitoringServiceDuration, MonitoringServiceResponse} from '../services/models/HealthcareMonitoringServiceProviderResponse';
import {MonitoringServiceCollectionServicesResponse, MonitoringServiceDurationResponse, MonitoringServiceRequest} from '../services/models/MonitoringServiceCollection';
import {MonitoringServiceUserRelationshipCollectionPartResponse} from "../services/models/MonitoringServiceUserRelationshipCollectionResponse";

// API enum
enum MonitoringTypeKey {
    EventMonitoring = <any>'EventMonitoring',
    ManagedRpm = <any>'ManagedRpm',
    Rpm = <any>'Rpm'
}

// API enum
enum DurationType {
    Days = 'Days',
    Months = 'Months',
    Indefinitely = 'Indefinitely',
}

interface Duration {
    id: string;
    type: DurationType;
    title: string;
    enabled: boolean;
}

/**
 * Used to translate, sort and hold Monitoring Service Providers and their durations.
 */
export class MonitoringService {
    private readonly translateService: angular.translate.ITranslateService;
    id: string;
    name: string;
    type: MonitoringTypeKey;
    enabled: boolean;
    hidden: boolean;
    language: string;
    durations: Duration[] = [];

    constructor(id: string, name: string, type: string, translateService: angular.translate.ITranslateService, monitoringServiceDurations: MonitoringServiceDuration[] | MonitoringServiceDurationResponse[]) {
        this.translateService = translateService;
        this.id = id;
        this.name = name;
        // @ts-ignore
        this.type = MonitoringTypeKey[type];
        this.enabled = false;
        this.hidden = false;
        this.language = translateService.use();

        const sortedDurations = monitoringServiceDurations.sort((dur1, dur2) => {
            return (dur1.duration > 0 ? dur1.duration : Infinity) - (dur2.duration > 0 ? dur2.duration : Infinity);
        });
        this.durations = sortedDurations.map((duration) => {
            const day = this.translateService.instant('general_day');
            const days = this.translateService.instant('general_days');
            const untilCancelled = this.translateService.instant('monitoring_service_until_cancelled');

            let title = '';
            let durationTypeName = '';
            if ('monitoringServiceDurationType' in duration) {
                durationTypeName = duration.monitoringServiceDurationType.name;
            } else if ('monitoringServicePartDurationDurationType' in duration) {
                durationTypeName = duration.monitoringServicePartDurationDurationType.name;
            }
            switch (durationTypeName) {
                case DurationType.Days:
                    title = duration.duration > 1 ? duration.duration + ' ' + days : duration.duration + ' ' + day;
                    break;
                case DurationType.Months:
                    title = this.getLocalizedDurationString(duration.duration * 30);
                    break;
                case DurationType.Indefinitely:
                    title = untilCancelled;
                    break;
            }
            return {
                id: duration.id,
                type: (<any>DurationType)[durationTypeName],
                title: title,
                enabled: false
            };
        });
        this.durations[this.durations.length -1].enabled = true;
    }

    getName(): string {
        return this.type.toString().replace(/([a-z])([A-Z])/g, '$1 $2').replace('Rpm', 'RPM');
    }

    getLocalizedDurationString(days: number): string {
        const aPronoun = this.translateService.instant('general_a_pronoun');
        moment.locale(this.language);
        const start = moment(0);
        const end = moment(days*1000*60*60*24);
        let localized = end.from(start, true);
        localized = localized.replace(aPronoun + ' ', '1 ');
        return localized.charAt(0).toUpperCase() + localized.slice(1);
    }

    public getMonitoringServiceResponse(order: number): MonitoringServiceRequest {
        const durations: {id: string}[] = [];
        for (const duration of this.durations) {
            if (duration.enabled) {
                durations.push({id: duration.id});
            }
        }
        return {
            id: this.id,
            order: order,
            monitoringServiceDurations: durations};
    }
}

/**
 * External (not Coala) providers for monitoring services
 */
export class MonitoringServiceProvider {
    id: string;
    name: string;
    enabled: boolean;
    services: MonitoringService[];

    constructor(id: string, name: string, services: MonitoringServiceResponse[], translateService: angular.translate.ITranslateService) {
        this.id = id;
        this.name = name;
        this.enabled = false;
        this.services = services.map((service) => new MonitoringService(service.id, service.name, service.monitoringServiceType.name, translateService, service.monitoringServiceDurations));
        //this.services[0] && (this.services[0].enabled = true);
    }
}

/**
 * Handler for Patient monitoring services and the chain of options to be triggered by UI selections
 */
export class PatientEventMonitoringServicesHandler {
    public hasChanges = false;
    public afterMonitorServiceSelectionEnabled = false
    public postMonitoringInactivate = false;

    public eventMonitoring!: MonitoringService;
    public afterMonitoringRpm!: MonitoringService;
    public rpm!: MonitoringService;
    public noMonitoringService = false;

    public static isEventMonitoringServiceCollection(services: MonitoringServiceCollectionServicesResponse[]) {
        for (const service of services) {
            if (service.monitoringServicePartServiceType.name == MonitoringTypeKey[MonitoringTypeKey.EventMonitoring]) return true;
        }
        return false;
    }

    addMonitoringServices(services: MonitoringService[]) {
        for (const service of services) {
            switch (service.type.toString()) {
                case MonitoringTypeKey[MonitoringTypeKey.EventMonitoring]:
                    this.eventMonitoring = service;
                    break;
                default:
                    this.afterMonitoringRpm = service;
                    break;
            }
        }
    }

    addRpmServices(services: MonitoringService[]) {
        if (services.length > 0) {
            this.rpm = services[0];
        }
    }

    setDefaultPreselectedService(editMode: boolean) {
        if (editMode) {
            this.noMonitoringService = true;
        }
        else if (this.eventMonitoring) {
            this.eventMonitoring.enabled = true;
            this.eventMonitoringEnabled();
        } else if (this.rpm) {
            this.rpm.enabled = true;
            this.rpmEnabled();
        }
    }

    setPatientSelectedServices(services: MonitoringServiceUserRelationshipCollectionPartResponse[]) {
        // afterMonitoringRpm and rpm can have the same id so logic has to be based on number of rows in array.
        if (services.length > 1) {
            for (const monitoringService of services) {
                if (this.eventMonitoring && this.eventMonitoring.id == monitoringService.monitoringServiceId) {
                    this.eventMonitoring.enabled = true;
                    const monitoringServiceDurationId = monitoringService.monitoringServiceDurationId;
                    for (const duration of this.eventMonitoring.durations) {
                        duration.enabled = duration.id == monitoringServiceDurationId;
                    }
                }
                if (this.afterMonitoringRpm && this.afterMonitoringRpm.id == monitoringService.monitoringServiceId) {
                    this.afterMonitoringRpm.enabled = true;
                    const monitoringServiceDurationId = monitoringService.monitoringServiceDurationId;
                    for (const duration of this.afterMonitoringRpm.durations) {
                        duration.enabled = duration.id == monitoringServiceDurationId;
                    }
                }
            }
        } else if (services.length == 1) {
            if (this.eventMonitoring && this.eventMonitoring.id == services[0].monitoringServiceId) {
                this.eventMonitoring.enabled = true;
                const monitoringServiceDurationId = services[0].monitoringServiceDurationId;
                for (const duration of this.eventMonitoring.durations) {
                    duration.enabled = duration.id == monitoringServiceDurationId;
                }
            }
            if (this.rpm && this.rpm.id == services[0].monitoringServiceId) {
                this.rpm.enabled = true;
                const monitoringServiceDurationId = services[0].monitoringServiceDurationId;
                for (const duration of this.rpm.durations) {
                    duration.enabled = duration.id == monitoringServiceDurationId;
                }
            }
        }

        if (this.eventMonitoring && this.eventMonitoring.enabled) {
            this.afterMonitorServiceSelectionEnabled = false;
            this.eventMonitoring.durations.forEach((duration) => {
                if (duration.type != DurationType.Indefinitely && duration.enabled) {
                    this.afterMonitorServiceSelectionEnabled = true;
                }
            });
        }
        this.postMonitoringInactivate = !this.afterMonitoringRpm || !this.afterMonitoringRpm.enabled;
    }

    rpmEnabled() {
        this.noMonitoringService = false;
        if (this.eventMonitoring) this.eventMonitoring.enabled = false;
        if (this.afterMonitoringRpm) this.afterMonitoringRpm.enabled = false;
        this.afterMonitorServiceSelectionEnabled = false;
        this.monitoringSettingsChanged();
    }

    eventMonitoringEnabled() {
        this.noMonitoringService = false;
        if (this.rpm) this.rpm.enabled = false;
        this.monitoringDurationChanged(this.eventMonitoring.id, this.eventMonitoring.durations[0].id);
        this.monitoringSettingsChanged();
    }

    disabled() {
        if (this.eventMonitoring) this.eventMonitoring.enabled = false;
        if (this.afterMonitoringRpm) this.afterMonitoringRpm.enabled = false;
        this.afterMonitorServiceSelectionEnabled = false;
        if (this.rpm) this.rpm.enabled = false;
        this.monitoringSettingsChanged();
    }

    /**
     * Triggered on change in monitoring form
     */
    monitoringSettingsChanged() {
        this.hasChanges = true;

        if (this.afterMonitoringRpm) this.afterMonitoringRpm.enabled = !this.postMonitoringInactivate;

        if (this.afterMonitoringRpm && this.afterMonitoringRpm.enabled) {
            this.postMonitoringInactivate = false;
        }

        if (!this.afterMonitorServiceSelectionEnabled) {
            if (this.afterMonitoringRpm) this.afterMonitoringRpm.enabled = false;
        }
    }

    monitoringDurationChanged(serviceId: string, durationId: string) {
        this.hasChanges = true;

        // A duration must always be set
        for (const service of this.getServices()) {
            if (service.id == serviceId) {
                for (const duration of service.durations) {
                    duration.enabled = duration.id == durationId;
                }
            }
        }

        if (this.eventMonitoring.enabled) {
            // If a limited time is set on Event monitoring then enable selection of what happens after
            this.afterMonitorServiceSelectionEnabled = false;
            this.eventMonitoring.durations.forEach((duration) => {
                if (duration.type != DurationType.Indefinitely && duration.enabled) {
                    this.afterMonitorServiceSelectionEnabled = true;
                    this.afterMonitoringRpm.enabled = true;
                }
            });
        }

        this.monitoringSettingsChanged();
    }


    getServices(): MonitoringService[] {
        return [
            ...(this.eventMonitoring ? [this.eventMonitoring] : []),
            ...(this.afterMonitoringRpm ? [this.afterMonitoringRpm] : []),
            ...(this.rpm ? [this.rpm] : [])
        ];
    }
}

/**
 * Handler for HCP monitoring services and the chain of options to be triggered by UI selections
 */
export class EventMonitoringServicesHandler {
    public hasChanges = false;
    public afterMonitorServiceSelectionEnabled = false
    public postMonitoringRpmEnabled = false;
    public managedMonitoringByCoala = true;

    public eventMonitoring!: MonitoringService;
    public managedRpm!: MonitoringService;
    public rpm!: MonitoringService;

    public static isEventMonitoringServiceCollection(services: MonitoringServiceCollectionServicesResponse[]) {
        for (const service of services) {
            if (service.monitoringServicePartServiceType.name == MonitoringTypeKey[MonitoringTypeKey.EventMonitoring]) return true;
        }
        return false;
    }

    addServices(services: MonitoringService[]) {
        for (const service of services) {
            switch (service.type.toString()) {
                case MonitoringTypeKey[MonitoringTypeKey.EventMonitoring]:
                    this.eventMonitoring = service;
                    break;
                case MonitoringTypeKey[MonitoringTypeKey.ManagedRpm]:
                    this.managedRpm = service;
                    break;
                case MonitoringTypeKey[MonitoringTypeKey.Rpm]:
                    this.rpm = service;
                    break;
            }
        }
    }

    /**
     * Triggered on change in monitoring form
     */
     monitoringSettingsEnabled() {
         if (this.eventMonitoring.enabled == true) {
             this.setDefaultEnabledServices();
         }
         this.monitoringSettingsChanged();
    }

    monitoringSettingsChanged() {
        this.hasChanges = true;

        // A duration must always be set
        if (this.eventMonitoring.durations.filter((duration) => duration.enabled).length == 0) {
            this.eventMonitoring.durations[this.eventMonitoring.durations.length-1].enabled = true;
        }
        if (this.managedRpm.durations.filter((duration) => duration.enabled).length == 0) {
            this.managedRpm.durations[this.managedRpm.durations.length-1].enabled = true;
        }
        if (this.rpm.durations.filter((duration) => duration.enabled).length == 0) {
            this.rpm.durations[this.rpm.durations.length-1].enabled = true;
        }

        if (this.eventMonitoring.enabled) {
            // If a limited time is set then enable selection of what happens after
            this.afterMonitorServiceSelectionEnabled = false;
            this.eventMonitoring.durations.forEach((duration) => {
                if (duration.type != DurationType.Indefinitely && duration.enabled) {
                    this.afterMonitorServiceSelectionEnabled = true;
                }
            });
        }

        if (!this.afterMonitorServiceSelectionEnabled) {
            this.postMonitoringRpmEnabled = false;
        }

        if (!this.eventMonitoring.enabled) {
            this.managedRpm.enabled = false;
            this.rpm.enabled = false;
            this.eventMonitoring.durations.forEach((duration) => duration.enabled = false);
            this.afterMonitorServiceSelectionEnabled = false;
            this.postMonitoringRpmEnabled = false;
        }

        if (this.postMonitoringRpmEnabled && !this.rpm.enabled) {
            this.managedMonitoringByCoala = true;
            this.managedRpm.enabled = true;
            this.rpm.enabled = false;
        }

        if (!this.postMonitoringRpmEnabled) {
            this.managedRpm.enabled = false;
            this.rpm.enabled = false;
        }

        this.printServiceStatus();
    }

    /**
     * Triggered on change in monitoring form
     */
    monitoringByCoalaSettingChanged() {
        this.hasChanges = true;

        this.managedRpm.enabled = this.managedMonitoringByCoala;
        this.rpm.enabled = !this.managedMonitoringByCoala;
        this.printServiceStatus();
    }

    getManagedRpmOptions(): MonitoringService[] {
        return this.managedRpm && this.rpm ? [this.managedRpm, this.rpm] : [];
    }

    private printServiceStatus() {
        console.table({
            EventMonitoring: this.eventMonitoring.enabled,
            ManagedRpm: this.managedRpm.enabled,
            Rpm: this.rpm.enabled
        });
        console.log(JSON.stringify(this.getMonitoringServiceConditionPayload(), null, 4));
    }

    public getMonitoringServiceConditionPayload(): MonitoringServiceRequest[] {
        const services: MonitoringServiceRequest[] = [];
        if (this.eventMonitoring.enabled) {
            services.push(this.eventMonitoring.getMonitoringServiceResponse(0));
        }
        if (this.managedRpm.enabled) {
            services.push(this.managedRpm.getMonitoringServiceResponse(1));
        }
        if (this.rpm.enabled) {
            services.push(this.rpm.getMonitoringServiceResponse(1));
        }
        return services;
    }

    public setDefaultEnabledServices() {
        this.hasChanges = true;
        this.eventMonitoring.enabled = true;
        this.managedRpm.enabled = true;
        this.rpm.enabled = false;

        for (const service of [this.eventMonitoring, this.managedRpm, this.rpm]) {
            for (const duration of service.durations) {
                duration.enabled = true;
                if (service.id == this.eventMonitoring.id && duration.type == DurationType.Indefinitely) {
                    duration.enabled = false;
                }
            }
        }

        this.eventMonitoring.enabled = true;
        this.afterMonitorServiceSelectionEnabled = true;
        this.postMonitoringRpmEnabled = true;
        this.managedMonitoringByCoala = true;
    }

    public setEnabledServices(services: MonitoringServiceCollectionServicesResponse[]) {
        this.eventMonitoring.enabled = false;
        this.managedRpm.enabled = false;
        this.rpm.enabled = false;

        for (const service of services) {
            if (service.monitoringServiceId == this.eventMonitoring.id) {
                this.eventMonitoring.enabled = true;
                this.afterMonitorServiceSelectionEnabled = false;
                for (const duration of this.eventMonitoring.durations) {
                    duration.enabled = service.monitoringServiceDurations.filter((e) => e.id == duration.id).length > 0;
                    // Enable afterMonitorServiceSelection
                    if (duration.type != DurationType.Indefinitely && duration.enabled) {
                        this.afterMonitorServiceSelectionEnabled = true;
                    }
                }
            }
            if (service.monitoringServiceId == this.managedRpm.id) {
                this.managedRpm.enabled = true;
                this.postMonitoringRpmEnabled = true;
                this.managedMonitoringByCoala = true;
                for (const duration of this.managedRpm.durations) {
                    duration.enabled = service.monitoringServiceDurations.filter((e) => e.id == duration.id).length > 0;
                }
            }
            if (service.monitoringServiceId == this.rpm.id) {
                this.rpm.enabled = true;
                this.postMonitoringRpmEnabled = true;
                this.managedMonitoringByCoala = false;
                for (const duration of this.rpm.durations) {
                    duration.enabled = service.monitoringServiceDurations.filter((e) => e.id == duration.id).length > 0;
                }
            }
        }
        this.printServiceStatus();
    }
}

/**
 * Handler for HCP remote patient monitoring services and the chain of options to be triggered by UI selections
 */
export class RemotePatientMonitoringServicesHandler {
    public hasChanges = false;
    public rpmEnabled = false;
    public managedMonitoringByCoala = true;

    public managedRpm!: MonitoringService;
    public rpm!: MonitoringService;

    addServices(services: MonitoringService[]) {
        for (const service of services) {
            switch (service.type.toString()) {
                case MonitoringTypeKey[MonitoringTypeKey.ManagedRpm]:
                    this.managedRpm = service;
                    break;
                case MonitoringTypeKey[MonitoringTypeKey.Rpm]:
                    this.rpm = service;
                    break;
            }
        }
    }

    /**
     * Triggered on change in rpm form
     */
    rpmSettingsChanged() {
        this.hasChanges = true;

        // A duration must always be set
        if (this.managedRpm.durations.filter((duration) => duration.enabled).length == 0) {
            this.managedRpm.durations[this.managedRpm.durations.length-1].enabled = true;
        }
        if (this.rpm.durations.filter((duration) => duration.enabled).length == 0) {
            this.rpm.durations[this.rpm.durations.length-1].enabled = true;
        }

        if (this.rpmEnabled) {
            this.managedRpm.enabled = this.managedMonitoringByCoala;
            this.rpm.enabled = !this.managedMonitoringByCoala;
        } else {
            this.managedRpm.enabled = false;
            this.rpm.enabled = false;
        }
        this.printServiceStatus();
    }

    getManagedRpmOptions(): MonitoringService[] {
        return this.managedRpm && this.rpm ? [this.managedRpm, this.rpm] : [];
    }

    private printServiceStatus() {
        console.table({
            ManagedRpm: this.managedRpm.enabled,
            Rpm: this.rpm.enabled
        });
        console.log(JSON.stringify(this.getMonitoringServiceConditionPayload(), null, 4));
    }

    public getMonitoringServiceConditionPayload(): MonitoringServiceRequest[] {
        const services: MonitoringServiceRequest[] = [];
        if (this.managedRpm.enabled) {
            services.push(this.managedRpm.getMonitoringServiceResponse(0));
        }
        if (this.rpm.enabled) {
            services.push(this.rpm.getMonitoringServiceResponse(0));
        }
        return services;
    }

    public setDefaultEnabledServices() {
        this.hasChanges = true;
        this.managedRpm.enabled = true;
        this.rpm.enabled = false;

        this.rpmEnabled = true;
        this.managedMonitoringByCoala = true;

        for (const service of [this.managedRpm, this.rpm]) {
            for (const duration of service.durations) {
                duration.enabled = true;
            }
        }
    }

    public setEnabledServices(services: MonitoringServiceCollectionServicesResponse[]) {
        this.managedRpm.enabled = false;
        this.rpm.enabled = false;

        this.rpmEnabled = services.length > 0;

        for (const service of services) {
            if (service.monitoringServiceId == this.managedRpm.id) {
                this.managedRpm.enabled = true;
                this.managedMonitoringByCoala = true;
                for (const duration of this.managedRpm.durations) {
                    duration.enabled = service.monitoringServiceDurations.filter((e) => e.id == duration.id).length > 0;
                }
            }
            if (service.monitoringServiceId == this.rpm.id) {
                this.rpm.enabled = true;
                this.managedMonitoringByCoala = false;
                for (const duration of this.rpm.durations) {
                    duration.enabled = service.monitoringServiceDurations.filter((e) => e.id == duration.id).length > 0;
                }
            }
        }
        this.printServiceStatus();
    }

}
