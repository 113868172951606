import * as angular from 'angular';

(function() {
  'use strict';

  function MessageService($q, $http, envConfig, SessionService, HttpClientService) {

    const service = {};
    const root = envConfig.baseUrl;

    service.sendMessage = function(obj) {
      const url = root + '/adminapi/v1/messages';
      return HttpClientService.post(url, obj);
    };

    service.sendMessageToAll = function(obj) {
      // must have this guid for the backend to send it to all users.
      obj.userId = '00000000-0000-0000-0000-000000000000';

      const url = root + '/adminapi/v1/messages';
      return HttpClientService.post(url, obj);
    };

    service.getMessages = function(id, paging) {
      if (paging == undefined) {
        paging = {};
      }

      const url = root + '/adminapi/v1/messages/users/' + id + '?Page=' + paging.page + '&PageSize=' + paging.pageSize + '&Sort=' + paging.sort + '&Search=' + paging.search;
      return HttpClientService.get(url);
    };

    return service;
  }

  angular
    .module('patients')
    .factory('MessageService', MessageService);

})();
