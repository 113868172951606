import * as angular from 'angular';
import * as jQuery from 'jquery';
import moment from "moment";
import {detectIE, findCapturedLocalTime, findRawUrl} from "../../../util";
import '../../../scripts/plot/jquery.flot';
import '../../../scripts/plot/jquery.flot.navigate';
import '../../../scripts/plot/flot.hbars';
import '../../../scripts/plot/flot.annotate';
import '../../../scripts/plot/flot.touch';
import * as viewer from '../../../scripts/plot/viewer';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientMeasurementController', PatientMeasurementController);

  function PatientMeasurementController($stateParams, $scope, $rootScope, $state, $timeout, $interval, $translate, PatientService, SessionService, ModalService, NationService, ReportTimeService, MeasurementHelperService) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    const catGroup = {};
    let startTab = 'finger';

    let chestPlot = null;
    let fingerPlot = null;
    let currentPlot = null;
    let currentPlotType = '';
    let currentPlotTypeConfigurationId = '';
    let currentMeasurementType = '';
    vm.chestLoaded = false;
    vm.fingerLoaded = false;
    vm.soundLoaded = false;
    vm.isPlaying = false;
    vm.soundUrl = null;
    vm.yZoomLevelOptions = [{name: '± 0.5 mV', value: 0.5}, {name: '± 1 mV', value: 1}, {
      name: '± 2 mV',
      value: 2
    }, {name: '± 5 mV', value: 5}];
    vm.yZoomLevelChest = vm.yZoomLevelOptions[1];
    vm.yZoomLevelFinger = vm.yZoomLevelOptions[2];
    vm.graphInvertedChest = false;
    vm.graphInvertedFinger = false;
    vm.showSpinner = false;
    vm.startItem = 0;
    vm.healthcareProviders = null;
    vm.assignedHealthcareProvider = null;
    vm.hcpContactDetails = null;
    vm.hcpPrescriberContactDetails = null;

    vm.enableCalipterFinger = false;
    vm.enableCalipterChest = false;
    let dragType = 'move';
    let caliperStorage = null;

    vm.disableMarkersChest = false;
    vm.disableMarkersFinger = false;

    const measurementParts = [];

    // Used for getting next or previous measurement
    vm.measurementlist = $rootScope.measurements;
    vm.measurementlistCount = $rootScope.nrOfMeasurements;
    vm.measurementlistPagingParameters = $rootScope.measurementsPagingParameters;
    vm.measurementlistPosition = $rootScope.measurementlistPosition;
    vm.fromUri = $rootScope.fromUri;

    // Stethoscope
    vm.stethoscopeParts = [];

    let interval = null;
    const refreshInterval = 5 * 1000;

    const playingSoundFilter = [];

    vm.partFlagged = false;

    activate();

    function activate() {
      const measurementId = $stateParams.measurementId;
      const userId = $stateParams.userId;
      vm.startItem = $stateParams.startItem;

      vm.userId = userId;
      vm.measurementId = measurementId;
      vm.feelingBeforeMeasurement = '';

      if (vm.measurementlistPosition == undefined && vm.measurementlist !== undefined) {
        const index = findObjectIndexByKey(vm.measurementlist, 'id', vm.measurementId);
        vm.measurementlistPosition = (index + 1) + ((vm.measurementlistPagingParameters.page) * vm.measurementlistPagingParameters.pageSize);
      }

      vm.isIE = detectIE();

      PatientService.getUserDetails(userId).then(function(data) {
        vm.userDetails = data;
      });

      PatientService.getMeasurement(measurementId).then(function(data) {
        vm.measurement = data;

        initializePartType();

        if (data.feelingBeforeMeasurement === 'good') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_good');
        } else if (data.feelingBeforeMeasurement === 'stressed') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_stressed');
        } else if (data.feelingBeforeMeasurement === 'anxious') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_anxious');
        } else if (data.feelingBeforeMeasurement === 'chestpain') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_chestpain');
        } else if (data.feelingBeforeMeasurement === 'rapidHeartRate') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_rapidHeartRate');
        } else if (data.feelingBeforeMeasurement === 'dizziness') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_dizziness');
        } else if (data.feelingBeforeMeasurement === 'other') {
          vm.feelingBeforeMeasurement = data.feelingBeforeMeasurementFreetext;
        } else {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_noinfo');
        }

        // If the measurement is not reviewed set it to reviewed
        if ((vm.measurement.flagged & 2) != 2) {
          vm.flagMeasurement('reviewed');
        }

        updateMeasurementFlag();
      });

      updateNotesTable();

      PatientService.getMedicalJournal(userId).then(function(data) {
        vm.medicalJournal = data;
      });

      if (SessionService.getIsMonitoringCenterStaff()) {
        PatientService.getUserMonitoringServiceContactInfo(vm.userId).then((response) => {
          const contactHCP = response.filter((x) => x.healthcareProvider != null);
          const contactPrescriber = response.filter((x) => x.healthcareProvider == null);
          vm.healthcareProviders = contactHCP.map((provider) => provider.healthcareProvider.name).join(", ");
          if (contactHCP.length > 0) {
            vm.hcpContactDetails = contactHCP[0]; // response[0];
          }
          if (contactPrescriber.length > 0) {
            vm.hcpPrescriberContactDetails = contactPrescriber[0]; // response[0];
          }
        });
      }

      startReportTimeTimer();
    }

    vm.getContactInfoNotificationCriteria = function() {
      if (vm.hcpPrescriberContactDetails !== null) {
        return vm.hcpPrescriberContactDetails.notificationCriteria;
       } else if (vm.hcpContactDetails !== null) {
        return vm.hcpContactDetails.notificationCriteria;
       }
      return undefined;
    };

    // Used to update UI (patient timer)
    function startReportTimeTimer() {
      if (vm.permissions.timereporter) {
        interval = $interval(updateTimeSpent, 1000);
      }
    }

    function updateTimeSpent() {
      // console.log('refresh...');
    }

    $scope.$on('$destroy', function() {
      // console.log('destroy...');
      if (vm.permissions.timereporter) {
        ReportTimeService.stopReportTime(vm.userId);
        $interval.cancel(interval);
      }
    });
    // -----------------------------

    function initializePartType() {
      const filterOptions = SessionService.getFilterOptions();
      startTab = filterOptions.measurementTab;
      if (vm.showTab(startTab) == false) {
        // Type does not exist for current measurement
        const defaultType = vm.measurement.measurementParts[0].measurementPartType;
        if (defaultType == 'ecgChest' || defaultType == 'heartSound') {
          startTab = 'chest';
        } else if (defaultType == 'ecgFinger') {
          startTab = 'finger';
        } else {
          startTab = 'stethoscope';
        }
      }

      currentPlotType = startTab;
      currentPlotTypeConfigurationId = getDefaultPartConfigurationId(currentPlotType);
      $('#myTabs #' + startTab + 'Tab').tab('show');
      setTab(startTab);
    }

    function getDefaultPartConfigurationId(type) {
      if (vm.measurement !== undefined) {
        const parts = jQuery.grep(vm.measurement.measurementParts, function(x) {
          return x.measurementPartType.toLowerCase() === 'ecg' + type || x.measurementPartType.toLowerCase() === 'heart' + type ||
            x.measurementPartType.toLowerCase() === type;
        });
        if (parts.length > 0) {
          return parts[0].measurementPartConfigurationId;
        }
      }
      return null;
    }

    vm.showTab = function(type) {
      if (vm.measurement !== undefined) {
        const parts = jQuery.grep(vm.measurement.measurementParts, function(x) {
          return x.measurementPartType.toLowerCase() === 'ecg' + type || x.measurementPartType.toLowerCase() === 'heart' + type ||
            x.measurementPartType.toLowerCase() === type;
        });
        if (parts.length > 0) {
          return true;
        }
      }
      return false;
    };

    vm.showSubTabs = function() {
      return vm.getSubTabs() !== null && vm.getSubTabs().length > 1;
    };

    vm.getSubTabs = function() {
      if (vm.measurement !== undefined) {
        const parts = jQuery.grep(vm.measurement.measurementParts, function(x) {
          return x.measurementPartType.toLowerCase() === 'ecg' + currentPlotType || x.measurementPartType.toLowerCase() === 'heart' + currentPlotType ||
            x.measurementPartType.toLowerCase() === currentPlotType;
        });
        return parts;
      }
      return null;
    };

    vm.setSubTab = function(partConfigurationId) {
      currentPlotTypeConfigurationId = partConfigurationId;
      setTab(currentPlotType, true);
    };

    vm.signalStatusOfPart = function(part) {
      return MeasurementHelperService.signalStatusOfPart(part);
    };

    vm.selectedPart = function(part) {
      if (part.measurementPartConfigurationId == currentPlotTypeConfigurationId) {
        return 'coalaFontBold13';
      }

      return 'coalaFont12';
    };

    function toggleIEPlayer(name) {
      if (vm.isPlayingSoundFilter(name)) {
        const player = getPlayingSoundFilter(name);
        const index = playingSoundFilter.indexOf(player);
        playingSoundFilter.splice(index, 1);
      } else {
        playingSoundFilter.push(name);
      }
    }

    function getPlayingSoundFilter(name) {
      return jQuery.grep(playingSoundFilter, function(x) {
        return x === name;
      });
    }

    vm.isPlayingSoundFilter = function(name) {
      const playing = getPlayingSoundFilter(name);
      return playing.length > 0;
    };

    vm.playIESoundFilter = function(name, url) {
      let audio;
      if (!vm.isPlayingSoundFilter(name)) {
        audio = $('#ie_' + name);
        audio.attr('src', url);
      } else {
        audio = $('#ie_' + name);
        audio.attr('src', '');
      }
      toggleIEPlayer(name);
    };

    vm.playIESound = function() {
      let audio;
      if (!vm.isPlaying) {
        audio = $('.audioDemo');
        audio.attr('src', vm.soundUrl);
        vm.isPlaying = true;
      } else if (vm.isPlaying) {
        audio = $('.audioDemo');
        audio.attr('src', '');
        vm.isPlaying = false;
      }
    };

    vm.soundUrlExists = function() {
      return vm.soundUrl !== null;
    };

    vm.soundUrlDoesNotExists = function() {
      return vm.soundUrl === null;
    };

    vm.showDownloadRawChestData = function() {
      return vm.rawChestUrl !== null;
    };

    vm.showDownloadRawFingerData = function() {
      return vm.rawFingerUrl !== null;
    };

    vm.isMonitoringCenterStaff = function() {
      return SessionService.getIsMonitoringCenterStaff();
    };

    vm.group = function(group, type) {
      if (catGroup[type] == null) {
        return 'ecgResultCircle disabled';
      }

      if (group === 3) {
        const num = catGroup[type];

        if (num >= 3) {
          return 'ecgResultCircle';
        } else {
          return 'ecgResultCircle disabled';
        }
      }

      return group == catGroup[type] ? 'ecgResultCircle' : 'ecgResultCircle disabled';
    };

    vm.showContactPatientModal = function() {
      $rootScope.userDetails = vm.userDetails;
      ModalService.showModal({
        template: require('../views/modals/patient.contact-patient.modal.tpl.html').default,
        controller: 'PatientContactController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
        });
      });
    };

    vm.showContactHcpModal = function() {
      $rootScope.hcpContactDetails = vm.hcpContactDetails;
      $rootScope.hcpPrescriberContactDetails = vm.hcpPrescriberContactDetails;
      ModalService.showModal({
        template: require('../views/modals/patient.contact-hcp.tpl.html').default,
        controller: 'HcpContactController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
        });
      });
    };

    vm.flagMeasurement = function(flagType) {
      const result = vm.measurement.flagged;
      let newResult = 0;
      if (flagType == 'alert') {
        newResult = result | 1;
        if (newResult == result) {
          // unmark
          newResult = result ^ 1;
        }
      } else if (flagType == 'reviewed') {
        newResult = result | 2;
        if (newResult == result) {
          // unmark
          newResult = result ^ 2;
        }
      }

      vm.measurement.flagged = newResult;
      // console.log('flagresult:' + vm.measurement.flagged);

      PatientService.putMeasurementFlag(vm.measurementId, vm.measurement.flagged).then(function(data) {
        updateMeasurementFlag();
      });
    };

    vm.setPartFlagged = function() {
      vm.showSpinner = true;
      const currentMarked = jQuery.grep(vm.measurement.measurementParts, function(x) {
        return x.flagged > 0;
      });

      // Reset all flagged parts
      jQuery.grep(vm.measurement.measurementParts, function(x) {
        x.flagged = 0;
      });

      const measurementPart = GetMeasurementPart(currentPlotTypeConfigurationId, currentMeasurementType);
      if (measurementPart && (currentMarked.length == 0 || currentMarked[0].id !== measurementPart.id)) {
        measurementPart.flagged = 1;
      }

      PatientService.putMeasurementPartFlag(measurementPart.id, measurementPart.flagged).then(function(data) {
        vm.showSpinner = false;
      });
    };

    vm.showPartFlagged = function(type) {
      if (!vm.measurement) {
        return false;
      }
      const measurementPart = jQuery.grep(vm.measurement.measurementParts, function(x) {
        return x.measurementPartType.toLowerCase() === type.toLowerCase() && x.flagged > 0;
      });
      if (measurementPart) {
        return measurementPart.length > 0;
      }
      return false;
    };

    vm.showPrevItem = function() {
      if (vm.measurementlist == undefined) return false;
      const index = findObjectIndexByKey(vm.measurementlist, 'id', vm.measurementId);
      // Is this the first one?
      const currentIndex = (index + 1) + ((vm.measurementlistPagingParameters.page) * vm.measurementlistPagingParameters.pageSize);
      return 1 < currentIndex;
    };

    vm.prevMeasurement = function() {
      let nextMeasurementId = null;
      let index = findObjectIndexByKey(vm.measurementlist, 'id', vm.measurementId) - 1;
      if (index >= 0) {
        nextMeasurementId = vm.measurementlist[index].id;

        $state.go('main.patientmeasurement', {
          measurementId: nextMeasurementId,
          userId: vm.userId,
          startItem: vm.startItem
        });
      } else {
        // Get new batch of measurements
        vm.measurementlistPagingParameters.page = vm.measurementlistPagingParameters.page - 1;
        index = 0;
        PatientService.getMeasurementsForUser(vm.userId, vm.measurementlistPagingParameters, false).then(function(data) {
          vm.measurementlist = data.rows;
          $rootScope.measurements = vm.measurementlist;
          nextMeasurementId = vm.measurementlist[vm.measurementlistPagingParameters.pageSize - 1].id;

          $state.go('main.patientmeasurement', {
            measurementId: nextMeasurementId,
            userId: vm.userId,
            startItem: vm.startItem
          });
        });
      }

      $rootScope.measurementlistPosition = (index + 1) + ((vm.measurementlistPagingParameters.page) * vm.measurementlistPagingParameters.pageSize);
    };

    vm.showNextItem = function() {
      if (vm.measurementlist == undefined) return false;
      const index = findObjectIndexByKey(vm.measurementlist, 'id', vm.measurementId);
      // Was this the last one?
      const currentIndex = (index + 1) + ((vm.measurementlistPagingParameters.page) * vm.measurementlistPagingParameters.pageSize);
      return vm.measurementlistCount > currentIndex;
    };

    vm.nextMeasurement = function() {
      let nextMeasurementId = null;
      let index = findObjectIndexByKey(vm.measurementlist, 'id', vm.measurementId) + 1;
      if (index < vm.measurementlist.length) {
        nextMeasurementId = vm.measurementlist[index].id;

        $state.go('main.patientmeasurement', {
          measurementId: nextMeasurementId,
          userId: vm.userId,
          startItem: vm.startItem
        });
      } else {
        // Get next batch of measurements
        vm.measurementlistPagingParameters.page = vm.measurementlistPagingParameters.page + 1;
        index = 0;
        PatientService.getMeasurementsForUser(vm.userId, vm.measurementlistPagingParameters, false).then(function(data) {
          vm.measurementlist = data.rows;
          $rootScope.measurements = vm.measurementlist;
          nextMeasurementId = vm.measurementlist[0].id;

          $state.go('main.patientmeasurement', {
            measurementId: nextMeasurementId,
            userId: vm.userId,
            startItem: vm.startItem
          });
        });
      }

      $rootScope.measurementlistPosition = (index + 1) + ((vm.measurementlistPagingParameters.page) * vm.measurementlistPagingParameters.pageSize);
    };

    function findObjectIndexByKey(array, key, value) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return i;
        }
      }
      return -1;
    }

    function updateMeasurementFlag() {
      const elemalert = document.getElementById('measurementflag');
      if ((vm.measurement.flagged & 1) == 1) {
        elemalert.style.color = 'Red';
      } else {
        elemalert.style.color = 'White';
      }

      const elemreviewed = document.getElementById('measurementreviewed');
      if ((vm.measurement.flagged & 2) == 2) {
        elemreviewed.style.color = 'Lightgreen';
      } else {
        elemreviewed.style.color = 'White';
      }
    }

    vm.getLocalTime = function(utctime) {
      const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
      const localTime = moment.utc(date).toDate();
      return localTime;
    };

    function updateNotesTable() {
      PatientService.getNotesForUser(vm.userId, vm.measurementId).then(function(data) {
        vm.notes = data.rows;
      });
    }

    vm.addNotes = function() {
      const note = {
        userId: vm.userId,
        measurementId: vm.measurementId,
        notes: vm.newnote
      };
      PatientService.postUserNote(vm.userId, note).then(function(data) {
        // vm.showFeedback('Note added.');
        // close("", 500);
        vm.newnote = '';
        updateNotesTable();
      });
    };

    vm.isNoteEmpty = function() {
      return vm.newnote == '';
    };

    // This is a fix for changing tab on mobile devices. The real issue could be that angular-touch needs to be updated.
    vm.mobileTabClick = function(type) {
      event.preventDefault();
      const tab = $('#' + type + 'Tab');
      tab[0].click();
    };

    vm.toggleTab = function(type) {
      event.preventDefault();
      currentPlotType = type;
      currentPlotTypeConfigurationId = getDefaultPartConfigurationId(currentPlotType);
      $('#myTabs #' + type + 'Tab').tab('show');
      setTab(type);
    };

    function setTab(type) {
      const filterOptions = SessionService.getFilterOptions();
      filterOptions.measurementTab = type;
      SessionService.setFilterOptions(filterOptions);
      const part = currentPart(currentPlotTypeConfigurationId, type);

      currentMeasurementType = 'ecgFinger';
      let enableMarkers = !vm.disableMarkersFinger;
      currentPlot = fingerPlot;
      if (type === 'finger') {
        vm.rawFingerUrl = findRawUrl(vm.measurement.measurementParts, 'ecgFinger', currentPlotTypeConfigurationId);
      } else if (type === 'chest') {
        currentMeasurementType = 'ecgChest';
        currentPlot = chestPlot;
        enableMarkers = !vm.disableMarkersChest;
        vm.soundUrl = findRawUrl(vm.measurement.measurementParts, 'heartSound', currentPlotTypeConfigurationId);
        if (!vm.isIE) {
          const audio = $('.audioDemo');
          audio.attr('src', vm.soundUrl);
        }
        vm.rawChestUrl = findRawUrl(vm.measurement.measurementParts, 'ecgChest', currentPlotTypeConfigurationId);
      } else if (type === 'sound') {
        currentMeasurementType = 'heartSound';
      } else if (type === 'stethoscope') {
        currentMeasurementType = 'stethoscope';
        if (part === undefined) {
          vm.showSpinner = true;
          vm.stethoscopeParts = [];
          let numOfParts = vm.getSubTabs().length;
          angular.forEach(vm.getSubTabs(), function(part) {
            PatientService.getMeasurementPartContent(vm.measurementId, part.measurementPartConfigurationId).then(function(data) {
              const soundUrl = findRawUrl(vm.measurement.measurementParts, currentMeasurementType, part.measurementPartConfigurationId);
              const imageBase64 = 'data:image/png;base64,' + data.measurementPartDirectionContentResponse.content1Base64;
              const stethoscopePart = {
                measurementPartConfigurationId: part.measurementPartConfigurationId,
                stethoscopeUrl: soundUrl,
                contentBase64: imageBase64,
                name: part.measurementPartConfigurationName,
                ecgAnalisysImageUrl: data.ecgAnalisysImageUrl
              };
              vm.stethoscopeParts.push(stethoscopePart);
              numOfParts--;
              if (numOfParts == 0) {
                vm.showSpinner = false;
              }

              const soundFilters = loadAudioFilters(part.measurementPartConfigurationId);
              measurementParts.push({
                configurationPartId: part.measurementPartConfigurationId,
                type: type,
                soundFilters: soundFilters
              });

            });
          });
        }
      }

      // Part contains numeric value value to be able to add different flags in the future (XOR logic)
      const currentMeasurementPart = GetMeasurementPart(currentPlotTypeConfigurationId, currentMeasurementType);
      vm.partFlagged = currentMeasurementPart.flagged > 0;

      if (type === 'finger' || type === 'chest') {
        if (part === undefined) {
          vm.showSpinner = true;
          PatientService.getMeasurementData(vm.userId, vm.measurementId, currentMeasurementType, currentPlotTypeConfigurationId).then(function(data) {
            const caliperDefault = {
              firstpoint: null,
              secondpoint: null,
              nailedToChart: false,
              caliperPositionInTime: 0,
              caliperWidthInTime: 0
            };
            measurementParts.push({
              configurationPartId: currentPlotTypeConfigurationId,
              type: type,
              data: data,
              caliper: caliperDefault
            });
            caliperStorage = caliperDefault;
            loadEcgPlot(type, currentPlotTypeConfigurationId, data, enableMarkers);

          }, function(error) {
            console.log('error: ' + error);
            if (type === 'chest') {
              if (error === '') {
                vm.errorMessageChest = $translate.instant('measurement_error_2');
              } else {
                vm.errorMessageChest = $translate.instant('measurement_error_3');
              }
              vm.showSpinner = false;
            } else {
              if (error === '') {
                vm.errorMessageFinger = $translate.instant('measurement_error_2');
              } else {
                vm.errorMessageFinger = $translate.instant('measurement_error_3');
              }
              vm.showSpinner = false;
            }
          });
        } else {
          console.log('Using cache of measurement part');
          caliperStorage = part.caliper;
          loadEcgPlot(type, currentPlotTypeConfigurationId, part.data, enableMarkers);
        }
      } else if (type === 'sound') {
        vm.errorMessageSound = null;
        $('#pcgAnalysis').attr('src', null);
        if (part === undefined) {
          vm.showSpinner = true;
          PatientService.getPCGAnalysisImage(vm.measurementId, currentPlotTypeConfigurationId).then(function(data) {
            vm.soundUrl = findRawUrl(vm.measurement.measurementParts, 'heartSound', currentPlotTypeConfigurationId);
            const soundFilters = loadAudioFilters(currentPlotTypeConfigurationId, vm.soundUrl);
            measurementParts.push({
              configurationPartId: currentPlotTypeConfigurationId,
              type: type,
              data: data,
              soundFilters: soundFilters
            });
            $('#pcgAnalysis').attr('src', 'data:image/png;base64,' + data);
            vm.showSpinner = false;
            vm.soundLoaded = true;
          }, function(error) {
            if (error === '') {
              vm.errorMessageSound = $translate.instant('measurement_error_4');
            } else {
              vm.errorMessageSound = $translate.instant('measurement_error_5');
            }
            vm.showSpinner = false;
            vm.showErrorMessageSound = true;
          });
        } else {
          $('#pcgAnalysis').attr('src', 'data:image/png;base64,' + part.data);
        }
      }
    }

    function GetCurrentInternalType() {
      // TODO use one type for each part type
      if (currentMeasurementType == 'ecgFinger') {
        return 'finger';
      }
      if (currentMeasurementType == 'ecgChest') {
        return 'chest';
      }
      if (currentMeasurementType == 'heartSound') {
        return 'sound';
      }
      if (currentMeasurementType == 'stethoscope') {
        return 'stethoscope';
      }
      return '';
    }

    vm.hasPcgAudioFilter = function(measurementPartConfigurationId) {
      if (vm.getSoundFilters(measurementPartConfigurationId) !== undefined &&
        vm.getSoundFilters(measurementPartConfigurationId) !== null) {
        return true;
      }
      return false;
    };

    function parseAudioFile(file) {
      const index = file.indexOf('.wav') - 11;
      const result = file.substring(index, index + 11);
      return result;
    }

    vm.getSignalNumberFromSoundFilterFileName = function(soundFilterFileName) {
      if (soundFilterFileName) {
        const signalNumber = soundFilterFileName.name.toLowerCase().replace('50hz', '').replace('60hz', '');
        return signalNumber;
      }
    };

    vm.soundSymbol = function(soundFilterFileName) {
      const soundFilterName = vm.getSignalNumberFromSoundFilterFileName(soundFilterFileName);
      if (soundFilterName === 'signal1' || soundFilterName === 'signal2' || soundFilterName === 'signal3') {
        return 'fas fa-heartbeat coalaBlue';
      } else if (soundFilterName === 'signal4') {
        return 'fas fa-lungs coalaBlue';
      }

      return '';
    };

    vm.soundSymbol2 = function(soundFilterFileName) {
      const soundFilterName = vm.getSignalNumberFromSoundFilterFileName(soundFilterFileName);
      if (soundFilterName === 'signal3') {
        return 'fas fa-lungs coalaBlue';
      }

      return '';
    };

    vm.getSoundFilters = function(measurementPartConfigurationId) {
      if (measurementPartConfigurationId === undefined) {
        measurementPartConfigurationId = currentPlotTypeConfigurationId;
      }
      const part = currentPart(measurementPartConfigurationId, GetCurrentInternalType());
      if (part === undefined) {
        return null;
      }

      return part.soundFilters;
    };

    function loadAudioFilters(partConfigurationId, defaultSoundUrl) {
      let audioFilterOptions = null;
      if (partConfigurationId !== '') {
        console.log('load audio filters for: ' + partConfigurationId);
        const part = GetMeasurementPart(partConfigurationId, currentMeasurementType);
        const result = part !== undefined && (part.soundFiltered50Hz || part.soundFiltered60Hz);
        if (result) {
          audioFilterOptions = [];
          if (defaultSoundUrl !== undefined) {
            audioFilterOptions.push({name: 'Orginal', url: defaultSoundUrl});
          }

          if (part.soundFiltered50Hz) {
            jQuery.grep(part.sasSoundFiltered50HzLinks, function(x) {
              audioFilterOptions.push({name: parseAudioFile(x), url: x});
            });
          }
          if (part.soundFiltered60Hz) {
            jQuery.grep(part.sasSoundFiltered60HzLinks, function(x) {
              audioFilterOptions.push({name: parseAudioFile(x), url: x});
            });
          }
        }
      }
      return audioFilterOptions;
    }

    vm.runPcgAudioFilterAnalysis = function(measurementPartConfigurationId) {
      if (measurementPartConfigurationId === undefined) {
        measurementPartConfigurationId = currentPlotTypeConfigurationId;
      }
      const part = GetMeasurementPart(measurementPartConfigurationId, currentMeasurementType);
      PatientService.runPcgAudioFilterAnalysis(part.id).then(function(data) {
        vm.showFeedback($translate.instant('measurement_run_pcgaudiofilter_message'));
        currentPlotTypeConfigurationId = measurementPartConfigurationId;
        autoRefresh();
      });
    };

    function autoRefresh() {
      vm.showSpinner = true;
      interval = $interval(checkIfAudioFiltersAreLoaded, refreshInterval);
    }

    function checkIfAudioFiltersAreLoaded() {
      PatientService.getMeasurement(vm.measurementId).then(function(data) {
        vm.measurement = data;
        const audioFilterOptions = loadAudioFilters(currentPlotTypeConfigurationId);
        if (audioFilterOptions !== null) {
          vm.showSpinner = false;
          $interval.cancel(interval);
          removePartFromCache(currentPlotTypeConfigurationId, currentPlotType);
          setTab(currentPlotType);
        }
      });
    }

    vm.addTimeReport = function(time) {
      const providerId = SessionService.getReprestingProviderId();
      const timereport = {
        userId: vm.userId,
        time: '00:' + time + ':00',
        healthcareProviderId: providerId
      };
      PatientService.postTimeReport(timereport).then(function(data) {
        vm.showFeedback($translate.instant('timereport_patient_created'));
        ReportTimeService.addTime(vm.userId, parseInt(time, 10) * 60);
      });
    };

    vm.reAnalyze = function() {
      PatientService.reAnalyzeMeasurement(vm.measurementId).then(function() {
        vm.showFeedback('The measurement is sent for reanalyze...');
      });
    };

    vm.displayPatientDetails = function() {
      $state.go('mainAdmin.patientDetails', {
        userId: vm.userDetails.userId,
        startItem: vm.startItem
      });
    };

    function currentPart(configurationPartId, type) {
      const part = jQuery.grep(measurementParts, function(x) {
        return x.type === type && x.configurationPartId === configurationPartId;
      });

      return part[0];
    }

    function resetCaliperForPartType(type) {
      jQuery.grep(measurementParts, function(x) {
        if (x.type === type) {
          x.caliper = {
            firstpoint: null,
            secondpoint: null,
            nailedToChart: false,
            caliperPositionInTime: 0,
            caliperWidthInTime: 0
          };
        }
      });

      caliperStorage.firstpoint = null;
      caliperStorage.secondpoint = null;
      caliperStorage.nailedToChart = false;
    }

    function removePartFromCache(configurationPartId, type) {
      const part = currentPart(configurationPartId, type);
      const index = measurementParts.indexOf(part);
      measurementParts.splice(index, 1);
    }

    function loadEcgPlot(type, id, data, enableMarkers) {
      vm.showSpinner = true;
      $timeout(function() {
        vm.measurementData = data;

        vm.soundDownloadName = new moment(findCapturedLocalTime(vm.measurement.measurementParts, currentPlotTypeConfigurationId)).format('YYYYMMDD_HHmm') + '_heartSound.wav';
        vm.chestDownloadName = new moment(findCapturedLocalTime(vm.measurement.measurementParts, currentPlotTypeConfigurationId)).format('YYYYMMDD_HHmm') + '_chestECG.data';
        vm.fingerDownloadName = new moment(findCapturedLocalTime(vm.measurement.measurementParts, currentPlotTypeConfigurationId)).format('YYYYMMDD_HHmm') + '_thumbECG.data';

        try {
          if (data.avBeatInfo == null || data.avBeatInfo.length == 0) {
            setErrorMessage(type, $translate.instant('measurement_error_1'));
            vm.showSpinner = false;
          }

          const plot = viewer.show_ecg(data, type, $translate, enableMarkers);

          let initializeEvents = false;
          if (type === 'finger') {
            if (fingerPlot == null) {
              initializeEvents = true;
            }
            fingerPlot = plot;
          } else {
            if (chestPlot == null) {
              initializeEvents = true;
            }
            chestPlot = plot;
          }

          currentPlot = plot;

          if (initializeEvents) {
            $('#zoomIn-' + type).click(function() {
              const axesBefore = currentPlot.getAxes();
              const fromBefore = axesBefore.xaxis.min.toFixed(2);
              const toBefore = axesBefore.xaxis.max.toFixed(2);

              currentPlot.zoom();

              zoomCaliperNode(fromBefore, toBefore);
            });

            $('#zoomOut-' + type).click(function() {
              const axesBefore = currentPlot.getAxes();
              const fromBefore = axesBefore.xaxis.min.toFixed(2);
              const toBefore = axesBefore.xaxis.max.toFixed(2);

              currentPlot.zoomOut();

              zoomCaliperNode(fromBefore, toBefore);
            });


            $('#ecg-plot-' + type).bind('mousedown', function(event) {
              setCaliperDragType(event);
            });
            $('#ecg-plot-' + type).bind('mouseup scroll', function(event) {
              setCaliperLines(event);
            });

            $('#ecg-plot-' + type).mousemove(function(event) {
              let pa;
              let dm;
              if (!caliperStorage.nailedToChart) {
                if (caliperStorage.secondpoint != null) {
                  dm = document.getElementById('dragme' + currentPlotType);
                  if (dm !== null) {
                    pa = getPlotPosition(event);
                    dm.style.left = pa.x + 'px';
                    dm.style.top = 0;

                    caliperStorage.caliperPositionInTime = getTimeFromPixel(pa.x);
                    const placeholder = $('#ecg-plot-' + type);
                    placeholder.append(dm);

                    drawCaliperInfo();
                  }
                }
                event.preventDefault();
                return false;
              } else {
                dm = document.getElementById('dragme' + currentPlotType);
                if (dm !== null) {
                  pa = getPlotPosition(event);
                  const timepos = getTimeFromPixel(pa.x) + caliperStorage.caliperWidthInTime;
                  if (timepos < (caliperStorage.caliperPositionInTime + 100)) {
                    dm.style.cursor = 'w-resize';
                  } else if (timepos > (caliperStorage.caliperPositionInTime + caliperStorage.caliperWidthInTime - 250)) {
                    dm.style.cursor = 'e-resize';
                  } else {
                    dm.style.cursor = 'move';
                  }
                }
              }
            });

            $('#ecg-plot-' + type).bind('touchend', function(event) {
              console.log('touchend');
              caliperPaning(event);
            });

            $('#ecg-plot-' + type).bind('plotpan', function(event, plot) {
              console.log('plotpan');
              caliperPaning(event);
            });

            addArrow('left', 55, 5, {left: -100});
            addArrow('right', 25, 5, {left: 100});
          }

          vm.yZoom();
          const segments = data['segments'][0];
          catGroup[type] = segments['info'][0]; // Segments[0].Info[0]

          if (type === 'chest') {
            vm.chestLoaded = true;
          } else if (type === 'finger') {
            vm.fingerLoaded = true;
          }

          if (caliperStorage !== null && caliperStorage.caliperPositionInTime > 0 &&
            ((vm.enableCalipterFinger && type == 'finger') || (vm.enableCalipterChest && type == 'chest'))) {
            const x = getPixelFromTime(caliperStorage.caliperPositionInTime);
            const width = getPixelFromTime(caliperStorage.caliperWidthInTime) - 21.5;
            console.log('x:' + x + ', width:' + width);
            createCaliper(width, x);
          }

        } catch (err) {
          console.log(err.message);
        }
        vm.showSpinner = false;
      }, 300);
    }

    function caliperPaning(event) {
      if (caliperStorage.secondpoint != null) {
        console.log('touchend');
        const dm = document.getElementById('dragme' + currentPlotType);
        const x = getPixelFromTime(caliperStorage.caliperPositionInTime);
        dm.style.left = x + 'px';
        dm.style.top = 0;

        const placeholder = $('#ecg-plot-' + currentPlotType);
        placeholder.append(dm);
      }
    }

    let int00; // declared here to make it visible to clearInterval.
    // Add panning buttons
    function addArrow(dir, right, top, offset) {
      const placeholder = $('#section-ecg-plot-' + currentPlotType);
      const arrowLeftImg = require('../../../images/arrow-left.gif');
      const arrowRightImg = require('../../../images/arrow-right.gif');
      const arrowImg = dir === 'left' ? arrowLeftImg : arrowRightImg;
      $('<img class=\'button\' src=' + arrowImg + ' style=\'right:' + right + 'px;top:' + top + 'px; position: absolute; cursor: pointer;\'>')
        .appendTo(placeholder)
        .mousedown(function(e) {
          e.preventDefault();
          int00 = setInterval(function() {
            movePlot(offset);
          }, 50);
        }).mouseup(function(event) {
        clearInterval(int00);
      }).click(function(e) {
        e.preventDefault();
        movePlot(offset);
      });
    }

    function movePlot(offset) {
      currentPlot.pan(offset);
    }

    function setCaliperDragType(event) {
      console.log('setCaliperDragType');
      if (caliperEnabled()) {
        if (caliperStorage.nailedToChart) {
          const dm = document.getElementById('dragme' + currentPlotType);
          const pa = getPlotPosition(event);
          const timepos = getTimeFromPixel(pa.x) + caliperStorage.caliperWidthInTime;
          if (timepos < (caliperStorage.caliperPositionInTime + 100)) {
            console.log('drag west');
            dragType = 'west';
          } else if (timepos > (caliperStorage.caliperPositionInTime + caliperStorage.caliperWidthInTime - 250)) {
            console.log('drag east');
            dragType = 'east';
          } else {
            console.log('drag move');
            dragType = 'move';
          }
        }
      }
    }

    function setCaliperLines(event) {
      let plotnode;
      console.log('plot clicked');
      if (caliperEnabled()) {
        if (caliperStorage.firstpoint === null) {
          caliperStorage.firstpoint = event.clientX;
          const pa = getPlotPosition(event);
          createCaliper(1, pa.x - 2);

          caliperStorage.secondpoint = null;
          plotnode = document.getElementById('ecg-plot-' + currentPlotType);
          plotnode.style.cursor = 'crosshair';
        } else if (caliperStorage.secondpoint === null) {
          caliperStorage.secondpoint = event.clientX;
          let width = caliperStorage.secondpoint - caliperStorage.firstpoint;
          if (width < 0) {
            width = width * -1;
          }

          const dm = document.getElementById('dragme' + currentPlotType);
          dm.style.cursor = 'move';
          plotnode = document.getElementById('ecg-plot-' + currentPlotType);
          plotnode.style.cursor = 'default';

          const posX = getPixelFromTime(caliperStorage.caliperPositionInTime);
          createCaliper(width, posX);
        } else if (!caliperStorage.nailedToChart) {
          caliperStorage.nailedToChart = true;
        }
      }
    }

    function drawCaliperInfo() {
      let numOfPoints = 0;
      let mean = 0;
      let beatsPerMin = 0;
      const caliperInfo = document.getElementById('caliper-info-' + currentPlotType);
      const beats_pos = vm.measurementData['beats']['pos'];
      const beats_rr = vm.measurementData['beats']['info'][0];
      for (let j = 0; j < beats_pos.length; j++) { //  beats_pos.length;
        const p = beats_pos[j]; // beats_pos[j]    -- var beats_pos = res['beats']['pos'];
        if (p > caliperStorage.caliperPositionInTime && p < (caliperStorage.caliperPositionInTime + caliperStorage.caliperWidthInTime)) {
          numOfPoints++;
          mean += beats_rr[j]; //  beats_rr[j]    --  var beats_rr = res['beats']['info'][0];
        }
      }
      if (numOfPoints > 0) {
        mean = mean / numOfPoints;
        const tmp = 60000 / (mean * numOfPoints);
        beatsPerMin = numOfPoints * tmp;
        caliperInfo.textContent = $translate.instant('measurement_caliper_info_1') +
          ': ' + mean.toFixed(0) + 'ms ' + $translate.instant('measurement_caliper_info_2') + ' ' +
          numOfPoints + ' ' + $translate.instant('measurement_caliper_info_3') + ' \n' + beatsPerMin.toFixed(0) + ' ' +
          $translate.instant('measurement_caliper_info_4');
      } else {
        caliperInfo.textContent = '-';
      }
    }

    function getOffset(el) {
      let _x = 0;
      let _y = 0;
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
      }
      return {top: _y, left: _x};
    }

    function zoomCaliperNode(fromBefore, toBefore) {
      const dm = document.getElementById('dragme' + currentPlotType);
      if (dm != null) {
        const axesAfter = currentPlot.getAxes();
        const fromAfter = axesAfter.xaxis.min.toFixed(2);
        const toAfter = axesAfter.xaxis.max.toFixed(2);
        const totmsAfter = toAfter - fromAfter;
        const totmsBefore = toBefore - fromBefore;
        const changedInPercent = totmsBefore / totmsAfter;
        if (changedInPercent != 1) {
          const x = getPixelFromTime(caliperStorage.caliperPositionInTime);
          const dmWidthNew = dm.offsetWidth * changedInPercent;
          createCaliper(dmWidthNew, x);
        }
      }
    }

    function createCaliper(width, x) {
      let dm = document.getElementById('dragme' + currentPlotType);
      if (dm !== null) {
        $('#dragme' + currentPlotType).remove();
      }

      const link = document.createElement('caliper');
      link.setAttribute('draggable', 'true');
      link.setAttribute('id', ('dragme' + currentPlotType));
      let firstpointonly = '';
      if (width == 1) {
        firstpointonly = '; border-right: 0px';
        caliperStorage.caliperWidthInTime = 0;
        caliperStorage.caliperPositionInTime = getTimeFromPixel(x);
      } else {
        caliperStorage.caliperWidthInTime = getTimeFromWidth2(x, width);

        const caliperTime = document.createElement('caliperTime');
        caliperTime.textContent = (caliperStorage.caliperWidthInTime).toFixed(0) + 'ms';
        link.appendChild(caliperTime);
        drawCaliperInfo();
      }
      link.setAttribute('style', 'width: ' + width + 'px; top: 0; left: ' + x + 'px' + firstpointonly);

      const sectionnode = $('#section-ecg-plot-' + currentPlotType);
      sectionnode.append(link);

      const placeholder = $('#ecg-plot-' + currentPlotType);
      placeholder.append(link);

      dm = document.getElementById('dragme' + currentPlotType);
      dm.addEventListener('dragstart', drag_start, false);
    }

    function getTimeFromWidth2(x, width) {
      const t1 = getTimeFromPixel(x);
      const t2 = getTimeFromPixel(x + width);
      return t2 - t1;
    }

    function getTimeFromWidth(value) {
      const plotnode = document.getElementById('ecg-plot-' + currentPlotType);
      const plotWidth = (plotnode.offsetWidth);
      const axes = currentPlot.getAxes();
      const from = axes.xaxis.min.toFixed(2);
      const to = axes.xaxis.max.toFixed(2);
      const msperpixel = (to - from) / plotWidth;

      return value * msperpixel;
    }

    function getTimeFromPixel(value) {
      const axes = currentPlot.getAxes();
      return axes.xaxis.c2p(value - (axes.xaxis.box.left + 5));
    }

    function getPixelFromTime(value) {
      const axes = currentPlot.getAxes();
      return axes.xaxis.p2c(value) + axes.xaxis.box.left + 5;
    }

    function getPlotPosition(event) {
      const axes = currentPlot.getAxes();
      const plotnode = document.getElementById('ecg-plot-' + currentPlotType);
      const x = getOffset(plotnode).left;
      let positionX = (event.clientX - x);
      let positionX2 = positionX;
      let dmWidth = 0;
      const dm = document.getElementById('dragme' + currentPlotType);
      if (dm != null) {
        positionX = (event.clientX - x - dm.offsetWidth);
        positionX2 = event.clientX - x;
        dmWidth = dm.offsetWidth;
      }

      const x2 = plotnode.offsetWidth - dmWidth;

      // console.log(getTimeFromPixel(positionX) + ' : ' + positionX + ' : ' + getTimeFromPixel(dmWidth) + ' : ' + dmWidth + ' : ' + getTimeFromPixel(positionX2));

      return {x: positionX, x2: positionX2};
    }

    vm.yZoom = function() {
      let yLevel = 0;
      if (currentPlotType === 'finger') {
        yLevel = vm.yZoomLevelFinger.value;
      } else {
        yLevel = vm.yZoomLevelChest.value;
      }
      if (currentPlot !== null) {
        const axes_data = currentPlot.getAxes();
        axes_data.yaxis.options.min = yLevel * -1;
        axes_data.yaxis.options.max = yLevel;
        if (yLevel === 0.5) {
          axes_data.yaxis.options.tickSize = 0.1;
        } else if (yLevel === 1) {
          axes_data.yaxis.options.tickSize = 0.5;
        } else {
          axes_data.yaxis.options.tickSize = 1;
        }
        currentPlot.setupGrid();
        currentPlot.draw();
      }
    };

    vm.gender = function() {
      if (vm.medicalJournal == null) {
        return 'fa';
      } else {
        return vm.medicalJournal.gender === 'female' ? 'fa fa-venus' : 'fa fa-mars';
      }
    };

    vm.goBack = function() {
      if (vm.fromUri != undefined && vm.fromUri == 'main.patientmeasurementlist') {
        $state.go('main.patientmeasurementlist', {
          startItem: vm.startItem
        });
      } else {
        $state.go('main.patientdetails', {
          userId: vm.userId,
          startItem: vm.startItem
        });
      }
    };

    vm.downloadPdf = function(type) {
      vm.showSpinner = true;
      let measurementType = 'EcgChestAndFinger';
      if (type) {
        if (type === 'finger') {
          measurementType = 'EcgFinger';
        } else if (type === 'chest') {
          measurementType = 'EcgChest';
        }
      }
      console.log('download pdf: ' + measurementType);

      let patientId = vm.userDetails.firstname + vm.userDetails.lastname;

      if (vm.userDetails.patientIdentificationNo != null) {
        patientId = patientId + '_' + vm.userDetails.patientIdentificationNo;
      } else {
        patientId = patientId + '_' + new moment(vm.userDetails.dateOfBirth).format('YYYYMMDD');
      }

      if (measurementType === 'EcgChestAndFinger') {
        const annotationsChest = !vm.disableMarkersChest ? 'annotationsAndHBars' : 'none';
        const annotationsFinger = !vm.disableMarkersFinger ? 'annotationsAndHBars' : 'none';

        PatientService.getChestAndFingerMeasurementPdf(
          patientId + '_' + new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm') + '.pdf',
          vm.measurementId,
          null,
          '0',
          'twentyFive',
          vm.getmVPerSecString(vm.yZoomLevelChest.value),
          'twentyFive',
          vm.getmVPerSecString(vm.yZoomLevelFinger.value),
          annotationsChest,
          annotationsFinger,
          vm.graphInvertedChest,
          vm.graphInvertedFinger,
          true).then(function(data) {
          vm.showSpinner = false;
        }, function(reason) {
          vm.showSpinner = false;
          vm.showError($translate.instant('download_pdffile_error'));
        });
      } else {
        let yLevel = 0;
        if (currentPlotType === 'finger') {
          yLevel = vm.yZoomLevelFinger.value;
        } else {
          yLevel = vm.yZoomLevelChest.value;
        }
        PatientService.getMeasurementPdf('measurement_' + patientId + '_' +
          new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm') + '.pdf', vm.measurementId, measurementType, null, '0', 'twentyFive',
          vm.getmVPerSecString(yLevel), 'annotationsAndHBars', vm.graphInvertedChest, vm.graphInvertedFinger).then(function(data) {
          vm.showSpinner = false;
        }, function(reason) {
          vm.showSpinner = false;
          vm.showError($translate.instant('download_pdffile_error'));
        });
      }
    };

    vm.getmVPerSecString = function(mVPerSecNumber) {
      let mVPerSecString = 'ten';
      if (mVPerSecNumber == 0.5) {
        mVPerSecString = 'fourty';
      } else if (mVPerSecNumber == 1) {
        mVPerSecString = 'twenty';
      } else if (mVPerSecNumber == 2) {
        mVPerSecString = 'ten';
      } else if (mVPerSecNumber == 5) {
        mVPerSecString = 'four';
      }
      return mVPerSecString;
    };

    vm.downloadFile = function(type) {
      vm.showSpinner = true;
      const measurementType = type === 'finger' ? 'ecgFinger' : type;
      const partName = GetMeasurementPartName(currentPlotTypeConfigurationId, type);
      let suffix = 'data';
      if (measurementType === 'HeartSound' || measurementType === 'Stethoscope') {
        suffix = 'wav';
      }

      console.log('download file: ' + measurementType);
      let patientId = vm.userDetails.patientIdentificationNo;
      if (patientId == null) {
        patientId = vm.userDetails.firstname + vm.userDetails.lastname;
      }
      PatientService.getMeasurementFile('measurement_' + patientId + '_' +
        new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm_') + measurementType + '_' + partName + '.' + suffix, vm.measurementId, measurementType, currentPlotTypeConfigurationId).then(function(data) {
        vm.showSpinner = false;
      }, function(reason) {
        vm.showSpinner = false;
        vm.showError($translate.instant('download_file_error'));
      });
    };

    function GetMeasurementPartName(configurationId, type) {
      if (configurationId != '00000000-0000-0000-0000-000000000001') {
        const parts = jQuery.grep(vm.measurement.measurementParts, function(x) {
          return x.measurementPartConfigurationId === configurationId && x.measurementPartType.toLowerCase() === type.toLowerCase();
        });

        return parts.length > 0 ? parts[0].measurementPartConfigurationName.split(' ').join('_') : '';
      }
      return '';
    }

    function GetMeasurementPart(configurationId, type) {
      if (vm.measurement === undefined) {
        return null;
      }
      const parts = jQuery.grep(vm.measurement.measurementParts, function(x) {
        return x.measurementPartConfigurationId === configurationId && x.measurementPartType.toLowerCase() === type.toLowerCase();
      });
      return parts[0];
    }

    vm.toggleInvertGraph = function() {
      console.log('invert graph');
      const obj = currentPlot.getData();
      const data = obj[0].data;

      for (let i = 0; i < data.length; i++) {
        data[i][1] = data[i][1] * (-1);
      }

      obj[0].data = data;
      currentPlot.setData(obj);
      currentPlot.draw();
    };

    function caliperEnabled() {
      if (currentPlotType === 'finger') {
        return vm.enableCalipterFinger;
      } else if (currentPlotType === 'chest') {
        return vm.enableCalipterChest;
      } else {
        return false;
      }

    }

    vm.toggleCaliper = function() {
      let plotnode;
      if (!caliperEnabled()) {
        resetCaliperForPartType(currentPlotType);
        const dm = document.getElementById('dragme' + currentPlotType);
        if (dm !== null) {
          $('#dragme' + currentPlotType).remove();
        }

        plotnode = document.getElementById('ecg-plot-' + currentPlotType);
        plotnode.style.cursor = 'default';
      } else {
        plotnode = document.getElementById('ecg-plot-' + currentPlotType);
        plotnode.style.cursor = 'crosshair';
      }

    };

    vm.toggleMarkers = function() {
      console.log('markers on/off');

      if (currentPlotType == 'finger') {
        vm.fingerLoaded = false;
        vm.enableCalipterFinger = false;
        vm.graphInvertedFinger = false;
      }
      if (currentPlotType == 'chest') {
        vm.chestLoaded = false;
        vm.enableCalipterChest = false;
        vm.graphInvertedChest = false;
      }

      removePartFromCache(currentPlotTypeConfigurationId, currentPlotType);

      vm.toggleCaliper();

      setTab(currentPlotType);
    };

    function setErrorMessage(type, message, error) {
      if (error === '') {
        message = $translate.instant('measurement_error_2');
      }

      if (type === 'finger') {
        vm.hideFingerSpinner = vm.showErrorMessageFinger = true;
        vm.errorMessageFinger = message;

      } else {
        vm.hideChestSpinner = vm.showErrorMessageChest = true;
        vm.errorMessageChest = message;
      }
    }

    function drag_start(event) {
      const style = window.getComputedStyle(event.target, null);
      event.dataTransfer.setData('text',
        (parseInt(style.getPropertyValue('left'), 10) - event.clientX) + ',' + (parseInt(style.getPropertyValue('top'), 10) - event.clientY));

    }

    function drag_over(event) {
      event.preventDefault();
      return false;
    }

    function drop(event) {
      let newWidth;
      let diff;
      const offset = event.dataTransfer.getData('text').split(',');
      const dm = document.getElementById('dragme' + currentPlotType);
      if (dm !== null) {
        const x = (event.clientX + parseInt(offset[0], 10));
        dm.style.top = 0;

        if (dragType == 'west') {
          const prevPositionX = getPixelFromTime(caliperStorage.caliperPositionInTime);
          diff = prevPositionX - x;
          newWidth = dm.offsetWidth + diff;
          caliperStorage.caliperPositionInTime = getTimeFromPixel(x);
          createCaliper(newWidth, x);
        } else if (dragType == 'east') {
          const x1 = getPixelFromTime(caliperStorage.caliperPositionInTime);
          diff = x - x1;
          newWidth = dm.offsetWidth + diff;
          createCaliper(newWidth, x1);
        } else {
          dm.style.left = x + 'px';
          caliperStorage.caliperPositionInTime = getTimeFromPixel(x);
          const placeholder = $('#ecg-plot-' + currentPlotType);
          placeholder.append(dm);
          drawCaliperInfo();
        }
      }

      event.preventDefault();
      return false;
    }

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    document.body.addEventListener('dragover', drag_over, false);
    document.body.addEventListener('drop', drop, false);

  }
})();
