import "core-js";
import "regenerator-runtime/runtime";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "angular-multiple-select/build/multiple-select.css";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "lobibox/dist/css/lobibox.css";
import Analytics from "analytics";
// @ts-ignore
import googleTagManager from "@analytics/google-tag-manager";

import "./styles/bootstrap_overrides.css";
import "./styles/components.scss";
import "./styles/main.css";
import "./styles/patient.css";
import "./styles/spinner.css";
import "./styles/modals.scss";
import "./styles/ccp3.scss";

import "jquery";
import "bootstrap";
import "angular";
import "angular-route";
import "@uirouter/angularjs";
import "@uirouter/angularjs/lib/legacy/stateEvents.js";
import "angular-ui-bootstrap";
import "angular-animate";
import "angular-cookies";
import "angular-modal-service/dst/angular-modal-service.min";
import "angular-multiple-select";
import "angular-resource";
import "angular-sanitize";
import "angular-smart-table";
import "angular-touch";
import "angular-translate";
import "angular-translate-storage-cookie";
import "angular-qr";
import "file-saver";
import "moment";
import "moment-timezone";
import "eonasdan-bootstrap-datetimepicker";
import "angular-eonasdan-datetimepicker";
import "lobibox/dist/js/lobibox";
import "multiple-select";
import "angularjs-dropdown-multiselect";

import "./app";
import "./modules/core";
import "./modules/login";
import "./modules/patients";
import "./modules/admin";
import "./services";
import "./directives";
import "./filters";

// Disable logging in prod builds
if (process.env.NODE_ENV != "development") {
    // @ts-ignore
    if (!window.console) window.console = {};
    const methods = ["log", "debug", "warn", "info", "table"];
    for (let i = 0; i < methods.length; i++) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        console[methods[i]] = () => {};
    }
}

if (window.self !== window.top) {
    document.body.className = document.body.className + " ccp3";
    if (document.location.href.includes("patient/measurement")) {
        document.body.style.backgroundColor = "white";
    }
}
