import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminHealthcareProviderListController', AdminHealthcareProviderListController);

  function AdminHealthcareProviderListController($scope, $rootScope, $translate, ModalService, HealthcareProviderService, SessionService, UtilService, NationService, envConfig) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    let _tableState = null;
    vm.showSpinner = false;

    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    vm.fetchHealthcareProviders = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);

      updateHealthcareProvidersTable();
    };

    function updateHealthcareProvidersTable() {
      if (vm.permissions.readHealthcareProvider) {
        vm.showSpinner = true;
        HealthcareProviderService.getHealthcareProviders(pagingParameters).then(function(response) {
          vm.providers = response.rows;
          $rootScope.providers = response.rows;
          vm.totalProviders = response.nbrOfRows;
          vm.nrOfPages = response.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = response.nbrOfPages;
          }
          vm.showSpinner = false;

        });
      }
    }

    vm.yesNo = function(value) {
      if (value) {
        return $translate.instant('yes');
      }
      return $translate.instant('no');
    };

    vm.createNewHealthcareProviderModal = function(isEditMode, id, name, publicVisible, genarallyAccepted, addressLine1, addressLine2,
                                                    zipcode, region, city, phone, email, myJournalRequeriesAuth, roles, healthcareProviderGroupId) {
      if (vm.permissions.writeHealthcareProvider) {
        $rootScope.isEditMode = isEditMode;
        vm.name = name;
        $rootScope.providerName = name;
        $rootScope.id = id;
        $rootScope.publiclyVisibleHealthcareProvider = publicVisible;
        $rootScope.genarallyAcceptedHealthcareProvider = genarallyAccepted;
        $rootScope.addressLine1 = addressLine1;
        $rootScope.addressLine2 = addressLine2;
        $rootScope.zipcode = zipcode;
        $rootScope.region = region;
        $rootScope.city = city;
        $rootScope.phone = phone;
        $rootScope.email = email;
        $rootScope.myJournalRequeriesAuth = myJournalRequeriesAuth;
        $rootScope.roles = roles;
        $rootScope.healthcareProviderGroupId = healthcareProviderGroupId;
        ModalService.showModal({
          template: require('../views/modals/healthcareProvider.create.edit.tpl.html').default,
          controller: 'AdminHealthcareProviderCreateEditController',
          controllerAs: 'vm',
        }).then(function(modal) {
          modal.element.modal();
          modal.close.then(function(result) {
            $('.modal-backdrop').hide(); // for black background
            updateHealthcareProvidersTable();
            console.log(result);
          });
        });
      }
    };
  }
})();
