import * as angular from 'angular';
import {RepresentationAlternative, TokenResponse} from '../../../services/models/TokenResponse';
import Permissions from '../../../services/models/Permissions';

angular.module('core')
    .component('coreHeader', {
        template: require('../views/header.tpl.html').default,
        controller: [
            '$rootScope', '$scope', '$translate', '$state', '$stateParams', '$http', '$window',
            'AuthService', 'SessionService', 'NationService', 'envConfig',

            class CoreHeaderController {
                public permissions: Permissions;
                public userLoginProfiles: RepresentationAlternative[];
                public currentUserIcon: string;
                public currentUserDescription: string;
                public languageTitle = 'English US';
                public languageOptions = [
                    {key: 'en-us', name: 'English US'},
                    {key: 'en', name: 'English'},
                    {key: 'sv', name: 'Svenska'},
                    {key: 'fr', name: 'Français'},
                    {key: 'it', name: 'Italiano'},
                    {key: 'de', name: 'Deutsch'},
                    {key: 'nl', name: 'Nederlands'},
                ];

                private sessionService: any
                private translationService: any;
                private authService: any;
                private nationService: any;

                constructor($rootScope: any, $scope: any, $translate: any, $state: any, $stateParams: any, $http: any, $window: any, AuthService: any, SessionService: any, NationService: any, envConfig: any) {
                    $rootScope.coalaVersion = envConfig.coalaVersion;
                    this.translationService = $translate;
                    this.authService = AuthService;
                    this.nationService = NationService;
                    this.sessionService = SessionService;
                    this.permissions = SessionService.getUserPermissions();
                    this.userLoginProfiles = [];
                    this.currentUserIcon = '';
                    this.currentUserDescription = '';
                    this.setUserLanguage($translate.use());
                    this.setUserLoginProfiles();
                }

                public changeUserLoginProfile = (id: string, representationType: string) => {
                    const represent = {'id': id, 'representationType': representationType};
                    const token = this.sessionService.getAuthToken().replace('bearer ', '');
                    const req = {'grant_type': 'changeRepresentation', 'access_token': token, 'represent': represent};
                    this.authService.login(req).then((response: TokenResponse) => {
                        this.authService.setAndRedirectUser(response);
                        this.setUserLoginProfiles();
                    });
                };

                public getRepresentationTypeIcon = (item: RepresentationAlternative) => {
                    let style = '';
                    const userToken = this.sessionService.getLoggedInUser();
                    if (userToken !== null && userToken.representing.id === item.id) {
                        style = ' darkBlueFontBold';
                    }
                    return this.getRepresentationIcon(item, style);
                };

                public getRepresentationTypeStyleClass = (item: RepresentationAlternative) => {
                    let style = '';
                    const userToken = this.sessionService.getLoggedInUser();
                    if (userToken !== null && userToken.representing.id === item.id) {
                        style = 'darkBlueFontBold';
                    }
                    return style;
                };

                public getUserName = () => {
                    const user = this.sessionService.getLoggedInUser();
                    if (user != null) {
                        const name = user.userFirstName + ' ' + user.userLastName;
                        return name !== '' ? name : user.userName;
                    } else {
                        return '';
                    }
                };

                public isLoggedIn = () => this.sessionService.getLoggedInUser() != undefined;

                public logout = () => this.authService.logOut();

                public goToStartPage = () => this.authService.goToStartPage();

                public getCoalaCareInstructionsUrl = () => this.nationService.getCoalaSiteUrlWithNationCode('coala-care-instructions');

                public getCoalaCareUserManualUrl = () => this.nationService.getCoalaSiteUrlWithNationCode('coalacareusermanual');

                public changeLanguage = (langKey: string) => this.setUserLanguage(langKey);

                public hasLanguage = (langKey: string) => this.nationService.hasLanguage(langKey);

                private setUserLoginProfiles(): void {
                    this.userLoginProfiles = [];
                    let desc = '';
                    let prefix = '';
                    const userToken: TokenResponse = this.sessionService.getLoggedInUser();
                    if (userToken !== null) {
                        if (userToken.representationAlternatives !== null) {
                            userToken.representationAlternatives.forEach((item) => {
                                if (item.name === '') {
                                    prefix = this.translationService.instant('login_as') + ' ';
                                    item.name = 'Administrator';
                                    desc = 'Administrator';
                                } else {
                                    prefix = this.translationService.instant('login_to') + ' ';
                                    desc = item.name;
                                }
                                if (userToken.representing.id === item.id) {
                                    this.currentUserIcon = this.getRepresentationIcon(item, '');
                                    this.currentUserDescription = desc;
                                    prefix = this.translationService.instant('logged_in_to') + ' ';
                                }
                                item.name = prefix + item.name;
                                this.userLoginProfiles.push({
                                    id: item.id,
                                    name: item.name,
                                    representationType: item.representationType
                                });
                            });
                        } else {
                            this.currentUserIcon = 'fas fa-user';
                        }
                        this.permissions = this.sessionService.getUserPermissions();
                    }
                }

                private setUserLanguage(lang: string) {
                    if (this.nationService.hasLanguage(lang)) {
                        for (const option of this.languageOptions) {
                            if (lang == option.key) {
                                this.languageTitle = option.name;
                            }
                        }
                        if (!this.languageTitle || this.languageTitle.length == 0) {
                            this.languageTitle = 'English';
                            lang = 'en';
                        }
                    } else {
                        if (this.nationService.hasLanguage('en')) {
                            this.languageTitle = 'English';
                            lang = 'en';
                        } else {
                            this.languageTitle = 'English US';
                            lang = 'en-us';
                        }
                    }
                    this.translationService.use(lang);
                }

                private getRepresentationIcon(item: RepresentationAlternative, style: string) {
                    if (item.representationType === 'healthcareProvider') {
                        return 'fas fa-hospital' + style;
                    } else if (item.representationType === 'unrestricted') {
                        return 'fas fa-user' + style;
                    } else {
                        return 'fas fa-user-friends' + style;
                    }
                }
            }]
    });
