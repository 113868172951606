import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminUserListController', AdminUserListController);

  function AdminUserListController($rootScope, $state, $stateParams, $filter, $translate, PatientService, MeasurementHelperService, ModalService, SessionService, NationService, HealthcareProviderService, UtilService, MessageService, envConfig, $timeout) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    let _tableState = null;
    let _timeout;
    vm.showSpinner = false;

    let pagingParameters = {
      page: 0,
      id: '',
      pageSize: 20
    };

    vm.input = '';
    vm.result = '';
    vm.multiplePatientsFound = false;
    vm.patientFound = false;
    vm.multiplePatients = [];
    vm.healthcareProviders = [];
    vm.subscriptions = [];
    vm.notificationRegistrations = [];
    vm.lastLoaded = '';
    vm.gender = '';
    vm.userdata = null;
    vm.hidePaging = false;
    vm.loadedR = 0;
    vm.inputDisabled = false;
    vm.searchOptions = [{name: 'user_searchoption_all', value: 0}, {name: 'user_searchoption_active', value: 1}, {name: 'user_searchoption_inactive', value: 2},
      {name: 'user_searchoption_activeconnection', value: 3}, {name: 'user_searchoption_cancelled', value: 5}, {name: 'user_searchoption_borrowed', value: 6},
      {name: 'user_searchoption_screening', value: 7}, {name: 'user_searchoption_coalaconnect', value: 8},
      {name: 'user_searchoption_nation_germany', value: 276}, {name: 'user_searchoption_nation_france', value: 250}, {name: 'user_searchoption_nation_italy', value: 380},
      {name: 'user_searchoption_nation_japan', value: 392}, {name: 'user_searchoption_nation_sweden', value: 752}, {name: 'user_searchoption_nation_us', value: 840},
      {name: 'user_searchoption_nation_unitedkinkdom', value: 826}, {name: 'user_searchoption_nation_netherlands', value: 528}];
    vm.searchOption = vm.searchOptions[0];

    vm.scheduledTasksSettings = {
      tasksList: [],
    };

    activate();

    function activate() {
      const filter = SessionService.getFilterOptions();
      vm.searchOption = filter.searchOptionUser;
      vm.input = filter.searchTextUser === undefined ? '' : filter.searchTextUser;

      const userId = $stateParams.userId;
      if (userId !== '' && userId !== undefined && userId !== null) {
        vm.userId = userId;
        getUserDetails(userId);
      }
    }

    document.getElementById('search-user').focus();

    function getUserDetails(id) {
      vm.userId = id;
      vm.startItem = $stateParams.startItem;
      vm.patientFound = true;
      vm.multiplePatientsFound = false;
      vm.inputDisabled = true;
      if (vm.lastLoaded != id) {
        vm.showSpinner = true;
        vm.userDetails = null;
        vm.lastLoaded = id;
        PatientService.getUserDetails(id).then(function(data) {
          vm.userDetails = data;
          vm.coalaAtlasAgreement = data.coalaAtlasAgreement;
          vm.input = vm.userDetails.email;
          vm.loadedR += 1;
          vm.checkDoneLoading();

          PatientService.getHealthcareProviders(id).then(function(data) {
            vm.healthcareProviders = data.rows;
            vm.loadedR += 1;
            vm.checkDoneLoading();
          });
        });

        PatientService.getUserStatistics(id).then(function(data) {
          console.log(data);
          vm.measurementsCount = data.nbrOfMeasurements;
          vm.latestMeasurement = data.lastMeasurement;
          console.log(vm.latestMeasurement);
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });

        PatientService.getUserData(id).then(function(data) {
          vm.userdata = data;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });

        PatientService.getMedicalJournalGender(id).then(function(data) {
          vm.gender = data.gender;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });

        PatientService.getNotificationRegistrations(id).then(function(data) {
          vm.notificationRegistrations = data;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });

        if (vm.permissions.readPatientSubscription) {
          PatientService.getUserSubscriptions(id).then(function(data) {
            vm.subscriptions = data.rows;
            vm.loadedR += 1;
            vm.checkDoneLoading();
          });

          PatientService.getUserSubscription(id).then(function(data) {
            vm.subscription = data;
            vm.loadedR += 1;
            vm.checkDoneLoading();
          });
        }

        if (vm.permissions.readPayment) {
          PatientService.getUserPayments(id).then(function(data) {
            vm.payments = data.rows;
            vm.loadedR += 1;
            vm.checkDoneLoading();
          });
        }

        PatientService.getScheduledTasks(vm.userId).then(function(data) {
          vm.scheduledTasksSettings.tasksList = data;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });
      }
    }

    vm.openAIMO = function() {
      console.log('Show AIMO');
      $state.go('mainAdmin.aimo', {
        userId: vm.userId
      });
    };

    vm.checkDoneLoading = function() {
      if (vm.loadedR >= 7) {
        vm.showSpinner = false;
        vm.loadedR = 0;
      }
    };

    vm.showCancelLastTransaction = function(paymentId) {
      vm.cancelPaymentId = paymentId;
    };

    vm.cancelLastTransaction = function() {
      vm.showSpinner = true;
      PatientService.cancelTransaction(vm.cancelPaymentId).then(function(data) {
        PatientService.getUserPayments(vm.userId).then(function(data) {
          vm.payments = data.rows;
          PatientService.getUserSubscriptions(vm.userId).then(function(data) {
            vm.subscriptions = data.rows;
            PatientService.getUserSubscription(vm.userId).then(function(data) {
              vm.subscription = data;
              vm.showSpinner = false;
            });
          });
        });
      });
    };

    vm.showAllSubscriptions = function() {
      vm.showSpinner = true;
      PatientService.getAllUserSubscriptions(vm.userId).then(function(data) {
        vm.subscriptions = data.rows;
        vm.showSpinner = false;
      });
    };

    vm.loginMethodConverter = function(data) {
      if (data === 'passwordAndBankId') {
        return $translate.instant('loginmethodsoptions_bankIdandpwd');
      }
      if (data === 'password') {
        return $translate.instant('loginmethodsoptions_pwd');
      }
      if (data === 'bankId') {
        return $translate.instant('loginmethodsoptions_bankId');
      }
      if (data === 'none') {
        return $translate.instant('loginmethodsoptions_none');
      }
      return data;
    };

    vm.relationshipStatusConverter = function(relationship) {
      if (relationship.relationshipStatus === 'accepted') {
        return $translate.instant('relationship_accepted') + ' ' + $filter('date')(relationship.accepted, 'yyyy-MM-dd HH:mm');
      } else if (relationship.relationshipStatus === 'noRelationship') {
        return $translate.instant('relationship_norequest');
      } else if (relationship.relationshipStatus === 'pending') {
        return $translate.instant('relationship_requestsent') + ' ' + $filter('date')(relationship.requestCreated, 'yyyy-MM-dd HH:mm');
      } else if (relationship.relationshipStatus === 'rejected') {
        return $translate.instant('relationship_rejected');
      } else if (relationship.relationshipStatus === 'generallyAccepted') {
        return $translate.instant('relationship_generallyaccepted');
      }
    };

    vm.fetchUsers = function(tableState) {
      if (vm.userId !== undefined) {
        return;
      }

      let changedByUri = false;
      if (_tableState === null) {
        changedByUri = true;
      }
      if (tableState) {
        _tableState = tableState;
      }

      let startItem = $stateParams.startItem;
      if (startItem != tableState.pagination.start / 10 && !changedByUri) {
        startItem = tableState.pagination.start / 10;
      }
      _tableState.pagination.number = pagingParameters.pageSize;
      _tableState.pagination.start = startItem * pagingParameters.pageSize;
      vm.startItem = startItem;

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);

      if (startItem !== undefined) {
        $state.go('mainAdmin.userlist', {
          startItem: vm.startItem
        }, {notify: false});
      }

      vm.searchCoalaUser();
    };

    vm.clearSearch = function() {
      const currentState = $state.current.name;
      if (currentState == 'mainAdmin.userlist') {
        vm.patientFound = false;
        vm.inputDisabled = false;
        vm.input = '';
        vm.searchOption = vm.searchOptions[0];
        vm.searchCoalaUser(0);
      } else {
        $state.go('mainAdmin.userlist', {
          startItem: $stateParams.startItem
        });
      }
    };

    vm.removeSpinner = function() {
      vm.loadedR = 9;
      vm.checkDoneLoading();
    };

    vm.searchCoalaUser = function(page) {

      if (!vm.patientFound) {
        if (_timeout) { // if there is already a timeout in process cancel it
          $timeout.cancel(_timeout);
        }
        _timeout = $timeout(function() {

          vm.showSpinner = true;
          pagingParameters.search = 'searchoption:' + vm.searchOption.value + ';' + vm.input;
          if (page != undefined) {
            // New search start from page 1 if we are on another page
            if (pagingParameters.page != page) {
              $state.go('mainAdmin.userlist', {
                startItem: page
              });
            }
          }

          const filterOptions = SessionService.getFilterOptions();
          filterOptions.searchOptionUser = vm.searchOption;
          filterOptions.searchTextUser = vm.input;
          SessionService.setFilterOptions(filterOptions);

          PatientService.searchPatient(pagingParameters).then(function(result) {
            vm.showSpinner = false;
            if (result.rows.length == 0) {
              vm.patientNotFound = true;
              vm.patientFound = false;
              vm.multiplePatientsFound = false;
            } else if (result.rows.length > 1 || vm.input === '') {
              vm.patientFound = false;
              vm.patientNotFound = false;
              vm.multiplePatientsFound = true;
              vm.multiplePatients = result.rows;
            } else {
              vm.multiplePatientsFound = false;
              const ud = result.rows[result.rows.length - 1];
              if (!vm.showSpinner) {
                getUserDetails(ud.id);
                updateMessagesTable();
              }
            }

            if (_tableState) {
              _tableState.pagination.numberOfPages = result.nbrOfPages;
              if (result.nbrOfPages === 0) {
                vm.hidePaging = true;
              } else {
                vm.hidePaging = false;
              }
              vm.totalUsers = result.nbrOfRows;
              vm.nrOfPages = result.nbrOfPages;
            }
          });
          _timeout = null;

        }, 1500);
      }
    };

    vm.getGender = function() {
      if (vm.gender == null) {
        return 'fa';
      } else {
        return vm.gender === 'female' ? 'fa fa-venus' : 'fa fa-mars';
      }
    };

    vm.parseCoalaAtlasAgreement = function(code) {
      if (code == 'no') {
        return $translate.instant('atlasagreement_no');
      } else if (code == 'anonymous') {
        return $translate.instant('atlasagreement_anonymous');
      } else if (code == 'personalDetails') {
        return $translate.instant('atlasagreement_yes');
      }
    };

    vm.isCancelled = function(item) {
      return item.cancelled === true ? 'fa fa-check' : 'fa';
    };

    vm.isActivePayment = function(item) {
      return item.active === true ? 'fa fa-check' : 'fa';
    };

    vm.showSendMessageModal = function() {
      $rootScope.userDetails = vm.userDetails;
      ModalService.showModal({
        template: require('../../patients/views/modals/patient.send-message.tpl.html').default,
        controller: 'PatientSendMessageController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          console.log(result);
        });
      });
    };

    vm.fetchMessages = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      updateMessagesTable();
    };

    vm.viewAllMessages = function() {
      updateMessagesTable(9999);
    };

    vm.getMessageSubject = function(message) {
      if (message.messageType == 'healthcareProviderAccessRequest') {
        return message.subject + ' ' + $translate.instant('message_request_access');
      }
      return message.subject;
    };

    function updateMessagesTable(pageSize) {
      if (vm.userId != undefined) {
        if (pageSize == null) {
          pageSize = 10;
        }
        const pagingParameters = {
          page: 0,
          id: '',
          pageSize: pageSize,
          sort: '',
          search: ''
        };

        MessageService.getMessages(vm.userId, pagingParameters).then(function(data) {
          vm.messages = data.rows;
          vm.nrOfMessages = data.nbrOfRows;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
        });
      }
    }

    vm.showContent = function(content, id) {
      if (content !== null) {
        content = content.replace(/(?:\r\n|\r|\n)/g, '<br />');
        $('#contentSpan').html(content);

        $('.fa-caret-right').each(function(i, obj) {
          $(obj).hide();
        });

        $('.emailItem').each(function(i, obj) {
          $(obj).removeClass('coalaLightGrayBackground');
        });

        $('#' + id).show();
        $('.' + id).addClass('coalaLightGrayBackground');
      }
    };

    vm.showCreateAccountModal = function() {
      $rootScope.isEditMode = false;
      $rootScope.user = null;
      $rootScope.subscription = null;
      $rootScope.userDetails = null;

      ModalService.showModal({
        template: require('../views/modals/user.create.edit.modal.tpl.html').default,
        controller: 'UserCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          vm.input = $rootScope.email;
          vm.searchOption = vm.searchOptions[0];
          vm.patientFound = false;
          vm.searchCoalaUser();
          console.log(result);
        });
      });
    };

    vm.showImportAccountModal = function() {
      ModalService.showModal({
        template: require('../views/modals/user.import.modal.tpl.html').default,
        controller: 'UserImportController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          vm.input = $rootScope.email;
          vm.searchOption = vm.searchOptions[0];
          vm.patientFound = false;
          vm.searchCoalaUser();
          console.log(result);
        });
      });
    };

    vm.unlockAccount = function() {
      PatientService.postUnlockUser(vm.userdata.id).then(function(response) {
        vm.showFeedback($translate.instant('user_account_unlocked'));
        PatientService.getUserData(vm.userdata.id).then(function(data) {
          vm.userdata = data;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });
      });
    };

    vm.getStaffName = function(subscription) {
      return subscription.createdByFirstname + ' ' + subscription.createdByLastname + ' (' + subscription.createdByEmail + ')';
    };

    vm.isTestUser = function(item) {
      return item.testUser === true ? 'fa fa-check' : 'fa';
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none');
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic');
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium');
      }

      return '';
    };

    function toUTC(/* Date*/date) {
      return Date.UTC(
        date.getFullYear()
        , date.getMonth()
        , date.getDate()
        , date.getHours()
        , date.getMinutes()
        , date.getSeconds()
        , date.getMilliseconds()
      );
    } // toUTC()

    vm.daysSinceNow = function(d1) {
      let ndays;
      const endDate = new Date();
      const tv1 = toUTC(new Date(d1));
      const tv2 = endDate.getTime();
      ndays = (tv2 - tv1) / 1000 / 86400;
      ndays = Math.round(ndays);
      return ndays;
    };

    vm.parseAppErrorCode = function(measurement) {
      return MeasurementHelperService.parseAppErrorCode(measurement);
    };

    vm.appStatus = function(measurement) {
      return MeasurementHelperService.appStatus(measurement);
    };

    vm.getLocalTime = function(utctime) {
      if (utctime != null) {
        const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
        const localTime = moment.utc(date).toDate();
        return localTime;
      }
      return '-';
    };

    vm.getCancelledColor = function(cancelled) {
      if (cancelled) {
        return {'color': 'red'};
      }
      return null;
    };

    vm.displayUserDetails = function(id) {
      $state.go('mainAdmin.userdetails', {
        userId: id,
        startItem: vm.startItem
      });
    };

    vm.displayPatientDetails = function() {
      $state.go('mainAdmin.patientDetails', {
        userId: vm.userDetails.userId,
        startItem: vm.startItem
      });
    };

    vm.showEditUserModal = function() {
      $rootScope.isEditMode = true;
      $rootScope.userdata = vm.userdata;
      $rootScope.subscription = vm.subscription;
      $rootScope.userDetails = vm.userDetails;

      ModalService.showModal({
        template: require('../views/modals/user.create.edit.modal.tpl.html').default,
        controller: 'UserCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          if (vm.userId != undefined) {
            PatientService.getUserSubscriptions(vm.userId).then(function(data) {
              vm.subscriptions = data.rows;
            });
            PatientService.getUserSubscription(vm.userId).then(function(data) {
              vm.subscription = data;
            });
          } else {
            vm.input = vm.userDetails.email;
            vm.searchCoalaUser();
          }
          console.log(result);
        });
      });
    };

    vm.showSendOrderModal = function() {
      $rootScope.userDetails = vm.userDetails;
      ModalService.showModal({
        template: require('../../patients/views/modals/patient.ordercoala.modal.tpl.html').default,
        controller: 'UserOrderCoalaController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          console.log(result);
        });
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
