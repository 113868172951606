import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('core')
    .controller('AzureInfoController', AzureInfoController);

  function AzureInfoController($rootScope, $state, $stateParams, $http, $window, envConfig, AzureService) {
    const vm = this;

    AzureService.listCloudServices().then(function(response) {
      console.log('response');
    });
  }
})();
