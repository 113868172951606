import * as angular from 'angular';
import Permissions from '../../../../services/models/Permissions';
import {IdNameSelectable} from '../../controllers/admin.healthcareProvider.create.edit.controller';


export interface HcpRolesFormSettings {
    rolesList: IdNameSelectable[];
    hasRoleChanged: boolean;
}

// language=HTML
const template = `
    <form name="$ctrl.form">
        <div class="row">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"healthcare_create_hcp_roles" | translate}}
                </div>
                <div class="col-md-12">
                    <h4>{{ "healthcare_create_lock_roles" | translate}}</h4>
                </div>
                <div class="col-md-6" ng-repeat="role in $ctrl.settings.rolesList" title="{{$ctrl.getToolTip(role)}}">
                    <label>
                        <input ng-click="$ctrl.roleChanged()" ng-model="role.selected" type="checkbox">
                        <span>{{ $ctrl.getRoleName(role) }}</span>
                    </label>
                </div>
            </fieldset>
        </div>
    </form>
`;

angular.module('admin')
    .component('hcpRolesForm',
        {
            bindings: {
                isEditMode: '<',
                validated: '=',
                settings: '='
            },
            template: template,
            controller: ['$translate', 'SessionService', class HcpRolesForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: HcpRolesFormSettings;
                form: any;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly permissions: Permissions;

                constructor($translate: angular.translate.ITranslateService, SessionService: any) {
                    this.translateService = $translate;
                    this.permissions = SessionService.getUserPermissions();
                }

                $doCheck() {
                    if (this.form) {
                        this.validated = this.form.$valid;
                    }
                }

                roleChanged = () => {
                    this.settings.hasRoleChanged = true;
                };


                getToolTip = (role: IdNameSelectable) => {
                    const text = this.translateService.instant(('roles_desc_' + role.name).toLowerCase());
                    if (text.indexOf('roles_desc_') > -1) {
                        return '';
                    }
                    return text;
                };

                getRoleName = (role: IdNameSelectable) => {
                    const text = this.translateService.instant(('roles_name_' + role.name).toLowerCase());
                    if (text.indexOf('roles_name_') > -1) {
                        return role.name;
                    }
                    return text;
                };
            }]
        }
    );
