import * as angular from 'angular';

angular.module('login', ['ja.qr']).config(['$stateProvider', '$urlRouterProvider', routes]);

// Routing
function routes($stateProvider: any, $urlRouterProvider: any) {
    $stateProvider
        .state('login', {
            url: '/login?:redirectUrl',
            controller: 'LoginController',
            template: require('./views/login.tpl.html').default,
            controllerAs: 'vm'
        }).state('loginOther', {
        url: '/loginother',
        controller: 'LoginController',
        template: require('./views/login.tpl.html').default,
        controllerAs: 'vm',
        params: {
            type: {
                value: 'other',
                squash: false
            }
        }
    }).state('loginSame', {
        url: '/loginsame',
        controller: 'LoginController',
        template: require('./views/login.tpl.html').default,
        controllerAs: 'vm',
        params: {
            type: {
                value: 'same',
                squash: false
            }
        }
    }).state('loginsiths', {
        url: '/loginsiths',
        controller: 'LoginController',
        template: require('./views/login.tpl.html').default,
        controllerAs: 'vm',
        params: {
            type: {
                value: 'siths',
                squash: false
            }
        }
    }).state('unauthorized', {
        url: '/unauthorized',
        params: {
            message: {value: 'you_are_not_authorized', squash: false}
        },
        controller: 'LoginController',
        template: require('./views/unauthorized.tpl.html').default,
        controllerAs: 'vm'
    }).state('invaliduserorpassword', {
        url: '/invaliduserorpassword',
        params: {
            message: {value: 'invalid_username_password', squash: false}
        },
        controller: 'LoginController',
        template: require('./views/unauthorized.tpl.html').default,
        controllerAs: 'vm'
    }).state('installtotpsecretcode', {
        url: '/installtotpsecretcode',
        controller: 'LoginController',
        template: require('./views/install.totp.secret.tpl.html').default,
        controllerAs: 'vm'
    }).state('maintenance', {
        url: '/maintenance',
        controller: 'LoginController',
        template: require('./views/maintenance.tpl.html').default,
        controllerAs: 'vm'
    });

    $urlRouterProvider.otherwise('/login');
}
