import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('core')
    .controller('SettingsEditController', SettingsEditController);

  function SettingsEditController($rootScope, ModalService, PatientService, SessionService, SettingsService, close) {
    const vm = this;
    vm.cultureOptions = [{name: 'SV', value: 'SV'}, {name: 'EN', value: 'EN'}, {name: 'DE', value: 'DE'}, {name: 'IT', value: 'IT'}, {name: 'FR', value: 'FR'}, {
      name: 'NL',
      value: 'NL'
    }, {name: 'DA', value: 'DA'}];
    vm.settingUpdated = true;
    vm.setting = $rootScope.setting;
    vm.value = vm.setting.value;
    vm.language = vm.cultureOptions[0];

    activate();

    function activate() {
      vm.language.value = vm.setting.language;
    }

    vm.updateSetting = function() {
      if (vm.settingUpdated) {
        const setting = {
          value: vm.value,
          language: vm.language.value,
          settingsType: vm.setting.settingsType,
        };

        vm.setting.value = vm.value;
        vm.setting.language = vm.language.value;

        SettingsService.putSetting(setting).then(function(data) {
          $rootScope.setting = setting;
          vm.showFeedback('Setting is updated.');
          close('', 500);
        });
      }
    };

    vm.setLanguage = function() {
      if (vm.setting.language !== vm.language) {
        vm.settingUpdated = true;
      }
    };

    vm.setValue = function() {
      if (vm.setting.value !== vm.value) {
        vm.settingUpdated = true;
      }
    };

    vm.getType = function(type) {
      if (type === 'welcomeMessageTitlePlus') {
        return 'Premium - Välkomstmeddelande titel';
      } else if (type === 'welcomeMessageBodyPlus') {
        return 'Premium - Välkomstmeddelande innehåll';
      } else if (type === 'welcomeMessageTitleBase') {
        return 'Basic - Välkomstmeddelande titel';
      } else if (type === 'welcomeMessageBodyBase') {
        return 'Basic - Välkomstmeddelande innehåll';
      }
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

  }
})();
