import * as angular from 'angular';
import '../../../scripts/plot/jquery.flot';
import '../../../scripts/plot/jquery.flot.time';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminAIMOController', AdminAIMOController);

  function AdminAIMOController($rootScope, $state, $stateParams, $filter, $translate, PatientService, SessionService, NationService, UtilService, MessageService, envConfig, $timeout) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();

    vm.evaluation = null;
    vm.evaluations = [];
    vm.evaluationsToPlot = [];

    activate();

    function activate() {

      const userId = $stateParams.userId;
      if (userId !== '' && userId !== undefined && userId !== null) {
        vm.userId = userId;
        getUserEvaluations(userId);
      }
    }

    function getUserEvaluations(id) {
      vm.userId = id;
      PatientService.getUserEvaluatedRisk(id).then(function(data) {
        vm.evaluation = data;
        PatientService.getUserEvaluatedRisks(id).then(function(data) {
          vm.evaluations = data;
          initPlot(vm.showSpinner ? 3000 : 0);
        });
      });
    }

    vm.createRiskEvaluation = function() {
      vm.showSpinner = true;
      PatientService.createEvaluationRisk(vm.userId).then(function(data) {
        vm.showSpinner = false;
      });
    };

    vm.goBack = function() {
      $state.go('mainAdmin.userdetails', {
        userId: vm.userId
      });
    };

    vm.plotRiskValues = function(model) {
      initPlot(0, model);
    };

    function initPlot(waitFor, model) {

      vm.evaluationsToPlot = vm.evaluations;
      if (model !== undefined) {
        vm.evaluationsToPlot = jQuery.grep(vm.evaluations, function(x) {
          return x.trainedModel === model;
        });
      }

      $timeout(function() {
        const colors = ['#0000FF', '#FFC0CB', '#00FF00', '#00FFFF'];

        function raw(plot, ctx) {
          const data = plot.getData();
          const axes = plot.getAxes();
          const offset = plot.getPlotOffset();
          for (let i = 0; i < data.length; i += 1) {
            const series = data[i];
            for (let j = 0; j < series.data.length; j += 1) {
              let color = 0;
              if (vm.evaluationsToPlot[j].trainedModel == 'st1_afdt-GB12.pickle') {
                color = 0;
              }
              if (vm.evaluationsToPlot[j].trainedModel == 'st2_afdt-GB12.pickle') {
                color = 1;
              }
              if (vm.evaluationsToPlot[j].trainedModel == 'st1_afnn-NN3.pickle') {
                color = 2;
              }
              if (vm.evaluationsToPlot[j].trainedModel == 'st2_afnn-NN3.pickle') {
                color = 3;
              }

              const d = (series.data[j]);
              const x = offset.left + axes.xaxis.p2c(d[0]);
              const y = offset.top + axes.yaxis.p2c(d[1]);
              const r = 5;
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.arc(x, y, r, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fillStyle = colors[color];
              ctx.fill();
            }
          }
        }

        function showPlotValues() {
          drawPlotData();
        }

        function drawPlotData() {
          const risks = [];

          const len = vm.evaluationsToPlot.length;
          for (let i = 0; i < len; i += 1) {
            const risk = vm.evaluationsToPlot[i].risk;
            if (risk >= 0) {
              const dtDate = new Date(vm.evaluationsToPlot[i].to);
              const dtInMilliseconds = dtDate.getTime();
              risks.push([dtInMilliseconds, risk]);
            }
          }

          $.plot('#placeholder', [risks], {
            series: {lines: {show: false}, points: {show: true}},
            grid: {hoverable: true, clickable: true}, xaxis: {mode: 'time'},
            hooks: {draw: [raw]}
          });

        }

        $('<div id=\'tooltip\'></div>').css({
          'position': 'absolute',
          'display': 'none',
          'border': '1px solid #fdd',
          'padding': '2px',
          'background-color': '#fee',
          'opacity': 0.80
        }).appendTo('body');

        $('#placeholder').bind('plothover', function(event, pos, item) {
          if (item) {
            const x = item.datapoint[0].toFixed(0);
              const risk = item.datapoint[1];
              const index = item.dataIndex;
            $('#tooltip').html($filter('date')(new Date(parseInt(x, 10)), vm.formats.longDate) + '<br/><b>Risk</b> ' + risk)
              .css({top: item.pageY + 5, left: item.pageX + 5})
              .fadeIn(200);
          } else {
            $('#tooltip').hide();
          }
        });

        $('#placeholder').bind('plotclick', function(event, pos, item) {
          if (item) {
            // what is plot?
            // plot.highlight(item.series, item.datapoint);
          }
        });

        showPlotValues();
      }, waitFor);
    }
  }
})();
