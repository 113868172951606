import * as angular from 'angular';
import * as moment from 'moment';
import '../../../scripts/plot/jquery.flot';
import '../../../scripts/plot/jquery.flot.time';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientDetailsController', PatientDetailsController);

  function PatientDetailsController(
    $stateParams,
    $state,
    $translate,
    $interval,
    $scope,
    $rootScope,
    $filter,
    PatientService,
    SessionService,
    NationService,
    HealthcareProviderService,
    ModalService,
    MessageService,
    envConfig,
    UtilService,
    MeasurementHelperService,
    $timeout,
    ReportTimeService
  ) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.result = '';
    let _tableState = null;
    let _tableReportsState = null;
    vm.multiplePatientsFound = false;
    vm.multiplePatients = [];
    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;
    vm.medicalJournal = null;
    vm.result = '';
    vm.lastPayed;
    vm.showSpinner = false;
    vm.loadedR = 0;
    vm.input = '';
    vm.patientNotFound = false;
    vm.noAccess = false;
    vm.noAccessRequestSent = false;
    vm.noAccessAccessDenied = false;
    vm.noHealthcareProviders = false;
    vm.userDetails = null;
    vm.hcpContactDetails = null;
    vm.hcpPrescriberContactDetails = null;
    vm.doneLoading = true;
    vm.userId = null;
    vm.startItem = 0;
    vm.markedmeasurments = [];
    vm.notes = null;
    vm.newnote = '';
    vm.searchOptions = NationService.getSearchFilterForStaff();
    vm.searchOption = vm.searchOptions[0];
    vm.searchPortalUserOptions = [];
    vm.searchPortalUserOption = null;
    vm.measurementFilterAlertFlag = false;
    vm.measurementFilterNotReviewedFlag = true;
    vm.showOnlyAbnormalResult = false;
    vm.healthcareProviders = null;
    vm.connectedHealthcareProviders = null;
    vm.showFilterOptions = false;
    vm.expandMeasurements = false;
    let measurementWithPreviewImgIsLoaded = false;
    vm.measurementTabIsActive = false;

    vm.selectedCategories = null;
    vm.currentCategories = null;
    vm.categories = NationService.getCategories();
    vm.selectedFeelings = null;
    vm.currentFeelings = null;
    vm.feelings = [
      {id: 0, name: $translate.instant('measurement_feeling_unknown')},
      {id: 1, name: $translate.instant('measurement_feeling_good')},
      {id: 2, name: $translate.instant('measurement_feeling_stressed')},
      {id: 3, name: $translate.instant('measurement_feeling_anxious')},
      {id: 4, name: $translate.instant('measurement_feeling_chestpain')},
      {id: 6, name: $translate.instant('measurement_feeling_rapidHeartRate')},
      {id: 7, name: $translate.instant('measurement_feeling_dizziness')},
      {id: 5, name: $translate.instant('measurement_feeling_other')}
    ];

    vm.selectedConfigurations = null;
    vm.currentConfigurations = null;
    vm.configurations = null;

    // Plot data for measurements over time
    let plotMeasurementResult = null;
    let plotMeasurementStoredResult = null;
    let plotMeasurementMinDate = moment();
    let _timeout;
    let trendIsInitialized = false;
    let patientdataIsInitialized = false;
    let patientlistIsInitialized = false;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    vm.userConfigurations = [];
    vm.totalReportTime = 0;

    vm.activeConnection = true;
    vm.numberOfUnViewedPatientSummaryReports = 0;

    let interval = null;

    activate();

    function activate() {
      vm.searchOption = SessionService.getFilterOptions().searchOptionPatient;
      vm.searchPortalUserOption = SessionService.getFilterOptions().searchPortalUserOption;
      vm.measurementFilterAlertFlag = SessionService.getFilterOptions().searchOptionPatientMeasurement.alert;
      vm.measurementFilterNotReviewedFlag = SessionService.getFilterOptions().searchOptionPatientMeasurement.notreviewed;
      vm.showOnlyAbnormalResult = SessionService.getFilterOptions().searchOptionPatientMeasurement.showonlyabnormalresult;
      vm.selectedCategories = SessionService.getFilterOptions().searchOptionPatientMeasurement.categories;
      vm.selectedFeelings = SessionService.getFilterOptions().searchOptionPatientMeasurement.feelings;
      vm.selectedConfigurations = SessionService.getFilterOptions().searchOptionPatientMeasurement.configurations;
      const userId = $stateParams.userId;
      if (userId !== '' && userId !== undefined && userId !== null) {
        vm.userId = userId;
        getUserDetails(userId);

        $('#filterCategories').click(function() {
          if (
            vm.currentCategories == null ||
            vm.currentCategories !==
            vm.selectedCategories
              .map(function(el) {
                return el.id;
              })
              .join(',')
          ) {
            vm.searchMeasurements();
          }
        });

        $('#filterFeelings').click(function() {
          if (
            vm.currentFeelings == null ||
            vm.currentFeelings !==
            vm.selectedFeelings
              .map(function(el) {
                return el.id;
              })
              .join(',')
          ) {
            vm.searchMeasurements();
          }
        });

        $('#filterConfigurations').click(function() {
          if (
            vm.currentConfigurations == null ||
            vm.currentConfigurations !==
            vm.selectedConfigurations
              .map(function(el) {
                return el.id;
              })
              .join(',')
          ) {
            vm.searchMeasurements();
          }
        });
      } else {
        document.getElementById('search-patient').focus();
        getSearchFilterByPortalUser();
      }
    }

    function getSearchFilterByPortalUser() {
      if (NationService.isUS()) {
        vm.searchPortalUserOptions = SessionService.getSearchFilterPortalUsers();
        if (vm.searchPortalUserOptions === null) {
          PatientService.getSearchFilterByPortalUser().then(function(response) {
            vm.searchPortalUserOptions = [{name: $translate.instant('user_searchoption_all'), value: ''}];
            jQuery.grep(response, function(x) {
              vm.searchPortalUserOptions.push({name: x.staff.lastname + ', ' + x.staff.firstname, value: x.id});
              if (vm.searchPortalUserOption.value === '') {
                // Show my patients default
                if (x.id === NationService.loggedInUserId()) {
                  vm.searchPortalUserOption = vm.searchPortalUserOptions[vm.searchPortalUserOptions.length - 1];
                }
              }
              SessionService.setSearchFilterPortalUsers(vm.searchPortalUserOptions);
            });
            vm.searchCoalaUser();
          });
        }
      }
    }

    // This is a fix for changing tab on mobile devices. The real issue could be that angular-touch needs to be updated.
    vm.mobileTabClick = function(type) {
      // alert('mobile tab clicked: ' + type);
      event.preventDefault();
      const tab = $('#' + type + 'Tab');
      tab[0].click();
    };

    vm.toggleTab = function(type) {
      // alert('tab clicked: ' + type);
      event.preventDefault();
      $('#myTabs #' + type + 'Tab').tab('show');
      setTab(type);
    };

    vm.filterOption = function() {
      vm.showFilterOptions = !vm.showFilterOptions;
    };

    vm.expandAll = function() {
      vm.expandMeasurements = !vm.expandMeasurements;
      if (!measurementWithPreviewImgIsLoaded) {
        updateMeasurementsTable();
        measurementWithPreviewImgIsLoaded = vm.expandMeasurements;
      }
    };

    vm.listOption = function() {
      if (vm.expandMeasurements) {
        return 'fa fa-list';
      } else {
        return 'fa fa-th';
      }
    };

    function setTab(tab) {
      vm.measurementTabIsActive = false;
      if (tab == 'trends' && trendIsInitialized === false) {
        trendIsInitialized = true;
        initPlot(300);
      } else if (tab == 'patientdata' && patientdataIsInitialized === false) {
        patientdataIsInitialized = true;
        loadPatientData();
      } else if (tab == 'measurements') {
        vm.measurementTabIsActive = true;
      }
    }

    vm.searchResult = function() {
      const text = vm.searchString;
      vm.result = text;
    };

    vm.isMonitoringCenterStaff = function() {
      return SessionService.getIsMonitoringCenterStaff();
    };

    vm.hideKeyboard = function() {
      document.activeElement.blur();
      document.getElementById('search-patient').blur();
    };

    vm.removeSpinner = function() {
      vm.loadedR = 3;
      vm.checkDoneLoading();
    };

    vm.fetchUsers = function(tableState) {
      if (vm.userId == null) {
        let changedByUri = false;
        if (_tableState === null) {
          changedByUri = true;
        }
        if (tableState) {
          _tableState = tableState;
        }

        let startItem = $stateParams.startItem;
        if (
          startItem !== '' &&
          startItem !== undefined &&
          startItem !== null &&
          changedByUri
        ) {
          _tableState.pagination.start = parseInt(startItem, 10);
        } else if (startItem !== undefined) {
          startItem = _tableState.pagination.start;
        }
        vm.startItem = startItem;

        pagingParameters = UtilService.preparePagingParameters(
          _tableState,
          vm.filters
        );

        if (startItem !== undefined) {
          $state.go(
            'main.patientsearch',
            {
              startItem: vm.startItem
            },
            {notify: false}
          );
        }

        vm.searchCoalaUser();
      }
    };

    function isAbleToSearch() {
      if (vm.searchPortalUserOptions == null && NationService.isUS()) {
        return false;
      }
      return (patientlistIsInitialized || (!patientlistIsInitialized && !vm.showSpinner));
    }

    vm.searchCoalaUser = function(page) {
      if (vm.userId == null && vm.permissions.readPatientUser && isAbleToSearch()) {
        if (_timeout) {
          // if there is already a timeout in process cancel it
          $timeout.cancel(_timeout);
        }
        _timeout = $timeout(function() {
          vm.showSpinner = true;

          const filters = [];
          if (vm.showPrescribingProfessional() && vm.searchPortalUserOption !== undefined && vm.searchPortalUserOption.value !== '') {
            filters.push({value: 'true', propertyKey: 'filterPortalUserRelations', propertyValue: vm.searchPortalUserOption.value});
          }

          pagingParameters = UtilService.preparePagingParameters(
            _tableState,
            filters
          );

          if (vm.input === undefined) {
            vm.input = '';
          }
          if (vm.searchOption === undefined) {
            pagingParameters.search = vm.input;
          } else {
            pagingParameters.search =
              'searchoption:' + vm.searchOption.value + ';' + vm.input;

            const filterOptions = SessionService.getFilterOptions();
            filterOptions.searchOptionPatient = vm.searchOption;
            filterOptions.searchPortalUserOption = vm.searchPortalUserOption;
            SessionService.setFilterOptions(filterOptions);
          }

          if (_tableState && page >= 0) {
            _tableState.pagination.start = page;
            pagingParameters.page = page / pagingParameters.pageSize;
          }

          PatientService.searchPatient(pagingParameters).then(function(result) {
            patientlistIsInitialized = true;
            vm.noHealthcareProviders = false;
            vm.patientNotFound = false;
            vm.noAccess = false;
            vm.noAccessRequestSent = false;
            vm.noAccessAccessDenied = false;
            if (result.rows.length == 0) {
              if (vm.input !== '') {
                vm.patientNotFound = true;
                searchPatientForAccess();
              } else {
                vm.multiplePatientsFound = false;
                vm.showSpinner = false;
              }
            } else if (result.rows.length > 1 || vm.input === '') {
              vm.patientFound = false;
              vm.multiplePatientsFound = true;
              vm.multiplePatients = result.rows;
              vm.showSpinner = false;
            } else {
              vm.multiplePatientsFound = false;
              const ud = result.rows[result.rows.length - 1];
              vm.showSpinner = false;
              if (!vm.showSpinner) {
                // We know that we have access to the user
                ud.relationshipStatus = 'accepted';
                vm.displayUserDetails(ud);
              }
            }

            if (_tableState) {
              _tableState.pagination.numberOfPages = result.nbrOfPages;
              if (result.nbrOfPages === 0) {
                vm.hidePaging = true;
              } else {
                vm.hidePaging = false;
              }
              vm.totalUsers = result.nbrOfRows;
              vm.nrOfPages = result.nbrOfPages;
            }
          }, function(error) {
            vm.showSpinner = false;
          });
          _timeout = null;
        }, 1000);
      }
    };

    function searchPatientForAccess() {
      const pnr = vm.input + '';
      const providerId = SessionService.getReprestingProviderId();

      if (providerId == '') {
        vm.noHealthcareProviders = true;
        vm.multiplePatientsFound = false;
        vm.showSpinner = false;
      } else {
        HealthcareProviderService.searchPatient(providerId, pnr).then(function(result) {
          vm.patientNotFound = false;

          if (result.rows.length != 1) {
            vm.patientNotFound = true;
            vm.multiplePatientsFound = false;
            vm.showSpinner = false;
          } else if (result.rows.length == 1) {
            vm.multiplePatientsFound = false;
            const ud = result.rows[result.rows.length - 1];
            vm.displayUserDetails(ud);
          }
          vm.showIntroText();
          vm.doneLoading = true;
        });
      }
    }

    // toUTC()
    function toUTC(/* Date*/ date) {
      return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      );
    }

    vm.daysSinceNow = function(d1) {
      let ndays;
      // var startDate = new Date(d1);
      const endDate = new Date(); // Today
      const tv1 = toUTC(new Date(d1)); // startDate.getTime();  // msec since 1970
      const tv2 = endDate.getTime();
      ndays = (tv2 - tv1) / 1000 / 86400;
      ndays = Math.round(ndays);
      return ndays;
    };

    function getUserDetails(id) {
      vm.showSpinner = true;

      vm.noAccess = false;
      vm.noAccessRequestSent = false;
      vm.noAccessAccessDenied = false;
      vm.multiplePatientsFound = false;
      vm.userId = id;

      PatientService.hasUserAccess(id).then(function(ud) {
        vm.userData = ud;
        vm.userDetails = ud;
        vm.activeConnection = activeConnection(ud);
        vm.input =
          ud.patientIdentificationNo !== null ?
            ud.patientIdentificationNo :
            ud.email;
        vm.userData.patientIdentificationNo = ud.patientIdentificationNo;

        if (
          ud.relationshipStatus !== 'accepted' &&
          ud.relationshipStatus !== 'generallyAccepted'
        ) {
          vm.noAccess = false;
          vm.noAccessRequestSent = false;
          vm.noAccessAccessDenied = false;
          if (ud.relationshipStatus === 'noRelationship') {
            vm.noAccess = true;
          } else if (ud.relationshipStatus === 'pending') {
            vm.noAccessRequestSent = true;
          } else if (ud.relationshipStatus === 'rejected') {
            vm.noAccessAccessDenied = true;
          }

          vm.showSpinner = false;
        } else {
          vm.showSpinner = true;
          loadHealthcareProvicerInfo();
          vm.fetchMeasurements();
          startReportTimeTimer();
          const startTab = 'measurements';
          $('#myTabs #' + startTab + 'Tab').tab('show');
          setTab(startTab);

          if (vm.permissions.readMeasurementConfiguration) {
            NationService.getAvailableFilterMeasurementConfigurations().then(function(data) {
              vm.configurations = data;
            });
          }

          if (vm.permissions.readMedicalData) {
            PatientService.getMedicalJournal(vm.userId).then(function(data) {
              vm.medicalJournal = data;
              vm.showSpinner = false;
            });
          } else {
            vm.showSpinner = false;
          }
        }
      });
    }

    function loadPatientData() {
      vm.showSpinner = true;

      if (vm.permissions.readPatientSubscription) {
        PatientService.getUserSubscription(vm.userId).then(function(response) {
          if (response !== null) {
            vm.lastPayed = response.created;
            vm.subscriptionType = response.subscriptionType;
          }

          vm.loadedR += 1;
          vm.checkDoneLoading();
        });
      } else {
        vm.loadedR += 1;
        vm.checkDoneLoading();
      }

      updateNotesTable();
      updateMessagesTable();
      getUserConfigurations();
      getConnectedHealthcareProviders();
      updateTimeReportTotalTime();
    }

    // Get user healthcare providers and monitoring contact
    function loadHealthcareProvicerInfo() {
      if (envConfig.monitoringCenter !== undefined && envConfig.monitoringCenter != '') {
        PatientService.getUserMonitoringServiceContactInfo(vm.userId).then((response) => {
          const contactHCP = response.filter((x) => x.healthcareProvider != null);
          const contactPrescriber = response.filter((x) => x.healthcareProvider == null);
          vm.healthcareProviders = contactHCP.map((provider) => provider.healthcareProvider.name).join(", ");
          if (contactHCP.length > 0) {
            vm.hcpContactDetails = contactHCP[0]; // response[0];
          }
          if (contactPrescriber.length > 0) {
            vm.hcpPrescriberContactDetails = contactPrescriber[0]; // response[0];
          }
        });
      }
    }

    vm.getContactInfoNotificationCriteria = function() {
      if (vm.hcpPrescriberContactDetails !== null) {
        return vm.hcpPrescriberContactDetails.notificationCriteria;
       } else if (vm.hcpContactDetails !== null) {
        return vm.hcpContactDetails.notificationCriteria;
       }
      return undefined;
    };

    vm.calculateAge = function(birthday) {
      // birthday is a date
      const ageDifMs = Date.now() - new Date(birthday);
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    vm.searchMeasurements = function() {
      _tableState.pagination.start = 0;
      vm.fetchMeasurements();
    };

    vm.fetchMeasurements = function(tableState) {
      if (vm.userId !== '' && vm.userId !== undefined && vm.userId !== null) {
        vm.showSpinner = true;
        let changedByUri = false;
        if (
          _tableState === null ||
          _tableState.pagination.numberOfPages === undefined
        ) {
          changedByUri = true;
        }
        if (tableState) {
          _tableState = tableState;
        }

        if (vm.showUserDetails() && vm.hasReadPatientUserPermission()) {
          let startItem = $stateParams.startItem;
          if (
            startItem !== '' &&
            startItem !== undefined &&
            startItem !== null &&
            changedByUri
          ) {
            _tableState.pagination.start = parseInt(startItem, 10);
          } else if (startItem !== undefined) {
            startItem = _tableState.pagination.start;
          }
          if (vm.startItem != startItem && !vm.expandMeasurements) {
            measurementWithPreviewImgIsLoaded = false;
          }
          vm.startItem = startItem;

          vm.filters = [];
          if (
            vm.measurementFilterAlertFlag == true ||
            vm.measurementFilterNotReviewedFlag == true
          ) {
            let result = vm.measurementFilterAlertFlag == true ? 1 : 0;
            result =
              vm.measurementFilterNotReviewedFlag == true ? result | 2 : result;
            // console.log('searchfilter:' + result);
            vm.filters.push({
              value: 'true',
              propertyKey: 'flagged',
              propertyValue: result
            });
          }
          if (vm.showOnlyAbnormalResult == true) {
            vm.filters.push({
              value: 'true',
              propertyKey: 'showonlyabnormalresult',
              propertyValue: vm.showOnlyAbnormalResult
            });
          }
          vm.currentCategories = '';
          if (vm.selectedCategories != null && vm.selectedCategories.length > 0) {
            vm.currentCategories = vm.selectedCategories
              .map(function(el) {
                return el.id;
              })
              .join(',');
            vm.filters.push({
              value: 'true',
              propertyKey: 'filtercategories',
              propertyValue: vm.selectedCategories
                .map(function(el) {
                  return el.id;
                })
                .join(',')
            });
          }
          vm.currentFeelings = '';
          if (vm.selectedFeelings != null && vm.selectedFeelings.length > 0) {
            vm.currentFeelings = vm.selectedFeelings
              .map(function(el) {
                return el.id;
              })
              .join(',');
            vm.filters.push({
              value: 'true',
              propertyKey: 'filterfeelings',
              propertyValue: vm.selectedFeelings
                .map(function(el) {
                  return el.id;
                })
                .join(',')
            });
          }

          vm.currentConfigurations = '';
          if (vm.selectedConfigurations != null && vm.selectedConfigurations.length > 0) {
            vm.currentConfigurations = vm.selectedConfigurations
              .map(function(el) {
                return el.id;
              })
              .join(',');
            vm.filters.push({
              value: 'true',
              propertyKey: 'filterconfigurations',
              propertyValue: vm.selectedConfigurations
                .map(function(el) {
                  return el.id;
                })
                .join(',')
            });
          }

          const filterOptions = SessionService.getFilterOptions();
          filterOptions.searchOptionPatientMeasurement.categories =
            vm.selectedCategories;
          filterOptions.searchOptionPatientMeasurement.feelings =
            vm.selectedFeelings;
          filterOptions.searchOptionPatientMeasurement.configurations =
            vm.selectedConfigurations;
          filterOptions.searchOptionPatientMeasurement.alert =
            vm.measurementFilterAlertFlag;
          filterOptions.searchOptionPatientMeasurement.notreviewed =
            vm.measurementFilterNotReviewedFlag;
          filterOptions.searchOptionPatientMeasurement.showonlyabnormalresult =
            vm.showOnlyAbnormalResult;
          SessionService.setFilterOptions(filterOptions);

          pagingParameters = UtilService.preparePagingParameters(
            _tableState,
            vm.filters
          );
          // pagingParameters.filter = 50;
          if (startItem !== undefined) {
            $state.go(
              'main.patientdetails',
              {
                userId: vm.userId,
                startItem: vm.startItem
              },
              {notify: false}
            );
          }

          updateMeasurementsTable();
        }
      }
    };

    function updateMeasurementsTable() {
      if (vm.userId !== '' && vm.userId !== undefined && vm.userId !== null && vm.permissions.readMedicalData) {
        vm.showSpinner = true;
        PatientService.getMeasurementsForUser(
          vm.userId,
          pagingParameters,
          vm.expandMeasurements
        ).then(function(data) {
          vm.measurements = data.rows;
          vm.nrOfMeasurements = data.nbrOfRows;
          vm.nrOfPages = data.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
          vm.showSpinner = false;
        });
      }
    }

    vm.getSasUrls = function(parts) {
      const urls = jQuery.grep(parts, function(x) {
        return x.ecgAnalisysImageUrl !== null;
      });
      return urls;
    };

    vm.getFlagColor = function(flagtype, flag) {
      if ((flag & 1) == 1 && flagtype == 'alert') {
        if ((flag & 2) == 2) {
          return {color: 'red'};
        }
        return {'color': 'red', 'margin-left': '16px'};
      } else if ((flag & 2) == 2 && flagtype == 'reviewed') {
        return {color: 'lightgreen'};
      }
      return {color: 'white'};
    };

    vm.showFlag = function(flagtype, flag) {
      if ((flag & 1) == 1 && flagtype == 'alert') {
        return true;
      } else if ((flag & 2) == 2 && flagtype == 'reviewed') {
        return true;
      }
      return false;
    };

    vm.getLocalTime = function(utctime) {
      const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
      const localTime = moment.utc(date).toDate();
      return localTime;
      // return moment.utc(date).toDate();
    };

    vm.getRegionalValue = function(regionalWeight) {
      if (regionalWeight == null) {
        return null;
      }
      return NationService.getRegionalValue(regionalWeight);
    };

    function getUserConfigurations() {
      if (vm.userId != null && vm.permissions.readMeasurementConfiguration) {
        PatientService.getAllMeasurementConfigurationsForUser(vm.userId).then(function(response) {
          vm.userConfigurations = response;
        });
      }
    }

    function getConnectedHealthcareProviders() {
      if ((SessionService.isUnrestrictedUser() && vm.permissions.writePatientUser) || vm.permissions.coalaAdmin) {
        PatientService.getHealthcareProviders(vm.userId).then(function(data) {
          vm.connectedHealthcareProviders = data.rows;
        });
      }
    }

    vm.addTimeReport = function(time) {
      const providerId = SessionService.getReprestingProviderId();
      const timereport = {
        userId: vm.userId,
        time: '00:' + time + ':00',
        healthcareProviderId: providerId
      };
      PatientService.postTimeReport(timereport).then(function(data) {
        vm.showFeedback($translate.instant('timereport_patient_created'));
        ReportTimeService.addTime(vm.userId, parseInt(time, 10) * 60);
        updateTimeReportTotalTime();
      });
    };

    vm.GetMinutes = function(time) {
      return time;
      // return moment.duration(time).asMinutes() + ' ' + $translate.instant('timereport_min');
    };

    // Used to update UI (patient timer)
    function startReportTimeTimer() {
      if (vm.permissions.timereporter) {
        interval = $interval(updateTimeSpent, 1000);
      }
    }

    function updateTimeSpent() {
      // console.log('refresh...');
    }

    $scope.$on('$destroy', function() {
      // console.log('destroy...');
      if (vm.permissions.timereporter) {
        ReportTimeService.stopReportTime(vm.userId);
        $interval.cancel(interval);
      }
    });
    // -----------------------------

    function updateTimeReportTotalTime() {
      if (vm.permissions.readTimeReports && vm.permissions.representingProvider) {
        const providerId = SessionService.getReprestingProviderId();
        const d = new Date();
        const offset = d.getTimezoneOffset();
        PatientService.getTimeReportTotalTimeForUser(providerId, vm.userId, offset).then(function(data) {
          vm.totalReportTime = data.totalTime;
        });
      }
    }

    function updateNotesTable() {
      if (vm.permissions.readNotes) {
        PatientService.getNotesForUser(vm.userId).then(function(data) {
          vm.notes = data.rows;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });
      } else {
        vm.loadedR += 1;
        vm.checkDoneLoading();
      }
    }

    vm.addNotes = function() {
      const note = {
        userId: vm.userId,
        notes: vm.newnote
      };
      PatientService.postUserNote(vm.userId, note).then(function(data) {
        vm.newnote = '';
        updateNotesTable();
      });
    };

    vm.isNoteEmpty = function() {
      return vm.newnote == '';
    };

    vm.viewAllMessages = function() {
      updateMessagesTable(9999);
    };

    vm.getMessageSubject = function(message) {
      if (message.messageType == 'healthcareProviderAccessRequest') {
        return (
          message.subject + ' ' + $translate.instant('message_request_access')
        );
      }
      return message.subject;
    };

    function updateMessagesTable(pageSize) {
      if (vm.userId != undefined) {
        if (pageSize == null) {
          pageSize = 10;
        }
        const pagingParameters = {
          page: 0,
          id: '',
          pageSize: pageSize,
          sort: '',
          search: ''
        };

        MessageService.getMessages(vm.userId, pagingParameters).then(function(
          data
        ) {
          vm.messages = data.rows;
          vm.nrOfMessages = data.nbrOfRows;
          vm.loadedR += 1;
          vm.checkDoneLoading();
        });
      }
    }

    vm.showContent = function(content, id) {
      if (content !== null) {
        content = content.replace(/(?:\r\n|\r|\n)/g, '<br />');
        $('#contentSpan').html(content);

        $('.fa-caret-right').each(function(i, obj) {
          $(obj).hide();
        });

        $('.emailItem').each(function(i, obj) {
          $(obj).removeClass('coalaLightGrayBackground');
        });

        $('#' + id).show();
        $('.' + id).addClass('coalaLightGrayBackground');
      }
    };

    vm.clearSearch = function() {
      const userId = $stateParams.userId;
      if (userId === undefined || userId == '') {
        console.log('clear search');
        vm.userId = null;
        vm.input = '';
        vm.userDetails = null;
        vm.noAccess = false;
        vm.noAccessRequestSent = false;
        vm.noAccessAccessDenied = false;
        vm.fetchUsers();
      } else {
        $state.go('main.patientsearch');
      }
    };

    vm.smokeStatus = function() {
      if (vm.medicalJournal == null) {
        return '?';
      }
      const smoker = vm.medicalJournal.smoker;

      if (smoker === 'everyDay') {
        return $translate.instant('patient_smokestatus_everyday');
      } else if (smoker === 'no') {
        return $translate.instant('patient_smokestatus_no');
      } else if (smoker === 'everyWeek') {
        return $translate.instant('patient_smokestatus_everyweek');
      } else if (smoker === 'everyMonth') {
        return $translate.instant('patient_smokestatus_everymonth');
      }

      return '';
    };

    vm.medicalImplant = function() {
      if (vm.medicalJournal == null) {
        return '?';
      }
      const medicalImplant = vm.medicalJournal.medicalImplant;

      if (medicalImplant) {
        return $translate.instant('yes');
      } else {
        return $translate.instant('no');
      }
    };

    vm.showIntroText = function() {
      const shouldShowIntroText =
        !vm.patientNotFound &&
        !vm.multiplePatientsFound &&
        !vm.noAccess &&
        !vm.noAccessRequestSent &&
        !vm.noAccessAccessDenied &&
        !vm.noHealthcareProviders &&
        !vm.showUserDetails() &&
        vm.doneLoading;
      return shouldShowIntroText;
    };

    vm.showUserDetails = function() {
      return (
        vm.userDetails !== null &&
        vm.userDetails !== undefined &&
        !vm.patientNotFound &&
        !vm.noAccess &&
        !vm.noAccessRequestSent &&
        !vm.noAccessAccessDenied &&
        !vm.multiplePatientsFound
      );
    };

    vm.hasReadPatientUserPermission = function() {
      return (
        vm.permissions.readPatientUser
      );
    };

    vm.showNoAccess = function() {
      return (
        (vm.noAccess || vm.noAccessRequestSent || vm.noAccessAccessDenied) &&
        !vm.patientNotFound
      );
    };

    vm.showMeasurementInfo = function(item) {
      return item.measurement !== null && item.nbrOfMeasurements > 0;
    };

    vm.showPrescribingProfessional = function() {
      return NationService.isUS() && !vm.showUserDetails();
    };

    vm.connectionStyle = function(user) {
      let connection = '';
      if (requestSent(user)) {
        connection = 'fa fa-exclamation-circle fadedBlueFont';
      } else if (!activeConnection(user)) {
        connection = 'fa fa-exclamation-circle fadedBlueFont';
      }
      return connection;
    };

    vm.conectionTooltip = function(user) {
      if (requestSent(user)) {
        return $translate.instant('patient_noaccess_requestsent_button');
      } else if (!activeConnection(user)) {
        return $translate.instant('patient_investigation_completed');
      }
      return '';
    };

    function activeConnection(user) {
      return user.acceptedTo == null;
    }

    function requestSent(user) {
      return user.requestSent !== null;
    }

    vm.requestAccessLinkText = function() {
      if (vm.userData !== undefined) {
        if (vm.userData.requestSent === null) {
          return $translate.instant('patient_request_access_link');
        } else {
          return $translate.instant('patient_noaccess_requestsent_button');
        }
      }
    };

    vm.getPartTypes = function(measurementParts, type) {
      const parts = jQuery.grep(measurementParts, function(x) {
        return x.measurementPartType === 'ecg' + type;
      });
      return parts;
    };

    vm.gender = function() {
      if (vm.medicalJournal == null) {
        return 'fa';
      } else {
        return vm.medicalJournal.gender === 'female' ?
          'fa fa-venus' :
          'fa fa-mars';
      }
    };

    vm.signalStatusOfPart = function(part) {
      return MeasurementHelperService.signalStatusOfPart(part);
    };

    vm.parseErrorCodeOfPart = function(part) {
      return MeasurementHelperService.parseErrorCodeOfPart(part);
    };

    vm.appStatus = function(measurement) {
      return MeasurementHelperService.appStatus(measurement);
    };

    vm.parseErrorCode = function(measurementParts, type) {
      return MeasurementHelperService.parseErrorCode(measurementParts, type);
    };

    vm.parseAppErrorCode = function(measurement) {
      return MeasurementHelperService.parseAppErrorCode(measurement);
    };

    vm.parsePulse = function(measurement) {
      return MeasurementHelperService.parsePulse(measurement);
    };

    vm.setMeasurementMarked = function(id) {
      const index = vm.markedmeasurments.indexOf(id);

      if (index < 0) {
        vm.markedmeasurments.push(id);
      } else {
        vm.markedmeasurments.splice(index, 1);
      }
    };

    vm.getMeasurementMarked = function(id) {
      return vm.markedmeasurments.indexOf(id) >= 0;
    };

    vm.isMeasurementMarket = function() {
      return vm.markedmeasurments.length > 0;
    };

    vm.downloadMeasurementsFile = function() {
      vm.showSpinner = true;
      console.log('download pdf file for multiple measurements');
      const fileName = (vm.userDetails.patientIdentificationNo ?
          vm.userDetails.patientIdentificationNo :
          vm.userDetails.firstname + '_' + vm.userDetails.lastname + '_' + new Date().getTime()) +
        '_' +
        moment().format('YYYYMMDD_HHmm') +
        '.pdf';

      PatientService.getChestAndFingerMeasurementsPdf(
        fileName,
        vm.markedmeasurments
      ).then(
        function(data) {
          vm.showSpinner = false;
        },
        function(reason) {
          vm.showSpinner = false;
          vm.showError($translate.instant('download_createpdffile_error'));
        }
      );
    };

    vm.feelingBeforeMeasurementString = function(measurement) {
      return MeasurementHelperService.feelingBeforeMeasurementString(measurement);
    };

    vm.displayUserDetails = function(ud) {
      vm.showSpinner = false;
      vm.multiplePatientsFound = false;
      ud = ud === undefined ? null : ud;
      vm.userData = ud;
      vm.userDetails = ud;
      vm.userId = ud.id;
      vm.forwardUser(ud.relationshipStatus, ud.id);
    };

    vm.forwardUser = function(relationship, userId) {
      vm.hideKeyboard();
      console.log('forwardUser: ' + relationship + ', id: ' + userId);
      if (relationship === 'accepted' || relationship === 'generallyAccepted') {
        $state.go('main.patientdetails', {
          userId: userId,
          startItem: 0
        });
        vm.noAccess = false;
        vm.noAccessRequestSent = false;
        vm.noAccessAccessDenied = false;
      } else if (relationship === 'noRelationship') {
        vm.noAccess = true;
      } else if (relationship === 'pending') {
        vm.noAccessRequestSent = true;
      } else if (relationship === 'rejected') {
        vm.noAccessAccessDenied = true;
      }
    };

    vm.relationshipStatusConverter = function(relationship) {
      if (relationship === 'accepted') {
        return $translate.instant('relationship_accepted');
      } else if (relationship === 'generallyAccepted') {
        return $translate.instant('relationship_generallyaccepted');
      } else if (relationship === 'noRelationship') {
        return $translate.instant('relationship_norequest');
      } else if (relationship === 'pending') {
        return $translate.instant('relationship_requestsent');
      } else if (relationship === 'rejected') {
        return $translate.instant('relationship_rejected');
      }
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none');
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic');
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium');
      }

      return '';
    };

    vm.checkDoneLoading = function() {
      if (vm.loadedR >= 3) {
        vm.showSpinner = false;
      }
    };

    vm.requestAccess = function(noAccessRequestSent) {
      const providerId = SessionService.getReprestingProviderId();
      if (noAccessRequestSent === undefined) {
        noAccessRequestSent = true;
      }
      HealthcareProviderService.requestAccess(providerId, vm.userId).then(
        function(data) {
          vm.noAccess = false;
          vm.noAccessAccessDenied = false;
          vm.patientNotFound = false;
          vm.noAccessRequestSent = noAccessRequestSent;
          vm.userData.requestSent = moment().utc().format('YYYY-MM-DD');

          vm.showFeedback($translate.instant('request_sent'));
        }
      );
    };

    vm.goToMeasurement = function(measurementId) {
      $rootScope.measurements = vm.measurements;
      $rootScope.nrOfMeasurements = vm.nrOfMeasurements;
      $rootScope.measurementsPagingParameters = pagingParameters;
      $rootScope.measurementlistPosition = null;
      $rootScope.fromUri = 'main.patientdetails';

      $state.go('main.patientmeasurement', {
        measurementId: measurementId,
        userId: vm.userId,
        startItem: vm.startItem
      });
    };

    // /////////////// Report ////////////////////////////
    vm.fetchReports = function(tableState) {
      if (tableState) {
        _tableReportsState = tableState;
      }

      const pagingReportsParameters = UtilService.preparePagingParameters(
        _tableReportsState,
        null
      );
    };

    // //////////////////////////////////////////////////

    vm.showContactPatientModal = function() {
      $rootScope.userDetails = vm.userDetails;
      ModalService.showModal({
        template: require('../views/modals/patient.contact-patient.modal.tpl.html').default,
        controller: 'PatientContactController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
        });
      });
    };

    vm.showContactHcpModal = function() {
      $rootScope.hcpContactDetails = vm.hcpContactDetails;
      $rootScope.hcpPrescriberContactDetails = vm.hcpPrescriberContactDetails;
      ModalService.showModal({
        template: require('../views/modals/patient.contact-hcp.tpl.html').default,
        controller: 'HcpContactController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
        });
      });
    };

    vm.showCreateAccountModal = function() {
      $rootScope.isEditMode = false;
      $rootScope.user = null;
      $rootScope.subscription = null;
      $rootScope.userDetails = null;

      ModalService.showModal({
        template: require('../../admin/views/modals/patient.create.edit.modal.tpl.html').default,
        controller: 'PatientCreateEditController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          vm.input = $rootScope.email; // $rootScope.patientIdentificationNo;
          vm.searchCoalaUser();
          // vm.searchPatient();
          console.log(result);
        });
      });
    };

    vm.showEditUserModal = function() {
      $rootScope.isEditMode = true;
      $rootScope.subscription = vm.subscription;
      $rootScope.userDetails = vm.userDetails;
      openEditPatient();
    };

    function openEditPatient() {
      ModalService.showModal({
        template: require('../../admin/views/modals/patient.create.edit.modal.tpl.html').default,
        controller: 'PatientCreateEditController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
          console.log(result);
          if (result === '') {
            getUserDetails(vm.userId);
          }
        });
      });
    }

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function getCategoryId(category) {
      const categories = [
        'poorQuality',
        'noRhythmDeviation',
        'irregularSequence',
        'pauseAVblockII',
        'fastRegular',
        'fastRegularAndWideQRS',
        'fastSlowSequences',
        'bigemini',
        'trigemini',
        'wideQR',
        'over5SVES',
        'over5VES',
        'irregularWithPWaves'
      ];
      if (category == 'unclassifiedHeartRhythm') {
        return 99;
      }
      return categories.indexOf(category);
    }

    function initPlot(waitFor) {
      _timeout = $timeout(function() {
        const colors = ['#0000FF', '#FFC0CB', '#00FF00'];

        function raw(plot, ctx) {
          const data = plot.getData();
          const axes = plot.getAxes();
          const offset = plot.getPlotOffset();
          for (let i = 0; i < data.length; i += 1) {
            const series = data[i];
            for (let j = 0; j < series.data.length; j += 1) {
              const feelingColor =
                plotMeasurementResult[j].feelingBeforeMeasurement == 'good' ?
                  0 :
                  1;
              const appresultColor =
                vm.parseAppErrorCode(plotMeasurementResult[j]) == 'Ok' ? 0 : 1;
              const fingerCategory = getCategoryId(
                plotMeasurementResult[j].analysisCategory
              );

              // Outer circle, feeling
              const color = colors[feelingColor];
              const d = series.data[j];
              const x = offset.left + axes.xaxis.p2c(d[0]);
              const y = offset.top + axes.yaxis.p2c(d[1]);
              let r = 7;
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.arc(x, y, r, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fillStyle = color;
              ctx.fill();

              // Inner circle, result
              r = 5;
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.arc(x, y, r, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fillStyle = colors[appresultColor];
              ctx.fill();

              // Category
              const text = fingerCategory;
              const metrics = ctx.measureText(text);
              ctx.font = '10px Arial';
              ctx.fillStyle = appresultColor == 0 ? 'white' : 'black';
              ctx.fillText(text, x - metrics.width / 2, y + 3);
            }
          }
        }

        function raw_bp(plot, ctx) {
          const data = plot.getData();
          const axes = plot.getAxes();
          const offset = plot.getPlotOffset();
          for (let i = 0; i < data.length; i += 1) {
            const series = data[i];
            for (let j = 0; j < series.data.length; j += 1) {
              const d = series.data[j];
              if (d[1] > 0) {
                const systolic =
                  plotMeasurementResult[j].systolicAfterMeasurement;
                const diastolic =
                  plotMeasurementResult[j].diastolicAfterMeasurement;
                const color = systolic == d[1] ? 2 : 0;
                const x = offset.left + axes.xaxis.p2c(d[0]);
                const y = offset.top + axes.yaxis.p2c(d[1]);
                const r = 5;
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.arc(x, y, r, 0, Math.PI * 2, true);
                ctx.closePath();
                ctx.fillStyle = colors[color];
                ctx.fill();
              }
            }
          }
        }

        function raw_weight(plot, ctx) {
          const data = plot.getData();
          const axes = plot.getAxes();
          const offset = plot.getPlotOffset();
          for (let i = 0; i < data.length; i += 1) {
            const series = data[i];
            for (let j = 0; j < series.data.length; j += 1) {
              const d = series.data[j];
              if (d[1] > 0) {
                const x = offset.left + axes.xaxis.p2c(d[0]);
                const y = offset.top + axes.yaxis.p2c(d[1]);
                const r = 5;
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.arc(x, y, r, 0, Math.PI * 2, true);
                ctx.closePath();
                ctx.fillStyle = colors[0];
                ctx.fill();
              }
            }
          }
        }

        function showPlotValues(minDate, tickSize) {
          if (tickSize == null) {
            const recordings = jQuery.grep(vm.measurements, function(x) {
              return x.pulse > 0;
            });
            plotMeasurementResult = recordings;
            drawPlotData();
          } else {
            if (minDate < plotMeasurementMinDate) {
              vm.showSpinner = true;
              const pagingParams = {
                page: 0,
                pageSize: 9999,
                id: ''
              };

              PatientService.getMeasurementsForUser(
                vm.userId,
                pagingParams,
                false
              ).then(function(data) {
                plotMeasurementStoredResult = jQuery.grep(data.rows, function(
                  x
                ) {
                  return x.pulse > 0;
                });
                plotMeasurementResult = plotMeasurementStoredResult;
                plotMeasurementMinDate = minDate;
                drawPlotData(minDate, tickSize);
                vm.showSpinner = false;
              });
            } else {
              drawPlotData(minDate, tickSize);
            }
          }
        }

        function drawPlotData(minDate, tickSize) {
          const currentDate = moment()
            .toDate()
            .valueOf();
          const avgmeasurements = [];
          const avgsystolic = [];
          const avgdiastolic = [];
          const avgweight = [];
          const len = plotMeasurementResult.length;
          for (let i = 0; i < len; i += 1) {
            const pulse = plotMeasurementResult[i].pulse;
            const systolic = plotMeasurementResult[i].systolicAfterMeasurement;
            const diastolic = plotMeasurementResult[i].diastolicAfterMeasurement;
            const weight = NationService.getRegionalWeight(
              plotMeasurementResult[i].regionalWeightAfterMeasurement
            ); // plotMeasurementResult[i].weightAfterMeasurement;
            if (pulse > 0) {
              const dtDate = new Date(plotMeasurementResult[i].capturedLocalTime);
              const dtInMilliseconds = dtDate.getTime();
              avgmeasurements.push([dtInMilliseconds, pulse]);
              avgsystolic.push([dtInMilliseconds, systolic]);
              avgdiastolic.push([dtInMilliseconds, diastolic]);
              avgweight.push([dtInMilliseconds, weight]);
            }
          }

          if (tickSize == null) {
            $.plot('#placeholder', [avgmeasurements], {
              series: {lines: {show: true}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {mode: 'time'},
              hooks: {draw: [raw]}
            });

            $.plot('#placeholder_bp', [avgsystolic, avgdiastolic], {
              series: {lines: {show: false}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {mode: 'time'},
              hooks: {draw: [raw_bp]}
            });

            $.plot('#placeholder_weight', [avgweight], {
              series: {lines: {show: false}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {mode: 'time'},
              hooks: {draw: [raw_weight]}
            });
          } else {
            $.plot('#placeholder', [avgmeasurements], {
              series: {lines: {show: true}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {
                minTickSize: [1, tickSize],
                min: minDate,
                max: currentDate,
                mode: 'time'
              },
              hooks: {draw: [raw]}
            });

            $.plot('#placeholder_bp', [avgsystolic, avgdiastolic], {
              series: {lines: {show: false}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {
                minTickSize: [1, tickSize],
                min: minDate,
                max: currentDate,
                mode: 'time'
              },
              hooks: {draw: [raw_bp]}
            });

            $.plot('#placeholder_weight', [avgweight], {
              series: {lines: {show: false}, points: {show: true}},
              grid: {hoverable: true, clickable: true},
              xaxis: {
                minTickSize: [1, tickSize],
                min: minDate,
                max: currentDate,
                mode: 'time'
              },
              hooks: {draw: [raw_weight]}
            });
          }
        }

        vm.plotMeasurementsInList = function() {
          showPlotValues();
        };

        vm.plotMeasurementsLastWeek = function() {
          const minDate = moment()
            .subtract(7, 'day')
            .valueOf();
          showPlotValues(minDate, 'day');
        };

        vm.plotMeasurementsLastMonth = function() {
          const minDate = moment()
            .subtract(1, 'month')
            .valueOf();
          showPlotValues(minDate, 'month');
        };

        vm.plotMeasurementsLastYear = function() {
          const minDate = moment()
            .subtract(1, 'year')
            .valueOf();
          showPlotValues(minDate, 'month');
        };

        vm.plotMeasurementsLastTenYears = function() {
          const minDate = moment()
            .subtract(10, 'year')
            .valueOf();
          showPlotValues(minDate, 'year');
        };

        vm.getWeightUnitClass = function() {
          return '_' + NationService.getWeightUnit();
        };

        $('<div id=\'tooltip\'></div>')
          .css({
            'position': 'absolute',
            'display': 'none',
            'border': '1px solid #fdd',
            'padding': '2px',
            'background-color': '#fee',
            'opacity': 0.8
          })
          .appendTo('body');

        $('#placeholder').bind('plothover', function(event, pos, item) {
          if (item) {
            const x = item.datapoint[0].toFixed(0);
              const pulse = item.datapoint[1].toFixed(0);
              const index = item.dataIndex;
            const appresult = vm.parseAppErrorCode(plotMeasurementResult[index]);
            const feeling = vm.feelingBeforeMeasurementString(
              plotMeasurementResult[index]
            );
            $('#tooltip')
              .html(
                $filter('date')(new Date(parseInt(x, 10)), vm.formats.longDate) +
                '<br/><b>' +
                $translate.instant('measurement_column_hr') +
                '</b> ' +
                pulse +
                ' <br/><b>' +
                $translate.instant('measurement_column_feeling') +
                ':</b> ' +
                feeling +
                ' <br/><b>' +
                $translate.instant('measurement_column_appresult') +
                ':</b> ' +
                appresult
              )
              .css({top: item.pageY + 5, left: item.pageX + 5})
              .fadeIn(200);
          } else {
            $('#tooltip').hide();
          }
        });

        $('#placeholder').bind('plotclick', function(event, pos, item) {
          if (item) {
            $('#tooltip').hide();
            const m = plotMeasurementResult[item.dataIndex];
            vm.goToMeasurement(m.id);
            // plot.highlight(item.series, item.datapoint);
          }
        });

        showPlotValues();
      }, waitFor);
    }
  }
})();
