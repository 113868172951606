import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminGroupCreateEditController', AdminGroupCreateEditController);

  function AdminGroupCreateEditController($q, $scope, $stateParams, $rootScope, $translate, ModalService, HealthcareProviderService, PatientService, envConfig, SessionService, NationService, close) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.tab = 0;
    vm.rolesList = [];
    vm.storing = false;
    let roleChanged = false;

    activate();

    function activate() {
      vm.isEditMode = $rootScope.isEditMode;

      if ($rootScope.item != null && vm.isEditMode) {
        vm.group = $.extend(true, {}, $rootScope.item);
        vm.originalGroup = $.extend(true, {}, $rootScope.item);
      } else {
        const group = {
          name: '',
          healthcareProviders: []
        };
        vm.group = $.extend(true, {}, group);
        vm.originalGroup = $.extend(true, {}, group);
      }

      getHealthcareProviders();
      getRoles();
    }

    function getRoles() {
      HealthcareProviderService.getUnrestrictedRoles().then(function(response) {
        vm.rolesList = populateRoles(response, vm.group.roles);
      });
    }

    function populateRoles(roles, myRoles) {
      jQuery.grep(roles, function(role) {
        role.selected = false;
        if (myRoles !== undefined) {
          jQuery.grep(myRoles, function(selectedRole) {
            if (selectedRole.id === role.id) {
              role.selected = true;
            }
          });
        }
      });

      // Add my roles that not are available anymore
      if (myRoles !== undefined) {
        jQuery.grep(myRoles, function(providerRole) {
          const found = jQuery.grep(roles, function(role) {
            if (providerRole.id === role.id) {
              return true;
            }
          });
          if (found.length == 0) {
            roles.push({id: providerRole.id, name: providerRole.name, selected: true});
          }
        });
      }

      return roles;
    }

    function storeRoles(groupId) {
      const deferred = $q.defer();
      if (roleChanged) {
        const request = {roles: []};
        jQuery.grep(vm.rolesList, function(role) {
          if (role.selected) {
            request.roles.push({id: role.id});
          }
        });

        HealthcareProviderService.updateHealthcareProviderGroupRoles(groupId, request).then(function(response) {
          console.log('HCP roles stored');
          deferred.resolve();
        });

      } else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    function getHealthcareProviders() {
      HealthcareProviderService.getAllHealthcareProviders().then(function(response) {
        vm.providers = response;
      });
    }

    vm.saveGroup = function() {
      vm.storing = true;
      const request = {
        id: vm.group.id,
        name: vm.group.name,
        healthcareProviderIds: []
      };

      jQuery.grep(vm.group.healthcareProviders, function(x) {
        request.healthcareProviderIds.push(x.id);
      });

      HealthcareProviderService.updateGroup(request).then(function(response) {
        storeRoles(response.id).then(function(response) {
          if (!vm.isEditMode) {
            vm.showFeedback($translate.instant('group_create_created'));
          } else {
            vm.showFeedback($translate.instant('group_create_updated'));
          }
          closeModal();
        });
      }, function(error) {
        vm.storing = false;
        vm.showError($translate.instant('group_create_error'));
      });
    };

    vm.addHealthcareProviderToGroup = function() {
      const provider = vm.selectedProvider;
      if (provider !== null && provider !== undefined) {
        const exists = jQuery.grep(vm.group.healthcareProviders, function(x) {
          return provider.id == x.id;
        });
        if (exists.length == 0) {
          addHealthcareProviderToList(provider);
        }
        vm.selectedProvider = null;
      }
    };

    function addHealthcareProviderToList(provider) {
      vm.group.healthcareProviders.push({id: provider.id, name: provider.name});
    }

    vm.roleChanged = function() {
      roleChanged = true;
    };

    vm.getToolTip = function(role) {
      const text = $translate.instant(('roles_desc_' + role.name).toLowerCase());
      if (text.indexOf('roles_desc_') > -1) {
        return '';
      }
      return text;
    };

    vm.getRoleName = function(role) {
      const text = $translate.instant(('roles_name_' + role.name).toLowerCase());
      if (text.indexOf('roles_name_') > -1) {
        return role.name;
      }
      return text;
    };

    vm.removeProvider = function(item) {
      const itemIndex = vm.group.healthcareProviders.indexOf(item);
      if (itemIndex != -1) {
        vm.group.healthcareProviders.splice(itemIndex, 1);
      }
    };

    vm.setTab = function(tabId) {
      this.tab = tabId;
    };

    vm.isSet = function(tabId) {
      return this.tab === tabId;
    };

    vm.remove = function() {
      HealthcareProviderService.deleteGroup(vm.group.id).then(function(response) {
        vm.showFeedback($translate.instant('group_deleted'));
        closeModal();
      }, function(error) {
        vm.showError($translate.instant('group_deleted_error'));
      });
    };

    vm.showInactivateGroup = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.delete.tpl.html').default,
        controller: 'AdminGroupCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
          $rootScope.updateGroupsTable();
        });
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal() {
      close('', 500);
      $('#usermodal').modal('hide');
    }
  }
})();
