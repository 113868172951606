import * as angular from 'angular';
import {NavStep, StepNavigation} from './navsteps.component';

angular.module('core')
    .component('navTabs',
        {
            bindings: {
                stepNavigation: '=',
            },
            template: `
                <ul class="nav nav-tabs step-tabs">
                    <li ng-repeat="tab in $ctrl.getTabs()" ng-class="{active: $ctrl.stepNavigation.index == $index}" >
                        <div class="darkBlueFont navLi" ng-click="$ctrl.stepNavigation.setActiveStep($index)">
                            {{ tab.title }}
                        </div>
                    </li>
                </ul>
            `,
            controller: class NavTabsController {
                stepNavigation?: StepNavigation;

                // If a step has an empty title it will not be displayed since those are used only in nav-steps component.
                getTabs(): NavStep[] {
                    return this.stepNavigation ? this.stepNavigation.steps.filter((tab) => tab.title !== '') : [];
                }
            }
        }
    );
