import * as angular from 'angular';

(function() {
  'use strict';

  function SessionService() {

    const service = {};
    let userDetails;
    let medicalJournal;
    let measurements;
    const shouldReloadFromSession = false;
    const defaultSearchPatientOption = 3;

    const permissions = {
      readPatientUser: false,
      writePatientUser: false,

      readStaffUser: false,
      writeStaffUser: false,

      readHealthcareProvider: false,
      writeHealthcareProvider: false,

      readHealthcareProviderGroup: false,
      writeHealthcareProviderGroup: false,

      readMeasurementConfiguration: false,
      writeMeasurementConfiguration: false,

      generateEcgReport: false,
      generatePatientSummaryReport: false,

      readNotes: false,
      writeNotes: false,

      readAudioFilter: false,
      writeAudioFilter: false,

      readRelay: true,
      writeRelay: true,

      readMedicalData: false,
      // readAnonymizedMedicalData: true,

      // readPatientHealthData: true,
      // writePatientHealthData: true,

      readPatientSubscription: false,
      writePatientSubscription: false,

      readTimeReports: false,
      writeTimeReports: false,

      orderCoala: false,

      readPayment: false,
      writePayment: false,

      readAI: false,
      writeAI: false,

      manageAccount: false,

      // coalaService: false,
      coalaAdmin: false,

      canSetBankIdOnMyJournal: false,

      readSerialNumber: false,
      writeSerialNumber: false,

      /* client specific permissions */
      portaluser: false,
      staff: false,
      patient: false,
      representingProvider: false,
      timereporter: false,
      healthcareprovider: false,
      isPatient: false,
      emailmonitoringservice: false,
      readAndroidDevices: false,
      writeAndroidDevices: false
    };

    service.clearPatientData = function() {
      sessionStorage.removeItem('userData');
    };

    service.setPatientData = function(ud) {
      sessionStorage.setItem('userData', JSON.stringify(ud));
    };

    service.getPatientData = function() {
      return jQuery.parseJSON(sessionStorage.getItem('userData'));
    };

    service.setLastActivity = function() {
      sessionStorage.setItem('lastActivity', new Date());
    };

    service.getLastActivity = function() {
      return new Date(sessionStorage.getItem('lastActivity'));
    };

    service.setLoggedInUser = function(user) {
      sessionStorage.setItem('loggedInUser', JSON.stringify(user));
    };

    service.getLoggedInUser = function() {
      return jQuery.parseJSON(sessionStorage.getItem('loggedInUser'));
    };

    service.getReprestingProviderId = function() {
      const userToken = service.getLoggedInUser();
      if (userToken.representing !== null && userToken.representing !== undefined &&
        userToken.representing.representationType == 'healthcareProvider') {
        return userToken.representing.id;
      }
      return '';
    };

    service.isPortalUser = function() {
      const userToken = service.getLoggedInUser();
      if (userToken.representing !== null && userToken.representing !== undefined) {
        return true;
      }
      return false;
    };

    service.isUnrestrictedOrGroupUser = function() {
      const userToken = service.getLoggedInUser();
      return userToken !== null && (userToken.representing.representationType === 'healthcareProviderGroup' ||
        userToken.representing.representationType === 'unrestricted');
    };

    service.isUnrestrictedUser = function() {
      const userToken = service.getLoggedInUser();
      return userToken !== null && userToken.representing.representationType === 'unrestricted';
    };

    service.setNation = function(nationId) {
      sessionStorage.setItem('nationId', nationId);
    };

    service.getNation = function() {
      const result = sessionStorage.getItem('nationId');
      return result !== null && result !== undefined ? result : '';
    };

    service.setExpandedMeasuremenConfigurations = function(data) {
      sessionStorage.setItem('measurementConfigurationOptions', JSON.stringify(data));
    };

    service.getExpandedMeasuremenConfigurations = function() {
      return jQuery.parseJSON(sessionStorage.getItem('measurementConfigurationOptions'));
    };

    service.setFilterOptions = function(filter) {
      sessionStorage.setItem('filterData', JSON.stringify(filter));
    };

    service.getFilterOptions = function() {
      let obj = jQuery.parseJSON(sessionStorage.getItem('filterData'));
      if (obj == null) {
        obj =
          {
            searchOptionPatient: {name: 'All', value: defaultSearchPatientOption},
            searchPortalUserOption: {name: 'All', value: ''},
            searchOptionPatientMeasurement: {alert: false, notreviewed: false, showonlyabnormalresult: false, categories: [], feelings: [], configurations: []},
            searchOptionMeasurements: {
              categories: [], feelings: [], feelingfreetext: null, fromdate: null, todate: null,
              measurementDays: null, flagFilterOption: [], autorefresh: false, configurations: []
            },
            searchOptionReports: {fromdate: null, todate: null, reviewType: {name: 'All', value: -1}, reportCreatorType: {name: 'All', value: -1}},
            searchOptionUser: {name: 'All', value: 0},
            searchTextUser: '',
            measurementTab: 'finger'
          };
      }
      return obj;
    };

    service.setSearchFilterPortalUsers = function(portalUsers) {
      sessionStorage.setItem('searchFilterPortalUsers', JSON.stringify(portalUsers));
    };

    service.getSearchFilterPortalUsers = function() {
      const portalUsers = jQuery.parseJSON(sessionStorage.getItem('searchFilterPortalUsers'));
      return portalUsers;
    };

    service.setPermissions = function(userpermissions, isPatient) {
      const isProvider = service.getReprestingProviderId() !== '';
      const isPortalUser = service.isPortalUser();
      permissions.readPatientUser = hasPermission(userpermissions, 'readPatientUser');
      permissions.writePatientUser = hasPermission(userpermissions, 'writePatientUser');
      permissions.readStaffUser = hasPermission(userpermissions, 'readStaffUser');
      permissions.writeStaffUser = hasPermission(userpermissions, 'writeStaffUser');
      permissions.readHealthcareProvider = hasPermission(userpermissions, 'readHealthcareProvider');
      permissions.writeHealthcareProvider = hasPermission(userpermissions, 'writeHealthcareProvider');
      permissions.readHealthcareProviderGroup = hasPermission(userpermissions, 'readHealthcareProviderGroup');
      permissions.writeHealthcareProviderGroup = hasPermission(userpermissions, 'writeHealthcareProviderGroup');
      permissions.readMeasurementConfiguration = hasPermission(userpermissions, 'readMeasurementConfiguration');
      permissions.writeMeasurementConfiguration = hasPermission(userpermissions, 'writeMeasurementConfiguration');
      permissions.generateEcgReport = hasPermission(userpermissions, 'generateEcgReport');
      permissions.generatePatientSummaryReport = hasPermission(userpermissions, 'generatePatientSummaryReport');
      permissions.readNotes = hasPermission(userpermissions, 'readNotes');
      permissions.writeNotes = hasPermission(userpermissions, 'writeNotes');
      permissions.readAudioFilter = hasPermission(userpermissions, 'readAudioFilter');
      permissions.writeAudioFilter = hasPermission(userpermissions, 'writeAudioFilter');
      permissions.readMedicalData = hasPermission(userpermissions, 'readMedicalData');
      permissions.readPatientSubscription = hasPermission(userpermissions, 'readPatientSubscription');
      permissions.writePatientSubscription = hasPermission(userpermissions, 'writePatientSubscription');
      permissions.readTimeReports = hasPermission(userpermissions, 'readTimeReports');
      permissions.writeTimeReports = hasPermission(userpermissions, 'writeTimeReports');
      permissions.orderCoala = hasPermission(userpermissions, 'orderCoala');
      permissions.readPayment = hasPermission(userpermissions, 'readPayment');
      permissions.writePayment = hasPermission(userpermissions, 'writePayment');
      permissions.readRelay = hasPermission(userpermissions, 'readRelay');
      permissions.writeRelay = hasPermission(userpermissions, 'writeRelay');
      permissions.readAI = hasPermission(userpermissions, 'readAI');
      permissions.writeAI = hasPermission(userpermissions, 'writeAI');
      permissions.manageAccount = hasPermission(userpermissions, 'manageAccount');
      permissions.coalaAdmin = hasPermission(userpermissions, 'coalaAdmin');
      permissions.readSerialNumber = hasPermission(userpermissions, 'readSerialNumber');
      permissions.writeSerialNumber = hasPermission(userpermissions, 'writeSerialNumber');
      permissions.canSetBankIdOnMyJournal = hasPermission(userpermissions, 'canSetBankIdOnMyJournal');
      permissions.readAndroidDevices = hasPermission(userpermissions, 'readAndroidDevices');
      permissions.writeAndroidDevices = hasPermission(userpermissions, 'writeAndroidDevices');

      permissions.representingProvider = isProvider;
      permissions.timereporter = permissions.writeTimeReports && isProvider;
      permissions.portaluser = isPortalUser;
      permissions.patient = permissions.readPatientUser || permissions.writePatientUser;
      permissions.staff = permissions.readStaffUser || permissions.writeStaffUser;
      permissions.healthcareprovider = permissions.readHealthcareProvider || permissions.writeHealthcareProvider;
      permissions.group = permissions.readHealthcareProviderGroup || permissions.writeHealthcareProviderGroup;
      permissions.isPatient = isPatient;
      permissions.emailmonitoringservice = permissions.readRelay && permissions.writeRelay;

      sessionStorage.setItem('permissions', JSON.stringify(permissions));
    };

    service.getUserPermissions = function() {
      const permissions = jQuery.parseJSON(sessionStorage.getItem('permissions'));
      return permissions;
    };

    function hasPermission(permissions, permission) {
      const found = jQuery.grep(permissions, function(p) {
        return p === permission;
      });
      return found.length > 0;
    }

    service.getIsPatient = function() {
      return service.getUserPermissions().isPatient;
    };

    service.setIsMonitoringCenterStaff = function(value) {
      let r = 'false';
      if (value) {
        r = 'true';
      }
      sessionStorage.setItem('isMonitoringCenterStaff', r);
    };

    service.getIsMonitoringCenterStaff = function() {
      let isMonitoringCenterStaff = false;
      const r = sessionStorage.getItem('isMonitoringCenterStaff');
      if (r === 'true') {
        isMonitoringCenterStaff = true;
      }
      return isMonitoringCenterStaff;
    };

    service.setRefreshToken = function(token) {
      sessionStorage.setItem('refresh_token', token);
    };

    service.getRefreshToken = function() {
      return sessionStorage.getItem('refresh_token');
    };

    service.setAuthToken = function(token) {
      sessionStorage.setItem('authheader', ('bearer ' + token));
    };

    service.getAuthToken = function() {
      return sessionStorage.getItem('authheader');
    };

    service.setAuthTokenValidTo = function(validTo) {
      sessionStorage.setItem('authtokenvalidto', validTo);
    };

    service.getAuthTokenValidTo = function() {
      return new Date(sessionStorage.getItem('authtokenvalidto'));
    };

    service.clearAll = function() {
      sessionStorage.clear();
    };

    return service;
  }

  angular
    .module('patients')
    .factory('SessionService', SessionService);

})();
