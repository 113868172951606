import * as angular from 'angular';
import RoleResponse from './models/RoleResponse';
import HealthcareProviderResponse from './models/HealthcareProviderResponse';
import {HealthcareProviderGroupResponse} from './models/HealthcareProviderGroupResponse';
import {PatientEmailMonitoringResponse} from './models/PatientEmailMonitoringResponse';
import {MeasurementConfigurationResponse} from './models/MeasurementConfigurationResponse';
import Paging from './models/Paging';
import {HealthcareMonitoringServiceProviderResponse} from './models/HealthcareMonitoringServiceProviderResponse';
import {PatientEnrollmentResponse} from './models/PatientEnrollmentResponse';
import {PatientEnrollmentConditionListRequest, PatientEnrollmentConditionResponse} from './models/PatientEnrollmentCondition';
import {MonitoringServiceCollectionListRequest, MonitoringServiceCollectionResponse} from './models/MonitoringServiceCollection';
import {StaffResponse} from "./models/StaffResponse";
import MonitoringServiceContactInfoResponse from "./models/MonitoringServiceContactInfoResponse";
import ScheduledTaskListResponse from './models/ScheduledTaskListResponse';

export default class HealthcareProviderService {
    root: string;
    httpClient: any;

    constructor($q: any, $http: any, envConfig: any, SessionService: any, HttpClientService: any) {
        this.httpClient = HttpClientService;
        this.root = envConfig.baseUrl;
    }

    getAllHealthcareProviders() {
        return this.httpClient.get(this.root + '/adminapi/v1/healthcareProviders');
    }

    getHealthcareProviders(paging: Paging) {
        const query = [];
        if (paging.page == undefined) {
            paging.page = 0;
        }
        paging.pageSize = 20;

        query.push(
            this.root,
            '/adminapi/v1/healthcareProviders/meta',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));

    }

    getHealthcareProvider(id: string): Promise<HealthcareProviderResponse> {
        return this.httpClient.get(this.root + '/adminapi/v1/healthcareProviders/' + id);
    }

    getHealthcareProviderMonitoringServiceContactInfo(id: string): Promise<MonitoringServiceContactInfoResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/monitoringServiceContactInfo/healthcareProvider/' + id);
    }

    postHealthcareProviderMonitoringServiceContactInfo(id: string, contactInfo: MonitoringServiceContactInfoResponse): Promise<void> {
        return this.httpClient.post(this.root + '/adminapi/v1/monitoringServiceContactInfo/healthcareProvider/' + id, contactInfo);
    }

    getPortalUserMonitoringServiceContactInfo(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/monitoringServiceContactInfo/staff/' + id);
    }

    postPortalUserMonitoringServiceContactInfo(id: string, contactInfo: MonitoringServiceContactInfoResponse) {
        return this.httpClient.post(this.root + '/adminapi/v1/monitoringServiceContactInfo/staff/' + id, contactInfo);
    }

    searchPatient(id: string, pnr: string) {
        const url = this.root + '/adminapi/v1/healthcareProviders/' + id + '/users?request.search=' + pnr;

        return this.httpClient.get(url);
    }

    requestAccess(providerId: string, userId: string) {
        return this.httpClient.postWithoutBody(this.root + '/adminapi/v1/healthcareProviders/' + providerId + '/users/' + userId + '/requestAccess');
    }

    getAdminMeasurements(paging: Paging) {
        const query = [];
        if (!paging.page) {
            paging.page = 0;
        }
        paging.pageSize = 20;

        query.push(
            this.root,
            '/adminapi/v1/measurements/admin',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    getAdminMeasurement(measurementId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurements/admin/' + measurementId);
    }

    getAllDoctorsWithHelthcareProviders(paging: Paging) {
        if (!paging) {
            // @ts-ignore
            paging = {};
        }

        let query = this.root + '/adminapi/v1/staffs?';

        if (paging.filter !== undefined && paging.filter !== '') {
            query += 'request.filter[0].key=healthcareproviderid&request.filter[0].value=' + paging.filter;
        }

        query += '&Page=' + paging.page + '&PageSize=' + paging.pageSize + '&Sort=' + paging.sort + '&Search=' + paging.search;

        return this.httpClient.get(query);
    }

    getHealthcareProvidersForDoctor(staffId: string) {
        const url = this.root + '/adminapi/v1/staffs/' + staffId + '/healthcareProviders';
        return this.httpClient.get(url);
    }

    createHealthcareProvider(obj: any): Promise<HealthcareProviderResponse> {
        const url = this.root + '/adminapi/v1/healthcareProviders';
        return this.httpClient.post(url, obj);
    }

    updateHealthcareProvider(id: string, obj: any): Promise<void> {
        const url = this.root + '/adminapi/v1/healthcareProviders/' + id;
        return this.httpClient.put(url, obj);
    }

    createNewDoctor(obj: any) {
        const url = this.root + '/adminapi/v1/staffs';
        return this.httpClient.post(url, obj);
    }

    rejectAccessRequest(id: string) {
        const url = this.root + '/api/v1/healthcareProviders/' + id + '/reject';
        return this.httpClient.postWithoutBody(url);
    }

    acceptAccessRequest(id: string) {
        const url = this.root + '/api/v1/healthcareProviders/' + id + '/accept';
        return this.httpClient.postWithoutBody(url);
    }

    updateDoctor(id: string, obj: any) {
        const url = this.root + '/adminapi/v1/staffs/' + id;
        return this.httpClient.put(url, obj);
    }

    inactivate(id: string) {
        const url = this.root + '/adminapi/v1/healthcareProviders/' + id;
        return this.httpClient.delete(url);
    }

    deleteDoctor(id: string) {
        const url = this.root + '/adminapi/v1/staffs/' + id;
        return this.httpClient.delete(url);
    }

    getStaff(id: string): Promise<StaffResponse> {
        const url = this.root + '/adminapi/v1/staffs/' + id;
        return this.httpClient.get(url);
    }

    getStaffs() {
        const url = this.root + '/adminapi/v1/staffs/minimized';
        return this.httpClient.get(url);
    }

    getAllPatientsForHealthcareProvider(healthcareProviderId: string) {
        const url = this.root + '/adminapi/v1/healthcareProviders/' + healthcareProviderId + '/users';
        return this.httpClient.get(url);
    }

    getDoctorByHealthcareProvider(providerId: string) {
        const url = this.root + '/adminapi/v1/staffs?request.filter[0].key=healthcareproviderid&request.filter[0].value=' + providerId;
        return this.httpClient.get(url);
    }

    getChestAndFingerMeasurementsPdf(fileName: string, measurements: any) {
        return this.httpClient.getPdfWithBody(fileName, measurements, this.root + '/adminapi/v1/measurements/pdf/chestandfinger?includeUserprofile=false');
    }

    connect(user: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/staffs/connect', user);
    }

    getPatientEmailMonitoring(healthcareProviderId: string): Promise<PatientEmailMonitoringResponse> {
        const url = this.root + '/adminapi/v1/outputrelay/standardrelay/standardPatientEmailMonitoring/?healthcareProviderId=' + healthcareProviderId;
        return this.httpClient.get(url);
    }

    deletePatientEmailMonitoring(schemeId: string) {
        const url = this.root + '/adminapi/v1/outputrelay/scheme/?schemeId=' + schemeId;
        return this.httpClient.delete(url);
    }

    updatePatientEmailMonitoring(obj: any) {
        const url = this.root + '/adminapi/v1/outputrelay/standardrelay/StandardPatientEmailMonitoring';
        return this.httpClient.post(url, obj);
    }

    getMeasuremenConfigurations(): Promise<MeasurementConfigurationResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/all/');
    }

    getMeasurementConfigurationsForHealthcareProvider(providerId: string): Promise<MeasurementConfigurationResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/healthcareproviders/' + providerId);
    }

    updateHealthcareProviderConfiguration(configurations: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/measurementconfiguration/setConfigurationsForHealthcareProvider/', configurations);
    }

    getHealthcareProviderRoles(providerId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/role/available/healthcareprovider/' + providerId);
    }

    getGroupRoles(groupId?: string): Promise<RoleResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/role/available/healthcareprovidergroup/' + groupId);
    }

    getUnrestrictedRoles() {
        return this.httpClient.get(this.root + '/adminapi/v1/role/available/unrestricted');
    }

    updateHealthcareProviderUserRoles(userId: string, roles: RoleResponse[]) {
        const url = this.root + '/adminapi/v1/rolecondition/user/' + userId + '/healthcareproviders';
        return this.httpClient.post(url, roles);
    }

    updateHealthcareProviderGroupUserRoles(userId: string, roles: RoleResponse[]) {
        const url = this.root + '/adminapi/v1/rolecondition/user/' + userId + '/healthcareprovidergroups';
        return this.httpClient.post(url, roles);
    }

    updateUnrestrictedUserRoles(userId: string, roles: RoleResponse[]) {
        const url = this.root + '/adminapi/v1/rolecondition/user/' + userId + '/unrestricted';
        return this.httpClient.post(url, roles);
    }

    updateHealthcareProviderRoles(providerId: string, roles: { roles: { id: string }[] }) {
        const url = this.root + '/adminapi/v1/rolecondition/healthcareprovider/' + providerId;
        return this.httpClient.post(url, roles);
    }

    updateHealthcareProviderGroupRoles(groupId: string, roles: RoleResponse[]) {
        const url = this.root + '/adminapi/v1/rolecondition/healthcareprovidergroup/' + groupId;
        return this.httpClient.post(url, roles);
    }

    getMyGroups(paging?: Paging): Promise<{rows: HealthcareProviderGroupResponse}> {
        const query = [];
        if (!paging) {
            paging = {page: 0, pageSize: 9999};
        }

        query.push(
            this.root,
            '/adminapi/v1/healthcareProviderGroups',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    updateGroup(request: any) {
        const url = this.root + '/adminapi/v1/healthcareProviderGroups';
        return this.httpClient.post(url, request);
    }

    deleteGroup(groupId: string) {
        const url = this.root + '/adminapi/v1/healthcareProviderGroups/' + groupId;
        return this.httpClient.delete(url);
    }

    getMonitoringServiceOptions(): Promise<HealthcareMonitoringServiceProviderResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/healthcareMonitoringServiceProvider');
    }

    getMonitoringServiceCollection(healthcareProviderId: string): Promise<MonitoringServiceCollectionResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/MonitoringServiceCollection/HealthcareProvider/' + healthcareProviderId);
    }

    postMonitoringServiceCollection(healthcareProviderId: string, payload: MonitoringServiceCollectionListRequest): Promise<void> {
        return this.httpClient.post(this.root + '/adminapi/v1/MonitoringServiceCollection/HealthcareProvider/' + healthcareProviderId, payload);
    }

    getPatientEnrollmentOptions(): Promise<PatientEnrollmentResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/PatientEnrollment');
    }

    getPatientEnrollmentCondition(healthcareProviderId: string): Promise<PatientEnrollmentConditionResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/PatientEnrollmentCondition/HealthcareProvider/' + healthcareProviderId);
    }

    postPatientEnrollmentCondition(healthcareProviderId: string, payload: PatientEnrollmentConditionListRequest): Promise<void> {
        return this.httpClient.post(this.root + '/adminapi/v1/PatientEnrollmentCondition/HealthcareProvider/' + healthcareProviderId, payload);
    }

    getScheduledTasks(providerId: string): Promise<ScheduledTaskListResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/scheduledtask/healthcareproviders/' + providerId);
    }
}

angular.module('patients').service('HealthcareProviderService',
    ['$q', '$http', 'envConfig', 'SessionService', 'HttpClientService', HealthcareProviderService]
);
