import * as angular from 'angular';
import {NationService} from '../../../../services/NationService';

export interface HomeEnrollmentFormSettings {
    enabled: boolean;
    address?: string;
    city?: string;
    zipcode?: string;
    phoneNumber?: string;
    addSubscriptionMonths?: number;
}

// language=HTML
const template = `
    <form name="$ctrl.homeEnrollmentForm">
        <div class="row">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{ "order_coalaconnect_menu" | translate }}
                </div>

                <div class="col-md-12">
                    <h4>{{"order_coalaconnect_desc" | translate}}</h4>
                    <div ng-repeat="option in $ctrl.homeEnrollmentOptions">
                        <label ng-show="option !== $ctrl.homeEnrollmentOptions.yes || $ctrl.isUS">
                            <input ng-click="$ctrl.homeEnrollmentOptionsChanged(option)" ng-model="option.selected" type="checkbox" class="radio">
                            <span>{{option.title}}</span>
                            <br>
                        </label>
                    </div>
                    <div ng-show="$ctrl.isEditMode">
                        <br>
                        <span>{{"order_coalaconnect_no_desc_1" | translate}}</span><br>
                        <span>{{"order_coalaconnect_no_desc_2" | translate}}</span>
                    </div>
                </div>
            </fieldset>

            <fieldset class="form-group">
                <div uib-collapse="!$ctrl.homeEnrollmentOptions.yesShipCoala.selected">
                    <div class="col-md-12">
                        <label>{{"address" | translate}} *</label><br>
                        <input autocomplete="off" autofocus
                               class="input-text-lg input-medium blockInput form-control"
                               name="address" translate-attr="{ placeholder: 'address' }"
                               ng-model="$ctrl.settings.address"
                               type="text" ng-required="$ctrl.homeEnrollmentOptions.yesShipCoala.selected">
                    </div>
                    <div class="col-md-6">
                        <label>{{"zipcode" | translate}} *</label><br>
                        <input autocomplete="off" autofocus
                               class="input-text-lg input-medium blockInput form-control"
                               name="zipcode" translate-attr="{ placeholder: 'zipcode' }"
                               ng-model="$ctrl.settings.zipcode"
                               type="text" ng-required="$ctrl.homeEnrollmentOptions.yesShipCoala.selected">
                    </div>
                    <div class="col-md-6">
                        <label>{{"city" | translate}} *</label><br>
                        <input autocomplete="off" autofocus
                               class="input-text-lg input-medium blockInput form-control"
                               name="zipcode" translate-attr="{ placeholder: 'city' }"
                               ng-model="$ctrl.settings.city"
                               type="text" ng-required="$ctrl.homeEnrollmentOptions.yesShipCoala.selected">
                    </div>
                </div>

                <div uib-collapse="$ctrl.homeEnrollmentOptions.no.selected" ng-show="!$ctrl.homeEnrollmentOptions.no.selected">
                    <div class="col-md-12">
                        <label>{{"phonenumber" | translate}} *</label><br>
                        <input autocomplete="off" autofocus
                               class="input-text-lg input-medium blockInput form-control"
                               name="phoneNumber" translate-attr="{ placeholder: 'phonenumber' }"
                               ng-model="$ctrl.settings.phoneNumber"
                               type="text" ng-required="!$ctrl.homeEnrollmentOptions.no.selected">
                    </div>
                    <div class="col-md-12" ng-show="!$ctrl.isUS">
                        <label>{{"monthsoptions_title" | translate}}</label><br>
                        <select name="fav_show" id="fav_show"
                                class="input-text-lg input-medium blockInput form-control"
                                style="padding-left:14px;"
                                ng-model="$ctrl.freeMonths"
                                ng-change="$ctrl.setToDate()"
                                ng-options="option.name | translate for option in $ctrl.freeMonthsOptions track by option.value"
                                />
                    </div>

                    <div class="col-md-12">
                        <label>
                            <input type="checkbox" ng-model="$ctrl.acceptagreement" ng-required="!$ctrl.homeEnrollmentOptions.no.selected">
                            <span>
                                <a style="font-size: 16px;" ng-href="{{ $ctrl.termsUrl }}" target="_blank">
                                    {{ "accept_agreement" | translate }}
                                </a>
                            </span>
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
    </form>
`;

angular.module('admin')
    .component('patientHomeEnrollmentForm',
        {
            bindings: {
                isEditMode: '<',
                settings: '=',
                validated: '=',
            },
            template: template,
            controller: ['$translate', 'NationService', class PatientHomeEnrollmentForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: HomeEnrollmentFormSettings;
                homeEnrollmentForm: any;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly isUS: boolean;
                private readonly termsUrl: string;
                private readonly freeMonthsOptions: { name: string; value: number }[];
                private readonly homeEnrollmentOptions: {
                    yesShipCoala: {title: string; selected: boolean};
                    yes: {title: string; selected: boolean};
                    no: {title: string; selected: boolean};
                };
                private freeMonths: { name: string; value: number };
                private initialized = false;

                constructor($translate: angular.translate.ITranslateService, NationService: NationService) {
                    this.translateService = $translate;
                    this.freeMonthsOptions = NationService.getOrderCoalaSubscriptionOptions();
                    this.isUS = NationService.isUS();
                    this.termsUrl = NationService.getOrderCoalaTermsUrl();
                    this.freeMonths = this.freeMonthsOptions[0];

                    this.homeEnrollmentOptions = {
                        yesShipCoala: {
                            title: this.translateService.instant('order_coalaconnect_with_training_shipment'),
                            selected: this.isUS
                        },
                        yes: {
                            title: this.translateService.instant('order_coalaconnect_with_training'),
                            selected: false
                        },
                        no: {
                            title: this.translateService.instant('order_coalaconnect_no'),
                            selected: !this.isUS
                        }
                    };
                }

                $doCheck() {
                    if (this.homeEnrollmentForm) {
                        this.validated = this.homeEnrollmentForm.$valid;
                        if (!this.initialized && this.isEditMode) {
                            this.initialized = true;
                            this.setHomeEnrollmentDefaultOptionNo();
                        }
                    }
                }

                setHomeEnrollmentDefaultOptionNo() {
                    this.homeEnrollmentOptions.yesShipCoala.selected = false;
                    this.homeEnrollmentOptions.no.selected = true;
                }

                homeEnrollmentOptionsChanged = (selected: any) => {
                    for (const [, value] of Object.entries(this.homeEnrollmentOptions)) {
                        value.selected = value === selected;
                    }
                    if (!this.homeEnrollmentOptions.yesShipCoala.selected) {
                        delete this.settings.address;
                        delete this.settings.city;
                        delete this.settings.zipcode;
                        delete this.settings.addSubscriptionMonths;
                    }
                    if (!this.homeEnrollmentOptions.yesShipCoala.selected && !this.homeEnrollmentOptions.yes.selected) {
                        delete this.settings.phoneNumber;
                    }
                    this.settings.enabled = !this.homeEnrollmentOptions.no.selected;
                }

                setToDate = () => {
                    this.settings.addSubscriptionMonths = this.freeMonthsOptions.filter((option) => {
                        return option.value === this.freeMonths.value;
                    })[0].value;
                };
            }]
        }
    );
