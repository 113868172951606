import * as angular from 'angular';
import * as base32 from '../../../scripts/lib/base32';

(function() {
  'use strict';

  angular
    .module('login')
    .controller('LoginController', LoginController);

  function LoginController($http, $state, $scope, $translate, $rootScope, $window, $stateParams, $location, SessionService, AuthService, UtilService, NationService, HttpClientService, envConfig) {
    const vm = this;
    vm.forgotPassword = false;
    vm.showResetToken = false;
    vm.buttonText = $translate.instant('login_button');
    vm.pageTitle = 'login_title';
    vm.callBack = false;
    vm.showSpinner = false;
    vm.showEnterTOTPToken = false;
    vm.qrcodeString = 'init';
    let secret = null;

    activate();

    function activate() {
      HttpClientService.isInMaintenanceMode();
      SessionService.setNation(envConfig.defaultNationId);

      vm.loginresponse = $stateParams.message;
      vm.email = $rootScope.email;
      vm.password = $rootScope.password;
      vm.qrcodeString = $rootScope.qrcodeString;
      secret = $rootScope.secret;

      if ($window.location.hash.indexOf('grandidsession') != -1) {

        const type = $stateParams.type == 'siths' ? 'sessionSITHS' : $stateParams.type == 'other' ? 'sessionOtherDevice' : 'sessionSameDevice';
        // var type = $stateParams.type == 'other' ? 'sessionOtherDevice' : 'sessionSameDevice';
        const grandidsession = UtilService.getParameterByName('grandidsession', $window.location.hash);

        const req = {'grant_type': type, 'session': grandidsession};

        vm.callBack = true;

        AuthService.login(req).then(function(response) {
          redirectUser(response);
        });
      } else {
        SessionService.setLastActivity();
        const usrNode = document.getElementById('login-username');
        const tokenNode = document.getElementById('login-token');
        if (secret !== null && secret !== undefined) {
          tokenNode.focus();
        } else if (usrNode != undefined) {
          usrNode.focus();
        }
      }
    }

    vm.chooseLoginMethod = function() {
      $state.go('login');
    };

    vm.loginOrResetPassword = function() {
      if (vm.forgotPassword) {
        vm.sendForgotPassword();
      } else {
        vm.login();
      }
    };

    vm.hideKeyboard = function() {
      document.activeElement.blur();
      document.getElementById('login-username').blur();
      document.getElementById('login-password').blur();
    };

    vm.login = function() {
      vm.showSpinner = true;
      let data = 'grant_type=password&username=' + vm.email + '&password=' + vm.password + '&client_id=self';
      if (vm.loginToken != null) {
        data = data + '&token=' + vm.loginToken;
      }
      if (secret != null) {
        data = data + '&secret=' + secret;
      }

      AuthService.login(data).then(function(response) {
        vm.hideKeyboard();
        /* AuthService.test().then(function(data){
            console.log(data);
        });*/
        vm.showSpinner = false;
        redirectUser(response);
      }, function(response) {
        vm.showSpinner = false;
        redirectUser(response);
      });
    };

    vm.bankId = function(type) {
      if (type === 'other') {
        AuthService.bankIdLogin('other').then(function(response) {
          window.location = response.redirectUrl;
        });
      } else if (type === 'same') {
        AuthService.bankIdLogin('same').then(function(response) {
          window.location = response.redirectUrl;
        });
      }
    };

    vm.siths = function() {
      AuthService.sithsLogin().then(function(response) {
        window.location = response.redirectUrl;
      });
    };

    vm.setForgotPassword = function() {
      vm.forgotPassword = true;
      vm.buttonText = 'resetpassword_button';
      vm.pageTitle = 'resetpassword_title';
    };

    vm.goBack = function() {
      vm.forgotPassword = false;
      vm.buttonText = 'login_button';
      vm.pageTitle = 'login_title';
    };

    vm.sendForgotPassword = function() {
      AuthService.resetPassword(vm.email).then(function(response) {
        vm.showFeedback($translate.instant('resetpassword_sentmessage'));
        vm.forgotPassword = false;
        vm.buttonText = 'login_button';
        vm.pageTitle = 'login_title';
      });
    };

    vm.resetToken = function() {
      AuthService.resetToken(vm.email).then(function(response) {
        vm.showFeedback($translate.instant('login_reset_token_message'));
        vm.showResetToken = false;
        vm.showEnterTOTPToken = false;
      });
    };

    vm.getTermsUrl = function() {
      return NationService.getTermsUrl();
    };

    vm.hasAuthMethod = function(authMethod) {
      return NationService.hasAuthMethod(authMethod);
    };

    vm.hasExternalAuthMethod = function() {
      return NationService.hasExternalAuthMethod();
    };

    function isTwoFactorAuthenticationRequired(type) {
      return type == 'authType=TOTP';
    }

    function setupUpTwoFactorAuthentication(secret) {
      if (secret !== null) {
        // You need to install the totp secret code
        $rootScope.email = vm.email;
        $rootScope.password = vm.password;
        $rootScope.secret = secret;

        const bytes = stringToAsciiByteArray(secret); // new Uint8Array(35);
        secret = base32.encode(bytes).toUpperCase() + '====';

        vm.qrcodeString = 'otpauth://totp/Coala?secret=' + secret + '&issuer=Coala%20Life%20AB';
        $rootScope.qrcodeString = vm.qrcodeString;
        // console.log('QRCode: ' + vm.qrcodeString);
        $state.go('installtotpsecretcode');
        return true;
      } else {
        // You need to enter topt token
        vm.showEnterTOTPToken = true;
        document.getElementById('login-token').focus();
        vm.showResetToken = true;
        return false;
      }
    }

    function stringToAsciiByteArray(str) {
      const bytes = [];
      for (let i = 0; i < str.length; i += 1) {
        const charCode = str.charCodeAt(i);
        if (charCode > 0xFF) { // char > 1 byte since charCodeAt returns the UTF-16 value
          console.log('Character ' + String.fromCharCode(charCode) + ' can\'t be represented by a US-ASCII byte.');
        }
        bytes.push(charCode);
      }
      return bytes;
    }

    function redirectUser(response) {
      vm.showResetToken = false;
      if (response !== null && response.message !== undefined) {
        if (response.message == 'Username or password is not valid') {
          $state.go('invaliduserorpassword');
        } else {
          if (isTwoFactorAuthenticationRequired(response.authenticateType)) {
            if (setupUpTwoFactorAuthentication(response.totpSecret)) {
              return false;
            }
          } else {
            $state.go('unauthorized');
          }
        }
      } else {
        $rootScope.secret = null;
        AuthService.setAndRedirectUser(response);
      }
    }

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
