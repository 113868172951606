import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminSerialNumberCreateEditController', AdminSerialNumberCreateEditController);

  function AdminSerialNumberCreateEditController($rootScope, $translate, ModalService, PatientService, SessionService, NationService, close) {
    const vm = this;
    vm.to = moment().utc();
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.healthcareProvider = null;
    vm.providersList = [];
    vm.storing = false;

    let closing = false;

    activate();

    function activate() {
      vm.isEditMode = $rootScope.isEditMode;
      if ($rootScope.item != null && vm.isEditMode) {
        vm.device = $.extend(true, {}, $rootScope.item);
      } else {
        vm.device = {
          number: '',
          healthcareProviderId: null
        };
      }

      vm.providersList = $rootScope.providers;
    }

    vm.showHealthcareProviders = function() {
      return vm.providersList !== null && vm.providersList.length > 0;
    };

    vm.saveDevice = function() {
      if (!vm.isEditMode) {
        vm.storing = true;
        vm.device.healthcareProviderId = vm.healthcareProvider.id;
        PatientService.addDevice(vm.device).then(function(response) {
          vm.showFeedback($translate.instant('serialnumber_created'));
          closeModal();
        }, function(error) {
          vm.showError($translate.instant('serialnumber_created_error'));
          vm.storing = false;
        });
      }
    };

    vm.isHealthcareProviderRequired = function() {
      if (!vm.isEditMode && vm.showHealthcareProviders()) {
        return true;
      }
      return false;
    };

    vm.isHealthcareProviderSelectedIfRequired = function() {
      if (vm.isHealthcareProviderRequired()) {
        if (vm.healthcareProvider == null) {
          return false;
        }
      }
      return true;
    };

    vm.showRemoveDevice = function() {
      ModalService.showModal({
        template: require('../views/modals/confirm.delete.tpl.html').default,
        controller: 'AdminSerialNumberCreateEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          console.log(result);
          $rootScope.updateGroupsTable();
        });
      });
    };

    vm.remove = function() {
      PatientService.removeDevice(vm.device.id).then(function(response) {
        vm.showFeedback($translate.instant('serialnumber_removed'));
        $rootScope.update();
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal() {
      if (!closing) {
        close('', 500);
        $('#usermodal').modal('hide');
      }
      closing = true;
    }
  }
})();
