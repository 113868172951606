import * as angular from 'angular';
import * as moment from 'moment';
import {NationService} from '../../../../services/NationService';
import Permissions from '../../../../services/models/Permissions';
import {EventMonitoringServicesHandler, RemotePatientMonitoringServicesHandler} from '../../../../models/MonitoringServices';
import MonitoringServiceContactInfoResponse from '../../../../services/models/MonitoringServiceContactInfoResponse';

export interface HcpMonitoringFormSettings {
    monitoringServices: EventMonitoringServicesHandler;
    rpmServices: RemotePatientMonitoringServicesHandler;
    eventMonitoringContact: MonitoringServiceContactInfoResponse;
    enableEmailMonitoring: boolean;
    notificationEmails: { email: string }[];
}

angular.module('admin')
    .component('hcpMonitoringForm',
        {
            bindings: {
                isEditMode: '<',
                validated: '=',
                settings: '='
            },
            template: require('./hcp.monitoring.form.component.html').default,
            controller: ['$translate', 'SessionService', 'NationService', class HcpMonitoringForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: HcpMonitoringFormSettings;
                form: any;

                monitoringNotificationOptions = {
                    timeZoneOptions: [
                        {id: 'America/Los_Angeles', selected: true},
                        {id: 'America/Phoenix', selected: false},
                        {id: 'America/Chicago', selected: false},
                        {id: 'America/New_York', selected: false}
                    ],
                    attemptNumOptions: [
                        {id: 1, selected: false},
                        {id: 3, selected: true}
                    ],
                    afterHoursContact: false,
                    notificationCriteriaOptions: [
                        {id: 'Standard', title: 'Standard notification criteria', selected: true},
                        {id: 'Expanded', title: 'Expanded notification criteria', selected: false}
                    ]
                }

                private readonly translateService: angular.translate.ITranslateService;
                private readonly permissions: Permissions;
                private readonly isUS: boolean;

                constructor($translate: angular.translate.ITranslateService, SessionService: any, NationService: NationService) {
                    this.translateService = $translate;
                    this.permissions = SessionService.getUserPermissions();
                    this.isUS = NationService.isUS();
                }

                $doCheck() {
                    if (this.form) {
                        this.validated = this.form.$valid;
                    }

                    if (this.settings) {
                        this.monitoringNotificationOptions.attemptNumOptions.forEach((option) => {
                            option.selected = option.id === this.settings.eventMonitoringContact.attempts;
                        });

                        this.monitoringNotificationOptions.notificationCriteriaOptions.forEach((criteria) => {
                            criteria.selected = criteria.id === this.settings.eventMonitoringContact.notificationCriteria;
                        });

                        this.monitoringNotificationOptions.timeZoneOptions.forEach((zone) => {
                            zone.selected = zone.id === this.settings.eventMonitoringContact.timeZone;
                        });
                    }
                }

                notificationCriteriaChanged = (selected: { id: string; selected: boolean; title: string }) => {
                    this.monitoringNotificationOptions.notificationCriteriaOptions.forEach((criteria) => {
                        criteria.selected = criteria === selected;
                    });
                    this.settings.eventMonitoringContact.notificationCriteria = selected.id;
                };

                attemptNumChanged = (selected: { id: number; selected: boolean }) => {
                    this.monitoringNotificationOptions.attemptNumOptions.forEach((option) => {
                        option.selected = option === selected;
                    });
                    this.settings.eventMonitoringContact.attempts = selected.id;
                };


                getTimeZoneAbbreviation = (zone: { id: string; selected: boolean }) => {
                    return moment.tz(zone.id).format('z');
                };

                timeZoneChanged = (selected: { id: string; selected: boolean }) => {
                    this.monitoringNotificationOptions.timeZoneOptions.forEach((zone) => {
                        zone.selected = zone === selected;
                    });
                    this.settings.eventMonitoringContact.timeZone = selected.id;
                };

                addNotificationEmailRow = () => {
                    this.settings.notificationEmails.push({email: ''});
                };

                removeNotificationEmailRow = (index: number) => {
                    this.settings.notificationEmails.splice(index, 1);
                };
            }]
        }
    );
