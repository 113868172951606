import * as angular from 'angular';

(function() {
  'use strict';

  function AuthService($q, $http, $interval, $state, $location, envConfig, SessionService, HttpClientService, NationService) {

    const service = {};
    let permissions = SessionService.getUserPermissions();
    const root = envConfig.baseUrl;
    const logoutInterval = null;
    const thirtySec = 30 * 1000;
    const intervals = [];

    const baseUrl = root + '/api/v1/account/token';

    service.externalLogin = function() {
      const deferred = $q.defer();
      const externalToken = $location.search().access_token;
      // externalToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzIjoiMjAxOS0wMy0yN1QxMjowNDozNS45MjM2MTY4KzAxOjAwIiwiZXhwIjoxNTUzNjg0Njc1LCJuYmYiOjE1NTM2ODQzNzUsImlhdCI6MTU1MzY4NDM3NSwiZW1haWwiOiJqb2hhbnBhdGllbnQ4QGdtYWlsLmNvbSIsInRva2VuVHlwZSI6ImFjY2Vzc1Rva2VuIiwiaXNzdWVkIjoiMjAxOS0wMy0yN1QxMDo1OTozNS45MjM2MTY4KzAwOjAwIiwic2VjdXJpdHlTdGFtcCI6IjFjZjlhNGY2LTVkNWMtNGIzMS05Y2RiLTRkOWRiYTE1NmFjZCIsInJvbGVzIjpbIkFjdGl2ZVN1YnNjcmlwdGlvbiIsIlBhdGllbnQiXSwidXNlcklkIjoiZDFjNzJiMzYtZWYxMC00YWY4LTk4NzAtMjhmYjcwOTIwMTFiIiwidXNlclNldHRpbmdzIjp7Imxhbmd1YWdlIjp7ImlkIjoxLCJuYW1lIjoiRU4ifSwidW5pdFN5c3RlbSI6eyJpZCI6MSwibmFtZSI6IlVTIiwibGVuZ3RoVW5pdHMiOlt7InR5cGVJZCI6MiwibmFtZSI6IkZvb3QiLCJ1bml0IjoiJyIsIm1heFZhbHVlIjo5OS4wLCJtaW5WYWx1ZSI6MC4wfSx7InR5cGVJZCI6MywibmFtZSI6IkluY2giLCJ1bml0IjoiXCIiLCJtYXhWYWx1ZSI6MTEuMCwibWluVmFsdWUiOjAuMH1dLCJ3ZWlnaHRVbml0cyI6W3sidHlwZUlkIjoxLCJuYW1lIjoiUG91bmQiLCJ1bml0IjoibGIiLCJtYXhWYWx1ZSI6OTkuMCwibWluVmFsdWUiOjAuMH1dfSwiZGF0ZUZvcm1hdCI6eyJpZCI6MiwibmFtZSI6Ik1NRERZWVlZIn19fQ==.j3k5/dBxXsHn+HtTsiy8TSM0ssk21G3LqcjcT0R4DQw=';
      console.log('Token: ' + externalToken);

      if (externalToken !== undefined) {
        const obj = {'grant_type': 'access_token', 'access_token': externalToken};
        service.login(obj).then(function(response) {
          deferred.resolve(response);
        });
      } else {
        deferred.resolve(false);
      }

      return deferred.promise;
    };

    service.login = function(body) {
      const deferred = $q.defer();

      $http.post(baseUrl, body, {
        cache: false,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      })
        .success(function(data) {
          SessionService.clearAll();
          for (let i = intervals.length - 1; i >= 0; i -= 1) {
            $interval.cancel(intervals[i]);
          }
          SessionService.setLoggedInUser(data);

          const isPatient = jQuery.grep(data.roles, function(x) {
            return x === 'patient';
          }).length > 0;
          SessionService.setPermissions(data.permissions, isPatient);
          permissions = SessionService.getUserPermissions();

          const isMonitoringCenterStaff = jQuery.grep(data.roles, function(x) {
            return x === 'monitoringCenterStaff';
          }).length > 0;
          SessionService.setIsMonitoringCenterStaff(isMonitoringCenterStaff);

          SessionService.setAuthToken(data.access_token);
          SessionService.setRefreshToken(data.refresh_token);
          SessionService.setAuthTokenValidTo(data.expires);

          SessionService.setNation(data.nation.nationCode);
          NationService.setConfiguration();

          intervals.push($interval(service.autoLogoutIfInactive, thirtySec));

          deferred.resolve(data);
        })
        .error(function(data, status, headers, config) {
          const totpSecret = headers('TOTPSecret');
          const authenticate = headers('WWW-Authenticate');
          data.totpSecret = totpSecret;
          data.authenticateType = authenticate;
          SessionService.clearAll();
          deferred.resolve(data);
        });

      return deferred.promise;
    };

    service.bankIdLogin = function(type) {
      const urlPart = type == 'other' ? '/#/loginother' : '/#/loginsame';
      if (!window.location.origin) {
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
      const callback = encodeURIComponent(window.location.origin + urlPart);
      const service = type == 'other' ? 'bankIdOtherDevice' : 'bankIdSameDevice';

      const url = root + '/api/v1/account/federatedLogin?callbackUri=' + callback + '&service=' + service;

      intervals.push($interval(service.autoLogoutIfInactive, thirtySec));

      return HttpClientService.postWithoutBodyAndAuthToken(url);
    };

    service.sithsLogin = function() {
      const urlPart = '/#/loginsiths';
      if (!window.location.origin) {
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
      const callback = encodeURIComponent(window.location.origin + urlPart);
      const service = 'SITHS';

      const url = root + '/api/v1/account/federatedLogin?callbackUri=' + callback + '&service=' + service;

      intervals.push($interval(service.autoLogoutIfInactive, thirtySec));

      return HttpClientService.postWithoutBodyAndAuthToken(url);
    };

    service.autoLogoutIfInactive = function() {
      console.log('Checking for inactivity');
      const lastActivity = SessionService.getLastActivity();

      const timeDiff = Math.abs(lastActivity.getTime() - new Date().getTime());
      const diffSecs = timeDiff / (1000);
      const maxInactivitySecs = envConfig.autoLogoutMinutes * 60;

      console.log('Inactive for ' + diffSecs + ' seconds, will autologout in ' + (maxInactivitySecs - diffSecs) + ' seconds');

      if (diffSecs >= maxInactivitySecs) {
        service.logOut();
      } else {
        HttpClientService.authToken();
      }
    };

    service.logOut = function() {
      SessionService.clearAll();
      for (let i = intervals.length - 1; i >= 0; i -= 1) {
        $interval.cancel(intervals[i]);
      }
      console.log('Logged out due to inactivity for ' + envConfig.autoLogoutMinutes + ' minutes.');

      $state.go('login');
    };

    service.authorize = function(area) {
      if (area === 'anon') {
        return true;
      }

      const user = SessionService.getLoggedInUser();
      return hasAccessTo(user, area);
    };

    service.isLoggedIn = function() {
      console.log('isLoggedIn');
      const user = SessionService.getLoggedInUser();
      const isLoggedIn = user !== null && user !== undefined;
      if (isLoggedIn) {
        intervals.push($interval(service.autoLogoutIfInactive, thirtySec));
      }

      return isLoggedIn;
    };

    service.resetPassword = function(email) {
      const obj = {
        email: email
      };

      const url = root + '/api/v1/account/resetpassword';
      return HttpClientService.postWithoutAuthToken(url, obj, 'application/json');
    };

    function hasAccessTo(user, area) {
      if (area === 'admin') {
        return permissions.portaluser;
      }

      return true;
    }

    service.resetToken = function(email) {
      const obj = {email: email};
      return HttpClientService.postWithoutAuthToken(root + '/adminapi/v1/account/resetTotpToken', obj, 'application/json');
    };

    service.setAndRedirectUser = function(response) {
      if (new Date(response.expires) < new Date()) {
        SessionService.clearAll();
        return false;
      }

      const redirectUrl = $location.search().redirectUrl;
      if (redirectUrl != undefined) {
        const redirectPage = decodeURIComponent(redirectUrl);
        console.log('redirectUrl:' + redirectPage);
        window.location.href = redirectPage;
      }

      return service.goToStartPage();
    };

    service.goToStartPage = function() {
      if (permissions.coalaAdmin) {
        $state.go('mainAdmin.dashboard', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.readMedicalData && NationService.isUS()) {
        $state.go('main.patientdashboard', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.patient) {
        $state.go('main.patientsearch', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.staff) {
        $state.go('mainAdmin.adminlist', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.healthcareprovider) {
        $state.go('mainAdmin.healthcareproviderlist', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.group) {
        $state.go('mainAdmin.grouplist', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.readTimeReports && permissions.representingProvider) {
        $state.go('main.timereports', null, {
          reload: true, inherit: false, notify: true
        });
        return false;
      }

      if (permissions.isPatient) {
        $state.go('me.myprofile');
        return false;
      }

      $state.go('unauthorized');
      return false;
    };

    service.test = function() {
      // var filterString = '&filter[0].Key=fromdate&filter[0].Value=' + moment().format('YYYY-MM-DD');
      const result = HttpClientService.get(root + '/api/v1/measurements?page=0&pageSize=9999&filter[0].Key=fromdate&filter[0].Value=2018-10-09%2015%3A29%3A31');
      // return result;
      // return HttpClientService.get(root + '/api/v1/userprofile/behalfofusers?page=0&pageSize=10');
      // return HttpClientService.post(root + '/adminapi/v1/measurements/extractmetadata');
      // return HttpClientService.post(root + '/api/v1/notification/test');
    };


    return service;
  }

  angular
    .module('patients')
    .factory('AuthService', AuthService);

})();
