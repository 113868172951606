import * as angular from 'angular';

(function() {
  'use strict';

  function AzureService($q, $http, envConfig, SessionService, HttpClientService) {
    const service = {};
    const azureSubscriptionId = envConfig.azureSubscriptionId;

    service.listCloudServices = function(id) {
      return HttpClientService.get('https://management.core.windows.net/' + azureSubscriptionId + '/services/hostedservices');
    };

    return service;
  }

  angular
    .module('core')
    .factory('AzureService', AzureService);

})();
