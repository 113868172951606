import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  function UtilService($q, $http, envConfig, SessionService, HttpClientService) {
    const service = {};
    const root = envConfig.baseUrl;

    service.preparePagingParameters = function(tableState, filters) {
      const pagination = tableState.pagination;

      const start = pagination.start || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
      const number = pagination.number || 10; // Number of entries showed per page.

      const page = start / number;

      let search = '';
      let filterString = '';

      if (tableState.search && tableState.search.predicateObject && tableState.search.predicateObject.$) {
        search = tableState.search.predicateObject.$;
      }

      if (tableState.search && tableState.search.predicateObject && tableState.search.predicateObject.name && tableState.search.predicateObject.name.distinct !== '') {
        filterString = tableState.search.predicateObject.name.distinct;
      }

      let sort = '';

      if (tableState.sort && tableState.sort.predicate) {
        sort = tableState.sort.predicate;
        if (tableState.sort.reverse) {
          sort += '+DESC';
        } else {
          sort += '+ASC';
        }
      }

      if (filters) {
        let i;
        const activeFilter = [];
        for (i = 0; i < filters.length; i += 1) {
          if (filters[i].value) {
            activeFilter.push(filters[i]);
          }
        }

        for (i = 0; i < activeFilter.length; i += 1) {
          if (i > 0) {
            filterString += '&';
          }
          filterString += 'filter[' + i + '].Key=' + activeFilter[i].propertyKey;
          filterString += '&filter[' + i + '].Value=' + activeFilter[i].propertyValue;
        }
      }

      return {
        page: page,
        pageSize: number,
        sort: sort,
        search: search,
        filter: filterString
      };
    };

    service.getParameterByName = function(name, url) {
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    service.getLocalTime = function(utctime) {
      const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
      const localTime = moment.utc(date).toDate();
      return localTime;
    };

    return service;
  }

  angular
    .module('core')
    .factory('UtilService', UtilService);

})();
