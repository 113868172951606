import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('UserOrderCoalaController', UserOrderCoalaController);

  function UserOrderCoalaController($rootScope, $translate, PatientService, NationService, close) {
    const vm = this;
    vm.userDetails = $rootScope.userDetails;
    vm.freeMonthsOptions = NationService.getOrderCoalaSubscriptionOptions();
    vm.freeMonths = vm.freeMonthsOptions[0];
    vm.addSubscriptionMonths = 0;
    vm.sending = false;
    vm.shipMonitor = true;

    vm.sendOrder = function() {
      if (!vm.sending) {
        vm.sending = true;
        const userId = vm.userDetails.userId;

        const orderinfo = {
          address: vm.address,
          city: vm.city,
          zipcode: vm.zipcode,
          phone: vm.phone,
          addSubscriptionMonths: vm.addSubscriptionMonths
        };

        PatientService.sendUserCoalaOrder(userId, orderinfo).then(function(response) {
          vm.showFeedback($translate.instant('order_coalaconnect_sent'));
          console.log('Order sent to : ' + userId);
          closeModal();
        }, function(error) {
          vm.sending = false;
          vm.showError($translate.instant('order_coalaconnect_error'));
          console.log('Failed to send order to user: ' + vm.userDetails + ' Error: ' + error);
        });
      }
    };

    vm.setShippment = function(ship) {
      console.log(ship);
      vm.shipMonitor = ship;
      vm.noMonitor = !ship;
    };

    vm.isUS = function() {
      return NationService.isUS();
    };

    vm.setToDate = function() {
      vm.addSubscriptionMonths = jQuery.grep(vm.freeMonthsOptions, function(x) {
        return x.value === vm.freeMonths.value;
      })[0].value;
    };

    vm.getTermsUrl = function() {
      return NationService.getOrderCoalaTermsUrl();
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal() {
      close('', 500);
      $('#usermodal').modal('hide');
    }
  }
})();
