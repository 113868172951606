import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminAnalysisResultController', AdminAnalysisResultController);

  function AdminAnalysisResultController($state, $rootScope, $scope, $filter, $translate, PatientService, NationService, UtilService) {
    const vm = this;
    vm.formats = NationService.getDateFormats();
    let _tableState = null;

    vm.itemsPerPage = 20;
    vm.totalNotAnalysedMeasurements = 0;
    vm.nrOfPages = 0;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    // updateMeasurementsTable();

    vm.fetchMeasurements = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = 20;

      updateMeasurementsTable();
    };

    function updateMeasurementsTable() {
      PatientService.getNotAnalysedMeasurements('none', pagingParameters).then(function(data) {
        vm.notAnalysedMeasurements = data.rows;
        vm.totalNotAnalysedMeasurements = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }
      });
    }

    vm.reanalyse = function(item) {
      PatientService.reanalyseMeasurementPart(item.measurementId, item.measurementPartType, item.measurementPartConfigurationId).then(function(result) {
        vm.showFeedback($translate.instant('analysis_queued') + '<br />(id: ' + item.measurementId + ')');
        updateMeasurementsTable();
      });
    };

    vm.fail = function(item) {
      PatientService.failMeasurementPart(item.measurementId, item.measurementPartType, item.measurementPartConfigurationId).then(function(result) {
        vm.showFeedback($translate.instant('analysis_failed') + ' (id: ' + item.measurementId + ')');
        updateMeasurementsTable();
      });
    };

    vm.getUserForMeasurement = function(item) {
      return item.measurementId;
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
