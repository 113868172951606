import * as angular from 'angular';

(function() {
  'use strict';

  function MeasurementHelperService($translate) {
    const service = {};

    service.signalStatusOfPart = function(part) {
      const errorCode = part.analysisErrorCode;
      const category = part.analysisCategory;

      if (category === null) {
        return 'fa fa-heart disabledFont';
      }
      if (errorCode === 'noError' && category === 'noRhythmDeviation') {
        return 'fa fa-heartbeat coalaGreen';
      }
      if (errorCode !== 'noError' || category === 'poorQuality') {
        return 'fa fa-heart disabledFont';
      }
      return 'fa fa-heartbeat error';
    };

    service.parseErrorCodeOfPart = function(part) {
      if (part !== null && part !== undefined) {
        const errorCode = part.analysisErrorCode;
        const category = part.analysisCategory;

        const partIsNotYetUploaded = measurementPartIsNotYetUploaded(part);


        if (partIsNotYetUploaded) {
          return $translate.instant('measurement_missing_data');
        }
        if (category === null) {
          return $translate.instant('measurement_notanalyzed');
        }
        if (errorCode === 'noError' && category === 'noRhythmDeviation') {
          return $translate.instant('measurement_ok');
        }
        if (errorCode !== 'noError' || category === 'poorQuality') {
          return $translate.instant('measurement_error');
        }
        return (
          $translate.instant('measurement_category') +
          ' ' +
          getCategoryId(category)
        );
      }

      return '';
    };

    service.appStatus = function(measurement) {
      const errorCode = service.parseAppErrorCode(measurement);
      if (errorCode === $translate.instant('measurement_ok')) {
        return 'fa fa-heartbeat coalaGreen';
      }
      if (errorCode === $translate.instant('measurement_error')) {
        return 'fa fa-heart disabledFont';
      }
      if (errorCode === $translate.instant('measurement_stethoscope')) {
        return 'fa fa-stethoscope coalaBlue';
      }

      return 'fa fa-heartbeat error';
    };

    service.parsePulse = function(measurement) {
      const fingerParts = jQuery.grep(measurement.measurementParts, function(x) {
        return x.measurementPartType === 'ecgFinger';
      });
      const chestParts = jQuery.grep(measurement.measurementParts, function(x) {
        return x.measurementPartType === 'ecgChest';
      });
      const fingerPart = fingerParts[0];
      const chestPart = chestParts[0];
      // If finger part exists it needs it can't be poor quality
      if (fingerPart !== undefined) {
        const fingerCategory = getCategoryId(fingerPart);
        if (fingerCategory === 0) {
          return '-';
        }
      }

      if (fingerPart !== undefined && chestPart !== undefined) {
        if (fingerParts.length == 1 && fingerPart.pulse > 0 && chestParts.length == 1 && chestPart.pulse > 0) {
          const fingerPulse = !fingerPart.pulse ? '-' : Math.round(fingerPart.pulse);
          const chestPulse = !chestPart.pulse ? '-' : Math.round(chestPart.pulse);
          return chestPulse + '/' + fingerPulse;
        }
      }

      const pulse = !measurement.pulse ? '-' : Math.round(measurement.pulse);
      return pulse;
    };

    service.parseErrorCode = function(measurementParts, type) {
      const part = jQuery.grep(measurementParts, function(x) {
        return x.measurementPartType === 'ecg' + type;
      });

      if (part !== null && part !== undefined && part.length > 0) {
        return service.parseErrorCodeOfPart(part[0]);
      }

      return '';
    };


    service.parseAppErrorCode = function(measurement) {
      const category = measurement.analysisCategory;
      const errorCode = measurement.analysisErrorCode;

      const onePartOrMoreIsNotYetUploaded = oneOrMoreEcgPartsAreNotYetUploaded(measurement);

      if (onePartOrMoreIsNotYetUploaded) {
        return $translate.instant('measurement_missing_data');
      } else if (category === null) {
        return $translate.instant('measurement_notanalyzed');
      } else if (errorCode === 'noError' && category === 'noRhythmDeviation') {
        return $translate.instant('measurement_ok');
      } else if (errorCode !== 'noError' || category === 'poorQuality') {
        return $translate.instant('measurement_error');
      } else if (category === 'stethoscope') {
        return $translate.instant('measurement_stethoscope');
      }

      return (
        $translate.instant('measurement_category') +
        ' ' +
        getCategoryId(category)
      );
    };

    service.feelingBeforeMeasurementString = function(measurement) {
      if (measurement.feelingBeforeMeasurement === 'unknown') {
        return $translate.instant('measurement_feeling_unknown');
      } else if (measurement.feelingBeforeMeasurement === 'good') {
        return $translate.instant('measurement_feeling_good');
      } else if (measurement.feelingBeforeMeasurement === 'stressed') {
        return $translate.instant('measurement_feeling_stressed');
      } else if (measurement.feelingBeforeMeasurement === 'anxious') {
        return $translate.instant('measurement_feeling_anxious');
      } else if (measurement.feelingBeforeMeasurement === 'chestpain') {
        return $translate.instant('measurement_feeling_chestpain');
      } else if (measurement.feelingBeforeMeasurement === 'rapidHeartRate') {
        return $translate.instant('measurement_feeling_rapidHeartRate');
      } else if (measurement.feelingBeforeMeasurement === 'dizziness') {
        return $translate.instant('measurement_feeling_dizziness');
      } else if (measurement.feelingBeforeMeasurement === 'other') {
        return measurement.feelingBeforeMeasurementFreetext;
      } else {
        return measurement.feelingBeforeMeasurement;
      }
    };

    function oneOrMoreEcgPartsAreNotYetUploaded(measurement) {
      if (measurement.analysisCategory == null) {
        const oneEcgPartOrMoreIsNotYetCaptured = jQuery.grep(measurement.measurementParts, function(x) {
          return x.measurementPartType.includes('ecg') && x.uploaded === null;
        });
        return oneEcgPartOrMoreIsNotYetCaptured.length > 0;
      } else {
        return false;
      }
    }

    function measurementPartIsNotYetUploaded(measurementPart) {
      const partIsNotYetUploaded = measurementPart.uploaded === null;
      return partIsNotYetUploaded;
    }

    function getCategoryId(category) {
      const categories = [
        'poorQuality',
        'noRhythmDeviation',
        'irregularSequence',
        'pauseAVblockII',
        'fastRegular',
        'fastRegularAndWideQRS',
        'fastSlowSequences',
        'bigemini',
        'trigemini',
        'wideQR',
        'over5SVES',
        'over5VES',
        'irregularWithPWaves'
      ];
      if (category == 'unclassifiedHeartRhythm') {
        return 99;
      }
      return categories.indexOf(category);
    }

    return service;
  }

  angular
    .module('core')
    .factory('MeasurementHelperService', MeasurementHelperService);

})();
