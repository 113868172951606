import * as angular from 'angular';
import * as jQuery from 'jquery';

// @ts-ignore
angular.module('envConfig', []).constant('envConfig', window.ENV_CONFIG);

const app = angular.module('coalaApp', [
    'ui.router',
    'ui.router.state.events',
    'ngAnimate',
    'ngResource',
    'angularModalService',
    'ngSanitize',
    'ngTouch',
    'patients',
    'admin',
    'core',
    'login',
    'envConfig',
    'multipleSelect',
    'smart-table',
    'ae-datetimepicker',
]);

app.config(function() {
    jQuery.support.cors = true;
});

app.config(['stConfig', function(stConfig: any) {
    stConfig.pagination.template = 'paging.tpl.html';
}]);

app.constant('APP_DATESHORTFORMAT', 'shortDate');

app.run(['$rootScope', '$state', '$location', 'AuthService', function($rootScope: any, $state: any, $location: any, AuthService: any) {
    $rootScope.$on('$stateChangeStart', function(event: any, toState: any) {
        const accessToken = $location.search().access_token;
        if (toState.authenticate && !AuthService.isLoggedIn() || accessToken) {
            AuthService.externalLogin().then(function(result: any) {
                console.log('External authentication result: ' + result);
                if (result.message !== undefined || result == false) {
                    console.log('redirecturl to store: ' + location.href);
                    // $state.go("login");
                    $state.go('login', {
                        redirectUrl: encodeURIComponent(location.href)
                    });
                    event.preventDefault();
                } else {
                    console.log('External authentication OK');
                    event.preventDefault();
                    $state.reload();
                }
            });
        }

        if (toState.data != null) {
            if (!AuthService.authorize(toState.data.access)) {
                event.preventDefault();
                $state.go('unauthorized');
            }
        }
    });
}]);
