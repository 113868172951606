import * as angular from 'angular';

(function() {
  'use strict';

  angular.module('core')
    .filter('trusted', ['$sce', function($sce) {
      return function(url) {
        return $sce.trustAsResourceUrl(url);
      };
    }]);
})();
