import * as angular from 'angular';

(function() {
  'use strict';
  angular.module('core')
    .directive('stSelectDistinct', [function() {
      return {
        restrict: 'E',
        require: '^stTable',
        scope: {
          collection: '=',
          predicate: '@',
          predicateExpression: '=',
          placeholder: '@',
        },
        // template: '<select ng-model="selectedOption" ng-change="optionChanged(selectedOption)" ng-options="opt for opt in distinctItems"></select>',
        template: '<select style="width: 400px;" ng-model="selectedOption" name="fav_show" id="fav_show" ng-change="optionChanged(selectedOption)">' +
          '<option ng-repeat="opt in distinctItems" value="{{opt.id}}">{{opt.value}}</option>' +
          '</select>',
        link: function(scope, element, attr, table) {
          const getPredicate = function() {
            let predicate = scope.predicate;
            if (!predicate && scope.predicateExpression) {
              predicate = scope.predicateExpression;
            }
            return predicate;
          };

          scope.$watch('collection', function(newValue) {
            const predicate = getPredicate();

            if (newValue) {
              const temp = [];
              scope.distinctItems = [];

              angular.forEach(scope.collection, function(item) {
                const value = item[predicate];

                if (value && value.trim().length > 0 && temp.indexOf(value) === -1) {
                  const id = item.id;
                  const obj = {'value': value, 'id': id};
                  temp.push(obj);
                }
              });

              scope.distinctItems = scope.distinctItems.concat(temp);
              scope.distinctItems.unshift({'value': scope.placeholder, 'id': ''});
              scope.selectedOption = scope.distinctItems[0].id;
              scope.optionChanged(scope.selectedOption);
            }
          }, true);

          scope.optionChanged = function(selectedOption) {
            const predicate = getPredicate();

            const query = {};

            query.distinct = selectedOption;

            if (query.distinct === scope.placeholder) {
              query.distinct = '';
            }

            table.search(query, predicate);
          };
        }
      };
    }]);
})();
