import * as angular from 'angular';

(function() {
  'use strict';

  angular.module('core')
    .filter('timezone', [function() {
      return function(date) {
        date = new Date(date);
        return date;
      };
    }]);
})();
