var plot = null;
(function() {
  'use strict';

  var comment, format, plot_data, show_meta;

  plot_data = function(res, type, flags, $translate) {
    var draw, limits, slice, slide;
    var beats_pos = res['beats']['pos'];
    var beats_type = res['beats']['type']
    var beats_rr = res['beats']['info'][0];
    var beats_cls = res['beats']['info'][1];
    var markers = res['beats']['markers'];
    var sequences = res['sequences'];
    var segment = res['segments'][0];

    limits = function(data) {
      var i, k, l, u;
      l = u = data[0];
      for (i = 1; i < data.length; i++) {
        if (data[i] < l) {
          l = data[i];
        }
        if (data[i] > u) {
          u = data[i];
        }
      }
      return [l, u];
    };

    slice = function(data, count, yoffset, xoffset) {
      if (yoffset == null) {
        yoffset = 0;
      }
      if (xoffset == null) {
        xoffset = 0;
      }
      var results = [];
      for (var i = 0; i < count; i++) {
        results.push([i + xoffset, data[i + yoffset]]);
      }
      return results;
    };

    slide = function(data, width, offset) {
      if (offset == null) {
        offset = 0;
      }
      return slice(data, Math.min(data.length - offset, width), offset);
    };

    draw = function(dest, data, flags, $translate, x0, width, offset) {
      var i, j, text, y, y1;
      if (flags == null) {
        flags = true;
      }
      if (x0 == null) {
        x0 = 0;
      }
      if (width == null) {
        width = -1;
      }
      if (offset == null) {
        offset = 0;
      }
      if (width === -1) {
        width = data.length;
      }

      var data_limits = limits(data);
      var l = Math.min(-0.5, data_limits[0]);
      var u = Math.max(0.5, data_limits[1]);
      var delta = (data_limits[1] - l) * 12; // 0.6;
      var u = u + delta;
      var hbars = [];
      var annotations = [];
      var c = beats_pos.length;
      var refRR = segment['info'][17];

      var refpeaks = (function() {
        var i, j;
        var results = [];
        var lead = 0  // the first lead (there is only one lead)
        var avbi = segment['avBeatInfo'];
        
        for (j = 0; j < beats_cls.length; j++) {

          var bc = beats_cls[j];

          // find index bcidx of beat class
          var bcidx = segment['beatClasses'].indexOf(bc);
          if (bcidx == -1) {
            console.log('beat class mismatch:' + bc + ', not in ' + beats_cls);
            continue;
          }

          // find the peak position from AvBeatInfo for the beat_class and lead
          var QRSmax = avbi[bcidx][lead][16];
          var QRSmaxpos = avbi[bcidx][lead][17];
          var QRSmin = avbi[bcidx][lead][18];
          var QRSminpos = avbi[bcidx][lead][19];
          if (Math.abs(QRSmax) > Math.abs(QRSmin)) {
            results.push(QRSmaxpos + beats_pos[j]);
          }
          else
          {
            results.push(QRSminpos + beats_pos[j]);
          }
        }
        return results;
      })();

      // mark beat classes, duration and relative length
      y = 10;
      y1 = 20;
      //y = u - delta * 0.73
      //y1 = u - delta * 0.85
      var x;
      for (j = 0; j < beats_pos.length; j++) {
        annotations.push({
          x: beats_pos[j],
          y: y,
          text: beats_cls[j],
          dX: 25000
        });
        if (j > 0) {
          x = beats_pos[j] - (beats_pos[j] - beats_pos[j - 1]) / 1.7;
          annotations.push({
            x: x,
            y: y,
            text: beats_rr[j] + 'ms'
          });
          annotations.push({
            x: x,
            y: y1,
            text: Math.round((beats_rr[j] / refRR) * 100) + '%'
          });
        }
      }

      // mark beats included in rr evaluation
      for (j = 0; j < beats_pos.length; j++) {
        if (markers[4][j]) {
          annotations.push({
            x: beats_pos[j],
            y: y1,
            text: '+',
            dX: 35000
          });
        }
      }

      y1=30;
      // mark peaks
      for (j = 0; j < refpeaks.length; j++) {
        annotations.push({
          x: refpeaks[j],
          y: y1,
          text: '|',
          dX: 35000
        });
      }

      if (flags) {
        // mark very long beats
        y=32;
        //y = u - delta * 0.54;
        for (j = 0; j < beats_pos.length-1; j++) {
          if (markers[3][j+1]) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 1]],
              y: [y, y],
              color: 'black',
              'background-color': 'rgb(127, 255, 127)',
              text: 'VERY LONG'
            });
          }
        }

        // mark AVBII/missed beats
        y = 44;
        // y = u - delta * 0.36
        for (j = 0; j < beats_pos.length-1; j++) {
          if (beats_type[j+1] == 3) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 1]],
              y: [y, y],
              color: 'black',
              'background-color': 'rgb(255, 255, 127)',
              text: 'AVBII/MISSED'
            });
          }
        }

        // mark SVES
        for (j = 0; j < beats_pos.length-1; j++) {
          if (beats_type[j+1] == 1) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 2]],
              y: [y, y],
              color: 'white',
              'background-color': 'rgb(127, 127, 255)',
              text: $translate.instant('measurement_viewer_sves'),
              tX: 35000
            });
          }
        }

        // mark VES
        for (j = 0; j < beats_pos.length-1; j++) {
          if (beats_type[j+1] == 2) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 2]],
              y: [y, y],
              color: 'black',
              'background-color': 'rgb(255, 178, 255)',
              text: $translate.instant('measurement_viewer_ves'),
              tX: 35000
            });
          }
        }

        // mark FAST/SLOW/BIGEMINI/TRIGEMINI sequences
        y = 56;
        //y = u - delta * 0.18
        for (j = 0; j < sequences.length; j++) {
          var seq = sequences[j];
          var col = '';
          if (seq['type'] == 1) {
            col = '255, 127, 127';
            text = 'FASTER';
          }
          else if (seq['type'] == 2) {
            col = '255, 127, 127';
            text = 'SLOWER';
          }
          else if (seq['type'] == 3) {
            col = '255, 77, 77';
            text = 'BIGE';
          }
          else if (seq['type'] == 4) {
            col = '255, 26, 26';
            text = 'TRIGE';
          }

          if (col) {
            hbars.push({
              x: [seq['pos'], seq['pos'] + seq['duration']],
              y: [y, y],
              color: 'white',
              'background-color': "rgb(" + col + ")",
              text: text,
              tX: 35000
            });
          }
        }

        // mark beats that are clearly shorter than median
        y = 68;
        //y = u;
        for (j = 0; j < beats_pos.length-1; j++) {
          if (markers[1][j+1]) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 1]],
              y: [y, y],
              color: 'black',
              'background-color': 'rgb(127, 255, 255)',
              text: 'S',
              tX: 35000
            });
          }
        }

        // mark beats that are clearly longer than median
        for (j = 0; j < beats_pos.length-1; j++) {
          if (markers[2][j+1]) {
            hbars.push({
              x: [beats_pos[j], beats_pos[j + 1]],
              y: [y, y],
              color: 'black',
              'background-color': 'rgb(255, 102, 255)',
              text: 'L',
              tX: 35000
            });
          }
        }
      }

      plot = $.plot(dest, [slide(data, width, offset)], {
        series: {
          shadowSize: 0,
          color: 'black',
          lines: {
            lineWidth: 1
          }
        },
        grid: {
          show: true,
          color: '#aaa',
          borderWidth: {
            top: 0,
            right: 0,
            bottom: 1,
            left: 0
          },
        },
        yaxis: {
          min: l,
          max: u,
          show: true,
          zoomRange: false,
          panRange: false,
          tickSize: 1,
        },
        xaxis: {
          show: true,
          min: 0,
          max: width,
          tickFormatter: function(n) {
          if(n % 1000 == 0)
          {
            return (x0 + n) / 1000;
          }
          return "";
          },
          zoomRange: [5000, width],
          panRange: [0, width],

        },
        zoom: {
          interactive: true
        },
        pan: {
          interactive: true
        },
        hbar: {
          tX: 10000
        },
        hbars: hbars,
        annotation: {
          dX: 15000
        },
        annotations: annotations,
        touch: {
          pan: 'x',
          scale: ''
        }
      });

      return plot;
    };

    var sel = '#ecg-plot-' + type;
    $(sel).addClass('plot');
    var chart = draw(sel, res['ecg'][0][0], flags, $translate);
    $(sel).bind("plotzoom", zoomed);

    return chart;
  };

  format = function(str) {
    var args;
    args = Array.prototype.slice.call(arguments, 1);
    return str.replace(/{(\d+)}/g, function(match, number) {
      if (typeof args[number] !== 'undefined') {
        return args[number];
      } else {
        return match;
      }
    });
  };

  comment = function(res, $translate) {
    // Not supported with ECG Parser v2+
    return 'Analys: Not supported with ECG Parser v2+';
  };

  show_meta = function(res, type, $translate) {
    var s = [];
    var stim_pos = res['stims']['pos'];
    var beats_type = res['beats']['type']
    var segment = res['segments'][0];
    var seg_info = segment['info'];
    var cat = seg_info[0];
    var qrs_dur = (function() {
      // dominant beat class
      var dbc = segment['info'][5];
        
      // find index bcidx of dominant beat class
      var bcidx = segment['beatClasses'].indexOf(dbc);
      if (bcidx == -1) {
        console.log('beat class mismatch:' + bc + ', not in ' + beats_cls);
        return NaN;
      }

      // return QRS duration for most dominant beat class
      return segment['avBeatInfo'][bcidx][0][7];
    })();

    var csum = function(a, c) {
      var v = 0;
      for(var k = 0; k < a.length; k++) {
        if (a[k] == c) {
          v = v + 1;
        }
      }
      return v;
    };

    $('#sampleRate-' + type).html(res.sampleRate);
    $('#signalLength-' + type).html(calculateSignalLength(res));
    $('#version-' + type).html(parseSoftwareVersionText(res.analysisSoftware));

    var com = 'Error parsing comment';
    try {
      com = comment(res, $translate);
    } catch (err) {
      console.log(err);
    }
    //$('#comment-' + type).html(com);

    s.push('<table class="table table-striped-ecg">');
    s.push('<tr><td>' + com + '</td></tr>');
    if (cat > 0) {
      s.push('<tr><td> ' + $translate.instant('measurement_viewer_category') + ' '  + cat + ': ' + $translate.instant('measurement_viewer_categories_' + cat)+ '</td></tr>');


      if (qrs_dur > 0) {
        s.push('<tr><td>QRS duration: ' + qrs_dur + ' ms</td></tr>');
      }
      var count = csum(beats_type, 2);
      if (count > 0) {
        s.push('<tr><td>' + $translate.instant('measurement_viewer_vescount') + ': ' + count + '</td></tr>');
      }
      count = csum(beats_type, 1);
      if (count > 0) {
        s.push('<tr><td>' + $translate.instant('measurement_viewer_svescount') + ': ' + count + '</td></tr>');
      }
      if (stim_pos.length > 0) {
        s.push('<tr><td>' + $translate.instant('measurement_viewer_stimuliescount') + ': ' + stim_pos.length + '</td></tr>');
      }
    }

    s.push("</table>");

    var centerTable = [];
    centerTable.push('<table class="table table-striped-ecg">');
    centerTable.push('<tr><td>' + $translate.instant('measurement_viewer_rrmedian') + ': ' + (cat > 0 ? (seg_info[13].toFixed(1) + ' ms') : '-') + '</td></tr>');
    centerTable.push('<tr><td>' + $translate.instant('measurement_viewer_rrstddev') + ': ' + (cat > 0 ? (seg_info[14].toFixed(1)) : '-') + '</td></tr>');
    centerTable.push('<tr><td>' + $translate.instant('measurement_viewer_heartrate') + ': ' + (cat > 0 ? (seg_info[16].toFixed(1) + ' ' + $translate.instant('measurement_viewer_beatsperminute')) : '-') + '</td></tr>');
    if (res['indication'] !== null) {
      centerTable.push('<tr><td>' + $translate.instant('measurement_viewer_p_wave_indicator_' + seg_info[1]) + ' ' + $translate.instant('measurement_viewer_pwaves') + '</td></tr>');
    }

    centerTable.push("</table>");

    if(res.sampleRate != undefined){
        $('.analysisParameters-' + type).show();
    }

    $('#heart-rate-' + type).html($translate.instant('measurement_viewer_hr') + ': ' + (cat > 0 ? (seg_info[16].toFixed(0) + ' ' + $translate.instant('measurement_viewer_beatsperminute')) : '-'));
    $('#ecg-info-center-' + type).html(centerTable.join('\n'));
    $('#ecg-info-' + type).html(s.join('\n'));
  };

  module.exports = {
    show_ecg: function(input, type, $translate, flags) {
      show_meta(input, type, $translate);
      return plot_data(input, type, flags, $translate); 
    }
  };

}).call(this);


function parseSoftwareVersionText(text){
    var sections = text.split(',');

    return sections[1];
}

function calculateSignalLength(data){
    return data.rawData.length / data.sampleRate;
}

function createMarkings(xMax){
  var result = [];

    var position = 0;
    for(var i = 0; i <= xMax; i +=40){

      if(i%200 == 0){
        result.push({ color: "#C3C3C3", lineWidth: 2, xaxis: { from: i, to: i} });
      }

      result.push({ color: "#C3C3C3", lineWidth: 1, xaxis: { from: i, to: i} });
    }

    return result;
}

function zoomed(){
  var axes = plot.getAxes();
  var x = axes.xaxis;

  var options = plot.getOptions();
  if(x.delta < 1000){
    options.grid.markings = createMarkings(options.xaxis.max);
    plot.draw();
  } else if(options.grid.markings != null && options.grid.markings.length > 0) {
    options.grid.markings = [];
    plot.draw();
  }
}
