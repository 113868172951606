import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientTimeReportController', PatientTimeReportController);

  function PatientTimeReportController($translate, PatientService, SessionService, UtilService, NationService) {
    const vm = this;
    vm.formats = NationService.getDateFormats();
    let _tableState = null;
    let expandUserId = null;
    vm.itemsPerPage = 20;
    vm.nrOfPages = 0;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      $('#datetimepickerperiod').datetimepicker({
        defaultDate: new Date(),
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: 'MMM -YY'
      });
      $('#datetimepickerperiod').on('dp.hide', function(e) {
        vm.perioddate = e.date;
        updateTimeReportsTable();
      });
      vm.perioddate = moment(moment().format('YYYY-MM-01'));
    }

    vm.fetchTimeReports = function(tableState) {
      updateTimeReportsTable(tableState);
    };

    function updateTimeReportsTable(tableState) {
      vm.showSpinner = true;
      const providerId = SessionService.getReprestingProviderId();

      if (tableState) {
        _tableState = tableState;
      }

      vm.filters = getSelectedPeriodDateFilter();
      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = 20;
      pagingParameters.search = vm.input;

      PatientService.getTimeReports(providerId, pagingParameters).then(function(data) {
        vm.showSpinner = false;
        vm.timereports = data.rows;
        vm.totalTimeReports = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }
      });
    }

    function getSelectedPeriodDateFilter() {
      const filters = [];
      let formatedDate = moment();
      if (vm.perioddate != undefined) {
        formatedDate = moment(vm.perioddate, vm.formats.shortDatePicker);
      }
      const from = formatedDate.toDate().toISOString();

      const todate = formatedDate.add(1, 'M');
      const to = todate.toDate().toISOString();

      console.log('from: ' + from);
      console.log('to: ' + to);

      filters.push({value: 'true', propertyKey: 'fromdate', propertyValue: from});
      filters.push({value: 'true', propertyKey: 'todate', propertyValue: to});

      return filters;
    }

    vm.GetMinutes = function(time) {
      return time;
      // return moment.duration(time).asMinutes() + ' ' + $translate.instant('timereport_min');
    };

    vm.expandUser = function(id) {
      if (expandUserId == id) {
        expandUserId = null;
      } else {
        expandUserId = id;
      }
    };

    vm.isExpanded = function(id) {
      return expandUserId == id;
    };

    vm.listOption = function(id) {
      if (expandUserId == id) {
        return 'fa fa-angle-down';
      } else {
        return 'fa fa-angle-right';
      }
    };

    function toUTC(/* Date*/ date) {
      return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      );
    }

    vm.daysSinceNow = function(d1) {
      let ndays;
      const endDate = new Date();
      const tv1 = toUTC(new Date(d1));
      const tv2 = endDate.getTime();
      ndays = (tv2 - tv1) / 1000 / 86400;
      ndays = Math.round(ndays);
      return ndays;
    };
  }
})();
