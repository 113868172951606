import * as angular from 'angular';
import {NationService} from '../../../../services/NationService';
import {PatientEventMonitoringServicesHandler} from '../../../../models/MonitoringServices';
import { User } from 'app/models/User';

export interface MonitoringFormSettings {
    enabled: boolean;
    user: User;
    monitoringServices: PatientEventMonitoringServicesHandler;
    prescribingProfessional?: {id: string; firstname: string; lastname: string };
    prescribingProfessionals: {id: string; firstname: string; lastname: string }[];
}

// language=HTML
export const template = `
    <form name="$ctrl.monitoringForm">
        <!-- PRESCRIBER-->
        <div class="row" ng-show="$ctrl.showPrescribingProfessional">
            <div class="col-md-12 title">
                {{ "user_create_assign_prescribing_professional" | translate }}
            </div>
            <div class="col-md-12" ng-show="!$ctrl.isRepresentingProvider">
                <h4>{{ "user_create_assign_prescribing_professional_disabled" | translate }}</h4>
            </div>
            <div class="col-md-12" ng-show="$ctrl.isRepresentingProvider">
                <label>
                    {{ "user_create_assign_prescribing_professional" | translate }} {{$ctrl.showPrescribingProfessional ? '*' : ''}}
                </label>
                <fieldset class="form-inline">
                    <div >
                    <select class="input-text-lg input-medium blockInput form-control" id="fav_show5" name="fav_show"
                            ng-class="{'input-invalid': $ctrl.invalidPrescribingProfessional()}"
                            ng-disabled="!$ctrl.isRepresentingProvider"
                            ng-model="$ctrl.settings.prescribingProfessional"
                            ng-options="$ctrl.getStaffFullName(user) for user in $ctrl.settings.prescribingProfessionals track by user.id"
                            ng-required="$ctrl.showPrescribingProfessional && $ctrl.isRepresentingProvider">
                        <option value="">{{ "user_create_choose_assign_prescribing_professional" | translate }}</option>
                    </select>
                    </div>
                </fieldset>
            </div>
        </div>

        <!-- MONITORING SERVICE-->
        <div class="row" ng-show="$ctrl.showMonitoringCenter">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{ "monitoring_service" | translate }}
                </div>
                <div class="col-md-12" ng-show="!$ctrl.isRepresentingProvider">
                    <h4>{{ "monitoring_service_patient_disabled" | translate }}</h4>
                </div>
                <div class="col-md-12" ng-show="$ctrl.settings.monitoringServices.getServices().length == 0">
                    <h4>{{ "monitoring_service_patient_not_available" | translate }}</h4>
                </div>

                <div ng-show="$ctrl.settings.monitoringServices.getServices().length > 0">
                    <div class="col-md-12">
                        <h4>{{ "monitoring_service_which_service_single_prompt" | translate }}</h4>
                    </div>

                    <div class="col-md-12">
                        <label ng-show="$ctrl.settings.monitoringServices.eventMonitoring">
                            <input ng-change="$ctrl.settings.monitoringServices.eventMonitoringEnabled()" ng-model="$ctrl.settings.monitoringServices.eventMonitoring.enabled" ng-value="true" type="radio">
                            <span>{{$ctrl.settings.monitoringServices.eventMonitoring.getName()}}</span>
                        </label>
                        <label ng-show="$ctrl.settings.monitoringServices.rpm">
                            <input ng-change="$ctrl.settings.monitoringServices.rpmEnabled()" ng-model="$ctrl.settings.monitoringServices.rpm.enabled" ng-value="true" type="radio">
                            <span>{{$ctrl.settings.monitoringServices.rpm.getName()}}</span>
                        </label>
                        <label >
                            <input ng-change="$ctrl.settings.monitoringServices.disabled()" ng-model="$ctrl.settings.monitoringServices.noMonitoringService" ng-value="true" type="radio">
                            <span>{{ "monitoring_service_nomonitoring" | translate }}</span>
                        </label>
                    </div>

                    <!--EVENT MONITORING durations-->
                    <div ng-show="$ctrl.settings.monitoringServices.eventMonitoring.enabled">
                        <div class="col-md-12">
                            <h4>{{"monitoring_service_event_monitoring_patient_time" | translate}} {{$ctrl.settings.monitoringServices.eventMonitoring.getName()}}?</h4>
                        </div>

                        <div class="col-md-12">
                            <label ng-repeat="option in $ctrl.settings.monitoringServices.eventMonitoring.durations">
                                <input ng-change="$ctrl.settings.monitoringServices.monitoringDurationChanged($ctrl.settings.monitoringServices.eventMonitoring.id, option.id)" ng-model="option.enabled" ng-value="true" type="radio">
                                <span>{{option.title}}</span>
                            </label>
                        </div>
                    </div>

                    <!-- AFTER EVENT MONITORING Options-->
                    <div ng-show="$ctrl.settings.monitoringServices.afterMonitorServiceSelectionEnabled && $ctrl.settings.monitoringServices.afterMonitoringRpm">
                        <div class="col-md-12">
                            <h4>{{"monitoring_service_patient_event_monitoring_what_happens_after" | translate}} {{$ctrl.settings.monitoringServices.eventMonitoring.getName()}}?</h4>
                        </div>

                        <div class="col-md-12">
                            <label>
                                <input ng-change="$ctrl.settings.monitoringServices.monitoringSettingsChanged()" ng-model="$ctrl.settings.monitoringServices.postMonitoringInactivate" ng-value="true" type="radio">
                                <span>{{"general_inactivate" | translate}}</span>
                            </label>
                            <label>
                                <input ng-change="$ctrl.settings.monitoringServices.monitoringSettingsChanged()" ng-model="$ctrl.settings.monitoringServices.postMonitoringInactivate" ng-value="false" type="radio">
                                <span>{{$ctrl.settings.monitoringServices.afterMonitoringRpm.getName()}}</span>
                            </label>
                        </div>
                    </div>

                    <!-- AFTER EVENT MONITORING RPM Durations-->
                    <div ng-show="$ctrl.settings.monitoringServices.afterMonitoringRpm.enabled">
                        <div class="col-md-12">
                            <h4>{{"monitoring_service_event_monitoring_patient_time" | translate}} {{$ctrl.settings.monitoringServices.afterMonitoringRpm.getName()}}?</h4>
                        </div>

                        <div class="col-md-12">
                            <label ng-repeat="option in $ctrl.settings.monitoringServices.afterMonitoringRpm.durations">
                                <input ng-change="$ctrl.settings.monitoringServices.monitoringDurationChanged($ctrl.settings.monitoringServices.afterMonitoringRpm.id, option.id)" ng-model="option.enabled" ng-value="true" type="radio">
                                <span>{{option.title}}</span>
                            </label>
                        </div>
                    </div>

                    <!-- RPM Durations-->
                    <div ng-show="$ctrl.settings.monitoringServices.rpm.enabled">
                        <div class="col-md-12">
                            <h4>{{"monitoring_service_event_monitoring_patient_time" | translate}} {{$ctrl.settings.monitoringServices.rpm.getName()}}?</h4>
                        </div>

                        <div class="col-md-12">
                            <label ng-repeat="option in $ctrl.settings.monitoringServices.rpm.durations">
                                <input ng-change="$ctrl.settings.monitoringServices.monitoringDurationChanged($ctrl.settings.monitoringServices.rpm.id, option.id)" ng-model="option.enabled" ng-value="true" type="radio">
                                <span>{{option.title}}</span>
                            </label>
                        </div>
                    </div>
                </div>

            </fieldset>
        </div>
    </form>
`;

angular.module('admin')
    .component('patientMonitoringForm',
        {
            bindings: {
                isEditMode: '<',
                settings: '=',
                validated: '=',
            },
            template: template,
            controller: ['$translate', 'SessionService', 'NationService', class PatientMonitoringForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: MonitoringFormSettings;
                monitoringForm: any;

                private translateService: angular.translate.ITranslateService;
                private readonly isUS: boolean;
                private readonly isRepresentingProvider: boolean;
                private readonly showMonitoringCenter: boolean;
                private readonly showPrescribingProfessional: boolean;

                constructor($translate: angular.translate.ITranslateService, SessionService: any, NationService: NationService) {
                    this.translateService = $translate;
                    this.isUS = NationService.isUS();
                    this.isRepresentingProvider = !SessionService.isUnrestrictedOrGroupUser();
                    this.showMonitoringCenter = NationService.isUS();
                    this.showPrescribingProfessional = NationService.isUS();
                }

                $doCheck() {
                    if (this.monitoringForm) {
                        this.validated = this.monitoringForm.$valid;
                    }
                }

                getStaffFullName = (user: any) => {
                    return user.lastname + ', ' + user.firstname;
                };

                invalidPrescribingProfessional = () => {
                    if (this.settings && this.showPrescribingProfessional && this.isRepresentingProvider) {
                        if (!this.settings.prescribingProfessional) {
                            return true;
                        }
                    }
                    return false;
                }

            }]
        }
    );
