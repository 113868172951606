import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
  .module('patients')
  .controller('HcpContactController', HcpContactController);

  function HcpContactController($rootScope, $translate, MessageService, envConfig, SessionService) {
    const vm = this;

    vm.hcpContactDetails = $rootScope.hcpContactDetails;
    vm.hcpPrescriberContactDetails = $rootScope.hcpPrescriberContactDetails;

    function activate() {}
    activate();

    vm.getAttempts = function() {
      if (vm.hcpPrescriberContactDetails != null && vm.hcpPrescriberContactDetails.attempts != null) {
        return vm.hcpPrescriberContactDetails.attempts;
      } else if (vm.hcpContactDetails != null) {
        return vm.hcpContactDetails.attempts;
      } else {
        return "-";
      }
    };

    vm.getTimeZoneAbbreviation = function() {
      if (vm.hcpPrescriberContactDetails != null && vm.hcpPrescriberContactDetails.timeZone != null) {
        return moment.tz(vm.hcpPrescriberContactDetails.timeZone).format('z');
      } else if (vm.hcpContactDetails != null) {
        return moment.tz(vm.hcpContactDetails.timeZone).format('z');
      } else {
        return "-";
      }
    };

    vm.getHcpLocalTime = function() {
      if (vm.hcpPrescriberContactDetails != null && vm.hcpPrescriberContactDetails.timeZone != null) {
        return moment.tz(moment(), vm.hcpPrescriberContactDetails.timeZone).format('LT');
      } else if (vm.hcpContactDetails != null) {
        return moment.tz(moment(), vm.hcpContactDetails.timeZone).format('LT');
      } else {
        return "-";
      }
    };

    vm.getPrescriberName = function() {
      if (vm.hcpContactDetails && vm.hcpContactDetails.portalUserRelations && vm.hcpContactDetails.portalUserRelations.length > 0) {
        const userRelation = vm.hcpContactDetails.portalUserRelations[0];
        return userRelation.firstName + ' ' + userRelation.lastName;
      }
      return '-';
    };
  }
})();
