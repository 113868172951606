import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('core')
    .controller('SettingsController', SettingsController);

  function SettingsController($rootScope, $state, $stateParams, $http, $window, ModalService, SettingsService) {
    const vm = this;

    activate();

    function activate() {
      SettingsService.getSettings().then(function(response) {
        vm.settings = response.rows;
      });
    }

    vm.formatValue = function(type, value) {
      value = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
      $('#' + type).html(value);
    };

    vm.getType = function(type) {
      if (type === 'welcomeMessageTitlePlus') {
        return 'Premium - Välkomstmeddelande titel';
      } else if (type === 'welcomeMessageBodyPlus') {
        return 'Premium - Välkomstmeddelande innehåll';
      } else if (type === 'welcomeMessageTitleBase') {
        return 'Basic - Välkomstmeddelande titel';
      } else if (type === 'welcomeMessageBodyBase') {
        return 'Basic - Välkomstmeddelande innehåll';
      }
    };

    vm.showUpdateSettingsModal = function(setting) {
      $rootScope.setting = setting;
      ModalService.showModal({
        template: require('../views/modals/core.settings.edit.tpl.html').default,
        controller: 'SettingsEditController',
        controllerAs: 'vm',
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          SettingsService.getSettings().then(function(response) {
            vm.settings = response.rows;
          });
          console.log(result);
        });
      });
    };

    vm.showAnalysisResults = function() {
      $state.go('mainAdmin.setAnalysisResult');
    };

    vm.showSystemInfo = function() {
      $state.go('mainAdmin.info');
    };

    vm.showAzureInfo = function() {
      $state.go('mainAdmin.azureInfo');
    };
  }
})();
