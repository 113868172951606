import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientDashboardController', PatientDashboardController);

  function PatientDashboardController(
    $scope,
    $stateParams,
    $state,
    $translate,
    $rootScope,
    $filter,
    PatientService,
    SessionService,
    NationService,
    UtilService,
    MeasurementHelperService,
    ReportHelperService,
    ModalService
  ) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.searchPortalUserOptions = [];
    vm.searchPortalUserOption = null;

    let _tableStateReport = null;
    let pagingParametersReport = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    let _tableStateMeasurement = null;
    let pagingParametersMeasurement = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      getSearchFilterByPortalUser();
      updateDashboardInfo();
    }

    function getSearchFilterByPortalUser() {
      if (NationService.isUS()) {
        vm.searchPortalUserOption = SessionService.getFilterOptions().searchPortalUserOption;
        vm.searchPortalUserOptions = SessionService.getSearchFilterPortalUsers();
        if (vm.searchPortalUserOptions === null) {
          PatientService.getSearchFilterByPortalUser().then(function(response) {
            vm.searchPortalUserOptions = [{name: $translate.instant('user_searchoption_all'), value: ''}];
            jQuery.grep(response, function(x) {
              vm.searchPortalUserOptions.push({name: x.staff.lastname + ', ' + x.staff.firstname, value: x.id});
            });
            SessionService.setSearchFilterPortalUsers(vm.searchPortalUserOptions);
            updateUserReports();
          });
        }
      }
    }

    function setSearchFilterByPortalUser() {
      if (NationService.isUS()) {
        const filterOptions = SessionService.getFilterOptions();
        filterOptions.searchPortalUserOption = vm.searchPortalUserOption;
        SessionService.setFilterOptions(filterOptions);
      }
    }

    function isAbleToSearch() {
      if (vm.searchPortalUserOptions == null && NationService.isUS()) {
        return false;
      }
      return true;
    }

    vm.filterOnPortalUser = function() {
      updateUserReports();
      updateUserMeasurements();
      setSearchFilterByPortalUser();
    };

    /* --------- Statistics -------------- */
    function updateDashboardInfo() {
      PatientService.getDashboardInfo().then(function(data) {
        vm.dashboardInfo = data;
      });
    }

    vm.getReportInfo = function() {
      if (vm.dashboardInfo !== undefined && vm.dashboardInfo.reportData != null) {
        return vm.dashboardInfo.reportData.totalReportCount + '/' + vm.dashboardInfo.reportData.unViewedReportCount;
      }
      return '-';
    };

    vm.getMeasurementInfo = function() {
      if (vm.dashboardInfo !== undefined && vm.dashboardInfo.measurementData != null) {
        return vm.dashboardInfo.measurementData.totalMeasurementCount + '/' + vm.dashboardInfo.measurementData.unReiewedMeasurementCount;
      }
      return '-';
    };

    vm.getPatientInfo = function() {
      if (vm.dashboardInfo !== undefined && vm.dashboardInfo.patientData != null) {
        return vm.dashboardInfo.patientData.totalPatientCount;
      }
      return '-';
    };

    vm.getPortalUserInfo = function() {
      if (vm.dashboardInfo !== undefined && vm.dashboardInfo.portalUserData != null) {
        return vm.dashboardInfo.portalUserData.totalPortalUserCount;
      }
      return '-';
    };

    vm.isUS = function() {
      return NationService.isUS();
    };

    /* --------------Patients ------------*/
    vm.showCreateAccountModal = function() {
      $rootScope.isEditMode = false;
      $rootScope.user = null;
      $rootScope.subscription = null;
      $rootScope.userDetails = null;
      ModalService.showModal({
        template: require('../../admin/views/modals/patient.create.edit.modal.tpl.html').default,
        controller: 'PatientCreateEditController',
        controllerAs: 'vm'
      }).then(function(modal) {
        modal.element.modal();
        modal.close.then(function(result) {
          $('.modal-backdrop').hide(); // for black background
        });
      });
    };
    /* ------------- Reports --------------*/
    function updateUserReports(tableState) {
      vm.showSpinner = true;
      if (tableState) {
        _tableStateReport = tableState;
      }

      if (isAbleToSearch()) {
        const reportfilters = [];
        reportfilters.push({value: 'true', propertyKey: 'viewed', propertyValue: 'false'});
        reportfilters.push({value: 'true', propertyKey: 'reportGeneratorStatus', propertyValue: 'GenerationFinished'});
        if (vm.isUS() && vm.searchPortalUserOption !== undefined && vm.searchPortalUserOption.value !== '') {
          reportfilters.push({value: 'true', propertyKey: 'filterPortalUserRelations', propertyValue: vm.searchPortalUserOption.value});
        }

        pagingParametersReport = UtilService.preparePagingParameters(
          _tableStateReport,
          reportfilters
        );
        pagingParametersReport.pageSize = 10;

        PatientService.getReports(pagingParametersReport).then(function(data) {
          vm.reports = data.rows;
          vm.totalReports = data.nbrOfRows;
          vm.nrOfReportPages = data.nbrOfPages;
          vm.showSpinner = false;

          if (_tableStateReport) {
            _tableStateReport.pagination.numberOfPages = data.nbrOfPages;
          }
        });
      }
    }

    vm.fetchReports = function(tableState) {
      updateUserReports(tableState);
    };

    vm.reportDescription = function(report) {
      return ReportHelperService.reportDescription(report);
    };

    vm.reportCreatedBy = function(report) {
      return ReportHelperService.reportCreatedBy(report);
    };

    vm.reportPatient = function(report) {
      return ReportHelperService.reportPatient(report);
    };

    vm.getLocalTime = function(utctime) {
      const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
      const localTime = moment.utc(date).toDate();
      return localTime;
    };

    vm.showDownloadLink = function(report) {
      return ReportHelperService.showDownloadLink(report);
    };

    vm.reportIsNotViewed = function(report) {
      return ReportHelperService.reportIsNotViewed(report);
    };

    vm.reportIsViewed = function(report) {
      return ReportHelperService.reportIsViewed(report);
    };

    vm.setToViewed = function(reportId, storagePath) {
      return ReportHelperService.setToViewed(reportId, storagePath, vm.reports);
    };

    vm.reportGeneratorFailed = function(report) {
      return ReportHelperService.reportGeneratorFailed(report);
    };

    /* ------------- Measurments --------------*/
    function updateUserMeasurements(tableState) {
      let changedByUri = false;
      if (_tableStateMeasurement === null) {
        changedByUri = true;
      }
      if (tableState) {
        _tableStateMeasurement = tableState;
      }

      if (isAbleToSearch()) {
        let startItem = $stateParams.startItem;
        if (startItem !== '' && startItem !== undefined && startItem !== null && changedByUri) {
          _tableStateMeasurement.pagination.start = parseInt(startItem, 10);
        } else if (startItem !== undefined) {
          startItem = _tableStateMeasurement.pagination.start;
        }

        vm.startItem = startItem;

        vm.filters = [];
        const formatedDate = moment().add(-6, 'months');
        vm.filters.push({value: 'true', propertyKey: 'fromdate', propertyValue: formatedDate.format('YYYY-MM-DD')});
        vm.filters.push({value: 'true', propertyKey: 'filtercategories', propertyValue: '2,3,4,5,6,7,8,9,10,11,12'});
        vm.filters.push({value: 'true', propertyKey: 'flagged', propertyValue: '2'});
        if (vm.isUS() && vm.searchPortalUserOption !== undefined && vm.searchPortalUserOption.value !== '') {
          vm.filters.push({value: 'true', propertyKey: 'filterPortalUserRelations', propertyValue: vm.searchPortalUserOption.value});
        }

        pagingParametersMeasurement = UtilService.preparePagingParameters(_tableStateMeasurement, vm.filters);
        pagingParametersMeasurement.pageSize = 10;

        updateMeasurementsTable();
      }
    }
    vm.fetchMeasurements = function(tableState) {
      updateUserMeasurements(tableState);
    };

    function updateMeasurementsTable() {
      vm.showSpinner = true;
      PatientService.getUsersMeasurements(pagingParametersMeasurement, false).then(function(data) {
        vm.measurements = data.rows;
        vm.nrOfMeasurements = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableStateMeasurement) {
          _tableStateMeasurement.pagination.numberOfPages = data.nbrOfPages;
        }
        vm.showSpinner = false;
      });
    }

    vm.parseAppErrorCode = function(measurement) {
      return MeasurementHelperService.parseAppErrorCode(measurement);
    };

    vm.appStatus = function(measurement) {
      return MeasurementHelperService.appStatus(measurement);
    };

    vm.parsePulse = function(measurement) {
      return MeasurementHelperService.parsePulse(measurement);
    };

    vm.goToMeasurement = function(measurementId, userId) {
      $rootScope.measurements = undefined;
      $rootScope.nrOfMeasurements = 0;
      $rootScope.measurementsPagingParameters = pagingParametersMeasurement;
      $rootScope.measurementlistPosition = null;
      $rootScope.measurementlistCount = 0;
      $rootScope.fromUri = 'main.patientdashboard';

      $state.go('main.patientmeasurement', {
        measurementId: measurementId,
        userId: userId,
        startItem: vm.startItem
      });
    };


  }
})();
