import * as angular from 'angular';

angular.module('patients', ['ui.bootstrap', 'angularjs-dropdown-multiselect']);

angular.module('patients').config(['$stateProvider', '$urlRouterProvider', routes]);

// Routing

function routes($stateProvider: any, $urlRouterProvider: any) {
    $stateProvider
        .state('main', {
            url: '',
            template: require('./views/main.layout.tpl.html').default,
            abstract: true,
            data: {
                access: 'loggedin'
            }
        }).state('main.patientsearch', {
        url: '/patient/search',
        controller: 'PatientDetailsController',
        template: require('./views/patient.details.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientdetails', {
        url: '/patient/details/:userId/:startItem',
        controller: 'PatientDetailsController',
        template: require('./views/patient.details.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientreport', {
        url: '/patient/report/:userId/',
        controller: 'PatientReportController',
        template: require('./views/patient.report.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientmeasurement', {
        url: '/patient/measurement/:measurementId/:userId/:startItem',
        controller: 'PatientMeasurementController',
        template: require('./views/patient.measurement.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientmeasurementlist', {
        url: '/patient/measurement/list/:startItem',
        controller: 'PatientMeasurementListController',
        template: require('./views/patient.measurement.list.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.timereports', {
        url: '/patient/timereports/list/:startItem',
        controller: 'PatientTimeReportController',
        template: require('./views/patient.timereport.list.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientdashboard', {
        url: '/patient/dashboard',
        controller: 'PatientDashboardController',
        template: require('./views/patient.dashboard.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('main.patientreports', {
        url: '/patient/reports',
        controller: 'PatientReportListController',
        template: require('./views/patient.report.list.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    });

    $stateProvider
        .state('me', {
            url: '',
            template: require('./views/me.layout.tpl.html').default,
            abstract: true,
            data: {
                access: 'loggedin'
            }
        }).state('me.myprofile', {
        url: '/myprofile',
        controller: 'MyProfileController',
        template: require('./views/patient.myprofile.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('me.paymentcompleted', {
        url: '/myprofile/confirmation',
        controller: 'PaymentCompletedController',
        template: require('./views/patient.paymentcompleted.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('me.registration', {
        url: '/registration?email=:userId',
        controller: 'RegistrationController',
        template: require('./views/patient.registration.tpl.html').default,
        controllerAs: 'vm',
        authenticate: false
    });

    $urlRouterProvider.otherwise('/login');
}

// / Small components

angular.module('patients')
    .component('patientReport', {
        template: require('./views/patient.report.tpl.html').default
    });

angular.module('patients')
    .component('patientChestEcg', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/chest.ecg.tpl.html').default
    });

angular.module('patients')
    .component('patientFingerEcg', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/finger.ecg.tpl.html').default
    });

angular.module('patients')
    .component('patientSound', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/sound.tpl.html').default
    });

angular.module('patients')
    .component('patientStethoscope', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/stethoscope.tpl.html').default
    });
