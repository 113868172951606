import * as angular from "angular";

export interface DateFormats {
    mediumDate: string;
    shortDatePicker: string;
    shortTime: string;
    longDate: string;
    shortDate: string;
}

export interface LoginMethodOption {
    name: string;
    value: string;
}

export interface NationConfiguration {
    identificationNoMustBeUnique: boolean;
    showAIMO: boolean;
    staffIdentificationNoValidationRegExp: string;
    showCoalaConnect: boolean;
    showHSAID: boolean;
    showInsuranceInfo: boolean;
    dateformats: DateFormats;
    dateOfBirthalidationRegExp: string;
    loginMetodOptions: LoginMethodOption[];
    hasStaffIdentificationNo: boolean;
    patientIdentificationNoFormat: string;
    staffIdentificationNoFormat: string;
    showDateOfBirth: boolean;
    showReport: boolean;
    dateOfBirthFormat: string;
    patientIdentificationNoValidationRegExp: string;
}

export class NationService {
    private readonly translateService: any;
    private readonly SessionService: any;
    private readonly envConfig: any;
    private readonly PatientService: any;

    private configurations!: NationConfiguration;

    private readonly validCoalaConnectHCPIds = [
        { id: "1577d7d8-a7e3-44a2-b5d2-99be4d002581" } /* Test i EU Coalalife*/,
        {
            id: "d05d2f4e-19c3-47ab-90b3-3045d89a43a8",
        } /* Hjärtmottagningen SUS Malmö */,
        {
            id: "2cf4962a-0146-4d55-b370-62ead1a44693",
        } /* HJC Hjärtcentrum AB */,
        { id: "703193cc-d6d6-40ed-897f-e1b19bffe9e2" } /* ADHD Hälsan */,
    ];

    constructor(
        $q: any,
        $http: any,
        envConfig: any,
        SessionService: any,
        PatientService: any,
        $translate: any
    ) {
        this.envConfig = envConfig;
        this.SessionService = SessionService;
        this.PatientService = PatientService;
        this.translateService = $translate;
        this.setConfiguration();
    }

    us_configurations = {
        dateformats: {
            shortDate: "MM/dd/yy",
            mediumDate: "MM/dd/yyyy",
            shortDatePicker: "MM/DD/YY",
            longDate: "MM/dd/yy h:mma",
            shortTime: "h:mma",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "Patient Id",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: true,
        showInsuranceInfo: true,
        dateOfBirthalidationRegExp:
            "^((0[1-9]|1[0-2])[/.](0[1-9]|1[0-9]|2[0-9]|3[01])[/.][0-9]{4})$",
        dateOfBirthFormat: "MM/DD/YYYY",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: false,
        loginMetodOptions: [
            {
                name: "loginmethodsoptions_pwd_with2fa",
                value: "passwordAndTotp",
            },
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    se_configurations = {
        dateformats: {
            shortDate: "yyyy-MM-dd",
            mediumDate: "yyyy-MM-dd",
            shortDatePicker: "YYYY-MM-DD",
            longDate: "yyyy-MM-dd HH:mm",
            shortTime: "HH:mm",
        },
        hasStaffIdentificationNo: true,
        patientIdentificationNoValidationRegExp:
            "^([0-9]{8})(X|[0-9])([0-9]{3})$",
        patientIdentificationNoFormat: "PNR (ÅÅÅÅMMDDXXXX)",
        staffIdentificationNoValidationRegExp:
            "^([0-9]{8})(X|[0-9])([0-9]{3})$",
        staffIdentificationNoFormat: "PNR (ÅÅÅÅMMDDXXXX)",
        identificationNoMustBeUnique: true,
        showHSAID: true,
        showCoalaConnect: true,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp: "",
        dateOfBirthFormat: "",
        showDateOfBirth: false,
        showReport: true,
        showAIMO: true,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
            { name: "loginmethodsoptions_bankId", value: "bankId" },
            {
                name: "loginmethodsoptions_bankIdandpwd",
                value: "passwordAndBankId",
            },
        ],
    };

    de_configurations = {
        dateformats: {
            shortDate: "dd.MM.yy",
            mediumDate: "dd.MM.yyyy",
            shortDatePicker: "DD.MM.YY",
            longDate: "dd.MM.yy HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "Patient Id",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^((0[1-9]|1[0-9]|2[0-9]|3[01])[/.](0[1-9]|1[0-2])[/.][0-9]{4})$",
        dateOfBirthFormat: "DD.MM.YYYY",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: false,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    gb_configurations = {
        dateformats: {
            shortDate: "dd/MM/yy",
            mediumDate: "dd/MM/yyyy",
            shortDatePicker: "DD/MM/YY",
            longDate: "dd/MM/yy HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "Patient Id",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^((0[1-9]|1[0-9]|2[0-9]|3[01])[/.](0[1-9]|1[0-2])[/.][0-9]{4})$",
        dateOfBirthFormat: "DD/MM/YYYY",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: false,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    nl_configurations = {
        dateformats: {
            shortDate: "dd/MM/yy",
            mediumDate: "dd/MM/yyyy",
            shortDatePicker: "DD/MM/YY",
            longDate: "dd/MM/yy HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "ID-nummer",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^((0[1-9]|1[0-9]|2[0-9]|3[01])[/.](0[1-9]|1[0-2])[/.][0-9]{4})$",
        dateOfBirthFormat: "DD/MM/YYYY",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: false,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    dk_configurations = {
        dateformats: {
            shortDate: "yyyy-MM-dd",
            mediumDate: "yyyy-MM-dd",
            shortDatePicker: "YYYY-MM-DD",
            longDate: "yyyy-MM-dd HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "ID-nummer",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^(([0-9]{4})[-](0[1-9]|1[0-2])[-](0[1-9]|1[0-9]|2[0-9]|3[01]))$",
        dateOfBirthFormat: "YYYY-MM-DD",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: true,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    jp_configurations = {
        dateformats: {
            shortDate: "yyyy-MM-dd",
            mediumDate: "yyyy-MM-dd",
            shortDatePicker: "YYYY-MM-DD",
            longDate: "yyyy-MM-dd HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "Patient ID",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^(([0-9]{4})[-](0[1-9]|1[0-2])[-](0[1-9]|1[0-9]|2[0-9]|3[01]))$",
        dateOfBirthFormat: "YYYY-MM-DD",
        showDateOfBirth: true,
        showReport: false,
        showAIMO: false,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    au_configurations = {
        dateformats: {
            shortDate: "dd/MM/yy",
            mediumDate: "dd/MM/yyyy",
            shortDatePicker: "DD/MM/YY",
            longDate: "dd/MM/yy HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "Patient Id",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^((0[1-9]|1[0-9]|2[0-9]|3[01])[/.](0[1-9]|1[0-2])[/.][0-9]{4})$",
        dateOfBirthFormat: "DD/MM/YYYY",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: false,
        loginMetodOptions: [
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    xx_configurations = {
        dateformats: {
            shortDate: 'yyyy-MM-dd',
            mediumDate: 'yyyy-MM-dd',
            shortDatePicker: 'YYYY-MM-DD',
            longDate: 'yyyy-MM-dd HH:mm',
            shortTime: 'HH:mm'
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: '',
        patientIdentificationNoFormat: 'ID-nummer',
        staffIdentificationNoValidationRegExp: '',
        staffIdentificationNoFormat: 'Id',
        hasStaffIdentificationNo: false,
        showHSAID: true,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp: '^(([0-9]{4})[-](0[1-9]|1[0-2])[-](0[1-9]|1[0-9]|2[0-9]|3[01]))$',
        dateOfBirthFormat: 'YYYY-MM-DD',
        showDateOfBirth: true,
        showReport: true,
        showAIMO: true,
        loginMetodOptions: [
            {name: 'loginmethodsoptions_bankId', value: 'bankId'},
            {name: 'loginmethodsoptions_bankIdandpwd', value: 'passwordAndBankId'},
            {name: 'loginmethodsoptions_pwd', value: 'password'}
        ]
    };
    
    default_configurations = {
        dateformats: {
            shortDate: "yyyy-MM-dd",
            mediumDate: "yyyy-MM-dd",
            shortDatePicker: "YYYY-MM-DD",
            longDate: "yyyy-MM-dd HH:mm",
            shortTime: "HH:mm",
        },
        identificationNoMustBeUnique: false,
        patientIdentificationNoValidationRegExp: "",
        patientIdentificationNoFormat: "ID-nummer",
        staffIdentificationNoValidationRegExp: "",
        staffIdentificationNoFormat: "Id",
        hasStaffIdentificationNo: false,
        showHSAID: false,
        showCoalaConnect: false,
        showInsuranceInfo: false,
        dateOfBirthalidationRegExp:
            "^(([0-9]{4})[-](0[1-9]|1[0-2])[-](0[1-9]|1[0-9]|2[0-9]|3[01]))$",
        dateOfBirthFormat: "YYYY-MM-DD",
        showDateOfBirth: true,
        showReport: true,
        showAIMO: true,
        loginMetodOptions: [
            { name: "loginmethodsoptions_bankId", value: "bankId" },
            {
                name: "loginmethodsoptions_bankIdandpwd",
                value: "passwordAndBankId",
            },
            { name: "loginmethodsoptions_pwd", value: "password" },
        ],
    };

    setConfiguration() {
        const nationId = this.SessionService.getNation();
        if (nationId === "US") {
            this.configurations = this.us_configurations;
        } else if (nationId === "SE") {
            this.configurations = this.se_configurations;
            this.configurations.patientIdentificationNoFormat =
                this.translateService.instant("ID_format");
            this.configurations.staffIdentificationNoFormat =
                this.translateService.instant("ID_format");
        } else if (nationId === "DE") {
            this.configurations = this.de_configurations;
        } else if (nationId === "GB") {
            this.configurations = this.gb_configurations;
        } else if (nationId === "NL") {
            this.configurations = this.nl_configurations;
        } else if (nationId === "DK") {
            this.configurations = this.dk_configurations;
        } else if (nationId === "JP") {
            this.configurations = this.jp_configurations;
        } else if (nationId === "AU") {
            this.configurations = this.au_configurations;
        } else if (nationId === 'XX') {
            this.configurations = this.xx_configurations;
        }
        else {
            this.configurations = this.default_configurations;
        }
    }

    getConfiguration() {
        return this.configurations;
    }

    getDateFormats() {
        console.log(
            "datetime formats:" + this.configurations.dateformats.shortDate
        );
        return this.configurations.dateformats;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAuthMethod(authMethod: any) {
        const nationId = this.SessionService.getNation();
        return !(nationId === "US" || nationId === "JP");
    }

    getRegionalValue(regionalItem: any) {
        let result = "";
        if (regionalItem !== undefined) {
            angular.forEach(regionalItem.values, function (item) {
                let value = item.value.toFixed(1);
                const diff = item.value.toFixed(0) - item.value.toFixed(1);
                if (diff == 0) {
                    value = item.value.toFixed(0);
                }
                result = result + value + item.unit;
            });
        }
        return result;
    }

    getRegionalWeight(regionalItem: any) {
        let result: any = null;
        if (regionalItem !== undefined && regionalItem !== null) {
            angular.forEach(regionalItem.values, function (item) {
                result += item.value;
            });
        }
        return result;
    }

    getWeightUnit() {
        const nationId = this.SessionService.getNation();
        if (nationId === "US") {
            return "pounds";
        }
        return "kg";
    }

    hasCoalaConnect() {
        const nationId = this.SessionService.getNation();
        if (nationId === "US") {
            return this.configurations.showCoalaConnect;
        } else {
            const user = this.SessionService.getLoggedInUser();
            var exists = this.validCoalaConnectHCPIds.filter(
                (x) => x.id == user.representing.id
            );
            return exists.length > 0 && this.configurations.showCoalaConnect;
        }
    }

    hasExternalAuthMethod() {
        return this.hasAuthMethod("bankId") || this.hasAuthMethod("siths");
    }

    hasLanguage(langKey: string) {
        const nationId = this.SessionService.getNation();
        if (nationId === "US" && langKey !== "en-us") {
            return false;
        }
        return !(nationId !== "US" && langKey === "en-us");
    }

    getPatientLoginOptions() {
        return this.configurations.loginMetodOptions.filter(
            (option) => option.name !== "loginmethodsoptions_pwd_with2fa"
        );
    }

    getLoginMetodOptions() {
        return this.configurations.loginMetodOptions;
    }

    getCategories() {
        if (this.envConfig.defaultNationId === "US_TEMPORARY_REMOVED") {
            return [
                {
                    id: 0,
                    name: this.translateService.instant("measurement_error"),
                },
                {
                    id: 1,
                    name: this.translateService.instant("measurement_ok"),
                },
                {
                    id: 2,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 2",
                },
                {
                    id: 99,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 99",
                },
                {
                    id: 100,
                    name: this.translateService.instant(
                        "measurement_stethoscope"
                    ),
                },
            ];
        } else {
            return [
                {
                    id: 0,
                    name: this.translateService.instant("measurement_error"),
                },
                {
                    id: 1,
                    name: this.translateService.instant("measurement_ok"),
                },
                {
                    id: 2,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 2",
                },
                {
                    id: 3,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 3",
                },
                {
                    id: 4,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 4",
                },
                {
                    id: 5,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 5",
                },
                {
                    id: 6,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 6",
                },
                {
                    id: 7,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 7",
                },
                {
                    id: 8,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 8",
                },
                {
                    id: 10,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 10",
                },
                {
                    id: 11,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 11",
                },
                {
                    id: 12,
                    name:
                        this.translateService.instant("measurement_category") +
                        " 12",
                },
                {
                    id: 100,
                    name: this.translateService.instant(
                        "measurement_stethoscope"
                    ),
                },
            ];
        }
    }

    getSearchFilterForStaff() {
        if (this.configurations.showCoalaConnect) {
            return [
                { name: "patient_searchoption_all", value: 0 },
                { name: "patient_searchoption_active", value: 3 },
                { name: "patient_searchoption_inactive", value: 4 },
                { name: "user_searchoption_coalaconnect", value: 8 },
            ];
        } else {
            return [
                { name: "patient_searchoption_all", value: 0 },
                { name: "patient_searchoption_active", value: 3 },
                { name: "patient_searchoption_inactive", value: 4 },
            ];
        }
    }

    getOrderCoalaSubscriptionOptions(): { name: string; value: number }[] {
        if (this.envConfig.defaultNationId === "US") {
            return [
                { name: "monthsoptions_no", value: 0 },
                { name: "freemonthsoptions_onemonths", value: 1 },
                { name: "freemonthsoptions_twomonths", value: 2 },
                { name: "freemonthsoptions_threemonths", value: 3 },
            ];
        } else {
            return [
                { name: "monthsoptions_no", value: 0 },
                { name: "freemonthsoptions_two_to_four_weeks", value: 1 },
                { name: "freemonthsoptions_twomonths", value: 2 },
                { name: "freemonthsoptions_threemonths", value: 3 },
            ];
        }
    }

    getAvailableFilterMeasurementConfigurations(): Promise<any> {
        return new Promise((resolve) => {
            let filters =
                this.SessionService.getExpandedMeasuremenConfigurations();
            if (filters == null) {
                this.PatientService.getExpandedMeasuremenConfigurations().then(
                    (response: any) => {
                        filters = response;
                        filters.push({
                            id: "00000000-0000-0000-0000-000000000001",
                            name: "OriginalWithNoRealConfiguration",
                        });
                        this.SessionService.setExpandedMeasuremenConfigurations(
                            filters
                        );
                        resolve(filters);
                    }
                );
            } else {
                resolve(filters);
            }
        });
    }

    getReportCreatorTypeOptions() {
        return [
            { name: "user_searchoption_all", value: -1 },
            {
                name: "report_reporttype_searchoption_manualgenerated",
                value: 0,
            },
            {
                name: "report_reporttype_searchoption_scheduledautomaticgenerated",
                value: 1,
            },
            {
                name: "report_reporttype_searchoption_scheduledeventmonitorgenerated",
                value: 2,
            },
            {
                name: "report_reporttype_searchoption_scheduledlongtermeventmonitorgenerated",
                value: 3,
            },
        ];
    }

    getReportReviewTypeOptions() {
        return [
            { name: "user_searchoption_all", value: -1 },
            { name: "report_reviewtype_searchoption_unreviewed", value: 1 },
            {
                name: "report_reviewtype_searchoption_unreviewed_byme",
                value: 2,
            },
        ];
    }

    showPayments() {
        const nationId = this.SessionService.getNation();
        return nationId === "SE";
    }

    getCoalaSiteUrlWithNationCode = (page: number) => {
        const nationId = this.SessionService.getNation();
        return (
            "https://www.coalalife.com/" + nationId.toLowerCase() + "/" + page
        );
    };

    getTermsUrl() {
        const nationId = this.SessionService.getNation();
        if (nationId === "US") {
            return "https://www.coalalife.com/us/terms";
        }
        return "https://www.coalalife.com/uk/terms";
    }

    getOrderCoalaTermsUrl() {
        const nationId = this.SessionService.getNation();
        if (nationId === "US") {
            return "https://www.coalalife.com/us/terms";
        }
        return "https://www.coalalife.com/villkor-coala-pro-x";
    }

    getCoalaUserTermsUrl() {
        return "https://www.coalalife.com/se/terms";
    }

    isUS() {
        const nationId = this.SessionService.getNation();
        return nationId === "US";
    }

    loggedInUserId() {
        const user = this.SessionService.getLoggedInUser();
        if (user != null) {
            return user.userId;
        }
        return null;
    }
}

angular
    .module("core")
    .service("NationService", [
        "$q",
        "$http",
        "envConfig",
        "SessionService",
        "PatientService",
        "$translate",
        NationService,
    ]);
