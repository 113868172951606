// Generated by CoffeeScript 1.10.0
(function() {
  (function($) {
    var defaultOptions, drawAnnotaion, drawAnnotations, init;
    defaultOptions = {
      annotation: {
        'class': 'jquery-flot-annotation',
        wrapperCss: {
          'position': 'absolute',
          'display': 'block',
          'margin': '0',
          'line-height': '1em',
          'padding': '0',
          'font-size': 'xx-small',
          'box-sizing': 'border-box',
          'text-align': 'center',
          'white-space': 'nowrap',
          'overflow-x': 'hide'
        },
        htmlTemplate: function(text, width) {
          return "<div class=\"" + this['class'] + "\" style=\"width: " + width + "px;\">" + (text || '') + "</div>";
        },
        show: true,
        position: {
          offsetX: 0,
          offsetY: 0,
          x: function(x) {
            return {
              'left': x + parseFloat(this.offsetX || 0)
            };
          },
          y: function(y) {
            return {
              'top': y + parseFloat(this.offsetY || 0)
            };
          }
        }
      }
    };
    drawAnnotations = function(plot) {
      var annotation, annotations, i, len, options;
      annotations = plot.getOptions().annotations;
      if (!annotations) {
        return;
      }
      options = plot.getOptions().annotation || defaultOptions;
      $(plot.getPlaceholder()).find('.' + options["class"]).remove();
      if ($.isArray(annotations) && options.show) {
        for (i = 0, len = annotations.length; i < len; i++) {
          annotation = annotations[i];
          drawAnnotaion.apply(plot, [annotation, options]);
        }
      }
    };
    drawAnnotaion = function(annotation, options) {
      var axes, canvasX, canvasY, dX, dY, html, plot, xaxis, yaxis;
      plot = this;
      axes = plot.getAxes();
      xaxis = axes.xaxis;
      yaxis = axes.yaxis;
      if (annotation.x < xaxis.min || annotation.x > xaxis.max) {
        return;
      }
      dX = annotation.dX || options.dX;
      if (dX && dX < (xaxis.max - xaxis.min)) {
        return;
      }
      canvasX = xaxis.p2c(annotation.x) + plot.getPlotOffset().left + (annotation.offsetX || 0);
      canvasY = annotation.y;
      html = options.htmlTemplate(annotation.text);
      $(html).css(options.wrapperCss).css(options.position.x(canvasX)).css(options.position.y(canvasY)).prependTo(plot.getPlaceholder());
    };
    init = function(plot) {
      plot.hooks.draw.push(drawAnnotations);
    };
    $.plot.plugins.push({
      init: init,
      options: defaultOptions,
      name: 'annotate',
      version: '0.2'
    });
  })(jQuery);

}).call(this);
