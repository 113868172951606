import * as angular from 'angular';
import {UserInfoResponse} from './models/UserInfoResponse';
import {InsuranceDataResponse} from './models/InsuranceDataResponse';
import {MeasurementConfigMinResponse} from './models/MeasurementConfigMinResponse';
import {OrderCoalaRequest} from './models/OrderCoalaRequest';
import {MonitoringServiceUserRelationshipCollectionResponse} from './models/MonitoringServiceUserRelationshipCollectionResponse';
import {HealthcareProviderUserRelationshipResponse} from './models/HealthcareProviderUserRelationshipResponse';
import MonitoringServiceContactInfoResponse from './models/MonitoringServiceContactInfoResponse';
import {AndroidDeviceResponse} from './models/AndroidDeviceResponse';

export default class PatientService {
    root: string;
    deviceApiBaseUrl: string;
    httpClient: any;

    constructor($q: any, $http: any, envConfig: any, SessionService: any, HttpClientService: any) {
        this.httpClient = HttpClientService;
        this.root = envConfig.baseUrl;
        this.deviceApiBaseUrl = this.getDeviceApiUrl(envConfig.baseUrl);
    }

    getDeviceApiUrl(baseUrl: string) {
        if (baseUrl.indexOf('centralusdev') >= 0) {
            return 'https://mobiledevicecentralusdevapi.azurewebsites.net';
        }
        if (baseUrl.indexOf('centralustest') >= 0) {
            return 'https://mobiledevicecentralustestapi.azurewebsites.net';
        }
        if (baseUrl.indexOf('centralusprod') >= 0) {
            return 'https://mobiledevicecentralusprodapi.azurewebsites.net';
        }
        return '';
    }

    getUserDetails(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id);
    }

    hasUserAccess(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id + '/access');
    }

    getUserStatistics(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id + '/statistics');
    }

    getMedicalJournal(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/medicaljournal/users/' + id);
    }

    getMedicalJournalGender(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/medicaljournal/users/' + id + '/gender');
    }

    getNotificationRegistrations(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id + '/notificationRegistrations');
    }

    getUserData(id: string): Promise<UserInfoResponse> {
        return this.httpClient.get(this.root + '/adminapi/v1/account/userinfo/' + id);
    }

    getUserImeiCode(userId: string): Promise<AndroidDeviceResponse> {
        return this.httpClient.get(this.deviceApiBaseUrl + '/api/v1/UserDevice/' + userId);
    }

    deleteUserImeiCode(userId: string) {
        return this.httpClient.delete(this.deviceApiBaseUrl + '/api/v1/UserDevice/' + userId);
    }

    postUserImeiCode(userId: string, imei: string) {
        const body = {
            userId: userId,
            imei: imei
        };
        return this.httpClient.post(this.deviceApiBaseUrl + '/api/v1/UserDevice/', body);
    }

    postUnlockUser(id: string) {
        return this.httpClient.postWithoutBody(this.root + '/adminapi/v1/account/unlock/' + id);
    }

    getIdentificationNoExists(identificationNo: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/account/identificationNoExists?identificationNo=' + identificationNo);
    }

    getEmailExists(email: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/account/emailexists?email=' + email.replace('@', '%40'));
    }

    postUpdateEmail(id: string, email: string) {
        const body = {
            email: email
        };
        return this.httpClient.put(this.root + '/adminapi/v1/users/email/' + id, body);
    }

    postUpdateTestUser(id: string, testUser: any) {
        const body = {
            testUser: testUser
        };
        return this.httpClient.put(this.root + '/adminapi/v1/users/testuser/' + id, body);
    }

    putUpdateUserPassword(id: string, password: string, confirmpassword: string) {
        const body = {
            oldPassword: password,
            newPassword: password,
            confirmPassword: confirmpassword
        };
        return this.httpClient.put(this.root + '/adminapi/v1/users/changepassword/' + id, body);
    }

    postUpdateAllowedLoginMethod(id: string, loginMethod: any) {
        const body = {
            allowedLoginMethods: loginMethod
        };
        return this.httpClient.put(this.root + '/adminapi/v1/account/loginmethods/' + id, body);
    }

    putMeasurementFlag(id: string, flag: string) {
        return this.httpClient.postWithoutBody(this.root + '/adminapi/v1/measurements/' + id + '/flagged/' + flag);
    }

    putMeasurementPartFlag(id: string, flag: string) {
        return this.httpClient.postWithoutBody(this.root + '/adminapi/v1/measurementparts/' + id + '/flagged/' + flag);
    }

    getMeasurementsForUser(userId: string, paging: any, includeSasUrls: string) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }

        query.push(
            this.root,
            '/adminapi/v1/measurements/users/',
            userId,
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize,
            '&includeSasUrls=',
            includeSasUrls
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&' + paging.filter);
            // query.push('&$filter=Pulse eq 65');
        }

        return this.httpClient.get(query.join(''));
    }

    getUsersMeasurements(paging: any, includeSasUrls: string) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        paging.pageSize = 20;

        query.push(
            this.root,
            '/adminapi/v1/measurements/patients',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize,
            '&includeSasUrls=',
            includeSasUrls
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    getNotesForUser(userId: string, measurementId: string) {
        if (measurementId !== undefined) {
            return this.httpClient.get(this.root + '/adminapi/v1/measurements/' + measurementId + '/notes');
        } else {
            return this.httpClient.get(this.root + '/adminapi/v1/users/' + userId + '/notes');
        }
    }

    getMeasurement(measurementId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurements/' + measurementId);
    }

    getMeasurementData(userId: string, measurementId: string, type: string, currentPlotTypeConfigurationId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurements/' + measurementId + '/analysis/' + type + '/' + currentPlotTypeConfigurationId);
    }

    getMeasurementPartContent(measurementId: string, measurementPartConfigurationId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurements/' + measurementId + '/partcontent/' + measurementPartConfigurationId);
    }

    getMeasurementPdf(fileName: string, measurementId: string, type: string, pageSize: string, fromSecond: string, millimetersPerSecond: string, millimetersPerMilliVolt: string, showAnnotations: string, invertGraphChest: string, invertGraphFinger: string) {
        return this.httpClient.getPdf(fileName, this.root + '/adminapi/v1/measurements/' + measurementId + '/pdf/' + type + '?pageSize=' + pageSize + '&fromSecond=' + fromSecond + '&millimetersPerSecond=' + millimetersPerSecond + '&millimetersPerMilliVolt=' + millimetersPerMilliVolt + '&showAnnotations=' + showAnnotations + '&invertGraphChest=' + invertGraphChest + '&invertGraphFinger=' + invertGraphFinger);
    }

    getChestAndFingerMeasurementPdf(fileName: string, measurementId: string, pageSize: number, fromSecond: number, millimetersPerSecondChest: number, millimetersPerMilliVoltChest: number, millimetersPerSecondFinger: number, millimetersPerMilliVoltFinger: number, showAnnotationsChest: boolean, showAnnotationsFinger: boolean, invertGraphChest: boolean, invertGraphFinger: boolean, includeUserprofile: boolean) {
        console.log(this.root + '/adminapi/v1/measurements/' + measurementId + '/pdf/chestandfinger?pageSize=' + pageSize + '&fromSecond=' + fromSecond + '&millimetersPerSecondChest=' + millimetersPerSecondChest + '&millimetersPerMilliVoltChest=' + millimetersPerMilliVoltChest + '&millimetersPerSecondFinger=' + millimetersPerSecondFinger + '&millimetersPerMilliVoltFinger=' + millimetersPerMilliVoltFinger + '&annotationTypeForFinger=' + showAnnotationsFinger + '&annotationTypeForChest=' + showAnnotationsChest + '&invertGraphChest=' + invertGraphChest + '&invertGraphFinger=' + invertGraphFinger + '&includeUserprofile=' + includeUserprofile);
        return this.httpClient.getPdf(fileName, this.root + '/adminapi/v1/measurements/' + measurementId + '/pdf/chestandfinger?pageSize=' + pageSize + '&fromSecond=' + fromSecond + '&millimetersPerSecondChest=' + millimetersPerSecondChest + '&millimetersPerMilliVoltChest=' + millimetersPerMilliVoltChest + '&millimetersPerSecondFinger=' + millimetersPerSecondFinger + '&millimetersPerMilliVoltFinger=' + millimetersPerMilliVoltFinger + '&annotationTypeForFinger=' + showAnnotationsFinger + '&annotationTypeForChest=' + showAnnotationsChest + '&invertGraphChest=' + invertGraphChest + '&invertGraphFinger=' + invertGraphFinger + '&includeUserprofile=' + includeUserprofile);
    }

    getChestAndFingerMeasurementsPdf(fileName: string, measurements: any) {
        console.log(this.root + '/adminapi/v1/measurements/pdf/chestandfinger');
        return this.httpClient.getPdfWithBody(fileName, measurements, this.root + '/adminapi/v1/measurements/pdf/chestandfinger');
    }

    getMeasurementFile(fileName: string, measurementId: string, type: string, currentPlotTypeConfigurationId: string) {
        return this.httpClient.getRawFile(fileName, this.root + '/adminapi/v1/measurements/' + measurementId + '/rawfile/' + type + '/' + currentPlotTypeConfigurationId);
    }

    getPCGAnalysisImage(measurementId: string, currentPlotTypeConfigurationId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurements/' + measurementId + '/' + currentPlotTypeConfigurationId + '/base64/PCGAnalysisImage');
    }

    getNotAnalysedMeasurements(type: string, paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/measurements/notanalysed/',
            type,
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        return this.httpClient.get(query.join(''));
    }

    reanalyseMeasurementPart(id: string, type: string, measurementPartConfigurationId: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/measurements/' + id + '/analysis/' + type + '/'+ measurementPartConfigurationId + '/reanalyze');
    }

    failMeasurementPart(id: string, type: string, measurementPartConfigurationId: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/measurements/' + id + '/analysis/' + type + '/'+ measurementPartConfigurationId + '/fail');
    }

    getUserHealthcareProviders(userId: string): Promise<HealthcareProviderUserRelationshipResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/healthcareProviderUserRelationship/' + userId);
    }

    getUserMonitoringServiceContactInfo(userId: string): Promise<MonitoringServiceContactInfoResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/monitoringServiceContactInfo/user/' + userId);
    }

    getHealthcareProviders(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + userId + '/healthcareProviders?Sort=Name&Page=0&PageSize=99999');
    }

    getUserSubscriptions(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/subscriptions/users/' + userId + '?Sort=validFrom+DESC&pageSize=10');
    }

    cancelTransaction(paymentId: string) {
        return this.httpClient.put(this.root + '/adminapi/v1/klarnapayments/payments/' + paymentId + '/cancellasttransaction');
    }

    getAllUserSubscriptions(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/subscriptions/users/' + userId + '?Sort=validFrom+DESC&PageSize=99999');
    }

    getUserPayments(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/payments/users/' + userId + '?Sort=created+DESC&pageSize=5');
    }

    reCreateReport(reportId: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/report/patient/summary/V3/' + reportId + '/reCreate');
    }

    createReport(patientSummaryReport: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/report/patient/summary/V3', patientSummaryReport);
    }

    isReportCreated(reportId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/report/patient/summary/' + reportId + '/status');
    }

    deleteReports(reports: any) {
        return this.httpClient.deleteWithBody(this.root + '/adminapi/v1/report/patient/summary/', reports);
    }

    reportIsViewed(reportId: string) {
        return this.httpClient.patch(this.root + '/adminapi/v1/report/viewStatus/' + reportId + '/isViewed');
    }

    getNumberOfUnViewedPatientSummaryReports(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/report/viewStatus/count/NotViewed/patient/' + userId);
    }

    getReport(reportId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/report/patient/summary/report/' + reportId);
    }

    getUserReports(userId: string, paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/report/patient/summary/users/' + userId + '/reports',
            '/?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    getReports(paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/report/patient/summary/reports/',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    searchPatient(paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/users',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&', paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    getSearchFilterByPortalUser() {
        return this.httpClient.get(this.root + '/adminapi/v1/portalUserUserRelationship/portalUsers');
    }

    getPatientPortalUser(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/portalUserUserRelationship/portalUsers/byPatient/' + id);
    }

    setPatientPortalUser(portalUser: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/portalUserUserRelationship', portalUser);
    }

    getCoalaUser(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id);
    }

    getUserSubscription(id: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/subscriptions/users/' + id + '/latest');
    }

    putSubscription(subscription: string) {
        return this.httpClient.put(this.root + '/adminapi/v1/subscriptions', subscription);
    }

    putUserProfile(id: string, userDetails: any) {
        return this.httpClient.put(this.root + '/adminapi/v1/users/' + id, userDetails);
    }

    createPatient(user: any): Promise<string> {
        return this.httpClient.post(this.root + '/adminapi/v1/account/register', user);
    }

    deletePatient(userId: string) {
        return this.httpClient.delete(this.root + '/adminapi/v1/account/' + userId);
    }

    unlinkPatient(userId: string) {
        return this.httpClient.delete(this.root + '/adminapi/v1/healthcareProviders/users/' + userId + '/unlinkfromprovider');
    }

    completeInvestigation(userId: string) {
        return this.httpClient.delete(this.root + '/adminapi/v1/healthcareProviders/users/' + userId + '/completeinvestigation');
    }

    resetPassword(email: string) {
        const obj = {email: email};
        return this.httpClient.post(this.root + '/adminapi/v1/account/resetpassword', obj);
    }

    connect(user: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/account/connect', user);
    }

    postUserNote(id: string, notes: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/users/' + id + '/notes', notes);
    }

    runPcgAudioFilterAnalysis(partId: string) {
        return this.httpClient.postWithoutBody(this.root + '/adminapi/v1/measurementparts/' + partId + '/analysis/runPcgAudioFilterAnalysis/');
    }

    postTimeReport(timereport: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/timereport', timereport);
    }

    getTimeReportTotalTimeForUser(hcpId: string, userId: string, offset: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/timereport/totalTime/healthcareProvider/' + hcpId + '/user/' + userId + '/offset/' + offset);
    }

    getTimeReports(hcpId: string, paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }

        query.push(
            this.root,
            '/adminapi/v1/timereport/healthcareprovider/',
            hcpId,
            '/groupdBy/user',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&' + paging.filter);
        }

        return this.httpClient.get(query.join(''));

        // return this.HttpClientService.get(this.root + '/adminapi/v1/timereport/healthcareprovider/' + hcpId + '/groupdBy/user');
    }

    reAnalyzeMeasurement(id: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/measurements/' + id + '/analysis/reanalyze');
    }

    sendUserCoalaOrder(id: string, orderinfo: OrderCoalaRequest): Promise<void> {
        return this.httpClient.post(this.root + '/adminapi/v1/users/' + id + '/ordercoala', orderinfo);
    }

    storeInsuranceInfo(id: string, insuranceData: InsuranceDataResponse) {
        return this.httpClient.post(this.root + '/adminapi/v1/users/' + id + '/insuranceData', insuranceData);
    }

    getInsuranceInfo(id: string): Promise<InsuranceDataResponse> {
        return this.httpClient.get(this.root + '/adminapi/v1/users/' + id + '/insuranceData');
    }

    getPayments(paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/payments/admin',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        return this.httpClient.get(query.join(''));
    }

    findKlarnaOrder(orderId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/klarnapayments/orders/' + orderId + '/check');
    }

    confirmKlarnaOrder(orderId: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/klarnapayments/push/' + orderId);
    }

    getUserEvaluatedRisk(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/aimo/evaluation/users/' + userId + '/latest/');
    }

    getUserEvaluatedRisks(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/aimo/evaluation/users/' + userId + '/all/');
    }

    createEvaluationRisk(userid: string) {
        return this.httpClient.post(this.root + '/adminapi/v1/aimo/patientprofile/users/' + userid + '/updateandevaluate');
    }

    getMeasuremenConfigurations(): Promise<MeasurementConfigMinResponse> {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/healthcareproviders/');
    }

    getExpandedMeasuremenConfigurations() {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/healthcareproviders/allandexpanded');
    }

    getMeasurementConfigurationsForUser(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/users/' + userId);
    }

    getAllMeasurementConfigurationsForUser(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/measurementconfiguration/min/users/' + userId + '/includepublic');
    }

    updateUserConfiguration(configurations: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/measurementconfiguration/setConfigurationsForPatient/', configurations);
    }

    findSerialNumber(serialnumber: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/payments/serialnumbers/' + serialnumber);
    }

    addSerialNumber(serialnumbers: string[]) {
        const body = {
            serialnumbers: serialnumbers
        };
        return this.httpClient.post(this.root + '/adminapi/v1/payments/serialnumbers', body);
    }

    getDashboardInfo() {
        return this.httpClient.get(this.root + '/adminapi/v1/dashboard');
    }

    getDevices(paging: any) {
        const query = [];
        if (paging.page === undefined || paging.page === '') {
            paging.page = 0;
        }
        query.push(
            this.root,
            '/adminapi/v1/serialnumbers',
            '?Page=',
            paging.page,
            '&PageSize=',
            paging.pageSize
        );

        if (paging.sort !== undefined && paging.sort !== '') {
            query.push(
                '&Sort=',
                paging.sort
            );
        }
        if (paging.search !== undefined && paging.search !== '') {
            query.push(
                '&Search=',
                paging.search
            );
        }

        if (paging.filter !== undefined && paging.filter !== '') {
            query.push('&request.filter[0].key=healthcareproviderid&request.filter[0].value=' + paging.filter);
        }

        return this.httpClient.get(query.join(''));
    }

    addDevice(device: any) {
        return this.httpClient.post(this.root + '/adminapi/v1/serialnumbers', device);
    }

    removeDevice(deviceId: string) {
        return this.httpClient.delete(this.root + '/adminapi/v1/serialnumbers/' + deviceId);
    }

    getMonitoringServiceUserRelationshipCollection(userId: string): Promise<MonitoringServiceUserRelationshipCollectionResponse[]> {
        return this.httpClient.get(this.root + '/adminapi/v1/monitoringServiceUserRelationshipCollection/' + userId);
    }

    postMonitoringServiceUserRelationshipCollection(payload: MonitoringServiceUserRelationshipCollectionResponse): Promise<MonitoringServiceUserRelationshipCollectionResponse> {
        return this.httpClient.post(this.root + '/adminapi/v1/monitoringServiceUserRelationshipCollection', payload);
    }

    getScheduledTasks(userId: string) {
        return this.httpClient.get(this.root + '/adminapi/v1/scheduledtask/users/' + userId);
    }
}

angular.module('patients').service('PatientService',
    ['$q', '$http', 'envConfig', 'SessionService', 'HttpClientService', PatientService]
);
