import * as angular from 'angular';
import moment from "moment";

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientMeasurementListController', PatientMeasurementListController);

  function PatientMeasurementListController($scope, $rootScope, $state, $interval, $stateParams, $translate, PatientService, MeasurementHelperService, NationService, SessionService, UtilService, envConfig) {
    const vm = this;
    let _tableState = null;
    const refreshInterval = 60 * 1000;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.markedmeasurments = [];
    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;
    vm.startItem = 0;
    vm.showSpinner = false;
    vm.fromdate = null;
    vm.todate = null;
    vm.selectedCategories = null;
    vm.currentCategories = null;
    vm.measurementDaysInput = null;
    vm.autorefresh = false;
    let interval = null;
    vm.expandMeasurements = false;
    let measurementWithPreviewImgIsLoaded = false;
    vm.categories = convertToDropDownBoxValues(NationService.getCategories());
    vm.selectedFeelings = null;
    vm.currentFeelings = null;
    vm.feelings = convertToDropDownBoxValues([
      {id: 0, name: $translate.instant('measurement_feeling_unknown')},
      {id: 1, name: $translate.instant('measurement_feeling_good')},
      {id: 2, name: $translate.instant('measurement_feeling_stressed')},
      {id: 3, name: $translate.instant('measurement_feeling_anxious')},
      {id: 4, name: $translate.instant('measurement_feeling_chestpain')},
      {id: 6, name: $translate.instant('measurement_feeling_rapidHeartRate')},
      {id: 7, name: $translate.instant('measurement_feeling_dizziness')},
      {id: 5, name: $translate.instant('measurement_feeling_other')}
    ]);
    vm.selectedConfigurations = null;
    vm.currentConfigurations = null;
    vm.configurations = null;

    vm.filterFlagOptions = [
      {id: 1, label: $translate.instant('patient_measurement_filter')},
      {id: 2, label: $translate.instant('patient_measurement_filter_notreviewed')},
      {id: 3, label: $translate.instant('patient_measurement_filter_hascomment')}
    ];
    vm.flagFilterOption = [];
    vm.filterSetting = {
      buttonClasses: 'multiselect-text-lg form-control',
      styleActive: true,
      // smartButtonMaxItems: 2,
      // smartButtonTextConverter: function(itemText, originalItem) {
      // 	return vm.flagFilterOption.length + ' filter';
      // }
    };
    vm.noFilterText = {
      buttonDefaultText: $translate.instant('user_searchoption_all'),
      dynamicButtonTextSuffix: $translate.instant('filter_selected')
    };

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      $('#datetimepickerFrom').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerTo').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerFrom').on('dp.hide', function(e) {
        vm.fromdate = e.date;
        vm.searchMeasurement();
      });

      $('#datetimepickerTo').on('dp.hide', function(e) {
        vm.todate = e.date;
        vm.searchMeasurement();
      });

      vm.selectedCategories = SessionService.getFilterOptions().searchOptionMeasurements.categories;
      vm.selectedFeelings = SessionService.getFilterOptions().searchOptionMeasurements.feelings;
      vm.selectedConfigurations = SessionService.getFilterOptions().searchOptionMeasurements.configurations;
      vm.measurementDaysInput = SessionService.getFilterOptions().searchOptionMeasurements.measurementDays;
      vm.flagFilterOption = SessionService.getFilterOptions().searchOptionMeasurements.flagFilterOption;
      vm.autorefresh = SessionService.getFilterOptions().searchOptionMeasurements.autorefresh;
      vm.searchPortalUserOption = SessionService.getFilterOptions().searchPortalUserOption;
      document.getElementById('datetimepickerFrom').value = SessionService.getFilterOptions().searchOptionMeasurements.fromdate;
      document.getElementById('datetimepickerTo').value = SessionService.getFilterOptions().searchOptionMeasurements.todate;

      getSearchFilterByPortalUser();

      if (vm.permissions.readMeasurementConfiguration) {
        NationService.getAvailableFilterMeasurementConfigurations().then(function(data) {
          vm.configurations = convertToDropDownBoxValues(data);
        });
      }
    }

    function convertToDropDownBoxValues(values) {
      const result = [];
      jQuery.grep(values, function(x) {
        result.push({id: x.id, label: x.name});
      });
      return result;
    }

    function getSearchFilterByPortalUser() {
      if (NationService.isUS()) {
        vm.searchPortalUserOptions = SessionService.getSearchFilterPortalUsers();
        if (vm.searchPortalUserOptions === null) {
          PatientService.getSearchFilterByPortalUser().then(function(response) {
            vm.searchPortalUserOptions = [{name: $translate.instant('user_searchoption_all'), value: ''}];
            let i = response.length;
            if (i == 0) {
              vm.searchMeasurement();
            } else {
              jQuery.grep(response, function(x) {
                i -= 1;
                vm.searchPortalUserOptions.push({name: x.staff.lastname + ', ' + x.staff.firstname, value: x.id});
                if (vm.searchPortalUserOption.value === '') {
                  // Show my patients default
                  if (x.id === NationService.loggedInUserId()) {
                    vm.searchPortalUserOption = vm.searchPortalUserOptions[vm.searchPortalUserOptions.length - 1];
                  }
                }
                SessionService.setSearchFilterPortalUsers(vm.searchPortalUserOptions);
                if (i == 0) {
                  vm.searchMeasurement();
                }
              });
            }
          });
        }
      }
    }

    vm.showPrescribingProfessional = function() {
      return NationService.isUS();
    };

    vm.isUS = function() {
      return NationService.isUS();
    };

    function isAbleToSearch() {
      if (vm.searchPortalUserOptions == null && NationService.isUS()) {
        return false;
      }
      return true;
    }

    vm.searchMeasurement = function() {
      if (_tableState !== null) {
        console.log('searchMeasurement...');
        _tableState.pagination.start = 0;
        vm.fetchMeasurements();
      }
    };

    vm.fetchMeasurements = function(tableState) {
      let formatedDate;
      let changedByUri = false;
      if (_tableState === null) {
        changedByUri = true;
      }
      if (tableState) {
        _tableState = tableState;
      }

      if (isAbleToSearch()) {
        let startItem = $stateParams.startItem;
        if (startItem !== '' && startItem !== undefined && startItem !== null && changedByUri) {
          _tableState.pagination.start = parseInt(startItem, 10);
        } else if (startItem !== undefined) {
          startItem = _tableState.pagination.start;
        }

        if (vm.startItem != startItem && !vm.expandMeasurements) {
          measurementWithPreviewImgIsLoaded = false;
        }
        vm.startItem = startItem;

        vm.filters = [];
        const fromInput = document.getElementById('datetimepickerFrom').value;
        const toInput = document.getElementById('datetimepickerTo').value;
        if (fromInput != '') {
          formatedDate = moment(fromInput, vm.formats.shortDatePicker);
          vm.filters.push({value: 'true', propertyKey: 'fromdate', propertyValue: formatedDate.format('YYYY-MM-DD')});
        }
        if (toInput != '') {
          formatedDate = moment(toInput, vm.formats.shortDatePicker);
          vm.filters.push({value: 'true', propertyKey: 'todate', propertyValue: formatedDate.format('YYYY-MM-DD')});
        }
        vm.currentCategories = '';
        if (vm.selectedCategories != null && vm.selectedCategories.length > 0) {
          vm.currentCategories = vm.selectedCategories.map(function(el) {
            return el.id;
          }).join(',');
          vm.filters.push({
            value: 'true', propertyKey: 'filtercategories', propertyValue: vm.selectedCategories.map(function(el) {
              return el.id;
            }).join(',')
          });
        }
        vm.currentFeelings = '';
        if (vm.selectedFeelings != null && vm.selectedFeelings.length > 0) {
          vm.currentFeelings = vm.selectedFeelings.map(function(el) {
            return el.id;
          }).join(',');
          vm.filters.push({
            value: 'true', propertyKey: 'filterfeelings', propertyValue: vm.selectedFeelings.map(function(el) {
              return el.id;
            }).join(',')
          });
        }
        vm.currentConfigurations = '';
        if (vm.selectedConfigurations != null && vm.selectedConfigurations.length > 0) {
          vm.currentConfigurations = vm.selectedConfigurations.map(function(el) {
            return el.id;
          }).join(',');
          vm.filters.push({
            value: 'true', propertyKey: 'filterconfigurations', propertyValue: vm.selectedConfigurations.map(function(el) {
              return el.id;
            }).join(',')
          });
        }
        if (vm.measurementDaysInput !== null && vm.measurementDaysInput !== '') {
          vm.filters.push({value: 'true', propertyKey: 'measurementdays', propertyValue: vm.measurementDaysInput});
        }
        if (vm.showPrescribingProfessional() && vm.searchPortalUserOption !== undefined && vm.searchPortalUserOption.value !== '') {
          vm.filters.push({value: 'true', propertyKey: 'filterPortalUserRelations', propertyValue: vm.searchPortalUserOption.value});
        }
        if (vm.flagFilterOption.length > 0) {
          let measurementFilterHasComment = false;
          let measurementFilterAlertFlag = false;
          let measurementFilterNotReviewedFlag = false;
          jQuery.grep(vm.flagFilterOption, function(x) {
            if (x.id == 1) {
              measurementFilterAlertFlag = true;
            }
            if (x.id == 2) {
              measurementFilterNotReviewedFlag = true;
            }
            if (x.id == 3) {
              measurementFilterHasComment = true;
            }
          });
          if (measurementFilterAlertFlag == true || measurementFilterNotReviewedFlag == true) {
            let result = measurementFilterAlertFlag == true ? 1 : 0;
            result = measurementFilterNotReviewedFlag == true ? (result | 2) : result;
            vm.filters.push({value: 'true', propertyKey: 'flagged', propertyValue: result});
          }
          if (measurementFilterHasComment == true) {
            vm.filters.push({value: 'true', propertyKey: 'hascomment', propertyValue: true});
          }
        }

        const filterOptions = SessionService.getFilterOptions();
        filterOptions.searchOptionMeasurements.categories = vm.selectedCategories;
        filterOptions.searchOptionMeasurements.feelings = vm.selectedFeelings;
        filterOptions.searchOptionMeasurements.configurations = vm.selectedConfigurations;
        filterOptions.searchOptionMeasurements.fromdate = fromInput;
        filterOptions.searchOptionMeasurements.todate = toInput;
        filterOptions.searchOptionMeasurements.flagFilterOption = vm.flagFilterOption;
        filterOptions.searchOptionMeasurements.measurementDays = vm.measurementDaysInput;
        filterOptions.searchOptionMeasurements.autorefresh = vm.autorefresh;
        filterOptions.searchPortalUserOption = vm.searchPortalUserOption;
        SessionService.setFilterOptions(filterOptions);

        pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);

        if (startItem !== undefined) {
          $state.go('main.patientmeasurementlist', {
            startItem: vm.startItem
          }, {notify: false});
        }

        updateMeasurementsTable();
      }
    };

    function updateMeasurementsTable() {
      vm.showSpinner = true;
      PatientService.getUsersMeasurements(pagingParameters, vm.expandMeasurements).then(function(data) {
        vm.measurements = data.rows;
        vm.nrOfMeasurements = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }

        if (vm.autorefresh) {
          interval = $interval(vm.searchMeasurement, refreshInterval, 1);
        }

        vm.showSpinner = false;
      });
    }

    vm.autoRefresh = function() {
      if (vm.autorefresh) {
        console.log('Set auto refresh');
        vm.searchMeasurement();
      } else if (interval !== null) {
        console.log('Cancel auto refresh');
        $interval.cancel(interval);
      }
    };

    vm.expandAll = function() {
      vm.expandMeasurements = !vm.expandMeasurements;
      if (!measurementWithPreviewImgIsLoaded) {
        updateMeasurementsTable();
        measurementWithPreviewImgIsLoaded = vm.expandMeasurements;
      }
    };

    vm.listOption = function() {
      if (vm.expandMeasurements) {
        return 'fa fa-list';
      } else {
        return 'fa fa-th';
      }
    };

    vm.getSasUrls = function(parts) {
      const urls = jQuery.grep(parts, function(x) {
        return x.ecgAnalisysImageUrl !== null;
      });
      return urls;
    };

    vm.getFlagColor = function(flagtype, flag) {
      if ((flag & 1) == 1 && flagtype == 'alert') {
        if ((flag & 2) == 2) {
          return {'color': 'red'};
        }
        return {'color': 'red', 'margin-left': '16px'};
      } else if ((flag & 2) == 2 && flagtype == 'reviewed') {
        return {'color': 'lightgreen'};
      }
      return {'color': 'white'};
    };

    vm.showFlag = function(flagtype, flag) {
      if ((flag & 1) == 1 && flagtype == 'alert') {
        return true;
      } else if ((flag & 2) == 2 && flagtype == 'reviewed') {
        return true;
      }
      return false;
    };

    vm.feelingBeforeMeasurementString = function(measurement) {
      return MeasurementHelperService.feelingBeforeMeasurementString(measurement);
    };

    vm.parseAppErrorCode = function(measurement) {
      return MeasurementHelperService.parseAppErrorCode(measurement);
    };

    vm.appStatus = function(measurement) {
      return MeasurementHelperService.appStatus(measurement);
    };

    vm.parsePulse = function(measurement) {
      return MeasurementHelperService.parsePulse(measurement);
    };

    vm.setMeasurementMarked = function(id) {
      const index = vm.markedmeasurments.indexOf(id);

      if (index < 0) {
        vm.markedmeasurments.push(id);
      } else {
        vm.markedmeasurments.splice(index, 1);
      }
    };

    vm.getMeasurementMarked = function(id) {
      return vm.markedmeasurments.indexOf(id) >= 0;
    };

    vm.isMarket = function() {
      return vm.markedmeasurments.length > 0;
    };

    vm.downloadMeasurementsFile = function() {
      vm.showSpinner = true;
      console.log('download pdf file for multiple measurements');
      PatientService.getChestAndFingerMeasurementsPdf('measurements_' + moment().format('YYYYMMDD_HHmm') + '.pdf', vm.markedmeasurments).then(function(data) {
        vm.showSpinner = false;
      }, function(reason) {
        vm.showSpinner = false;
        vm.showError($translate.instant('download_createpdffile_error'));
      });
    };

    vm.goToMeasurement = function(measurementId, userId) {
      $rootScope.measurements = undefined;
      $rootScope.nrOfMeasurements = 0;
      $rootScope.measurementsPagingParameters = pagingParameters;
      $rootScope.measurementlistPosition = null;
      $rootScope.measurementlistCount = 0;
      $rootScope.fromUri = 'main.patientmeasurementlist';

      $state.go('main.patientmeasurement', {
        measurementId: measurementId,
        userId: userId,
        startItem: vm.startItem
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
