import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminController', AdminController);

  function AdminController($scope, $filter, $stateParams, $rootScope, $translate, ModalService, HealthcareProviderService, SessionService, UtilService, NationService, envConfig) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    let _tableState = null;
    vm.showSpinner = false;

    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    $scope.toggle = function(id) {
      $scope.$broadcast('event:toggle', id);
    };

    function activate() {
      getAllHealthcareProviders();
    }

    vm.fetchStaffs = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = vm.itemsPerPage;
      updateStaffsTable();
    };

    function updateStaffsTable() {
      if (vm.permissions.readStaffUser) {
        vm.showSpinner = true;
        HealthcareProviderService.getAllDoctorsWithHelthcareProviders(pagingParameters).then(function(data) {
          vm.staffs = data.rows;
          vm.totalStaffs = data.nbrOfRows;
          vm.nrOfPages = data.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
          vm.showSpinner = false;
        });
      }
    }

    vm.createNewDoctorModal = function(isEditMode, item) {
      if (vm.permissions.writeStaffUser) {
        $rootScope.isEditMode = isEditMode;
        $rootScope.item = item;
        $rootScope.providers = $scope.providers;
        $rootScope.ust = function() {
          updateStaffsTable();
        };

        if (isEditMode) {
          ModalService.showModal({
            template: require('../views/modals/doctor.create.edit.modal.tpl.html').default,
            controller: 'AdminDoctorCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            const t = modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateStaffsTable();
              console.log(result);
            });
          });
        } else {
          ModalService.showModal({
            template: require('../views/modals/doctor.create.edit.modal.tpl.html').default,
            controller: 'AdminDoctorCreateEditController',
            controllerAs: 'vm',
          }).then(function(modal) {
            modal.element.modal();
            modal.close.then(function(result) {
              $('.modal-backdrop').hide(); // for black background
              updateStaffsTable();
              console.log(result);
            });
          });
        }
      }
    };

    function getAllHealthcareProviders() {
      HealthcareProviderService.getAllHealthcareProviders().then(function(response) {
        $rootScope.providers = response;
        vm.providers = response;
      });
    }

    vm.showProviderFilter = function() {
      return vm.providers !== undefined && vm.providers.length > 1;
    };

    vm.hasUnrestrictedRoles = function(item) {
      return item.unrestrictedRoles !== null && item.unrestrictedRoles.length > 0;
    };

    vm.hasGroupdRoles = function(item) {
      return item.healthcareProviderGroups !== null && item.healthcareProviderGroups.length > 0;
    };

    vm.hasHealthcareProviderRoles = function(item) {
      return item.healthcareProviders !== null && item.healthcareProviders.length > 0;
    };

    vm.shouldShowBankId = function(item) {
      if (item.allowedLoginMethods === 'bankId' || item.allowedLoginMethods === 'passwordAndBankId') {
        return true;
      }
      return false;
    };

    vm.shouldShowPassword = function(item) {
      if (item.allowedLoginMethods === 'password' || item.allowedLoginMethods === 'passwordAndBankId') {
        return true;
      }
      return false;
    };

    vm.truncate = function(providers, stringLength) {
      if (providers.length > 1) {
        return '...';
      } else if (providers.length == 1) {
        if (providers[0].length > stringLength) {
          return providers[0].name.substring(0, stringLength - 2) + '...';
        }
        return providers[0].name;
      }
      return '-';
    };
  }
})();
