import * as angular from 'angular';
import * as moment from 'moment';
import {detectIE, findCapturedLocalTime, findRawUrl} from "../../../util";
import '../../../scripts/plot/jquery.flot';
import '../../../scripts/plot/jquery.flot.navigate';
import '../../../scripts/plot/flot.hbars';
import '../../../scripts/plot/flot.annotate';
import '../../../scripts/plot/flot.touch';
import * as viewer from '../../../scripts/plot/viewer';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminMeasurementDetailController', AdminMeasurementDetailController);

  function AdminMeasurementDetailController($stateParams, $rootScope, $state, $window, $translate, HealthcareProviderService, PatientService, NationService) {
    const vm = this;
    vm.formats = NationService.getDateFormats();
    const catGroup = {};
    const commentGroup = {};
    const startTab = 'finger';

    let chestPlot = null;
    let fingerPlot = null;
    let currentPlotType = '';
    vm.chestLoaded = false;
    vm.fingerLoaded = false;
    vm.soundLoaded = false;
    vm.isPlaying = false;
    vm.soundUrl = null;
    vm.yZoomLevelOptions = [{name: '± 0.5 mV', value: 0.5}, {name: '± 1 mV', value: 1}, {name: '± 2 mV', value: 2}, {name: '± 5 mV', value: 5}];
    vm.yZoomLevelChest = vm.yZoomLevelOptions[1];
    vm.yZoomLevelFinger = vm.yZoomLevelOptions[2];
    vm.graphInvertedChest = false;
    vm.graphInvertedFinger = false;
    vm.showSpinner = false;
    vm.startItem = 0;

    activate();

    function activate() {
      const measurementId = $stateParams.measurementId;
      vm.startItem = $stateParams.startItem;

      vm.measurementId = measurementId;
      vm.feelingBeforeMeasurement = '';

      vm.isIE = detectIE();

      HealthcareProviderService.getAdminMeasurement(measurementId).then(function(data) {
        if (data.feelingBeforeMeasurement === 'good') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_good');
        } else if (data.feelingBeforeMeasurement === 'stressed') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_stressed');
        } else if (data.feelingBeforeMeasurement === 'anxious') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_anxious');
        } else if (data.feelingBeforeMeasurement === 'chestpain') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_chestpain');
        } else if (data.feelingBeforeMeasurement === 'rapidHeartRate') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_rapidHeartRate');
        } else if (data.feelingBeforeMeasurement === 'dizziness') {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_dizziness');
        } else if (data.feelingBeforeMeasurement === 'other') {
          vm.feelingBeforeMeasurement = data.feelingBeforeMeasurementFreetext;
        } else {
          vm.feelingBeforeMeasurement = $translate.instant('measurement_feeling_noinfo');
        }
        vm.measurement = data;

        vm.soundUrl = findRawUrl(data.measurementParts, 'heartSound');
        if (!vm.isIE) {
          const audio = $('.audioDemo');
          audio.attr('src', vm.soundUrl);
        }

        vm.rawChestUrl = findRawUrl(data.measurementParts, 'ecgChest');
        vm.rawFingerUrl = findRawUrl(data.measurementParts, 'ecgFinger');

        vm.soundDownloadName = new moment(findCapturedLocalTime(data.measurementParts, 'heartSound')).format('YYYYMMDD_HHmm') + '_heartSound.wav';
        vm.chestDownloadName = new moment(findCapturedLocalTime(data.measurementParts, 'ecgChest')).format('YYYYMMDD_HHmm') + '_chestECG.data';
        vm.fingerDownloadName = new moment(findCapturedLocalTime(data.measurementParts, 'ecgFinger')).format('YYYYMMDD_HHmm') + '_thumbECG.data';
      });

      currentPlotType = startTab;
      setTab(startTab);
    }

    vm.playIESound = function() {
      let audio;
      if (!vm.isPlaying) {
        audio = $('.audioDemo');
        audio.attr('src', vm.soundUrl);
        vm.isPlaying = true;
      } else if (vm.isPlaying) {
        audio = $('.audioDemo');
        audio.attr('src', '');
        vm.isPlaying = false;
      }
    };

    vm.soundUrlExists = function() {
      return vm.soundUrl !== null;
    };

    vm.soundUrlDoesNotExists = function() {
      return vm.soundUrl === null;
    };

    vm.showDownloadRawChestData = function() {
      return vm.rawChestUrl !== null;
    };

    vm.showDownloadRawFingerData = function() {
      return vm.rawFingerUrl !== null;
    };

    vm.downloadPdf = function(type) {
      vm.showSpinner = true;
      let measurementType = 'EcgChestAndFinger';
      if (type) {
        if (type === 'finger') {
          measurementType = 'EcgFinger';
        } else if (type === 'chest') {
          measurementType = 'EcgChest';
        }
      }
      console.log('download pdf: ' + measurementType);
      if (measurementType === 'EcgChestAndFinger') {
        PatientService.getChestAndFingerMeasurementPdf(
          'Measurement_' + new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm') + '.pdf',
          vm.measurementId,
          null,
          '0',
          'twentyFive',
          vm.getmVPerSecString(vm.yZoomLevelChest.value),
          'twentyFive',
          vm.getmVPerSecString(vm.yZoomLevelFinger.value),
          'annotationsAndHBars',
          'annotationsAndHBars',
          vm.graphInvertedChest,
          vm.graphInvertedFinger,
          false).then(function(data) {
          vm.showSpinner = false;
        }, function(reason) {
          vm.showSpinner = false;
          vm.showError($translate.instant('download_pdffile_error'));
        });
      } else {
        let yLevel = 0;
        if (currentPlotType === 'finger') {
          yLevel = vm.yZoomLevelFinger.value;
        } else {
          yLevel = vm.yZoomLevelChest.value;
        }
        PatientService.getMeasurementPdf('Measurement_' + new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm') + '.pdf', vm.measurementId, measurementType,
          null, '0', 'twentyFive', vm.getmVPerSecString(yLevel), 'annotationsAndHBars', vm.graphInvertedChest, vm.graphInvertedFinger).then(function(data) {
          vm.showSpinner = false;
        }, function(reason) {
          vm.showSpinner = false;
          vm.showError($translate.instant('download_pdffile_error'));
        });
      }
    };

    vm.getmVPerSecString = function(mVPerSecNumber) {
      let mVPerSecString = 'ten';
      if (mVPerSecNumber == 0.5) {
        mVPerSecString = 'fourty';
      } else if (mVPerSecNumber == 1) {
        mVPerSecString = 'twenty';
      } else if (mVPerSecNumber == 2) {
        mVPerSecString = 'ten';
      } else if (mVPerSecNumber == 5) {
        mVPerSecString = 'four';
      }
      return mVPerSecString;
    };

    vm.downloadFile = function(type) {
      vm.showSpinner = true;
      const measurementType = type === 'finger' ? 'ecgFinger' : type;
      let suffix = 'data';
      if (measurementType === 'HeartSound') {
        suffix = 'wav';
      }

      console.log('download file: ' + measurementType);
      PatientService.getMeasurementFile('Measurement_' + new moment(vm.measurement.capturedLocalTime).format('YYYYMMDD_HHmm_') + measurementType + '.' +
        suffix, vm.measurementId, measurementType).then(function(data) {
        vm.showSpinner = false;
      }, function(reason) {
        vm.showSpinner = false;
        vm.showError($translate.instant('download_file_error'));
      });
    };

    vm.group = function(group, type) {
      if (catGroup[type] == null) {
        return 'ecgResultCircle disabled';
      }

      if (group === 3) {
        const num = catGroup[type];

        if (num >= 3) {
          return 'ecgResultCircle';
        } else {
          return 'ecgResultCircle disabled';
        }
      }

      return group == catGroup[type] ? 'ecgResultCircle' : 'ecgResultCircle disabled';
    };

    vm.toggleTab = function(type) {
      currentPlotType = type;
      setTab(type);
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none') + ' - ';
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic') + ' - ';
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium') + ' - ';
      }

      return '';
    };

    function setTab(type) {
      let measurementType = 'ecgFinger';
      if (type === 'chest') {
        measurementType = 'ecgChest';
      } else if (type === 'sound') {
        measurementType = 'pcgAnalysisImage';
      }

      if ((type === 'finger' && !vm.fingerLoaded) || (type === 'chest' && !vm.chestLoaded)) {
        vm.showSpinner = true;
        PatientService.getMeasurementData(vm.userId, vm.measurementId, measurementType, '').then(function(data) {
          vm.measurementData = data;

          if (data.avBeatInfo == null || data.avBeatInfo.length == 0) {
            setErrorMessage(type, $translate.instant('measurement_error_1'));
          }

          const plot = viewer.show_ecg(data, type, $translate);

          if (type === 'finger') {
            fingerPlot = plot;
          } else {
            chestPlot = plot;
          }

          $('#zoomIn-' + type).click(function() {
            plot.zoom();
          });

          $('#zoomOut-' + type).click(function() {
            plot.zoomOut();
          });

          vm.yZoom();
          const segments = data['segments'][0];
          catGroup[type] = segments['info'][0];

          if (type === 'chest') {
            vm.chestLoaded = true;
          } else {
            vm.fingerLoaded = true;
          }
          vm.showSpinner = false;
        }, function(error) {
          console.log('error: ' + error);
          if (type === 'chest') {
            if (error === '') {
              vm.errorMessageChest = $translate.instant('measurement_error_2');
            } else {
              vm.errorMessageChest = $translate.instant('measurement_error_3');
            }
            vm.showSpinner = false;
            vm.showErrorMessageChest = true;
          } else {
            if (error === '') {
              vm.errorMessageFinger = $translate.instant('measurement_error_2');
            } else {
              vm.errorMessageFinger = $translate.instant('measurement_error_3');
            }
            vm.showSpinner = false;
            vm.showErrorMessageFinger = true;
          }
        });
      } else if (type === 'sound' && !vm.soundLoaded) {
        vm.showSpinner = true;
        PatientService.getPCGAnalysisImage(vm.measurementId).then(function(data) {
          $('#pcgAnalysis').attr('src', 'data:image/png;base64,' + data);
          vm.soundLoaded = true;
          vm.showSpinner = false;
        }, function(error) {
          if (error === '') {
            vm.errorMessageSound = $translate.instant('measurement_error_4');
          } else {
            vm.errorMessageSound = $translate.instant('measurement_error_5');
          }
          vm.showSpinner = false;
          vm.showErrorMessageSound = true;
        });
      }
    }

    vm.yZoom = function() {
      let yLevel = 0;
      if (currentPlotType === 'finger') {
        yLevel = vm.yZoomLevelFinger.value;
      } else {
        yLevel = vm.yZoomLevelChest.value;
      }
      const plot = currentPlotType === 'finger' ? fingerPlot : chestPlot;
      if (plot !== null) {
        const axes_data = plot.getAxes();
        axes_data.yaxis.options.min = yLevel * -1;
        axes_data.yaxis.options.max = yLevel;
        if (yLevel === 0.5) {
          axes_data.yaxis.options.tickSize = 0.1;
        } else if (yLevel === 1) {
          axes_data.yaxis.options.tickSize = 0.5;
        } else {
          axes_data.yaxis.options.tickSize = 1;
        }
        plot.setupGrid();
        plot.draw();
      }
    };

    vm.goBack = function() {
      $state.go('mainAdmin.measurement', {
        startItem: vm.startItem
      });
    };

    vm.toggleInvertGraph = function() {
      console.log('invert graph');
      const plot = currentPlotType === 'finger' ? fingerPlot : chestPlot;
      const obj = plot.getData();
      const data = obj[0].data;

      for (let i = 0; i < data.length; i += 1) {
        data[i][1] = data[i][1] * (-1);
      }

      obj[0].data = data;
      plot.setData(obj);
      plot.draw();
    };

    vm.parseApproxDateTime = function(approxDateTime) {
      let dateTime = new Date(approxDateTime);
      const hours = 2 * 60 * 60 * 1000;
      dateTime = dateTime.getTime() + hours;
      return new Date(dateTime);
    };

    vm.gender = function() {
      if (vm.measurement === undefined || vm.measurement.gender === null) {
        return 'fa';
      } else {
        return vm.measurement.gender === 'female' ? 'fa fa-venus' : 'fa fa-mars';
      }
    };

    vm.age = function() {
      if (vm.measurement !== undefined && vm.measurement !== undefined) {
        if (vm.measurement.ageApprox >= 0 && vm.measurement.ageApprox < 10) {
          return '0 - 10 years';
        } else if (vm.measurement.ageApprox >= 10 && vm.measurement.ageApprox < 20) {
          return '10 - 20 years';
        } else if (vm.measurement.ageApprox >= 20 && vm.measurement.ageApprox < 30) {
          return '20 - 30 years';
        } else if (vm.measurement.ageApprox >= 30 && vm.measurement.ageApprox < 40) {
          return '30 - 40 years';
        } else if (vm.measurement.ageApprox >= 40 && vm.measurement.ageApprox < 50) {
          return '40 - 50 years';
        } else if (vm.measurement.ageApprox >= 50 && vm.measurement.ageApprox < 60) {
          return '50 - 60 years';
        } else if (vm.measurement.ageApprox >= 60 && vm.measurement.ageApprox < 70) {
          return '60 - 70 years';
        } else if (vm.measurement.ageApprox >= 70 && vm.measurement.ageApprox < 80) {
          return '70 - 80 years';
        } else if (vm.measurement.ageApprox >= 80 && vm.measurement.ageApprox < 90) {
          return '80 - 90 years';
        } else if (vm.measurement.ageApprox >= 90 && vm.measurement.ageApprox < 100) {
          return '90 - 100 years';
        } else if (vm.measurement.ageApprox >= 100 && vm.measurement.ageApprox < 1100) {
          return '100 - 110 years';
        } else if (vm.measurement.ageApprox >= 110 && vm.measurement.ageApprox < 120) {
          return '110 - 120 years';
        } else if (vm.measurement.ageApprox >= 120) {
          return 'over 120 years';
        }
      }
      return 'Unknown age';
    };

    function setErrorMessage(type, message, error) {
      if (error === '') {
        message = 'There is no ECG available.';
      }

      if (type === 'finger') {
        vm.hideFingerSpinner = vm.showErrorMessageFinger = true;
        vm.errorMessageFinger = message;

      } else {
        vm.hideChestSpinner = vm.showErrorMessageChest = true;
        vm.errorMessageChest = message;
      }
    }
  }
})();
