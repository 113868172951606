import {UserRegisterRequest, UserUpdateRequest} from '../services/models/UserRequest';
import * as moment from 'moment';
import {LoginMethodOption} from '../services/NationService';

export interface ScopeUserDetails {
    relationshipStatus: string;
    acceptedTo: any;
    requestSent: any;
    id: string;
    validFrom: string;
    validTo: any;
    userId: string;
    email: string;
    testUser: boolean;
    borrowedMonitor: Date;
    hasBorrowedMonitor: boolean;
    nation: string;
    language: string;
    paperFormat: string;
    socialSecurityNumber: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    dateOfBirth: string;
    patientIdentificationNo?: string;
}

export class User {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    password?: string;
    confirmPassword?: string;
    patientIdentificationNo?: string;
    allowedLoginMethod: LoginMethodOption;
    dateOfBirth?: string;
    testUser = false;
    measurementConfigurationGroupIds: string[] = [];
    healthcareProvider?: {name: string; id: string};
    hasBorrowedMonitor = false;
    borrowedMonitorDate?: Date;
    updatedBorrowedMonitor = false;
    acceptedTo?: any;
    dateOfBirthFormat?: string;
    birthValidationRegEx?: RegExp;
    pnrValidationRegEx?: RegExp;

    constructor(allowedLoginMethod: LoginMethodOption, dateOfBirthFormat: string, birthValidationRegEx: string, pnrValidationRegEx: string, userDetails?: ScopeUserDetails) {
        this.allowedLoginMethod = allowedLoginMethod;
        this.dateOfBirthFormat = dateOfBirthFormat != '' ? dateOfBirthFormat : undefined;
        this.birthValidationRegEx = birthValidationRegEx != '' ? new RegExp(birthValidationRegEx) : undefined;
        this.pnrValidationRegEx = pnrValidationRegEx != '' ? new RegExp(pnrValidationRegEx) : undefined;

        if (userDetails) {
            this.initFromScope(userDetails);
        }
    }

    public initFromScope(userDetails: ScopeUserDetails) {
        this.id = userDetails.userId;
        this.firstName = userDetails.firstname;
        this.lastName = userDetails.lastname;
        this.testUser = userDetails.testUser;
        this.borrowedMonitorDate = userDetails.borrowedMonitor;
        this.email = userDetails.email;
        this.phoneNumber = userDetails.phoneNumber;
        this.dateOfBirth = moment(userDetails.dateOfBirth).format(this.dateOfBirthFormat);
        this.acceptedTo = userDetails.acceptedTo;
        this.hasBorrowedMonitor = userDetails.hasBorrowedMonitor;
        this.patientIdentificationNo = userDetails.patientIdentificationNo;
    }

    setBorrowedMonitor = () => {
        this.updatedBorrowedMonitor = true;
        if (!this.borrowedMonitorDate) {
            this.borrowedMonitorDate = new Date();
        } else {
            this.borrowedMonitorDate = undefined;
        }
    }

    getDateOfBirth(): string {
        let result;
        if (this.dateOfBirthFormat) {
            const date = moment(this.dateOfBirth, this.dateOfBirthFormat);
            result = date.format('YYYY-MM-DD');
            return result;
        } else if (this.patientIdentificationNo) {
            const date = moment(this.patientIdentificationNo, 'YYYYMMDD');
            result = date.format('YYYY-MM-DD');
            return result;
        }
        return '';
    }

    getRegisterPayload(): UserRegisterRequest {
        return {
            firstName: this.firstName!,
            lastName: this.lastName!,
            email: this.email!,
            phoneNumber: this.phoneNumber!,
            patientIdentificationNo: this.patientIdentificationNo,
            dateOfBirth: this.getDateOfBirth(),
            password: this.password!,
            confirmPassword: this.confirmPassword!,
            measurementConfigurationGroupIds: this.measurementConfigurationGroupIds,
            testUser: this.testUser,
            allowedLoginMethods: this.allowedLoginMethod!.value,
            healthcareProviderId: this.healthcareProvider?.id
        };
    }

    getUpdatePayload(): UserUpdateRequest {
        return {
            firstname: this.firstName!,
            lastname: this.lastName!,
            phoneNumber: this.phoneNumber!,
            patientIdentificationNo: this.patientIdentificationNo,
            dateOfBirth: this.getDateOfBirth(),
            testUser: this.testUser,
            // Only posts a new date for borrowed monitor if the value has changed, otherwise undefined
            borrowedMonitor: (this.updatedBorrowedMonitor ? this.borrowedMonitorDate ? this.borrowedMonitorDate.toISOString() : '' : undefined)
        };
    }
}
