import * as jQuery from "jquery";

export function findRawUrl(measurementParts, type, configurationId) {
  const rawData = jQuery.grep(measurementParts, function(x) {
    return x.measurementPartConfigurationId === configurationId && x.measurementPartType === type;
  });

  return rawData.length > 0 ? rawData[0].sasRawDirectLink : null;
}

export function findCapturedLocalTime(measurementParts, configurationId) {
  const rawData = jQuery.grep(measurementParts, function(x) {
    return x.measurementPartConfigurationId === configurationId;
  });

  return rawData.length > 0 ? rawData[0].capturedLocalTime : null;
}

export function detectIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return true;
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    const rv = ua.indexOf('rv:');
    return true;
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return true;
  }

  // other browser
  return false;
}
