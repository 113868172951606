import * as angular from 'angular';

(function() {
  'use strict';

  function MeService($q, $http, envConfig, SessionService, HttpClientService) {
    const service = {};
    const root = envConfig.baseUrl;

    service.register = function(user) {
      return HttpClientService.postWithoutAuthToken(root + '/api/v1/account/registerfrompayment', user, 'application/json');
    };

    service.createKlarnaCheckout = function(request) {
      if (request.actionType == 'None') {
        return HttpClientService.post(root + '/api/v1/klarnapayments/orders', request);
      } else {
        return HttpClientService.post(root + '/api/v1/klarnapayments/updatepaymentinfo', request);
      }
    };

    service.updateSubscriptionWithToken = function(request) {
      return HttpClientService.post(root + '/api/v1/klarnapayments/updatesubscription', request);
    };

    service.getKlarnaOrder = function(orderId) {
      return HttpClientService.get(root + '/api/v1/klarnapayments/orders/' + orderId);
    };

    service.getUpdatePaymentInfo = function() {
      return HttpClientService.get(root + '/api/v1/klarnapayments/updatepaymentinfo');
    };

    service.getUserDetails = function() {
      return HttpClientService.get(root + '/api/v1/userprofile');
    };

    service.getProducts = function(subscriptionType) {
      return HttpClientService.get(root + '/api/v1/payments/products?type=Update&subscriptionType=' + subscriptionType);
    };

    service.getUserSubscription = function() {
      return HttpClientService.get(root + '/api/v1/subscriptions');
    };

    service.cancelSubscription = function(cancelled) {
      return HttpClientService.put(root + '/api/v1/subscriptions/cancelled/' + cancelled);
    };

    service.deletePayment = function(paymentId) {
      return HttpClientService.delete(root + '/api/v1/klarnapayments/payments/' + paymentId);
    };

    service.getPaymentInfo = function(request) {
      return HttpClientService.get(root + '/api/v1/payments/info?productId=' + request.productId + '&voucher=' + request.voucher);
    };

    service.getActiveRecurrentPayments = function(request) {
      return HttpClientService.get(root + '/api/v1/payments/recurrent');
    };

    service.checkVoucher = function(productId, voucher) {
      return HttpClientService.get(root + '/api/v1/payments/products/' + productId + '/voucher/' + voucher);
    };

    service.checkPaymentVoucher = function(voucher, type) {
      return HttpClientService.getWithoutAuthToken(root + '/api/v1/payments/paymentvoucher/' + voucher + '?type=' + type);
    };

    service.usePaymentVoucher = function(request) {
      return HttpClientService.post(root + '/api/v1/payments/paymentvoucher', request);
    };

    return service;
  }

  angular
    .module('patients')
    .factory('MeService', MeService);

})();
