import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminPaymentController', AdminPaymentController);

  function AdminPaymentController($state, $rootScope, $scope, $filter, $translate, $location, PatientService, envConfig, UtilService) {
    const vm = this;
    let _tableState = null;
    vm.paymentOrderFound = true;
    vm.serialNumberFound = false;
    vm.paymentOrderAlreadyAdded = false;
    vm.itemsPerPage = 20;
    vm.nrOfPages = 0;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    vm.fetchPayments = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);
      pagingParameters.pageSize = 20;
      pagingParameters.search = vm.input;

      updatePaymentsTable();
    };

    function updatePaymentsTable() {
      PatientService.getPayments(pagingParameters).then(function(data) {
        vm.payments = data.rows;
        vm.totalPayments = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }
      });
    }

    vm.clearSearch = function() {
      vm.input = '';
      vm.fetchPayments();
    };

    vm.getNotRegisteredColor = function(registered) {
      if (!registered) {
        return {'color': 'red'};
      }
      return null;
    };

    vm.goToText = function(payment) {
      if (payment.registered) {
        if (payment.paymentMethod !== 'klarna') {
          return 'To Mondido';
        }
      } else {
        return 'Register';
      }
    };

    vm.goTo = function(payment) {
      if (payment.registered) {
        if (payment.paymentMethod !== 'klarna') {
          goToMondido(payment.transactionId);
        }
      } else {
        goToRegister(payment.customerRef);
      }
    };

    function goToMondido(transactionId) {
      window.open(
        (transactionId > 0 ? ('https://admin.mondido.com/sv/transactions/' + transactionId) : 'https://admin.mondido.com/sv/customers/'),
        '_blank'
      );
    }

    function goToRegister(customerRef) {
      $state.go('me.registration', {
        email: customerRef
      });
    }

    vm.goToUser = function(userId) {
      $state.go('mainAdmin.userdetails', {
        userId: userId
      });
    };

    vm.showAddOrderButton = function() {
      return vm.paymentOrderFound && !vm.paymentOrderAlreadyAdded && !vm.showSpinner;
    };

    vm.addKlarnaOrderId = function() {
      vm.showSpinner = true;
      PatientService.confirmKlarnaOrder(vm.orderId).then(function(response) {
        vm.showSpinner = false;
        vm.showFeedback($translate.instant('payment_order_success'));
        updatePaymentsTable();
        $('#addPaymentModal').modal('hide');
      }, function(response) {
        vm.showSpinner = false;
        vm.showError($translate.instant('payment_order_error'));
      });
    };

    vm.checkKlarnaOrderId = function() {
      vm.paymentOrderFound = true;
      vm.paymentOrderAlreadyAdded = false;
      vm.showSpinner = true;
      PatientService.findKlarnaOrder(vm.orderId).then(function(response) {
        vm.showSpinner = false;
        if (response == 400 || response == 404) {
          vm.paymentOrderFound = false;
        } else if (response == 302) {
          vm.paymentOrderAlreadyAdded = true;
        }

      }, function(response) {
        vm.showSpinner = false;
      });
    };

    vm.addSerialNumber = function() {
      vm.showSpinner = true;
      PatientService.addSerialNumber(vm.serialnumbers).then(function(response) {
        vm.showSpinner = false;
        vm.serialnumber = null;
        vm.serialNumberFound = false;
        vm.showFeedback($translate.instant('payment_serialnumbers_success'));
        updatePaymentsTable();
        $('#serialnumberModal').modal('hide');
      }, function(response) {
        vm.showSpinner = false;
        vm.showError($translate.instant('payment_serialnumbers_error'));
      });
    };

    vm.checkSerialNumber = function() {
      vm.serialNumberFound = false;
      if (vm.serialnumber.length >= 17) {
        vm.showSpinner = true;
        PatientService.findSerialNumber(vm.serialnumber).then(function(response) {
          vm.serialNumberFound = response;
          vm.showSpinner = false;
        });
      }
    };


    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

  }
})();
