import * as angular from 'angular';
import {ReportTimeService} from '../../../services/ReportTimeService';

// language=HTML
export const template = `
    <div class="patient-timer">
    
        <span class="darkBlueFont"><i class="far fa-clock"></i> {{$ctrl.getTimeSpent()}} <i ng-click="$ctrl.toogleTimer()" ng-class="$ctrl.getTimerStatusClass()"></i></span>
    </div>
`;

angular.module('patients')
    .component('patientTimeSpent',
        {
            bindings: {
                userId: '<'
            },
            template: template,
            controller: ['$translate', 'ReportTimeService', class patientTimeSpent {
                userId!: any;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly reportTimeService: ReportTimeService;
                // public timer!: string;
                private started!: boolean;

                constructor($translate: angular.translate.ITranslateService, reportTimeService: ReportTimeService) {
                    this.translateService = $translate;
                    this.reportTimeService = reportTimeService;
                    if (this.userId !== null) {
                        this.reportTimeService.startReportTime(this.userId);
                        this.started = true;
                    }
                }

                getTimeSpent = () => {
                    return this.reportTimeService.getPatientTotalTimeSpentThisMonth();
                };

                toogleTimer() {
                    this.started = !this.started;
                    if (this.started) {
                        this.reportTimeService.startReportTime(this.userId);
                    } else {
                        this.reportTimeService.stopReportTime(this.userId);
                    }
                }

                getTimerStatusClass() {
                    if (this.started) {
                        return "fa fa-pause";
                    }
                    return "fa fa-play";
                }
            }]
        }
    );
