import * as angular from 'angular';

(function() {
  'use strict';

  function ReportHelperService($translate, $filter, PatientService, NationService) {
    const service = {};
    const formats = NationService.getDateFormats();

    service.reportDescription = function(report) {
      let desc = 'Report';
      if (
        report.reportType.id == 0 /* Summery report v1*/ ||
        report.reportType.id == 3 /* Summery report v2*/ ||
        report.reportType.id == 5 /* Summery report v3*/
      ) {
        let name = $translate.instant("report_monitoring_name");
        if (report.reportCreatorTypeId === 'manualGenerated') {
          name = $translate.instant("report_manual_name");
        } else if (report.reportCreatorTypeId === 'scheduledAutomaticGenerated') {
          name = $translate.instant("report_rpm_name");
        }

        if (report.reportData.length > 0) {
          const fromDate = jQuery.grep(report.reportData, function(x) {
            return x.fieldName === 'FromDate';
          })[0].fieldData;
          const toDate = jQuery.grep(report.reportData, function(x) {
            return x.fieldName === 'ToDate';
          })[0].fieldData;
          const from = $filter("date")(new Date(fromDate), formats.shortDate);
          const to = $filter("date")(new Date(toDate), formats.shortDate);
          desc = name + " " + from + " - " + to;
        } else {
          desc = name;
        }
      }

      return desc;
    };

    service.reportPatient = function(report) {
      let desc = '';
      let firstname = jQuery.grep(report.reportData, function(x) {
        return x.fieldName === 'Firstname';
      });
      let lastname = jQuery.grep(report.reportData, function(x) {
        return x.fieldName === 'Lastname';
      });

      if (firstname.length == 1) {
        firstname = firstname[0].fieldData;
        lastname = lastname[0].fieldData;
        desc = lastname + ', ' + firstname;
      }

      return desc;
    };

    service.reportCreatedBy = function(report) {
      return report.createdByLastName + ', ' + report.createdByFirstName;
    };

    service.showDownloadLink = function(report) {
      if (report.storagePath !== null) {
        return true;
      }
      return false;
    };

    service.reportIsNotViewed = function(report) {
      if (!isViewed(report) && report.reportGeneratorStatus.id === 2) {
        return true;
      }
      return false;
    };

    service.reportIsViewed = function(report) {
      if (isViewed(report) && report.reportGeneratorStatus.id === 2) {
        return true;
      }
      return false;
    };

    function isViewed(report) {
      if (report.viewed) {
        return true;
      }
      const result = jQuery.grep(report.reportViewStatus, function(x) {
        return x.viewed;
      });
      return result.length > 0;
    }

    service.setToViewed = function(reportId, storagePath, reports) {
      PatientService.reportIsViewed(reportId).then(function() {
        jQuery.grep(reports, function(x) {
          return x.id == reportId;
        })[0].viewed = true;
        window.open(storagePath, '_self');
      });
    };

    service.reportGeneratorFailed = function(report) {
      if (report.reportGeneratorStatus.id === 1) {
        return true;
      }
      return false;
    };

    return service;
  }

  angular
    .module('core')
    .factory('ReportHelperService', ReportHelperService);

})();
