import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientSendMessageController', PatientSendMessageController);

  function PatientSendMessageController($rootScope, $translate, MessageService, envConfig, SessionService) {
    const vm = this;

    activate();
    vm.userDetails = $rootScope.userDetails;

    function activate() {

    }

    vm.sendMessage = function() {
      const userId = vm.userDetails.userId;
      const obj = {
        'userId': userId,
        'subject': vm.subject,
        'language': '',
        'content': vm.message,
        'messageType': 'text',
        'entityId': '',
      };

      MessageService.sendMessage(obj).then(function(response) {
        vm.showFeedback($translate.instant('sendmessage_sent'));
      });
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

  }
})();
