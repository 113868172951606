import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('RegistrationController', RegistrationController);

  function RegistrationController($state, $translate, $scope, $filter, $location, MeService, SessionService, AuthService, NationService) {
    const vm = this;
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.emailExists = false;
    vm.IdentificationNoExists = false;
    vm.activationCodeError = false;
    vm.passwordErrorText = '';
    let monitor = null;
    let email = null;
    let ordernumber = null;
    vm.paymentVoucherInfo = null;
    let voucherId = null;

    activate();

    function activate() {
      monitor = $location.search().monitor;
      email = $location.search().email;
      ordernumber = $location.search().ordernumber;
      if (email !== null) {
        vm.email = email;
      }
      if (ordernumber !== null) {
        vm.paymentvoucher = ordernumber;
        // monitor = false;
        checkPaymentVoucher();
      }
    }

    vm.checkPaymentCallback = function() {
      const paymentStatus = $location.search().payment_status;

      if (paymentStatus == 'success') {
        // vm.showFeedback($translate.instant('payment_success'));
      } else if (paymentStatus == 'error') {
        vm.showError($translate.instant('payment_error'));
      }
    };

    vm.savePatient = function() {
      vm.showSpinner = true;
      const user = {
        firstName: vm.firstname,
        lastName: vm.lastname,
        email: vm.email,
        password: vm.password,
        confirmPassword: vm.confirmPassword,
        patientIdentificationNo: vm.patientIdentificationNo,
        address: vm.address,
        zipcode: vm.zipcode,
        city: vm.city,
        phone: vm.phone,
        voucherId: voucherId,
        dateOfBirth: getDateOfBirth(),
        language: 'sv',
        nationId: 752,
        coalaOrderNumber: ordernumber
      };

      vm.emailExists = false;
      vm.IdentificationNoExists = false;
      vm.passwordError = false;
      MeService.register(user).then(function(response) {
        window.location.href = 'https://www.coalalife.com/din-coala-ar-pa-vag/';
        // vm.showFeedback($translate.instant('user_created'));
        // The user should be logged in and redirected to myprofile
        // login();
      }, function(error) {
        let errorText = '';
        for (const key in error.modelState) {
          if (key == 'registerUserRequest.Password') {
            $scope.createPatientForm.password.$setValidity('', false);
            errorText = error.modelState['registerUserRequest.Password'];
            vm.passwordErrorText = errorText[0];
            vm.passwordError = true;
          }
          if (key == 'registerUserRequest.ConfirmPassword') {
            $scope.createPatientForm.confirmPassword.$setValidity('', false);
            errorText = error.modelState['registerUserRequest.ConfirmPassword'];
            vm.passwordErrorText = errorText[0];
            vm.passwordError = true;
          }
          if (key == 'registerUserRequest.Email') {
            vm.emailExists = true;
          }
          if (key == 'registerUserRequest.PatientIdentificationNo') {
            vm.IdentificationNoExists = true;
          }
          if (key == 'registerUserRequest.Voucher' || key == 'registerUserRequest.CoalaOrderNumber') {
            vm.activationCodeError = true;
          }
        }
        vm.showSpinner = false;
        vm.showError($translate.instant('user_created_error') + ' ' + errorText);
      });
    };

    function getDateOfBirth() {
      // Get it from the patientIdentificationId (TODO validate that it is date?)
      const date = vm.patientIdentificationNo;
      const tmpdate = date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
      const result = $filter('date')(tmpdate, 'yyyy-MM-dd');
      console.log(result);
      return result;
    }

    function checkPaymentVoucher() {
      voucherId = null;
      ordernumber = null;
      vm.activationCodeError = false;
      if (vm.paymentvoucher != '') {
        vm.showSpinner = true;
        MeService.checkPaymentVoucher(vm.paymentvoucher, 'new').then(function(response) {
          vm.paymentVoucherInfo = response;
          if (vm.paymentVoucherInfo != null && vm.paymentVoucherInfo != '') {
            if (vm.paymentVoucherInfo.paymentId !== null) {
              ordernumber = vm.paymentvoucher;
            } else {
              voucherId = vm.paymentVoucherInfo.id;
            }
          } else {
            vm.paymentVoucherInfo = null;
          }
          vm.showSpinner = false;
        }, function(response) {
          vm.paymentVoucherInfo = null;
          vm.showSpinner = false;
        });
      } else {
        vm.paymentvoucher = null;
      }
    }

    vm.checkPaymentVoucher = function() {
      checkPaymentVoucher();
    };

    vm.isEmailReadonly = function() {
      return email !== undefined && email !== '';
    };

    vm.showPaymentVoucherInfo = function() {
      return !vm.showSpinner && vm.paymentvoucher != null;
    };

    vm.voucherFound = function() {
      return vm.paymentVoucherInfo !== null;
    };

    vm.showDeliveryInfo = function() {
      return (monitor == null || monitor.toLowerCase() == 'true') && (vm.paymentVoucherInfo == null || vm.paymentVoucherInfo.monitor) && ordernumber == null;
    };

    vm.showVoucherCode = function() {
      return (monitor == null);
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none');
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic');
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium');
      }

      return '';
    };

    vm.setPassword = function() {
      $scope.createPatientForm.password.$setValidity('', true);
      $scope.createPatientForm.confirmPassword.$setValidity('', true);
    };

    function login() {
      const data = 'grant_type=password&username=' + vm.email + '&password=' + vm.password + '&client_id=self';
      AuthService.login(data).then(function(response) {
        vm.showSpinner = false;
        redirectUser(response);
      }, function(response) {
        vm.showSpinner = false;
        redirectUser(response);
      });
    }

    function redirectUser(response) {
      if (response == null) {
        if (response.message == 'Username or password is not valid') {
          $state.go('invaliduserorpassword');
        } else {
          $state.go('unauthorized');
        }
      } else {
        if (new Date(response.expires) < new Date()) {
          SessionService.clearAll();
          return false;
        }

        if (SessionService.getIsPatient()) {
          // To get rid of parameters berfore the hash bang
          /* var redirectUri = $location.absUrl();
          if (redirectUri.indexOf('?') > 0) {
            redirectUri = redirectUri.substr(0, redirectUri.indexOf('?')) + '#/myprofile';
            console.log(redirectUri);
            window.location.href=redirectUri;
          } else {*/
          $state.go('me.myprofile');
          return false;
          // }
        }

        $state.go('unauthorized');
      }
    }

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
