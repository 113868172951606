import * as angular from 'angular';
import {NavStep, StepNavigation} from '../../core/components/navsteps.component';
import HealthcareProviderService from '../../../services/HealthcareProviderService';
import Permissions from '../../../services/models/Permissions';
import PatientService from '../../../services/PatientService';
import {LoginMethodOption, NationConfiguration, NationService} from '../../../services/NationService';
import {ScopeUserDetails, User} from '../../../models/User';
import {UserRegisterRequest, UserUpdateRequest} from '../../../services/models/UserRequest';
import {HomeEnrollmentFormSettings} from '../components/forms/patient.homenrollment.form.component';
import {InsuranceDataFormSettings} from '../components/forms/patient.insurance.form.component';
import {MonitoringFormSettings} from '../components/forms/patient.monitoring.form.component';
import {PatientDetailsFormSettings} from '../components/forms/patient.details.form.component';
import {OrderCoalaRequest} from '../../../services/models/OrderCoalaRequest';
import {InsuranceDataResponse} from '../../../services/models/InsuranceDataResponse';
import * as moment from 'moment';
import {MonitoringService, PatientEventMonitoringServicesHandler} from '../../../models/MonitoringServices';
import {
    MonitoringServiceUserRelationshipCollectionPartResponse,
    MonitoringServiceUserRelationshipCollectionResponse
} from '../../../services/models/MonitoringServiceUserRelationshipCollectionResponse';

interface RootScope {
    userDetails: ScopeUserDetails;
    subscription: any;
    isEditMode: boolean;
}

angular
    .module('admin')
    .controller('PatientCreateEditController', [
        '$scope', '$state', '$filter', '$rootScope', '$translate', 'ModalService',
        'PatientService', 'HealthcareProviderService', 'envConfig', 'SessionService', 'NationService', 'close',

        class PatientCreateEditController {
            private readonly scope: any;
            private readonly state: any;
            private readonly filter: any;
            private readonly rootScope: RootScope;
            private readonly translateService: angular.translate.ITranslateService;
            private readonly modalService: any;
            private readonly patientService: PatientService;
            private readonly healthcareProviderService: HealthcareProviderService;
            private readonly nationService: NationService;
            private readonly nationConfiguration: NationConfiguration;
            private readonly sessionService: any;
            private readonly envConfig: any;
            private readonly closeFunction: any;
            private readonly permissions: Permissions;
            private readonly isEditMode: boolean;
            private readonly representingHealthcareProviderId: string;
            private readonly createPatientForm: any;

            stepNavigation: StepNavigation;
            loadingCount = 0;

            private patientSettings: PatientDetailsFormSettings = {
                // Temporary assignment before data is prepared
                user: new User({name: '', value: ''}, '', '', ''),
                // Temporary assignment before data is prepared
                originalUser: new User({name: '', value: ''}, '', '', ''),
                healthCareProviders: [],
                measurementConfigurations: [],
                selectedMeasurementConfigurations: [],
                loginMethodsOptions: [],
                existsAsStaff: false,
                imei: ''
            }

            private monitoringSettings: MonitoringFormSettings = {
                enabled: true,
                user: new User({name: '', value: ''}, '', '', ''),
                monitoringServices: new PatientEventMonitoringServicesHandler(),
                prescribingProfessionals: []
            }

            private homeEnrollmentSettings: HomeEnrollmentFormSettings = {
                enabled: false
            }

            private insuranceSettings: InsuranceDataFormSettings = {
                enabled: false,
                eventmonitoring: true
            }
            private originalInsuranceSettings?: InsuranceDataFormSettings;
            private originalSelectedMeasurementConfigurations: {name: string; id: string}[];
            private originalPrescribingProfessional?: {id: string; firstname: string; lastname: string };
            private originalImeiCode = '';

            constructor(
                $scope: any, $state: any, $filter: any, $rootScope: RootScope, $translate: angular.translate.ITranslateService, ModalService: any, PatientService: PatientService,
                HealthcareProviderService: HealthcareProviderService, envConfig: any, SessionService: any, NationService: NationService, close: any
            ) {
                this.scope = $scope;
                this.state = $state;
                this.filter = $filter;
                this.translateService = $translate;
                this.rootScope = $rootScope;
                this.envConfig = envConfig;
                this.modalService = ModalService;
                this.patientService = PatientService;
                this.healthcareProviderService = HealthcareProviderService;
                this.nationService = NationService;
                this.sessionService = SessionService;
                this.closeFunction = close;

                this.permissions = SessionService.getUserPermissions();
                this.nationConfiguration = NationService.getConfiguration();
                this.isEditMode = this.rootScope.isEditMode;
                this.patientSettings.loginMethodsOptions = this.nationService.getPatientLoginOptions();
                this.originalSelectedMeasurementConfigurations = [];
                this.representingHealthcareProviderId = this.sessionService.getReprestingProviderId();

                // DEV PERMISSIONS HELPER
                // Object.entries(this.permissions).forEach(function (permission) {
                //   this.permissions[permission[0]] = true;
                // });

                // TABS AND STEPS
                // Align steps and tabs with permissions
                const steps: NavStep[] = [];
                steps.push({id: 'details', title: $translate.instant('general_details'), done: true, validated: false});
                if (this.nationService.hasCoalaConnect() && this.permissions.orderCoala) {
                    this.homeEnrollmentSettings.enabled = this.nationService.isUS();
                    steps.push({id: 'homeEnrollment', title: this.translateService.instant('order_coalaconnect_menu'), done: false, validated: false});
                }
                if (this.nationService.isUS()) {
                    steps.push({id: 'monitoring', title: this.translateService.instant('monitoring_service_settings'), done: false, validated: false});
                }
                if (this.nationConfiguration.showInsuranceInfo && this.permissions.readPatientUser) {
                    steps.push({id: 'insurance', title: this.translateService.instant('insuranceinfo_link'), done: false, validated: false});
                }
                steps.push({id: 'done', title: '', done: false, validated: true});
                this.stepNavigation = new StepNavigation(steps);

                if (!this.isEditMode) {
                    const loginMethod = this.patientSettings.loginMethodsOptions[this.patientSettings.loginMethodsOptions.length - 1];
                    this.patientSettings.user = new User(
                        loginMethod,
                        this.nationConfiguration.dateOfBirthFormat,
                        this.nationConfiguration.dateOfBirthalidationRegExp,
                        this.nationConfiguration.patientIdentificationNoValidationRegExp
                    );
                    this.patientSettings.originalUser = new User(
                        loginMethod,
                        this.nationConfiguration.dateOfBirthFormat,
                        this.nationConfiguration.dateOfBirthalidationRegExp,
                        this.nationConfiguration.patientIdentificationNoValidationRegExp
                    );
                    this.monitoringSettings.user = this.patientSettings.user;
                    if (this.permissions.representingProvider && this.permissions.readMeasurementConfiguration) {
                        this.getConfigurations();
                    }

                    if (this.sessionService.isUnrestrictedOrGroupUser()) {
                        this.getAllHealthcareProviders();
                    } else {
                        this.getPrescribingProfessionalUsers();
                    }
                    if (this.representingHealthcareProviderId) {
                        this.getHealthcareProviderMonitoringServiceCollection(this.representingHealthcareProviderId)
                            .then((hasMonitoringServices) => {
                                if (hasMonitoringServices) this.monitoringSettings.monitoringServices.setDefaultPreselectedService(this.isEditMode);
                            });
                    }
                } else {
                    this.homeEnrollmentSettings.enabled = false;
                    this.getUserData($rootScope.userDetails)
                        .then(() => {
                            if (this.permissions.representingProvider && this.permissions.readMeasurementConfiguration) {
                                this.getConfigurations(this.patientSettings.user);
                            }
                            if (!this.sessionService.isUnrestrictedOrGroupUser()) {
                                this.getAllHealthcareProviders();
                                this.getPrescribingProfessionalUsers(this.patientSettings.user);
                            }
                            this.getInsuranceInfo(this.patientSettings.user);
                            if (this.permissions.writeAndroidDevices && this.nationService.isUS()) {
                                this.getUserImeiCode(this.patientSettings.user);
                            }
                            if (this.representingHealthcareProviderId) {
                                this.getHealthcareProviderMonitoringServiceCollection(this.representingHealthcareProviderId)
                                    .then((hasMonitoringServices) => {
                                        if (hasMonitoringServices) this.getMonitoringServiceUserRelationshipCollection(this.patientSettings.user);
                                    });
                            }
                        });
                }
            }

            /**
             * SETUP
             */

            getUserData(userDetails: ScopeUserDetails) {
                return new Promise<void>((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.getUserData(userDetails.userId)
                        .then((response) => {
                            this.loadingCount -= 1;
                            const userdata = response;
                            let loginMethod: LoginMethodOption = this.patientSettings.loginMethodsOptions.filter((option) => option.value == userdata.allowedLoginMethods)[0];
                            if (!loginMethod) loginMethod = this.patientSettings.loginMethodsOptions[this.patientSettings.loginMethodsOptions.length - 1];

                            this.patientSettings.user = new User(
                                loginMethod,
                                this.nationConfiguration.dateOfBirthFormat,
                                this.nationConfiguration.dateOfBirthalidationRegExp,
                                this.nationConfiguration.patientIdentificationNoValidationRegExp,
                                userDetails
                            );
                            this.patientSettings.originalUser = new User(
                                loginMethod,
                                this.nationConfiguration.dateOfBirthFormat,
                                this.nationConfiguration.dateOfBirthalidationRegExp,
                                this.nationConfiguration.patientIdentificationNoValidationRegExp,
                                userDetails
                            );
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            getPrescribingProfessionalUsers(user?: User) {
                this.loadingCount += 1;
                this.healthcareProviderService.getStaffs()
                    .then((response: any) => {
                        this.loadingCount -= 1;
                        this.monitoringSettings.prescribingProfessionals = response;
                        if (user && user.id) {
                            this.loadingCount += 1;
                            this.patientService.getPatientPortalUser(user.id)
                                .then((response: any) => {
                                    this.loadingCount -= 1;
                                    if (response !== null && response.length > 0) {
                                        const found = jQuery.grep(this.monitoringSettings.prescribingProfessionals, (x) => {
                                            return x.id == response[0].id;
                                        });
                                        if (found.length > 0) {
                                            this.monitoringSettings.prescribingProfessional = found[0];
                                            this.originalPrescribingProfessional = Object.assign({}, {...this.monitoringSettings.prescribingProfessional});
                                        }
                                    }
                                })
                                .catch((error: Error) => {
                                    this.loadingCount -= 1;
                                    console.error(error);
                                    this.showError(error);
                                });
                        }
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getHealthcareProviderMonitoringServiceCollection(hcpId: string): Promise<boolean> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getMonitoringServiceCollection(hcpId)
                        .then((response) => {
                            this.loadingCount -= 1;
                            if (response && response.length > 0) {
                                for (const monitoringServiceCollectionResponse of response) {
                                    if (monitoringServiceCollectionResponse.monitoringServices.length > 0) {

                                        if (PatientEventMonitoringServicesHandler.isEventMonitoringServiceCollection(monitoringServiceCollectionResponse.monitoringServices)) {
                                            this.monitoringSettings.monitoringServices.addMonitoringServices(monitoringServiceCollectionResponse.monitoringServices.map((service) => {
                                                return new MonitoringService(service.monitoringServiceId, '', service.monitoringServicePartServiceType.name, this.translateService, service.monitoringServiceDurations);
                                            }));
                                        } else {
                                            this.monitoringSettings.monitoringServices.addRpmServices(monitoringServiceCollectionResponse.monitoringServices.map((service) => {
                                                return new MonitoringService(service.monitoringServiceId, '', service.monitoringServicePartServiceType.name, this.translateService, service.monitoringServiceDurations);
                                            }));
                                        }
                                    }
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            getMonitoringServiceUserRelationshipCollection(user: User) {
                this.loadingCount += 1;
                this.patientService.getMonitoringServiceUserRelationshipCollection(user.id!)
                    .then((response) => {
                        this.loadingCount -= 1;
                        if (response && response.length > 0 && response[0].monitoringServiceUserRelationshipCollectionParts.length > 0) {
                            this.monitoringSettings.monitoringServices.setPatientSelectedServices(response[0].monitoringServiceUserRelationshipCollectionParts);
                        } else {
                            this.monitoringSettings.monitoringServices.setDefaultPreselectedService(this.isEditMode);
                        }
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getAllHealthcareProviders() {
                this.loadingCount += 1;
                this.healthcareProviderService.getAllHealthcareProviders()
                    .then((response: any) => {
                        this.loadingCount -= 1;
                        this.patientSettings.healthCareProviders = response;
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getConfigurations(user?: User) {
                this.loadingCount += 1;
                this.patientSettings.measurementConfigurations.push({name: this.translateService.instant('user_created_allconfiguration_option'), id: ''});
                this.patientService.getMeasuremenConfigurations()
                    .then((response: any) => {
                        this.loadingCount -= 1;
                        for (const index in response) {
                            this.patientSettings.measurementConfigurations.push({name: response[index].groupName, id: response[index].groupId});
                        }
                        if (user && user.id) {
                            this.getUserConfigurations(user);
                        }
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getUserConfigurations(user: User) {
                this.loadingCount += 1;
                this.patientService.getMeasurementConfigurationsForUser(user.id!)
                    .then((response: any) => {
                        this.loadingCount -= 1;
                        for (const index in response) {
                            this.patientSettings.selectedMeasurementConfigurations.push({name: response[index].groupName, id: response[index].groupId});
                        }
                        // Check if all are selected
                        if (this.patientSettings.selectedMeasurementConfigurations.length == 0) {
                            this.patientSettings.selectedMeasurementConfigurations = [{name: this.translateService.instant('user_created_allconfiguration_option'), id: ''}];
                        }
                        this.originalSelectedMeasurementConfigurations = Object.assign([], {...this.patientSettings.selectedMeasurementConfigurations});
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getInsuranceInfo(user: User) {
                this.loadingCount += 1;
                this.patientService.getInsuranceInfo(user.id!)
                    .then((response) => {
                        this.loadingCount -= 1;
                        if (response) {
                            this.insuranceSettings = Object.assign({enabled: response.primaryCompany != undefined}, {...response});
                            this.insuranceSettings.dateOfBirth = this.filter('date')(response.dateOfBirth, this.nationService.getDateFormats().mediumDate);
                            this.originalInsuranceSettings = Object.assign({}, {...this.insuranceSettings});
                        } else {
                            this.insuranceSettings.enabled = false;
                        }
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            getUserImeiCode(user: User) {
                this.loadingCount += 1;
                this.patientService.getUserImeiCode(user.id!)
                    .then((response) => {
                        this.loadingCount -= 1;
                        if (response) {
                            this.originalImeiCode = response.androidDevice.imei;
                            this.patientSettings.imei = response.androidDevice.imei;
                        }
                    })
                    .catch((error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        this.showError(error);
                    });
            }

            /**
             * UI FUNCTIONS
             */

            private showFeedback = (message: string) => {
                // @ts-ignore
                Lobibox.notify('success', {
                    msg: message,
                    icon: false,
                    title: 'Message',
                    position: 'top right'
                });
            };

            private showError(error: any) {
                this.showErrorMessage(error.message ? error.message : error);
            }

            private showErrorMessage(message: string) {
                // @ts-ignore
                Lobibox.notify('error', {
                    msg: message,
                    icon: false,
                    title: 'Message',
                    position: 'top right'
                });
            }

            /**
             * TEMPLATE FUNCTIONS
             */

            isUnrestrictedUser = () => {
                return this.sessionService.isUnrestrictedUser();
            };

            showCompleteInvestigationLink = () => {
                return this.permissions.representingProvider && this.patientSettings.user && this.patientSettings.user.acceptedTo == null;
            };

            showDisconnectPatientLink = () => {
                return this.permissions.representingProvider && !this.nationService.isUS();
            };

            showRemoveUser = () => {
                this.modalService.showModal({
                    template: require('../views/modals/confirm.delete.user.tpl.html').default,
                    controller: 'PatientCreateEditController',
                    controllerAs: 'vm',
                }).then((modal: any) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        // closeModal();
                    });
                });
            };

            showRemoveUserFromHealthcareProvider = () => {
                this.modalService.showModal({
                    template: require('../views/modals/confirm.unlink.user.tpl.html').default,
                    controller: 'PatientCreateEditController',
                    controllerAs: 'vm',
                }).then((modal: any) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        // closeModal();
                    });
                });
            };

            showCompleteInvestigation = () => {
                this.modalService.showModal({
                    template: require('../views/modals/confirm.completeinvestigation.user.tpl.html').default,
                    controller: 'PatientCreateEditController',
                    controllerAs: 'vm',
                }).then((modal: any) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        // closeModal();
                    });
                });
            };

            savePatient = () => {
                this.storePatient(this.patientSettings.user);
            }

            resetPassword = () => {
                this.patientService.resetPassword(this.patientSettings.user.email!).then(() => {
                    this.showFeedback(this.translateService.instant('user_resetpassword'));
                }, (error: Error) => {
                    console.error(error);
                    this.showError(this.translateService.instant('user_resetpassword_error'));
                });
            };

            unlockAccount = () => {
                this.patientService.postUnlockUser(this.patientSettings.user.id!).then(() => {
                    this.showFeedback(this.translateService.instant('user_account_unlocked'));
                    this.patientService.getUserData(this.patientSettings.user.id!).then((data) => {
                        this.patientSettings.user.email = data.email;
                    });
                });
            };

            removeUser = () => {
                this.patientService.deletePatient(this.patientSettings.user.id!).then(() => {
                    this.showFeedback(this.translateService.instant('user_removed'));
                    if (this.rootScope.userDetails) {
                        this.rootScope.userDetails.email = '';
                    }
                    // closeModal();
                    this.state.transitionTo('mainAdmin.userlist', {
                        startItem: 0
                    });
                });
            };

            removeUserFromHealthcareProvider = () => {
                this.patientService.unlinkPatient(this.patientSettings.user.id!).then(() => {
                    this.showFeedback(this.translateService.instant('user_unlinkedfromprovider'));
                    if (this.rootScope.userDetails) {
                        this.rootScope.userDetails.email = '';
                    }
                    // closeModal();
                    this.state.transitionTo('main.patientsearch', {
                        startItem: 0
                    });
                });
            };

            completeInvestigation = () => {
                this.patientService.completeInvestigation(this.patientSettings.user.id!).then(() => {
                    this.showFeedback(this.translateService.instant('user_investigation_completed'));
                    if (this.rootScope.userDetails) {
                        this.rootScope.userDetails.email = '';
                    }
                    // closeModal();
                    this.state.transitionTo('main.patientsearch', {
                        startItem: 0
                    });
                });
            };

            /**
             * SAVING OF DATA
             */

            isUserChanged(): boolean {
                return JSON.stringify(this.patientSettings.user.getUpdatePayload()) != JSON.stringify(this.patientSettings.originalUser.getUpdatePayload());
            }

            areMeasurementConfigurationsChanged(): boolean {
                return JSON.stringify(this.patientSettings.selectedMeasurementConfigurations) != JSON.stringify(this.originalSelectedMeasurementConfigurations);
            }

            areInsuranceSettingsChanged(): boolean {
                return JSON.stringify(this.insuranceSettings) != JSON.stringify(this.originalInsuranceSettings);
            }

            isPrescribingProfessionalChanged(): boolean {
                return JSON.stringify(this.monitoringSettings.prescribingProfessional) != JSON.stringify(this.originalPrescribingProfessional);
            }

            isImeiCodeChanged(): boolean {
                return JSON.stringify(this.patientSettings.imei) != JSON.stringify(this.originalImeiCode) && this.patientSettings.imei.length > 0;
            }

            isImeiCodeDeleted(): boolean {
                return this.originalImeiCode.length > 0 && this.patientSettings.imei.length === 0;
            }

            storePatient(user: User) {
                if (!this.isEditMode) {
                    const userRequest: UserRegisterRequest = user.getRegisterPayload();
                    if (this.patientSettings.existsAsStaff) {
                        this.connectStaffPatient(userRequest)
                            .then((userId) => this.monitoringSettings.prescribingProfessional && this.monitoringSettings.prescribingProfessional.id ? this.updatePrescribingProfessional(userId) : userId)
                            .then((userId) => this.monitoringSettings.monitoringServices.hasChanges ? this.updateMonitoringServiceCollection(userId, this.monitoringSettings.monitoringServices.getServices()) : userId)
                            .then((userId) => this.homeEnrollmentSettings.enabled ? this.sendUserCoalaOrder(userId, this.homeEnrollmentSettings) : userId)
                            .then((userId) => this.insuranceSettings.enabled ? this.sendInsuranceInfo(userId, this.insuranceSettings) : userId)
                            .then((userId) => this.patientSettings.imei.length > 0 ? this.sendImeiCode(userId, this.patientSettings.imei) : null)
                            .then(() => {
                                this.showFeedback(this.translateService.instant('user_connected_to_staff'));
                                this.closeFunction('', 500);
                            });
                    } else {
                        this.createPatient(userRequest)
                            .then((userId) => this.monitoringSettings.prescribingProfessional && this.monitoringSettings.prescribingProfessional.id ? this.updatePrescribingProfessional(userId) : userId)
                            .then((userId) => this.monitoringSettings.monitoringServices.hasChanges ? this.updateMonitoringServiceCollection(userId, this.monitoringSettings.monitoringServices.getServices()) : userId)
                            .then((userId) => this.homeEnrollmentSettings.enabled ? this.sendUserCoalaOrder(userId, this.homeEnrollmentSettings) : userId)
                            .then((userId) => this.insuranceSettings.enabled ? this.sendInsuranceInfo(userId, this.insuranceSettings) : userId)
                            .then((userId) => this.patientSettings.imei.length > 0 ? this.sendImeiCode(userId, this.patientSettings.imei) : null)
                            .then(() => {
                                this.showFeedback(this.translateService.instant('user_created'));
                                this.closeFunction('', 500);
                            });
                    }
                } else {
                    Promise.resolve()
                        .then(() => this.isUserChanged() || user.updatedBorrowedMonitor ? this.updateUserProfile(user) : null)
                        .then(() => user.email && user.email !== this.patientSettings.originalUser.email ? this.updateEmail(user) : null)
                        .then(() => this.areMeasurementConfigurationsChanged() && this.permissions.writeMeasurementConfiguration ? this.updateUserConfigurations(user) : null)
                        .then(() => user.password && user.confirmPassword && user.password == user.confirmPassword && user.password != this.patientSettings.originalUser.password ? this.updatePassword(user) : null)
                        .then(() => user.testUser !== this.patientSettings.originalUser.testUser && this.permissions.manageAccount ? this.updateTestUser(user) : null)
                        .then(() => user.allowedLoginMethod.value !== this.patientSettings.originalUser.allowedLoginMethod.value && this.permissions.manageAccount ? this.updateAllowedLoginMethods(user) : null)
                        .then(() => this.homeEnrollmentSettings.enabled ? this.sendUserCoalaOrder(user.id!, this.homeEnrollmentSettings) : null)
                        .then(() => this.areInsuranceSettingsChanged() ? this.sendInsuranceInfo(user.id!, this.insuranceSettings) : null)
                        .then(() => this.isPrescribingProfessionalChanged() ? this.updatePrescribingProfessional(user.id!) : null)
                        .then(() => this.monitoringSettings.monitoringServices.hasChanges ? this.updateMonitoringServiceCollection(user.id!, this.monitoringSettings.monitoringServices.getServices()) : null)
                        .then(() => this.isImeiCodeDeleted() ? this.deleteImeiCode(user.id!) : null)
                        .then(() => this.isImeiCodeChanged() ? this.sendImeiCode(user.id!, this.patientSettings.imei) : null)

                        .then(() => {
                            this.showFeedback(this.translateService.instant('user_updated'));
                            this.closeFunction('', 500);
                        });
                }
            }

            createPatient(userRequest: UserRegisterRequest): Promise<string> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.createPatient(userRequest)
                        .then((response) => {
                            this.loadingCount -= 1;
                            if (this.rootScope.userDetails) {
                                this.rootScope.userDetails.email = userRequest.email!;
                                this.rootScope.userDetails.patientIdentificationNo = userRequest.patientIdentificationNo;
                            }
                            resolve(response);
                        })
                        .catch((error: any) => {
                            this.loadingCount -= 1;
                            //console.error(error);

                            let errorText = '';
                            if (error.patientIdentificationNo) {
                                errorText = 'Invalid patient identification number';
                            }
                            for (const key in error.errors) {
                                if (key == 'password') {
                                    this.createPatientForm.password.$setValidity('', false);
                                    errorText = error.errors['password'];
                                }
                                if (key == 'confirmPassword') {
                                    this.createPatientForm.confirmPassword.$setValidity('', false);
                                    errorText = error.errors['confirmPassword'];
                                }
                                if (key == 'dateOfBirth') {
                                    if (this.nationService.isUS()) {
                                        this.createPatientForm.dateOfBirth.$setValidity('', false);
                                    }
                                    errorText = 'Invalid date of birth';
                                }
                                if (key == 'patientIdentificationNo') {
                                    this.createPatientForm.patientIdentificationNo.$setValidity('', false);
                                    errorText = error.errors["patientIdentificationNo"];
                                }
                            }
                            
                            this.showError(this.translateService.instant('user_created_error') + ' ' + errorText);
                        });
                });
            }

            connectStaffPatient(userRequest: UserRegisterRequest): Promise<string> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.connect(userRequest)
                        .then((response: any) => {
                            this.loadingCount -= 1;
                            if (this.rootScope.userDetails) {
                                this.rootScope.userDetails.email = userRequest.email!;
                                this.rootScope.userDetails.patientIdentificationNo = userRequest.patientIdentificationNo;
                            }
                            resolve(response);
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(this.translateService.instant('user_connected_to_staff_error'));
                        });
                });
            }

            updateUserProfile(user: User): Promise<void> {
                return new Promise((resolve) => {
                    const userProfile: UserUpdateRequest = user.getUpdatePayload();
                    this.loadingCount += 1;
                    this.patientService.putUserProfile(user.id!, userProfile)
                        .then(() => {
                            this.loadingCount -= 1;
                            // @ts-ignore
                            this.rootScope.userDetails = userProfile;
                            resolve();
                        })
                        .catch((error: any) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            const errorText = '';
                            for (const key in error.modelState) {
                                if (key == 'patientIdentificationNo') {
                                    this.createPatientForm.patientIdentificationNo.$setValidity('', false);
                                    // errorText = error.modelState["patientIdentificationNo"];
                                }
                            }
                            this.showError(this.translateService.instant('user_updated_error') + errorText);
                        });
                });
            }

            updateEmail(user: User): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.postUpdateEmail(user.id!, user.email!)
                        .then(() => {
                            this.loadingCount -= 1;
                            if (this.rootScope.userDetails) {
                                this.rootScope.userDetails.email = user.email!;
                            }
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            updateUserConfigurations(user: User): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    const request = this.createMeasurementConfigurationsRequest(user);
                    this.patientService.updateUserConfiguration(request)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            createMeasurementConfigurationsRequest(user: User) {
                const allConfigs = jQuery.grep(this.patientSettings.selectedMeasurementConfigurations, (x: any) => {
                    return x.id == '';
                });
                const request: {patientUserId: string; measurementConfigurationGroupIds: string[]} = {
                    patientUserId: user.id!,
                    measurementConfigurationGroupIds: []
                };
                if (allConfigs.length == 0) {
                    for (const config of this.patientSettings.selectedMeasurementConfigurations) {
                        request.measurementConfigurationGroupIds.push(config.id);
                    }
                }
                return request;
            }

            updatePassword(user: User): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.putUpdateUserPassword(user.id!, user.password!, user.confirmPassword!)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve();
                        })
                        .catch((error: any) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            for (const key in error.modelState) {
                                if (key == 'userRequest.Password') {
                                    this.createPatientForm.password.$setValidity('', false);
                                }
                                if (key == 'model.ConfirmPassword') {
                                    this.createPatientForm.confirmPassword.$setValidity('', false);
                                }
                            }
                            this.showError(this.translateService.instant('user_password_error'));
                        });
                });
            }

            updateMonitoringServiceCollection(userId: string, services: MonitoringService[]): Promise<string> {
                return new Promise((resolve) => {

                    const parts: MonitoringServiceUserRelationshipCollectionPartResponse[] = [];
                    let enabledIndex = 0;
                    for (const service of services) {
                        if (service.enabled) {
                            let durationId = '';
                            for (const duration of service.durations) {
                                if (duration.enabled) durationId = duration.id;
                            }
                            parts.push({
                                order: enabledIndex,
                                monitoringServiceId: service.id,
                                monitoringServiceDurationId: durationId
                            });
                            enabledIndex += 1;
                        }
                    }

                    if (parts.length >= 0) {
                        const payload: MonitoringServiceUserRelationshipCollectionResponse = {
                            userId: userId,
                            monitoringServiceUserRelationshipCollectionParts: parts
                        };
                        this.loadingCount += 1;
                        this.patientService.postMonitoringServiceUserRelationshipCollection(payload)
                            .then(() => {
                                this.loadingCount -= 1;
                                resolve(userId);
                            })
                            .catch((error: Error) => {
                                this.loadingCount -= 1;
                                console.error(error);
                                this.showError(error);
                            });
                    } else {
                        resolve(userId);
                    }
                });
            }

            updatePrescribingProfessional(userId: string): Promise<string> {
                return new Promise((resolve) => {
                    if (this.monitoringSettings.prescribingProfessional && this.monitoringSettings.prescribingProfessional.id) {
                        this.loadingCount += 1;
                        const request = {patientUserId: userId, portalUserId: this.monitoringSettings.prescribingProfessional.id};
                        this.patientService.setPatientPortalUser(request)
                            .then(() => {
                                this.loadingCount -= 1;
                                this.sessionService.setSearchFilterPortalUsers(null);
                                resolve(userId);
                            })
                            .catch((error: Error) => {
                                this.loadingCount -= 1;
                                console.error(error);
                                this.showError(error);
                            });
                    }
                });
            }

            updateTestUser(user: User): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.postUpdateTestUser(user.id!, user.testUser)
                        .then(() => {
                            this.loadingCount -= 1;
                            if (this.rootScope.userDetails) {
                                this.rootScope.userDetails.testUser = user.testUser;
                            }
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            updateAllowedLoginMethods(user: User): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.postUpdateAllowedLoginMethod(user.id!, user.allowedLoginMethod.value)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(error);
                        });
                });
            }

            sendUserCoalaOrder(userId: string, settings: HomeEnrollmentFormSettings): Promise<string> {
                return new Promise((resolve) => {
                    const payload: OrderCoalaRequest = {
                        address: settings.address,
                        city: settings.city,
                        zipcode: settings.zipcode,
                        phone: settings.phoneNumber,
                        addSubscriptionMonths: settings.addSubscriptionMonths
                    };
                    this.loadingCount += 1;
                    this.patientService.sendUserCoalaOrder(userId, payload)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve(userId);
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(this.translateService.instant('order_coalaconnect_error'));
                        });
                });
            }

            sendInsuranceInfo(userId: string, settings: InsuranceDataFormSettings): Promise<string> {
                return new Promise((resolve) => {
                    const request: InsuranceDataResponse = Object.assign({}, {...settings});
                    request.dateOfBirth = '2000-01-01';
                    if (settings.enabled) {
                        const dt = moment(settings.dateOfBirth, this.nationConfiguration.dateOfBirthFormat);
                        request.dateOfBirth = dt.format('YYYY-MM-DD');
                    }
                    this.loadingCount += 1;
                    this.patientService.storeInsuranceInfo(userId, request)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve(userId);
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                            this.showError(this.translateService.instant('insuranceinfo_error'));
                        });
                });
            }

            sendImeiCode(userId: string, imei: string): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.postUserImeiCode(userId, imei)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                        });
                });
            }

            deleteImeiCode(userId: string): Promise<void> {
                return new Promise((resolve) => {
                    this.loadingCount += 1;
                    this.patientService.deleteUserImeiCode(userId)
                        .then(() => {
                            this.loadingCount -= 1;
                            resolve();
                        })
                        .catch((error: Error) => {
                            this.loadingCount -= 1;
                            console.error(error);
                        });
                });
            }
        }
    ]);
