import * as angular from 'angular';
import * as jQuery from 'jquery';
import {NavStep, StepNavigation} from '../../core/components/navsteps.component';
import Permissions from '../../../services/models/Permissions';
import {PatientEmailMonitoringResponse} from '../../../services/models/PatientEmailMonitoringResponse';
import {MeasurementConfigurationResponse} from '../../../services/models/MeasurementConfigurationResponse';
import HealthcareProviderResponse from '../../../services/models/HealthcareProviderResponse';
import {HealthcareProviderGroupResponse} from '../../../services/models/HealthcareProviderGroupResponse';
import RoleResponse from '../../../services/models/RoleResponse';
import HealthcareProviderService from '../../../services/HealthcareProviderService';
import {EventMonitoringServicesHandler, MonitoringServiceProvider, RemotePatientMonitoringServicesHandler} from '../../../models/MonitoringServices';
import {HcpDetailsFormSettings} from '../components/forms/hcp.details.form.component';
import {HcpRolesFormSettings} from '../components/forms/hcp.roles.form.component';
import {HcpMonitoringFormSettings} from '../components/forms/hcp.monitoring.form.component';
import MonitoringServiceContactInfoResponse from "../../../services/models/MonitoringServiceContactInfoResponse";
import ScheduledTaskListResponse from 'app/services/models/ScheduledTaskListResponse';
import { HcpScheduledTasksSettings } from '../components/forms/hcp.scheduledtasks.form.component';

export interface IdNameSelectable {
    id: string;
    name: string;
    selected?: boolean;
}

angular.module('admin')
    .controller('AdminHealthcareProviderCreateEditController', [
        '$rootScope', '$translate', 'ModalService', 'HealthcareProviderService', 'NationService', 'SessionService', 'close',

        class AdminHealthcareProviderCreateEditController {
            private readonly rootScope: any;
            private readonly translateService: angular.translate.ITranslateService;
            private readonly modalService: any;
            private readonly healthcareProviderService: HealthcareProviderService;
            private readonly nationService: any;
            private readonly sessionService: any;
            private readonly closeFunction: any;
            private readonly permissions: Permissions;
            // private monitoringProviders: MonitoringServiceProvider[] = [];
            // private patientEnrollment?: PatientEnrollment;

            stepNavigation: StepNavigation;
            loadingCount = 0;
            isEditMode: boolean;

            patientEmailMonitoring?: PatientEmailMonitoringResponse;

            currentConfigurations?: string;
            
            hcpSettings: HcpDetailsFormSettings = {
                healthcareProvider: {},
                configurations: [],
                groupList: [],
                useDefaultConfigurations: true,
                selectedConfigurations: []
            }

            roleSettings: HcpRolesFormSettings = {
                rolesList: [],
                hasRoleChanged: false
            }

            monitoringSettings: HcpMonitoringFormSettings = {
                monitoringServices: new EventMonitoringServicesHandler(),
                rpmServices: new RemotePatientMonitoringServicesHandler(),
                notificationEmails: [{email: ''}],
                enableEmailMonitoring: false,
                eventMonitoringContact: {
                    attempts: 3,
                    notificationCriteria: 'Standard',
                    timeZone: 'America/Los_Angeles'
                }
            }

            scheduledTasksSettings: HcpScheduledTasksSettings = {
                tasksList: []
            }

            constructor(
                $rootScope: any, $translate: any, ModalService: any,
                HealthcareProviderService: HealthcareProviderService, NationService: any, SessionService: any, close: any
            ) {
                this.rootScope = $rootScope;
                this.translateService = $translate;
                this.modalService = ModalService;
                this.healthcareProviderService = HealthcareProviderService;
                this.nationService = NationService;
                this.sessionService = SessionService;
                this.closeFunction = close;

                this.isEditMode = $rootScope.isEditMode;
                this.permissions = SessionService.getUserPermissions();
                
                if (this.isEditMode) {
                    const healthcareProviderId = $rootScope.id;
                    const assignedRoles = $rootScope.roles;
                    this.fetchHealthcareProvider(healthcareProviderId)
                        .then((healthcareProvider) => {
                            this.hcpSettings.healthcareProvider = healthcareProvider;
                            this.fetchHealthcareProviderMonitoringServiceContactInfo(healthcareProviderId)
                                .then(() => {
                                    this.fetchRoles(assignedRoles);
                                    this.hcpSettings.groupList = this.fetchAvailableGroups();
                                    this.fetchMonitoringServiceProviders();
                                });
                    });
                    this.fetchMonitoringEmails(healthcareProviderId);
                    this.fetchConfigurations(healthcareProviderId);
                    this.fetchScheduledTasks(healthcareProviderId);
                } else {
                    this.hcpSettings.healthcareProvider = {};
                    this.fetchConfigurations();
                    this.fetchRoles();
                    this.hcpSettings.groupList = this.fetchAvailableGroups();
                    this.fetchMonitoringServiceProviders();
                }

                // TABS AND STEPS
                // Align steps and tabs with permissions
                const steps: NavStep[] = [];
                steps.push({id: 'details', title: this.translateService.instant('general_details'), done: true, validated: false});
                if (this.permissions.writeHealthcareProvider || (this.permissions.emailmonitoringservice && this.isEditMode)) {
                    steps.push({id: 'monitoring', title: this.translateService.instant('monitoring_service_settings'), done: false, validated: false});
                }
                steps.push({id: 'roles', title: this.translateService.instant('healthcare_create_hcp_roles'), done: false, validated: false});
                if (this.permissions.coalaAdmin  && this.isEditMode) {
                    steps.push({id: 'scheduledtasks', title: this.translateService.instant('scheduledtasks_title'), done: false, validated: true});
                }
                steps.push({id: 'done', title: '', done: false, validated: true});
                this.stepNavigation = new StepNavigation(steps);

                // DEV PERMISSIONS HELPER
                // Object.entries(this.permissions).forEach((permission) => {
                //   // @ts-ignore
                //     this.permissions[permission[0]] = true;
                // });
            }

            /**
             * SETUP
             */

            private fetchHealthcareProvider(id: string): Promise<HealthcareProviderResponse> {
                return new Promise<HealthcareProviderResponse>((resolve, reject) => {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getHealthcareProvider(id).then((response: HealthcareProviderResponse) => {
                        this.loadingCount -= 1;
                        resolve(response);
                    }, (error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        reject(error);
                    });
                });
            }

            private fetchHealthcareProviderMonitoringServiceContactInfo(id: string): Promise<void> {
                return new Promise<void>((resolve, reject) => {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getHealthcareProviderMonitoringServiceContactInfo(id).then((response) => {
                        this.loadingCount -= 1;
                        if (response.length > 0) {
                            this.monitoringSettings.eventMonitoringContact = response[0];
                        }
                        resolve();
                    }, (error: Error) => {
                        this.loadingCount -= 1;
                        console.error(error);
                        reject(error);
                    });
                });
            }

            private fetchRoles(assignedRoles?: IdNameSelectable[]) {
                this.loadingCount += 1;
                this.healthcareProviderService.getGroupRoles(this.hcpSettings.healthcareProvider.healthcareProviderGroupId ? this.hcpSettings.healthcareProvider.healthcareProviderGroupId : '').then((response: RoleResponse[]) => {
                    this.roleSettings.rolesList = this.populateRoles(response, assignedRoles);
                    this.loadingCount -= 1;
                }, (error: Error) => {
                    console.error(error);
                    this.loadingCount -= 1;
                });
            }

            private populateRoles(roles: IdNameSelectable[], myRoles?: { id: string; name: string }[]) {
                for (const role of roles) {
                    role.selected = false;
                    if (myRoles) {
                        for (const selectedRole of myRoles) {
                            if (selectedRole.id === role.id) {
                                role.selected = true;
                            }
                        }
                    }
                }

                // Add my roles that not are available anymore
                if (myRoles) {
                    for (const providerRole of myRoles) {
                        const found = roles.filter((role) => providerRole.id === role.id);
                        if (found.length == 0) {
                            roles.push({id: providerRole.id, name: providerRole.name, selected: true});
                        }
                    }
                }

                return roles;
            }

            private fetchAvailableGroups(): IdNameSelectable[] {
                const groups: IdNameSelectable[] = [];
                if (this.sessionService.isUnrestrictedOrGroupUser()) {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getMyGroups().then((response: { rows: HealthcareProviderGroupResponse }) => {
                        this.loadingCount -= 1;
                        angular.forEach(response.rows, (item) => {
                            groups.push({id: item.id, name: item.name});
                        });

                        const selected = jQuery.grep(groups, (x) => {
                            return x.id === this.hcpSettings.healthcareProvider.healthcareProviderGroupId;
                        });
                        if (selected.length > 0) {
                            this.hcpSettings.selectedGroup = selected[0];
                        } else if (groups.length > 0 && !this.sessionService.isUnrestrictedUser()) {
                            this.hcpSettings.selectedGroup = groups[0];
                        }
                    }, (error: Error) => {
                        console.error(error);
                        this.loadingCount -= 1;
                    });
                }
                console.log(this.hcpSettings.selectedGroup);
                return groups;
            }

            private fetchMonitoringServiceProviders() {
                this.loadingCount += 1;
                this.healthcareProviderService.getMonitoringServiceOptions().then((response) => {
                    this.loadingCount -= 1;
                    const monitoringProviders = response.map((provider) => new MonitoringServiceProvider(provider.id, provider.name, provider.monitoringServices, this.translateService));
                    for (const monitoringProvider of monitoringProviders) {
                        this.monitoringSettings.monitoringServices.addServices(monitoringProvider.services);
                    }
                    const rpmMonitoringProviders = response.map((provider) => new MonitoringServiceProvider(provider.id, provider.name, provider.monitoringServices, this.translateService));
                    for (const monitoringProvider of rpmMonitoringProviders) {
                        this.monitoringSettings.rpmServices.addServices(monitoringProvider.services);
                    }
                    if (this.hcpSettings.healthcareProvider.id) {
                        this.fetchHcpMonitoringServiceCollection(this.hcpSettings.healthcareProvider.id);
                    } else {
                        this.monitoringSettings.monitoringServices.setDefaultEnabledServices();
                        this.monitoringSettings.rpmServices.setDefaultEnabledServices();
                    }
                }, (error: Error) => {
                    console.error(error);
                    this.loadingCount -= 1;
                });
            }

            private fetchHcpMonitoringServiceCollection(hcpId: string) {
                this.loadingCount += 1;
                this.healthcareProviderService.getMonitoringServiceCollection(hcpId).then((response) => {
                    this.loadingCount -= 1;
                    for (const serviceCollection of response) {
                        if (serviceCollection.monitoringServices.length > 0) {
                            if (EventMonitoringServicesHandler.isEventMonitoringServiceCollection(serviceCollection.monitoringServices)) {
                                this.monitoringSettings.monitoringServices.setEnabledServices(serviceCollection.monitoringServices);
                            } else {
                                this.monitoringSettings.rpmServices.setEnabledServices(serviceCollection.monitoringServices);
                            }
                        }
                    }
                }, (error: Error) => {
                    console.error(error);
                    this.loadingCount -= 1;
                });
            }

            private fetchMonitoringEmails(hcpId: string) {
                this.loadingCount += 1;
                this.healthcareProviderService.getPatientEmailMonitoring(hcpId).then((response: PatientEmailMonitoringResponse) => {
                    this.loadingCount -= 1;
                    if (response) {
                        this.patientEmailMonitoring = response;
                        this.monitoringSettings.enableEmailMonitoring = true;
                        this.monitoringSettings.notificationEmails = [];
                        response.emailReceivers.forEach((value) => {
                            this.monitoringSettings.notificationEmails.push({email: value});
                        });
                    }
                }, (error: Error) => {
                    console.error(error);
                    this.loadingCount -= 1;
                });
            }

            private fetchConfigurations(hcpId?: string) {
                if (this.permissions.readMeasurementConfiguration) {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getMeasuremenConfigurations().then((response: MeasurementConfigurationResponse[]) => {
                        this.loadingCount -= 1;
                        for (const index in response) {
                            this.hcpSettings.configurations.push({name: response[index].groupName, id: response[index].groupId});
                        }
                        if (hcpId) {
                            this.fetchHealthcareProviderConfigurations(hcpId);
                        }
                    }, (error: Error) => {
                        console.error(error);
                        this.loadingCount -= 1;
                    });
                }
            }

            private fetchHealthcareProviderConfigurations(hcpId: string) {
                if (this.permissions.readMeasurementConfiguration) {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getMeasurementConfigurationsForHealthcareProvider(hcpId).then((response: MeasurementConfigurationResponse[]) => {
                        this.loadingCount -= 1;
                        for (const index in response) {
                            this.hcpSettings.selectedConfigurations.push({name: response[index].groupName, id: response[index].groupId});
                        }

                        this.hcpSettings.useDefaultConfigurations = this.hcpSettings.selectedConfigurations.length == 0;
                        this.currentConfigurations = this.hcpSettings.selectedConfigurations.map(function(el) {
                            return el.id;
                        }).join(',');
                    }, (error: Error) => {
                        console.error(error);
                        this.loadingCount -= 1;
                    });
                }
            }

            private fetchScheduledTasks(hcpId: string) {
                if (this.permissions.coalaAdmin) {
                    this.loadingCount += 1;
                    this.healthcareProviderService.getScheduledTasks(hcpId).then((response: ScheduledTaskListResponse[]) => {
                        this.loadingCount -= 1;
                        this.scheduledTasksSettings.tasksList = response;
                    }, (error: Error) => {
                        console.error(error);
                        this.loadingCount -= 1;
                    });
                }
            }

            /**
             * VALIDATION
             */

            private validateMonitoringEmails() {
                for (const notificationEmail of this.monitoringSettings.notificationEmails) {
                    if (!this.validateEmail(notificationEmail.email)) return false;
                }
                return !(this.monitoringSettings.notificationEmails.length == 0 || this.monitoringSettings.notificationEmails[0].email === '');
            }

            private validateEmail(email: string): boolean {
                const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
                return re.test(email);
            }

            /**
             * UI FUNCTIONS
             */

            private showFeedback(message: string) {
                // @ts-ignore
                Lobibox.notify('success', {
                    msg: message,
                    icon: false,
                    title: 'Message',
                    position: 'top right'
                });
            }

            private showError(error: any) {
                this.showErrorMessage(error.message ? error.message : error);
            }

            private showErrorMessage(message: string) {
                // @ts-ignore
                Lobibox.notify('error', {
                    msg: message,
                    icon: false,
                    title: 'Message',
                    position: 'top right'
                });
            }

            /**
             * SAVING OF DATA
             */

            storeHealthcareProvider() {
                if (!this.monitoringSettings.enableEmailMonitoring || this.validateMonitoringEmails()) {
                    this.hcpSettings.healthcareProvider.healthcareProviderGroupId = this.hcpSettings.selectedGroup ? this.hcpSettings.selectedGroup.id : this.hcpSettings.healthcareProvider.healthcareProviderGroupId;
                    const payload = this.hcpSettings.healthcareProvider;
                    const eventMonitoringContactInfoPayload = this.monitoringSettings.eventMonitoringContact;
                    console.info('Saving HealthcareProvider', payload);

                    if (!this.isEditMode) {
                        this.loadingCount += 1;
                        let healthcareProviderId = '';
                        payload.publicVisible = true;
                        this.healthcareProviderService.createHealthcareProvider(payload)
                            .then((response) => {
                                this.hcpSettings.healthcareProvider.id = response.id;
                                healthcareProviderId = response.id!;
                                return this.updateHealthcareProviderConfigurations(healthcareProviderId);
                            })
                            .then(() => this.storeHealthcareProviderMonitoringServiceContactInfo(healthcareProviderId, eventMonitoringContactInfoPayload))
                            .then(() => this.storeMonitoringEmails(healthcareProviderId))
                            .then(() => this.storeRoles(healthcareProviderId))
                            .then(() => this.saveMonitoringServiceProviders(healthcareProviderId))
                            .then(() => {
                                this.loadingCount -= 1;
                                this.showFeedback(this.translateService.instant('healthcare_create_info_created'));
                                this.stepNavigation.goToDone();
                            })
                            .catch((error) => {
                                this.loadingCount -= 1;
                                console.error(error);
                                this.showError(error);
                            });
                    } else if (this.hcpSettings.healthcareProvider.id) {
                        const healthcareProviderId = this.hcpSettings.healthcareProvider.id;
                        this.loadingCount += 1;
                        this.healthcareProviderService.updateHealthcareProvider(healthcareProviderId, payload)
                            .then(() => this.storeHealthcareProviderMonitoringServiceContactInfo(healthcareProviderId, eventMonitoringContactInfoPayload))
                            .then(() => this.updateHealthcareProviderConfigurations(healthcareProviderId))
                            .then(() => this.storeMonitoringEmails(healthcareProviderId))
                            .then(() => this.storeRoles(healthcareProviderId))
                            .then(() => this.saveMonitoringServiceProviders(healthcareProviderId))
                            .then(() => {
                                this.loadingCount -= 1;
                                this.showFeedback(this.translateService.instant('healthcare_create_info_updated'));
                                this.closeFunction('', 500);
                            })
                            .catch((error) => {
                                this.loadingCount -= 1;
                                console.error(error);
                                this.showError(error);
                            });
                    }
                } else {
                    this.showErrorMessage(this.translateService.instant('healthcare_create_error_emailaddresses_needed'));
                }
            }

            private storeHealthcareProviderMonitoringServiceContactInfo(hcpId: string, contactInfo: MonitoringServiceContactInfoResponse): Promise<void> {
                return new Promise((resolve, reject) => {
                    this.healthcareProviderService.postHealthcareProviderMonitoringServiceContactInfo(hcpId, contactInfo).then(() => {
                        resolve();
                    }, (error: Error) => {
                        reject(error);
                    });
                });
            }

            private storeMonitoringEmails(hcpId: string): Promise<void> {
                return new Promise((resolve, reject) => {
                    if (!this.monitoringSettings.enableEmailMonitoring && this.patientEmailMonitoring != undefined && this.patientEmailMonitoring.schemeId) {
                        this.healthcareProviderService.deletePatientEmailMonitoring(this.patientEmailMonitoring.schemeId).then(() => {
                            resolve();
                        }, (error: Error) => {
                            reject(error);
                        });
                    } else if (this.monitoringSettings.enableEmailMonitoring) {
                        const emailReceivers: string[] = [];
                        this.monitoringSettings.notificationEmails.forEach((value) => {
                            if (value.email !== undefined && value.email !== '') {
                                emailReceivers.push(value.email);
                            }
                        });
                        if (!this.patientEmailMonitoring) {
                            this.patientEmailMonitoring = {
                                emailReceivers: emailReceivers,
                                healthcareProviderId: hcpId,
                                schemeId: undefined
                            };
                            this.healthcareProviderService.updatePatientEmailMonitoring(this.patientEmailMonitoring).then(() => {
                                resolve();
                            }, (error: Error) => {
                                reject(error);
                            });
                        } else if (this.patientEmailMonitoring.emailReceivers !== emailReceivers) {
                            this.patientEmailMonitoring.emailReceivers = emailReceivers;
                            this.healthcareProviderService.updatePatientEmailMonitoring(this.patientEmailMonitoring).then(() => {
                                resolve();
                            }, (error: Error) => {
                                reject(error);
                            });
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                });
            }

            private storeRoles(hcpId: string): Promise<void> {
                return new Promise((resolve, reject) => {
                    if (this.roleSettings.hasRoleChanged) {
                        const request: { roles: { id: string }[] } = {roles: []};
                        for (const role of this.roleSettings.rolesList) {
                            if (role.selected) {
                                request.roles.push({id: role.id});
                            }
                        }
                        this.healthcareProviderService.updateHealthcareProviderRoles(hcpId, request).then(() => {
                            resolve();
                        }, (error: Error) => {
                            reject(error);
                        });
                    } else {
                        resolve();
                    }
                });
            }

            private updateHealthcareProviderConfigurations(hcpId: string): Promise<void> {
                return new Promise((resolve, reject) => {
                    if (this.permissions.writeMeasurementConfiguration) {
                        if (this.hcpSettings.useDefaultConfigurations) {
                            this.hcpSettings.selectedConfigurations = [];
                        }
                        if (this.currentConfigurations == null || this.currentConfigurations !== this.hcpSettings.selectedConfigurations.map(function(el: any) {
                            return el.id;
                        }).join(',')) {
                            const request = this.createMeasurementConfigurationsRequest(hcpId);
                            this.healthcareProviderService.updateHealthcareProviderConfiguration(request).then(() => {
                                resolve();
                            }, (error: Error) => {
                                reject(error);
                            });
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                });
            }

            private createMeasurementConfigurationsRequest(hcpId: string) {
                const allConfigs = this.hcpSettings.selectedConfigurations.filter((x: IdNameSelectable) => x.id == '');
                const groupIds = [];
                if (allConfigs.length == 0) {
                    for (const index in this.hcpSettings.selectedConfigurations) {
                        groupIds.push(this.hcpSettings.selectedConfigurations[index].id);
                    }
                }

                return {
                    healthcareProviderId: hcpId,
                    measurementConfigurationGroupIds: groupIds
                };
            }

            private saveMonitoringServiceProviders(hcpId: string): Promise<void> {
                return new Promise((resolve, reject) => {
                    if ((this.monitoringSettings.monitoringServices.hasChanges || this.monitoringSettings.rpmServices.hasChanges) && this.isUS() && hcpId) {
                        const monitoringServicesPayload = this.monitoringSettings.monitoringServices.getMonitoringServiceConditionPayload();
                        const rpmServicesPayload = this.monitoringSettings.rpmServices.getMonitoringServiceConditionPayload();
                        const payload = {monitoringServiceCollections: [{monitoringServices: monitoringServicesPayload}, {monitoringServices: rpmServicesPayload}]};
                        this.healthcareProviderService.postMonitoringServiceCollection(hcpId, payload).then(() => {
                            resolve();
                        }, (error: Error) => {
                            reject(error);
                        });
                    } else {
                        resolve();
                    }
                });
            }

            /**
             * TEMPLATE FUNCTIONS
             */

            close = () => {
                this.closeFunction('', 500);
            }

            showInactivateHealthcareProvider = () => {
                this.modalService.showModal({
                    template: require('../views/modals/confirm.inactivate.healthcare-provider.tpl.html').default,
                    controller: 'AdminHealthcareProviderCreateEditController',
                    controllerAs: 'vm',
                }).then((modal: { element: { modal: () => void }; closeFunction: Promise<any> }) => {

                    modal.element.modal();
                    modal.closeFunction.then(function(result) {
                        console.log(result);
                    });
                });
            };

            inactivateHealthcareProvider = () => {
                const id = this.hcpSettings.healthcareProvider.id!;
                this.loadingCount += 1;
                this.healthcareProviderService.inactivate(id).then(() => {
                    console.log('inactivated healthcareprovider');
                    this.showFeedback(this.translateService.instant('healthcare_create_info_remove'));

                    for (let i = 0; i < this.rootScope.providers.length; i += 1) {
                        if (this.rootScope.providers[i].id == id) {
                            this.rootScope.providers.splice(i, 1);
                            break;
                        }
                    }
                    this.loadingCount -= 1;
                    this.closeFunction('', 500);
                }, (error: Error) => {
                    console.error(error);
                    this.loadingCount -= 1;
                });
            };

            saveHealthcareProvider = () => {
                this.storeHealthcareProvider();
            };

            isUS = () => {
                return this.nationService.isUS();
            };
        }]
    );
