import * as angular from 'angular';

(function() {
  'use strict';

  function InfoService($q, $http, envConfig, SessionService, HttpClientService) {
    const service = {};
    const root = envConfig.baseUrl;

    service.getServerInfoDetails = function(id) {
      return HttpClientService.get(root + '/adminapi/v1/info');
    };

    service.getAnalyserStatus = function(id) {
      return HttpClientService.get(root + '/adminapi/v1/measurements/analyserstatus');
    };

    return service;
  }

  angular
    .module('core')
    .factory('InfoService', InfoService);

})();
