import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('UserImportController', UserImportController);

  function UserImportController($scope, $state, $stateParams, $rootScope, $translate, $q, $timeout, ModalService, PatientService, HealthcareProviderService, envConfig, SessionService, close) {
    const vm = this;
    vm.to = moment().utc();
    vm.subscriptionTypes = [{name: 'subscriptiontype_basic', value: 'base'}, {name: 'subscriptiontype_premium', value: 'plus'}];
    vm.subscriptionType = vm.subscriptionTypes[0];
    vm.subscription = null;
    vm.password = null;
    vm.testUser = false;
    vm.importdata = '';
    vm.selectedProvider = null;
    vm.healthcareProviderId = null;

    vm.options = {
      format: 'YYYY-MM-DD',
      minDate: moment(),
      viewDate: vm.to,
      icons: {
        time: 'fa fa-time',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-dot-circle',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    };

    let closing = false;

    activate();

    $scope.file_changed = function(element) {

      const file = element.files[0];
      const reader = new FileReader();
      reader.onload = function(e) {
        $scope.$apply(function() {
          // console.log(e.target.result);
          vm.setContent(e.target.result);
        });
      };
      reader.readAsText(file);
    };

    function activate() {
      vm.freeMonthsOptions = [{name: 'freemonthsoptions_onemonths', value: 1}, {name: 'freemonthsoptions_twomonths', value: 2},
        {name: 'freemonthsoptions_threemonths', value: 3}, {name: 'freemonthsoptions_sixmonths', value: 6}, {name: 'freemonthsoptions_oneyear', value: 12}];
      vm.freeMonths = vm.freeMonthsOptions[0];

      vm.subscription = {
        email: '',
        from: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
        to: vm.to.format('YYYY-MM-DDTHH:mm:ss'),
        subscriptionType: 'base',
        cancelled: true,
        contractEnd: moment(),
        callsLeft: 12,
      };

      vm.to = vm.to.add(1, 'month');

      getAllHealthcareProviders();
    }

    function getAllHealthcareProviders() {
      HealthcareProviderService.getAllHealthcareProviders().then(function(response) {
        vm.providersList = response;
      });
    }

    function CSVToArray(strData, strDelimiter) {
      // Check to see if the delimiter is defined. If not,
      let strMatchedValue;
// then default to comma.
      strDelimiter = (strDelimiter || ';');
      // Create a regular expression to parse the CSV values.
      const objPattern = new RegExp((
        // Delimiters.
        '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' + strDelimiter + '\\r\\n]*))'), 'gi');
      // Create an array to hold our data. Give the array
      // a default empty first row.
      const arrData = [[]];
      // Create an array to hold our individual pattern
      // matching groups.
      let arrMatches = null;
      // Keep looping over the regular expression matches
      // until we can no longer find a match.
      while (arrMatches = objPattern.exec(strData)) {
        // Get the delimiter that was found.
        const strMatchedDelimiter = arrMatches[1];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push([]);
        }
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          strMatchedValue = arrMatches[2].replace(
            new RegExp('""', 'g'), '"');
        } else {
          // We found a non-quoted value.
          strMatchedValue = arrMatches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
      }
      // Return the parsed data.
      return (arrData);
    }

    vm.setContent = function(csv) {
      const array = CSVToArray(csv);
      const objArray = [];
      for (let i = 1; i < array.length; i += 1) {
        if (array[i][0].length > 0) {
          objArray[i - 1] = {};
          objArray[i - 1].status = '-';
          for (let k = 0; k < array[0].length && k < array[i].length; k += 1) {
            const key = array[0][k];
            objArray[i - 1][key] = array[i][k];
          }
        }
      }

      vm.importdata = objArray;
    };

    vm.validateInput = function() {
      angular.forEach(vm.importdata, function(value, key) {
        PatientService.getEmailExists(value.Email).then(function(response) {
          if (response.exist) {
            if (response.existsAsUser) {
              value.status = 'Email exists';
            } else if (response.existsAsStaff) {
              value.status = 'Exists as staff';
            }
          } else {
            PatientService.getIdentificationNoExists(value.PNRNumber.replace('-', '').trim()).then(function(response) {
              if (response.exist) {
                value.status = 'PNR exists';
              } else {
                value.status = 'Ok';
              }
            });
          }
        });
      });
    };

    vm.savePatient = function() {

      // Creating an empty initial promise that always resolves itself.
      let promise = $q.all([]);

      angular.forEach(vm.importdata, function(value, key) {
        promise = promise.then(function() {
          return $timeout(function() {
            console.log('Item executed: ' + value.Email);
            const user = {
              firstName: value.Firstname,
              lastName: value.Lastname,
              email: value.Email,
              password: vm.password,
              confirmPassword: vm.password,
              patientIdentificationNo: value.PNRNumber.replace('-', '').trim(),
              allowedLoginMethods: 'passwordAndBankId',
              // If healthcare provider admin don't craete a subscription or if is set to Coala pro user
              subscriptionEnds: vm.to.format('YYYY-MM-DD'),
              subscriptionType: vm.subscriptionType.value,
              subscriptionCancelled: true,
              subscriptionDibsId: 'CoalaAdminPortal',
              callsLeft: 0,
              testUser: vm.testUser,
              healthcareProviderId: vm.healthcareProviderId
            };

            PatientService.createPatient(user).then(function(response) {
              vm.showFeedback($translate.instant('user_created') + ' ' + user.email);
              console.log('Created user: ' + user.email);
              value.status = 'Ok';
              // closeModal();
            }, function(error) {
              value.status = 'Failed';
              let errorText = user.email;
              if (error.message !== undefined) {
                errorText = error.message;
              }
              vm.showError($translate.instant('user_created_error') + ' ' + errorText);
              console.log('Failed to create user: ' + user.email + ' Error: ' + error);
            });
          }, 2000);
        });
      });
    };

    vm.setCaregiver = function() {
      vm.healthcareProviderId = vm.selectedProvider.id;
    };

    vm.setToDate = function() {
      let ending;
      const fm = jQuery.grep(vm.freeMonthsOptions, function(x) {
        return x.value === vm.freeMonths.value;
      })[0].value;

      vm.to = moment(vm.subscription.to);
      if (vm.comment === undefined) {
        vm.comment = '';
      }
      if (vm.isEditMode) {
        ending = vm.oldFreeMonths === 1 ? '' : 's';
        const remove = 'Added ' + vm.oldFreeMonths + ' free month' + ending + '\n';
        vm.comment = vm.comment.replace(remove, '');
      }
      if (fm !== 0) {
        vm.freeMonthsUpdated = true;
        vm.to = vm.to.add(fm, 'month');
        if (vm.isEditMode) {
          ending = fm === 1 ? '' : 's';
          vm.comment = 'Added ' + fm + ' free month' + ending + '\n' + vm.comment;
        }
        vm.oldFreeMonths = fm;
      } else {
        vm.freeMonthsUpdated = false;
      }
    };

    vm.setPassword = function() {
      $scope.importPatientsForm.password.$setValidity('', true);
      // $scope.createPatientForm.confirmPassword.$setValidity('', true);
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    function closeModal() {
      if (!closing) {
        close('', 500);
        $('#usermodal').modal('hide');
      }
      closing = true;
    }
  }
})();
