import * as angular from 'angular';

(function() {
  'use strict';
  angular.module('core').directive('ngMobileClick', [function() {
    return function(scope, elem, attrs) {
      elem.bind('touchstart', function(e) {
        e.preventDefault();
        e.stopPropagation();

        scope.$apply(attrs['ngMobileClick']);
      });
    };
  }]);
})();
