import * as angular from 'angular';
import moment from "moment";

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientReportListController', PatientReportListController);

  function PatientReportListController(
    $translate,
    $filter,
    PatientService,
    SessionService,
    NationService,
    UtilService,
    ReportHelperService
  ) {
    const vm = this;
    vm.permissions = SessionService.getUserPermissions();
    vm.formats = NationService.getDateFormats();
    vm.configuration = NationService.getConfiguration();
    vm.reportReviewTypeOptions = NationService.getReportReviewTypeOptions();
    vm.reportCreatorTypeOptions = NationService.getReportCreatorTypeOptions();

    let _tableStateReport = null;
    let pagingParametersReport = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      $('#datetimepickerFrom').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerTo').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerFrom').on('dp.hide', function(e) {
        vm.fromdate = e.date;
        vm.searchReports();
      });

      $('#datetimepickerTo').on('dp.hide', function(e) {
        vm.todate = e.date;
        vm.searchReports();
      });

      vm.reviewType = SessionService.getFilterOptions().searchOptionReports.reviewType;
      vm.reportCreatorType = SessionService.getFilterOptions().searchOptionReports.reportCreatorType;
      document.getElementById('datetimepickerFrom').value = SessionService.getFilterOptions().searchOptionReports.fromdate;
      document.getElementById('datetimepickerTo').value = SessionService.getFilterOptions().searchOptionReports.todate;


    }

    function updateUserReports(tableState) {
      let formatedDate;
      vm.showSpinner = true;
      if (tableState) {
        _tableStateReport = tableState;
      }

      const reportfilters = [];
      const fromInput = document.getElementById('datetimepickerFrom').value;
      const toInput = document.getElementById('datetimepickerTo').value;
      if (fromInput != '') {
        formatedDate = moment(fromInput, vm.formats.shortDatePicker);
        reportfilters.push({value: 'true', propertyKey: 'fromdate', propertyValue: formatedDate.format('YYYY-MM-DD')});
      }
      if (toInput != '') {
        formatedDate = moment(toInput, vm.formats.shortDatePicker).add(1, 'days');
        reportfilters.push({value: 'true', propertyKey: 'todate', propertyValue: formatedDate.format('YYYY-MM-DD')});
      }
      if (vm.reviewType !== undefined && vm.reviewType.value == 1) {
        reportfilters.push({value: 'true', propertyKey: 'viewed', propertyValue: false});
      }
      if (vm.reviewType !== undefined && vm.reviewType.value == 2) {
        reportfilters.push({value: 'true', propertyKey: 'viewedByMe', propertyValue: false});
      }
      // if (vm.reportType !== undefined && vm.reportType.value >= 0) {
      //   reportfilters.push( { value: 'true', propertyKey: 'reportTypeId', propertyValue: vm.reportType.value } );
      // }
      if (vm.reportCreatorType !== undefined && vm.reportCreatorType.value >= 0) {
        reportfilters.push({value: 'true', propertyKey: 'reportCreatorTypeId', propertyValue: vm.reportCreatorType.value});
      }

      pagingParametersReport = UtilService.preparePagingParameters(
        _tableStateReport,
        reportfilters
      );
      pagingParametersReport.pageSize = 20;

      const filterOptions = SessionService.getFilterOptions();
      filterOptions.searchOptionReports.fromdate = fromInput;
      filterOptions.searchOptionReports.todate = toInput;
      filterOptions.searchOptionReports.reviewType = vm.reviewType;
      filterOptions.searchOptionReports.reportCreatorType = vm.reportCreatorType;
      SessionService.setFilterOptions(filterOptions);

      PatientService.getReports(pagingParametersReport).then(function(data) {
        vm.reports = data.rows;
        vm.totalReports = data.nbrOfRows;
        vm.nrOfReportPages = data.nbrOfPages;
        vm.showSpinner = false;

        if (_tableStateReport) {
          _tableStateReport.pagination.numberOfPages = data.nbrOfPages;
        }
      });
    }

    vm.searchReports = function() {
      if (_tableStateReport) {
        _tableStateReport.pagination.start = 0;
        updateUserReports();
      }
    };

    vm.fetchReports = function(tableState) {
      updateUserReports(tableState);
    };

    vm.reportDescription = function(report) {
      return ReportHelperService.reportDescription(report);
    };

    vm.reportCreatedBy = function(report) {
      return ReportHelperService.reportCreatedBy(report);
    };

    vm.reportPatient = function(report) {
      return ReportHelperService.reportPatient(report);
    };

    vm.getLocalTime = function(utctime) {
      return UtilService.getLocalTime(utctime);
    };

    vm.showDownloadLink = function(report) {
      return ReportHelperService.showDownloadLink(report);
    };

    vm.reportIsNotViewed = function(report) {
      return ReportHelperService.reportIsNotViewed(report);
    };

    vm.reportIsViewed = function(report) {
      return ReportHelperService.reportIsViewed(report);
    };

    vm.setToViewed = function(reportId, storagePath) {
      ReportHelperService.setToViewed(reportId, storagePath, vm.reports);
    };

    vm.reportGeneratorFailed = function(report) {
      return ReportHelperService.reportGeneratorFailed(report);
    };

  }
})();
