import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminMeasurementController', AdminMeasurementController);

  function AdminMeasurementController($scope, $state, $filter, $stateParams, $translate, HealthcareProviderService, MeasurementHelperService, UtilService, NationService, envConfig) {
    const vm = this;
    let _tableState = null;
    vm.formats = NationService.getDateFormats();
    vm.markedmeasurments = [];
    vm.itemsPerPage = envConfig.defaultTableItemsPerPage;
    vm.startItem = 0;
    vm.showSpinner = false;
    vm.fromdate = null;
    vm.todate = null;

    let pagingParameters = {
      page: 0,
      pageSize: vm.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      $('#datetimepickerFrom').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerTo').datetimepicker({
        icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
        format: vm.formats.shortDatePicker
      });

      $('#datetimepickerFrom').on('dp.hide', function(e) {
        vm.fromdate = e.date;
        vm.searchMeasurement();
      });

      $('#datetimepickerTo').on('dp.hide', function(e) {
        vm.todate = e.date;
        vm.searchMeasurement();
      });
    }

    vm.searchMeasurement = function() {
      vm.fetchMeasurements();
    };

    vm.fetchMeasurements = function(tableState) {
      let changedByUri = false;
      if (_tableState === null) {
        changedByUri = true;
      }
      if (tableState) {
        _tableState = tableState;
      }

      let startItem = $stateParams.startItem;
      if (startItem !== '' && startItem !== undefined && startItem !== null && changedByUri) {
        _tableState.pagination.start = parseInt(startItem, 10);
      } else if (startItem !== undefined) {
        startItem = _tableState.pagination.start;
      }
      vm.startItem = startItem;

      vm.filters = [];
      const fromInput = document.getElementById('datetimepickerFrom').value;
      const toInput = document.getElementById('datetimepickerTo').value;
      if (vm.fromdate != null && fromInput != '') {
        vm.filters.push({value: 'true', propertyKey: 'fromdate', propertyValue: vm.fromdate.format('YYYY-MM-DD')});
      }
      if (vm.todate != null && toInput != '') {
        vm.filters.push({value: 'true', propertyKey: 'todate', propertyValue: vm.todate.format('YYYY-MM-DD')});
      }

      pagingParameters = UtilService.preparePagingParameters(_tableState, vm.filters);

      if (startItem !== undefined) {
        $state.go('mainAdmin.measurement', {
          startItem: vm.startItem
        }, {notify: false});
      }

      updateMeasurementsTable();
    };

    function updateMeasurementsTable() {
      vm.showSpinner = true;
      HealthcareProviderService.getAdminMeasurements(pagingParameters).then(function(data) {
        vm.measurements = data.rows;
        vm.nrOfMeasurements = data.nbrOfRows;
        vm.nrOfPages = data.nbrOfPages;

        if (_tableState) {
          _tableState.pagination.numberOfPages = data.nbrOfPages;
        }

        vm.showSpinner = false;
      });
    }

    vm.feelingBeforeMeasurementString = function(measurement) {
      return MeasurementHelperService.feelingBeforeMeasurementString(measurement);
    };

    vm.parseSubscriptionType = function(type) {
      if (type === 'none') {
        return $translate.instant('subscriptiontype_none');
      } else if (type === 'base') {
        return $translate.instant('subscriptiontype_basic');
      } else if (type === 'plus') {
        return $translate.instant('subscriptiontype_premium');
      }

      return '';
    };

    vm.parseErrorCode = function(measurementParts, type) {
      return MeasurementHelperService.parseErrorCode(measurementParts, type);
    };

    vm.parseAppErrorCode = function(measurement) {
      return MeasurementHelperService.parseAppErrorCode(measurement);
    };

    vm.parseApproxDateTime = function(approxDateTime) {
      let dateTime = new Date(approxDateTime);
      const hours = 2 * 60 * 60 * 1000;
      dateTime = dateTime.getTime() + hours;
      return new Date(dateTime);
    };

    vm.appStatus = function(measurement) {
      return MeasurementHelperService.appStatus(measurement);
    };

    vm.signalStatus = function(measurementParts, type) {
      const finger = jQuery.grep(measurementParts, function(x) {
        return x.measurementPartType === 'ecg' + type;
      });

      if (finger !== null && finger !== undefined && finger.length > 0) {
        const errorCode = finger[0].analysisErrorCode;
        const category = finger[0].analysisCategory;

        if (category === null) {
          return 'fa fa-heart disabledFont';
        }
        if (errorCode === 'noError' && category === 'noRhythmDeviation') {
          return 'fa fa-heartbeat coalaGreen';
        }
        if (errorCode !== 'noError' || category === 'poorQuality') {
          return 'fa fa-heart disabledFont';
        }
        return 'fa fa-heartbeat error';
      }

      return 'fa';
    };

    vm.setMeasurementMarked = function(id) {
      const index = vm.markedmeasurments.indexOf(id);

      if (index < 0) {
        vm.markedmeasurments.push(id);
      } else {
        vm.markedmeasurments.splice(index, 1);
      }

      /* angular.forEach(vm.markedmeasurments, function(item) {
        alert('item: ' + item);
      });	*/
    };

    vm.getMeasurementMarked = function(id) {
      return vm.markedmeasurments.indexOf(id) >= 0;
    };

    vm.isMarket = function() {
      return vm.markedmeasurments.length > 0;
    };

    vm.downloadMeasurementsFile = function() {
      vm.showSpinner = true;
      console.log('download pdf file for multiple measurements');
      HealthcareProviderService.getChestAndFingerMeasurementsPdf('measurements_' + moment().format('YYYYMMDD_HHmm') + '.pdf', vm.markedmeasurments).then(function(data) {
        vm.showSpinner = false;
      }, function(reason) {
        vm.showSpinner = false;
        vm.showError($translate.instant('download_createpdffile_error'));
      });
    };

    vm.goToMeasurement = function(measurementId) {
      $state.go('mainAdmin.measurementdetail', {
        measurementId: measurementId,
        startItem: vm.startItem
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
