import * as angular from 'angular';
import * as moment from 'moment';

export interface TimeReport {
    userId: any;
    timereportId: any;
    sessiontime: number;
    time: number;
    totaltime: number;
    laststored: any;
    active: boolean;
}

export class ReportTimeService {
    private readonly translateService: any;
    private readonly SessionService: any;
    private readonly PatientService: any;
    private intervalautostore: any;
    private intervalticker: any;
    
    private timereport!: TimeReport;
    
    constructor($http: any, $interval: any, SessionService: any, PatientService: any, $translate: any) {
        this.SessionService = SessionService;
        this.PatientService = PatientService;
        this.translateService = $translate;
        this.timereport = {userId: null, timereportId: null, time: 0, sessiontime: 0, totaltime: 0, active: false, laststored: moment.utc()};
    }

    startReportTime(userId: any) {
        if (this.SessionService.getIsMonitoringCenterStaff()) {
            console.log('START time report for user: ' + userId);
            
            if (this.timereport.userId == null) {
                let cached = this.getReportTime();
                if (cached !== null) {
                    if (cached.userId == userId) {
                        this.timereport = cached;
                    }
                } 
            }

            if (this.timereport.userId !== userId) {
                this.storeReportTime();
                this.stopReportTime(this.timereport.userId);
                this.timereport.userId = userId;
                this.timereport.timereportId = null;
                this.fetchPatientTimeReportTotalTime();
            }

            this.timereport.userId = userId;
            this.timereport.active = true;

            if (this.intervalautostore == null) {
                this.intervalautostore = setInterval(() => { this.autoStoreReportTime(); }, 30000);
                this.intervalticker = setInterval(() => { this.updateReportTimeTicker(); }, 1000);
            }
        }
    };

    stopReportTime(userId: any) {
        if (this.SessionService.getIsMonitoringCenterStaff()) {
            if (userId == this.timereport.userId && this.timereport.userId !== null) {
                console.log('STOP time report for user: ' + userId);
                if (this.intervalautostore) {
                    clearInterval(this.intervalautostore);
                    clearInterval(this.intervalticker);
                    this.intervalautostore = null;
                    this.intervalticker = null;
                }
                this.timereport.active = false;
                this.storeReportTime();
            }
        }
    };

    addTime(userId: any, time: number) {
        if (this.timereport.userId == userId) {
            this.timereport.totaltime += time;
        }
    }

    getPatientTotalTimeSpentThisMonth() {
        return this.getPatientSessionTimeSpent() + ' (' + this.getPatientTotalTimeSpent() + ')';
    };

    private getPatientTotalTimeSpent() {
        let seconds = this.timereport.totaltime % 60;
        return Math.floor(this.timereport.totaltime/60) + ':' + (seconds < 10 ? '0' + seconds : seconds) + ' ' + this.translateService.instant('timereport_min');
    };

    private getPatientSessionTimeSpent() {
        let seconds = this.timereport.sessiontime % 60;
        return Math.floor(this.timereport.sessiontime/60) + ':' + (seconds < 10 ? '0' + seconds : seconds) + this.translateService.instant('timereport_min');
    };

    private getPatientTimeSpent() {
        let seconds = this.timereport.time % 60;
        return '00:' + Math.floor(this.timereport.time/60) + ':' + (seconds < 10 ? '0' + seconds : seconds);
    };

    private autoStoreReportTime() {
        if (this.timereport.time > 30) {
            this.storeReportTime();
        }
    }

    private storeReportTime() {
        if (this.timereport.time > 0) {
            console.log('STORE time report for user: ' + this.timereport.userId + ', time: ' + this.timereport.time + ', totaltime: ' + this.timereport.totaltime);
            this.addTimeReport();
            sessionStorage.setItem('timereport', JSON.stringify(this.timereport));
        }
    }

    private getReportTime() {
        const timereport = sessionStorage.getItem('timereport');
        if (timereport == null) {
            return null;
        }
        return jQuery.parseJSON(timereport);
    };

    private updateReportTimeTicker() {
        if (this.timereport.active && this.timereport.userId !== null) {
            this.timereport.totaltime = this.timereport.totaltime + 1;
            this.timereport.sessiontime = this.timereport.sessiontime + 1;
            this.timereport.time = this.timereport.time + 1;
        }
    };

    private fetchPatientTimeReportTotalTime() {
        const providerId = this.SessionService.getReprestingProviderId();
        const d = new Date();
        const offset = d.getTimezoneOffset();
        this.PatientService.getTimeReportTotalTimeForUser(providerId, this.timereport.userId, offset).then((data: { totalTime: any; }) => {
            let timeArray = data.totalTime.split(':');
            let tot = parseInt(timeArray[0]) * 60 * 60 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[2]);
            console.log('totalTime: ' + tot);
            this.timereport.totaltime = tot;
            this.timereport.sessiontime = 0;
            this.timereport.time = 0;
        });
    };

    private addTimeReport() {
        const providerId = this.SessionService.getReprestingProviderId();
        const timereport = {
            userId: this.timereport.userId,
            timeReportId: this.timereport.timereportId,
            time: this.getPatientTimeSpent(),
            healthcareProviderId: providerId
        };
        this.PatientService.postTimeReport(timereport).then((data: any) => {
            this.timereport.timereportId = data;
            this.timereport.time = 0;
            console.log('Time report Stored');
        });
    };
}

angular.module('core').service('ReportTimeService',
    ['$http', '$interval', 'SessionService', 'PatientService', '$translate', ReportTimeService]
);
