import * as angular from 'angular';

angular.module('admin', []);
angular.module('admin').config(['$stateProvider', '$urlRouterProvider', routes]);

function routes($stateProvider: any, $urlRouterProvider: any) {
    $stateProvider
        .state('mainAdmin', {
            url: '',
            template: require('./views/admin.layout.tpl.html').default,
            abstract: true,
            data: {
                access: 'admin'
            }
        })
        .state('mainAdmin.dashboard', {
            url: '/admin/dashboard',
            controller: 'AdminDashboardController',
            template: require('./views/admin.dashboard.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.adminlist', {
            url: '/admin/doctor/list',
            controller: 'AdminController',
            template: require('./views/admin.doctor.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.healthcareproviderlist', {
            url: '/admin/healthcareprovider/list',
            controller: 'AdminHealthcareProviderListController',
            template: require('./views/admin.healthcareProvider.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.grouplist', {
            url: '/admin/group/list',
            controller: 'AdminGroupController',
            template: require('./views/admin.group.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.userlist', {
            url: '/admin/user/list/:startItem',
            controller: 'AdminUserListController',
            template: require('./views/admin.user.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.userdetails', {
            url: '/admin/user/details/:userId/:startItem',
            controller: 'AdminUserListController',
            template: require('./views/admin.user.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.measurement', {
            url: '/admin/measurement/list/:startItem',
            controller: 'AdminMeasurementController',
            template: require('./views/admin.measurement.list.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.measurementdetail', {
            url: '/admin/measurement/:measurementId/:startItem/detail',
            controller: 'AdminMeasurementDetailController',
            template: require('./views/admin.measurement.detail.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.emailtool', {
            url: '/admin/emailtool',
            controller: 'AdminEmailtoolController',
            template: require('./views/admin.emailtool.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.setAnalysisResult', {
            url: '/admin/listanalysisresults',
            controller: 'AdminAnalysisResultController',
            template: require('./views/admin.analysisresult.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.info', {
            url: '/core/info',
            controller: 'InfoController',
            template: require('../core/views/core.info.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.azureInfo', {
            url: '/core/azureinfo',
            controller: 'AzureInfoController',
            template: require('../core/views/core.azure.info.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.patientDetails', {
            url: '/patient/details/:userId/:startItem',
            controller: 'PatientDetailsController',
            template: require('../patients/views/patient.details.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        })
        .state('mainAdmin.settings', {
            url: '/core/settings',
            controller: 'SettingsController',
            template: require('../core/views/core.settings.tpl.html').default,
            controllerAs: 'vm',
            authenticate: true
        }).state('mainAdmin.payments', {
        url: '/admin/payments/list/:startItem',
        controller: 'AdminPaymentController',
        template: require('./views/admin.payment.list.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('mainAdmin.serialnumberlist', {
        url: '/admin/serialnumber/list/:startItem',
        controller: 'AdminSerialNumberController',
        template: require('./views/admin.serialnumber.list.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    }).state('mainAdmin.aimo', {
        url: '/admin/user/aimo/:userId',
        controller: 'AdminAIMOController',
        template: require('./views/admin.aimo.tpl.html').default,
        controllerAs: 'vm',
        authenticate: true
    });

    $urlRouterProvider.otherwise('/login');
}

// / Small components

angular.module('admin')
    .component('adminChestEcg', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/chest.ecg.tpl.html').default
    });

angular.module('admin')
    .component('adminFingerEcg', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/finger.ecg.tpl.html').default
    });

angular.module('admin')
    .component('adminSound', {
        bindings: {
            vm: '=',
        },
        template: require('./views/partials/sound.tpl.html').default
    });
