import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('admin')
    .controller('AdminDashboardController', AdminDashboardController);

  function AdminDashboardController(InfoService, SessionService) {
    const vm = this;

    vm.analyserStatus = '';
    vm.permissions = SessionService.getUserPermissions();

    vm.runAnalyserStatus = function() {
      if (!vm.permissions.coalaAdmin) {
        return;
      }
      vm.analyserStatus = 'error';
      InfoService.getAnalyserStatus().then(function(response) {
        vm.analyserStatus = 'coalaGreen';
      }, function(response) {
        vm.analyserStatus = 'warning';
      });
    };

    vm.runAnalyserStatus();
  }
})();
