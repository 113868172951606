import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PaymentCompletedController', PaymentCompletedController);

  function PaymentCompletedController($translate, $state, $location, MeService) {
    const vm = this;

    activate();

    function activate() {
      checkPaymentCallback();
    }

    function checkPaymentCallback() {
      vm.showSpinner = true;
      vm.orderId = $location.search().order_id;
      if (vm.orderId != null) {
        vm.showKlarnaConfirm = true;
        MeService.getKlarnaOrder(vm.orderId).then(function(response) {
          const confirmationContainer = document.getElementById('klarna-confirm-container');
          confirmationContainer.innerHTML = response.html_snippet;
          const scriptsTags = confirmationContainer.getElementsByTagName('script');
          // This is necessary otherwise the scripts tags are not going to be evaluated
          for (let i = 0; i < scriptsTags.length; i += 1) {
            const parentNode = scriptsTags[i].parentNode;
            const newScriptTag = document.createElement('script');
            newScriptTag.type = 'text/javascript';
            newScriptTag.text = scriptsTags[i].text;
            parentNode.removeChild(scriptsTags[i]);
            parentNode.appendChild(newScriptTag);
          }
          vm.showSpinner = false;
          vm.showFeedback($translate.instant('payment_success'));

        }, function(response) {
          vm.showSpinner = false;
          vm.showError($translate.instant('payment_error'));
        });
      }
    }

    vm.showConfirmationWidget = function() {
      return !vm.showSpinner;
    };

    vm.goToMyPages = function() {
      $state.go('me.myprofile');
    };

    vm.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vm.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
