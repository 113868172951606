import * as angular from 'angular';
import ScheduledTaskListResponse from 'app/services/models/ScheduledTaskListResponse';
import {NationService} from '../../../../services/NationService';

export interface HcpScheduledTasksSettings {
    tasksList: ScheduledTaskListResponse[];
}

// language=HTML
export const template = `
    <div class="coala-block-fullwidth">
        <table class="table table-condensed">
            <thead>
            <tr>
                <th>{{ "scheduledtasks_column_name" | translate }}</th>
                <th>{{ "scheduledtasks_column_patient" | translate }}</th>
                <th>{{ "scheduledtasks_column_nextrundate" | translate }}</th>
                <th>{{ "scheduledtasks_column_lastrundate" | translate }}</th>
                <th>{{ "scheduledtasks_column_recurrent" | translate }}</th>
                <th>{{ "scheduledtasks_column_result" | translate }}</th>
            </tr>
            </thead>
            <tbody ng-repeat="item in $ctrl.settings.tasksList">
            <tr>
                <td>
                    {{ item.name }}
                </td>
                <td>
                    {{ item.fullName }}
                </td>
                <td>
                    {{ item.nextRunDate | date:$ctrl.formats.longDate }}
                </td>
                <td>
                    {{ item.lastRunDate | date:$ctrl.formats.longDate }}
                </td>
                <td>
                    {{ $ctrl.recurrentInfo(item) }}
                </td>
                <td>
                    {{ $ctrl.resultInfo(item) }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
`;

angular.module('admin')
    .component('hcpScheduledTasksForm',
        {
            bindings: {
                settings: '='
            },
            template: template,
            controller: ['$translate', 'NationService', class HcpScheduledTasksForm {
                settings!: HcpScheduledTasksSettings;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly formats: any;

                constructor($translate: angular.translate.ITranslateService, NationService: NationService) {
                    this.translateService = $translate;
                    this.formats = NationService.getDateFormats();
                }

                recurrentInfo = (item: any) => {
                    const triggerType = item.scheduledTaskTriggerId;
                    if (triggerType === 'once') {
                       return '-';
                   }
                   return item.recurEvery + ' ' + triggerType;
                };

                resultInfo = (item: any) => {
                    const resultCode = item.resultCode;
                    if (resultCode === 'notExecuted') {
                        return 'Not executed';
                    }
                    return item.resultCode;
                 };

            }]
        }
    );
