import * as angular from 'angular';

(function() {
  'use strict';

  function SettingsService($q, $http, envConfig, SessionService, HttpClientService) {
    const service = {};
    const root = envConfig.baseUrl;

    service.getSettings = function() {
      return HttpClientService.get(root + '/adminapi/v1/settings');
    };

    service.putSetting = function(setting) {
      return HttpClientService.put(root + '/adminapi/v1/settings', setting);
    };

    return service;
  }

  angular
    .module('core')
    .factory('SettingsService', SettingsService);

})();
