import * as angular from 'angular';
import {DateFormats, LoginMethodOption, NationConfiguration, NationService} from '../../../../services/NationService';
import {User} from '../../../../models/User';
import Permissions from '../../../../services/models/Permissions';
import PatientService from '../../../../services/PatientService';
import HealthcareProviderService from '../../../../services/HealthcareProviderService';


export interface PatientDetailsFormSettings {
    user: User;
    originalUser: User;
    healthCareProviders: {id: string; name: string}[];
    measurementConfigurations: {name: string; id: string}[];
    selectedMeasurementConfigurations: {name: string; id: string}[];
    loginMethodsOptions: LoginMethodOption[];
    existsAsStaff: boolean;
    imei: string;
}

// language=HTML
export const template = `
    <form name="$ctrl.createPatientForm">
        <div class="row">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"patient_details" | translate}}
                </div>
                <div class="col-md-6">
                    <label>{{"firstname" | translate}} *</label><br>
                    <input autocomplete="off" autofocus class="input-text-lg input-medium blockInput form-control"
                           id="userfirstname" name="firstname"
                           ng-class="{'has-error': $ctrl.settings.user.firstName.$invalid}" ng-disabled="$ctrl.settings.existsAsStaff"
                           ng-model="$ctrl.settings.user.firstName" required
                           style="margin-right:10px;" translate-attr="{ placeholder: 'firstname' }" type="text">

                    <label>{{"lastname" | translate}} *</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="lastname"
                           ng-class="{'has-error': $ctrl.settings.user.lastName.$invalid}"
                           ng-disabled="$ctrl.settings.existsAsStaff" ng-model="$ctrl.settings.user.lastName" required
                           translate-attr="{ placeholder: 'lastname' }" type="text">
                </div>

                <div class="col-md-6">

                    <span class="warning" ng-show="$ctrl.createPatientForm.email.$dirty && $ctrl.createPatientForm.email.$invalid && !$ctrl.emailExists" style="float: right">
                        {{ "user_create_email" | translate}}
                    </span>
                    <span class="warning" ng-show="$ctrl.emailExists && !$ctrl.settings.existsAsStaff" style="float: right">
                        {{ "user_create_email_insystem" | translate}}
                    </span>
                    <span class="warning" ng-show="$ctrl.emailExists && $ctrl.settings.existsAsStaff" style="float: right">
                        {{ "user_create_doctor_exists_message" | translate}}
                    </span>
                    <label>{{"email" | translate}} *</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="email"
                           ng-change="$ctrl.checkEmail()" ng-class="{'has-error': $ctrl.settings.user.email.$invalid}"
                           ng-model="$ctrl.settings.user.email"
                           ng-pattern="/^[-a-z0-9~!$%^&*_=+}{\\'?]+(\\.[-a-z0-9~!$%^&*_=+}{\\'?]+)*@([a-z0-9_][-a-z0-9_]*(\\.[-a-z0-9_]+)*\\.([a-z]{2,10}))?$/i"
                           required translate-attr="{ placeholder: 'email' }"
                           type="email">

                    <label>{{"general_phone_number" | translate}} {{$ctrl.isUS ? '*' : ''}}</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="phone"
                           ng-class="{'has-error': $ctrl.settings.user.phoneNumber.$invalid}"
                           ng-model="$ctrl.settings.user.phoneNumber"
                           ng-required="$ctrl.isUS"
                           translate-attr="{ placeholder: 'general_phone_number' }"
                           type="tel">

                </div>
                <div>
                    <div class="col-md-6" ng-show="$ctrl.settings.user.dateOfBirthFormat">
                        <span class="warning" ng-show="$ctrl.createPatientForm.dateOfBirth.$dirty && $ctrl.createPatientForm.dateOfBirth.$invalid"
                              style="float: right">
                          {{ "user_create_invalid_dateofbirth" | translate}}
                        </span>
                        <label>{{"patient_date_of_birth" | translate}} {{$ctrl.settings.user.dateOfBirthFormat ? '*' : ''}}</label><br>
                        <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" date='dd-MM-yyyy' name="dateOfBirth"
                               ng-class="{'has-error': $ctrl.settings.user.dateOfBirth.$invalid}" ng-model="$ctrl.settings.user.dateOfBirth"
                               ng-pattern="$ctrl.nationConfiguration.dateOfBirthalidationRegExp"
                               ng-required="$ctrl.settings.user.dateOfBirthFormat" ng-show="$ctrl.settings.user.dateOfBirthFormat"
                               placeholder="{{$ctrl.settings.user.dateOfBirthFormat}}" type="text">
                    </div>
                    <div class="col-md-6">
                        <span class="warning"
                              ng-show="$ctrl.createPatientForm.patientIdentificationNo.$dirty && $ctrl.createPatientForm.patientIdentificationNo.$invalid && !$ctrl.identificationNoExists"
                              style="float: right">
                            {{ "user_create_invaidid" | translate}}
                        </span>
                        <span class="warning" ng-show="$ctrl.identificationNoExists" style="float: right">
                            {{ "user_create_id_insystem" | translate}}
                        </span>
                        <label>{{"patient_identification_no" | translate}} {{$ctrl.isPatientIdRequired() ? '*' : ''}}</label><br>
                        <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="patientIdentificationNo"
                               ng-change="$ctrl.setIdentificationNo()" ng-class="{'has-error': $ctrl.settings.user.patientIdentificationNo.$invalid}"
                               ng-disabled="$ctrl.disablePatientIdentificationNo()"
                               ng-model="$ctrl.settings.user.patientIdentificationNo"
                               ng-pattern="$ctrl.nationConfiguration.patientIdentificationNoValidationRegExp"
                               ng-required="$ctrl.isPatientIdRequired()" placeholder="{{$ctrl.nationConfiguration.patientIdentificationNoFormat}}"
                               type="text">
                    </div>
                </div>

            </fieldset>
        </div>

        <!-- HEALTHCARE PROVIDER -->
        <div class="row" ng-show="!$ctrl.isEditMode && $ctrl.showHealthcareProviders()">
            <div class="col-md-12 title">
                {{ "serialnumber_column_hcp" | translate }}
            </div>
            <div class="col-md-12">
                <fieldset class="form-inline">
                    <label>{{"doctor_create_select_healthcare" | translate}} {{$ctrl.isHealthcareProviderRequired() ? '*' : ''}}</label><br>
                    <select class="input-text-lg input-medium blockInput form-control" id="fav_show6" name="fav_show"
                            ng-model="$ctrl.settings.user.healthcareProvider"
                            ng-options="provider.name for provider in $ctrl.settings.healthCareProviders track by provider.id"
                            ng-required="$ctrl.isHealthcareProviderRequired()">
                        <option value="">{{ "doctor_create_select_healthcare" | translate }}</option>
                    </select>
                </fieldset>
            </div>
        </div>

        <!-- PASSWORD -->
        <div class="row">
            <fieldset class="form-group">
                <div class="col-md-12 title">
                    {{"password" | translate}}
                </div>
                <!-- LOGIN METHODS-->
                <div class="col-md-12" ng-show="$ctrl.permissions.manageAccount">
                    <label>{{"user_create_loginmethods" | translate}} *</label><br>
                    <select class="input-text-lg input-medium blockInput form-control" id="fav_show4" name="fav_show"
                            ng-disabled="$ctrl.settings.existsAsStaff" ng-model="$ctrl.settings.user.allowedLoginMethod"
                            ng-options="option.name | translate for option in $ctrl.settings.loginMethodsOptions track by option.value"
                            required
                            style="padding-left:14px;">
                    </select>
                </div>

                <div class="col-md-12" ng-show="$ctrl.isEditMode">
                    <p>{{ "user_create_pwd_info" | translate }}</p>
                </div>

                <div class="col-md-6">
                    <label>{{"password" | translate}} {{!$ctrl.isEditMode && !$ctrl.settings.existsAsStaff ? '*' : ''}}</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="password" id="password"
                           ng-change="$ctrl.setPassword()" ng-class="{'has-error': $ctrl.settings.user.password.$invalid}"
                           ng-disabled="$ctrl.settings.existsAsStaff" ng-model="$ctrl.settings.user.password"
                           ng-required="!$ctrl.isEditMode && !$ctrl.settings.existsAsStaff" ng-focus="$ctrl.togglePassword('password', false)" translate-attr="{ placeholder: 'password' }"
                           type="{{$ctrl.typePasswordInput}}">
                            <a style="float: right; margin-top: -45px; margin-right: 20px;" ng-click="$ctrl.togglePassword('password')">
                                <i ng-class="$ctrl.togglePasswordIcon('password')"></i>
                            </a>
                </div>

                <div class="col-md-6">
                    <label>{{"confirmpassword" | translate}} {{!$ctrl.isEditMode && !$ctrl.settings.existsAsStaff ? '*' : ''}}</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control" name="confirmPassword" id="confirmPassword"
                           ng-change="$ctrl.setPassword()" ng-class="{'has-error': $ctrl.settings.user.confirmPassword.$invalid}"
                           ng-disabled="$ctrl.settings.existsAsStaff"
                           ng-model="$ctrl.settings.user.confirmPassword" ng-required="!$ctrl.isEditMode && !$ctrl.settings.existsAsStaff"
                           ng-focus="$ctrl.togglePassword('confirmPassword', false)" translate-attr="{ placeholder: 'confirmpassword' }"
                           ng-pattern="$ctrl.settings.user.password" type="{{$ctrl.typeConfirmPasswordInput}}">
                            <a style="float: right; margin-top: -45px; margin-right: 20px;" ng-click="$ctrl.togglePassword('confirmPassword')">
                                <i ng-class="$ctrl.togglePasswordIcon('confirmPassword')"></i>
                            </a>
                </div>
                <div class="col-md-12">
                    <p>{{ "user_create_pwd_requirement" | translate}}</p>
                </div>

                <div class="col-md-12" ng-show="$ctrl.permissions.writeAndroidDevices && $ctrl.isUS">
                    <label>Coala Go! IMEI</label><br>
                    <input autocomplete="off" class="input-text-lg input-medium blockInput form-control"
                           name="imei" id="imei"
                           ng-class="{'has-error': $ctrl.settings.imei.$invalid}"
                           ng-disabled="$ctrl.settings.existsAsStaff" ng-model="$ctrl.settings.imei"
                           type="text">
                </div>
            </fieldset>
        </div>

        <div class="row" ng-show="$ctrl.isEditMode && $ctrl.permissions.manageAccount">
            <fieldset class="form-inline">
                <div class="col-md-12 title">
                    {{ "general_other_settings" | translate }}
                </div>

                <!-- TEST USER -->
                <div class="col-md-6" ng-show="$ctrl.permissions.manageAccount">
                    <label>
                        <input ng-model="$ctrl.settings.user.testUser" type="checkbox">
                        <span>
                            {{ "user_create_testuser" | translate}}
                        </span>
                    </label>
                    <br/>
                    <span>
                        {{ "user_create_testuser_desc" | translate }}
                    </span>
                </div>

                <!-- BORROWED MONITOR -->
                <div class="col-md-6" ng-show="$ctrl.isEditMode && $ctrl.permissions.manageAccount">
                    <label>
                        <input ng-change="$ctrl.setBorrowedMonitor()" ng-model="$ctrl.settings.user.hasBorrowedMonitor"
                               type="checkbox">
                        <span>
                            {{ "user_create_borrowed_monitor" | translate}}
                        </span>
                        <span class="coalaFont15">
                            {{ $ctrl.settings.user.borrowedMonitorDate ? ($ctrl.settings.user.borrowedMonitorDate | timezone | date:$ctrl.dateFormats.shortDate) : ''}}
                        </span>
                    </label>
                </div>
            </fieldset>
        </div>

        <!-- MEASUREMENT CONFIGURATIONS-->
        <div class="row" ng-show="$ctrl.permissions.readMeasurementConfiguration && $ctrl.isConfigurationsAvailable()">
            <fieldset class="form-inline">
                <div class="col-md-12 title">
                    {{ "user_create_configurations" | translate }}
                </div>
                <div class="col-md-12">
                    <multiple-autocomplete after-select-item="$ctrl.afterSelectItem" id="userconfigurations"
                                           ng-model="$ctrl.settings.selectedMeasurementConfigurations" object-property="name"
                                           suggestions-arr="$ctrl.settings.measurementConfigurations">
                    </multiple-autocomplete>
                </div>
            </fieldset>
        </div>
    </form>
`;

angular.module('admin')
    .component('patientDetailsForm',
        {
            bindings: {
                isEditMode: '<',
                validated: '=',
                settings: '=',
                createPatientForm: '='
            },
            template: template,
            controller: ['$translate', 'PatientService', 'HealthcareProviderService', 'SessionService', 'NationService', class PatientDetailsForm {
                isEditMode?: boolean;
                validated?: boolean;
                settings!: PatientDetailsFormSettings;
                createPatientForm: any;

                private readonly translateService: angular.translate.ITranslateService;
                private readonly patientService: PatientService;
                private readonly healthcareProviderService: HealthcareProviderService;
                private readonly isUS: boolean;
                private readonly permissions: Permissions;
                private readonly nationConfiguration: NationConfiguration;
                private readonly dateFormats: DateFormats;
                private readonly isUnrestrictedUser: boolean;

                private emailExists = false;
                private identificationNoExists = false;
                private typePasswordInput = "";
                private typeConfirmPasswordInput = "";

                constructor($translate: angular.translate.ITranslateService, PatientService: PatientService, HealthcareProviderService: HealthcareProviderService, SessionService: any, NationService: NationService) {
                    this.translateService = $translate;
                    this.patientService = PatientService;
                    this.healthcareProviderService = HealthcareProviderService;
                    this.isUS = NationService.isUS();
                    this.permissions = SessionService.getUserPermissions();
                    this.nationConfiguration = NationService.getConfiguration();
                    this.dateFormats = NationService.getDateFormats();
                    this.isUnrestrictedUser = SessionService.isUnrestrictedUser();
                }

                $doCheck() {
                    if (this.createPatientForm) {
                        this.validated = this.createPatientForm.$valid;
                    }
                }

                togglePassword = (id: string, toogle: boolean = true) => {
                    if (toogle) {
                        if (id === 'password') {
                            this.typePasswordInput = this.typePasswordInput == "password" ? "text" : "password";
                        } else {
                            this.typeConfirmPasswordInput = this.typeConfirmPasswordInput == "password" ? "text" : "password";
                        }
                    } else if (this.typePasswordInput == "") {
                        this.typePasswordInput = "password";
                        this.typeConfirmPasswordInput = "password";
                    }
                };

                togglePasswordIcon = (id: string) => {
                    var type = this.typeConfirmPasswordInput;
                    if (id === 'password') {
                        type = this.typePasswordInput;
                    }
                    return type == "text" ? "fas fa-eye" : "fas fa-eye-slash";
                }

                afterSelectItem = (item: any) => {
                    console.log('Passed - After Select Item: ' + item.id);
                    if (item.id == '') {
                        this.settings.selectedMeasurementConfigurations = [{name: this.translateService.instant('user_created_allconfiguration_option'), id: ''}];
                    } else {
                        const allIsSelected = jQuery.grep(this.settings.selectedMeasurementConfigurations, (x: any) => {
                            return x.id == '';
                        });
                        if (allIsSelected.length > 0) {
                            const itemIndex = this.settings.selectedMeasurementConfigurations.indexOf(allIsSelected[0]);
                            if (itemIndex != -1) {
                                this.settings.selectedMeasurementConfigurations.splice(itemIndex, 1);
                            }
                        }
                    }
                };

                isConfigurationsAvailable = () => {
                    return this.settings.measurementConfigurations !== null && this.settings.measurementConfigurations.length > 2;
                };

                setBorrowedMonitor = () => {
                    this.settings.user.setBorrowedMonitor();
                };

                setPassword = () => {
                    let valid = true;
                    if ((this.settings.user.password && this.settings.user.password.length > 0) || 
                        (this.settings.user.confirmPassword && this.settings.user.confirmPassword.length > 0)) {
                        valid = this.settings.user.password == this.settings.user.confirmPassword;
                    }
                    this.createPatientForm.password.$setValidity('', valid);
                    this.createPatientForm.confirmPassword.$setValidity('', valid);
                };

                disablePatientIdentificationNo = () => {
                    return !this.isUS && this.settings.existsAsStaff;
                };

                isPatientIdRequired = () => {
                    return this.nationConfiguration.patientIdentificationNoValidationRegExp !== '';
                };

                showHealthcareProviders = () => {
                    return this.settings.healthCareProviders !== null && this.settings.healthCareProviders.length > 0;
                };

                isHealthcareProviderRequired = () => {
                    return !this.isEditMode && this.showHealthcareProviders() && !this.isUnrestrictedUser;
                };

                setIdentificationNo = () => {
                    this.identificationNoExists = false;
                    this.createPatientForm.patientIdentificationNo.$setValidity('', true);
                    if (this.settings.user.patientIdentificationNo && this.settings.user.patientIdentificationNo !== this.settings.originalUser.patientIdentificationNo) {
                        if (this.nationConfiguration.identificationNoMustBeUnique) {
                            this.patientService.getIdentificationNoExists(this.settings.user.patientIdentificationNo)
                                .then((response: any) => {
                                    if (response.exist) {
                                        this.identificationNoExists = true;
                                        this.createPatientForm.patientIdentificationNo.$setValidity('', false);
                                    } else {
                                        this.identificationNoExists = false;
                                        this.createPatientForm.patientIdentificationNo.$setValidity('', true);
                                    }
                                });
                        }
                    }
                };

                checkEmail = () => {
                    this.settings.existsAsStaff = false;
                    if (this.settings.user.email && this.settings.user.email !== this.settings.originalUser.email) {
                        this.patientService.getEmailExists(this.settings.user.email)
                            .then((response: any) => {
                                if (response.exist) {
                                    if (response.existsAsUser) {
                                        this.createPatientForm.email.$setValidity('', false);
                                    } else if (response.existsAsStaff) {
                                        this.settings.existsAsStaff = true;
                                        this.showFeedback(this.translateService.instant('user_connect_to_staff'));
                                        this.healthcareProviderService.getStaff(response.userId).then((data) => {
                                            this.settings.user.firstName = data.firstName;
                                            this.settings.user.lastName = data.lastName;
                                            this.settings.user.patientIdentificationNo = data.staffIdentificationNo;
                                            this.createPatientForm.email.$setValidity('', true);
                                        });
                                    }
                                    this.emailExists = true;
                                } else {
                                    this.emailExists = false;
                                    this.createPatientForm.email.$setValidity('', true);
                                }
                            });
                    }
                    this.emailExists = false;
                };

                private showFeedback = (message: string) => {
                    // @ts-ignore
                    Lobibox.notify('success', {
                        msg: message,
                        icon: false,
                        title: 'Message',
                        position: 'top right'
                    });
                };

            }]
        }
    );
