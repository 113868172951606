import * as angular from 'angular';
import * as moment from 'moment';

(function() {
  'use strict';

  angular
    .module('patients')
    .controller('PatientReportController', PatientReportController);

  function PatientReportController(
    $scope,
    $stateParams,
    $state,
    $translate,
    $interval,
    $filter,
    PatientService,
    NationService,
    UtilService,
    SessionService,
    ReportHelperService
  ) {
    const vmreport = this;
    const refreshInterval = 5 * 1000;
    vmreport.formats = NationService.getDateFormats();
    vmreport.permissions = SessionService.getUserPermissions();
    vmreport.fromdate = null;
    vmreport.todate = null;
    vmreport.showSpinner = false;
    vmreport.primaryIndications = '';
    vmreport.otherIndications = '';
    vmreport.prescribingClinician = '';
    vmreport.monitoringCenter = '';
    vmreport.includeNotesAttachment = false;
    vmreport.includeAttachemnts = false;
    vmreport.reports = [];
    let createdReportId = null;
    let interval = null;
    vmreport.markedreports = [];

    let _tableState = null;
    vmreport.totalReports = 0;
    vmreport.categories = NationService.getCategories();
    vmreport.measurementFilterAlertFlag = false;
    vmreport.measurementFilterReviewedFlag = false;
    vmreport.measurementFilterHasComment = false;
    vmreport.selectedFeelings = null;
    vmreport.selectedCategories = null;
    const apap = vmreport.selectedCategories;
    vmreport.feelings = [
      {id: 0, name: $translate.instant('measurement_feeling_unknown')},
      {id: 1, name: $translate.instant('measurement_feeling_good')},
      {id: 2, name: $translate.instant('measurement_feeling_stressed')},
      {id: 3, name: $translate.instant('measurement_feeling_anxious')},
      {id: 4, name: $translate.instant('measurement_feeling_chestpain')},
      {id: 6, name: $translate.instant('measurement_feeling_rapidHeartRate')},
      {id: 7, name: $translate.instant('measurement_feeling_dizziness')},
      {id: 5, name: $translate.instant('measurement_feeling_other')}
    ];

    let pagingParameters = {
      page: 0,
      pageSize: vmreport.itemsPerPage,
      id: ''
    };

    activate();

    function activate() {
      vmreport.userId = $stateParams.userId;
      if (hasUserId()) {
        $('#datetimepickerFrom').datetimepicker({
          icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
          format: vmreport.formats.shortDatePicker
        });
        $('#datetimepickerTo').datetimepicker({
          icons: {previous: 'fa fa-chevron-left', next: 'fa fa-chevron-right'},
          format: vmreport.formats.shortDatePicker
        });
        $('#datetimepickerFrom').on('dp.hide', function(e) {
          vmreport.fromdate = e.date;
        });

        $('#datetimepickerTo').on('dp.hide', function(e) {
          vmreport.todate = e.date;
        });

        getNumberOfUnViewedPatientSummaryReports();
      }
    }

    function hasUserId() {
      return vmreport.userId !== undefined && vmreport.userId !== '' && vmreport.permissions.generatePatientSummaryReport;
    }

    function getNumberOfUnViewedPatientSummaryReports() {
      PatientService.getNumberOfUnViewedPatientSummaryReports(vmreport.userId).then(
        function(data) {
          if (data > 0) {
            $scope.$parent.$parent.vm.numberOfUnViewedPatientSummaryReports = data;
          } else {
            $scope.$parent.$parent.vm.numberOfUnViewedPatientSummaryReports = 0;
          }
        }
      );
    }

    vmreport.createReport = function() {
      let formatedDate;
      vmreport.showSpinner = true;
      let fromDate = '2016-01-01'; // T13:18:12';
      let toDate = moment()
        .utc()
        .format('YYYY-MM-DD'); // '2019-06-10T13:18:12';
      const fromInput = document.getElementById('datetimepickerFrom').value;
      const toInput = document.getElementById('datetimepickerTo').value;
      if (fromInput != '') {
        formatedDate = moment(fromInput, vmreport.formats.shortDatePicker);
        fromDate = formatedDate.format('YYYY-MM-DD');
      }
      if (toInput != '') {
        formatedDate = moment(toInput, vmreport.formats.shortDatePicker);
        toDate = formatedDate.format('YYYY-MM-DD');
      }

      const d = new Date();
      const offset = d.getTimezoneOffset() * -1;

      const filters = createFilters();

      const patientSummaryReport = {
        patientUserId: vmreport.userId,
        fromDate: fromDate,
        toDate: toDate,
        primaryIndications: vmreport.primaryIndications,
        otherIndications: vmreport.otherIndications,
        prescribingClinician: vmreport.prescribingClinician,
        monitoringCenter: vmreport.monitoringCenter,
        includeNotesAttachment: vmreport.includeNotesAttachment,
        offset: offset,
        filter: filters
      };

      PatientService.createReport(patientSummaryReport).then(
        function(response) {
          vmreport.showSpinner = false;
          if (response !== null) {
            createdReportId = response.id;
            vmreport.showFeedback($translate.instant('report_start_create'));
            autoRefresh();
          } else {
            vmreport.showError($translate.instant('report_error'));
          }
        },
        function(reason) {
          vmreport.showSpinner = false;
          vmreport.showError($translate.instant('report_error'));
        }
      );
    };

    function createFilters() {
      let filter = null;
      let measurementFilterAll = false;
      if (vmreport.includeAttachemnts) {
        const categories = vmreport.selectedCategories.map(function(item) {
          return item.id;
        });
        const feelings = vmreport.selectedFeelings.map(function(item) {
          return item.id;
        });

        if (categories.length == 0 && feelings.length == 0 && vmreport.measurementFilterAlertFlag == false &&
          vmreport.measurementFilterReviewedFlag == false && vmreport.measurementFilterHasComment == false) {
          measurementFilterAll = true;
        }

        filter = {
          categorisations: categories,
          feelings: feelings,
          showAll: measurementFilterAll,
          mustBeFlagged: vmreport.measurementFilterAlertFlag,
          mustBeReviewed: vmreport.measurementFilterReviewedFlag,
          mustBeCommented: vmreport.measurementFilterHasComment
        };
      }
      return filter;
    }

    vmreport.reCreateReport = function(report) {
      vmreport.showSpinner = true;
      PatientService.reCreateReport(report.id).then(
        function(response) {
          if (response !== null) {
            createdReportId = response.id;
            vmreport.showFeedback($translate.instant('report_start_create'));
            // recreatingReport = report;
            autoRefresh();
          } else {
            vmreport.showSpinner = false;
            vmreport.showError($translate.instant('report_error'));
          }
        },
        function(reason) {
          vmreport.showSpinner = false;
          vmreport.showError($translate.instant('report_error'));
        }
      );
    };

    vmreport.reportGeneratorFailed = function(report) {
      return ReportHelperService.reportGeneratorFailed(report);
    };

    function autoRefresh() {
      interval = $interval(checkUserReports, refreshInterval);
    }

    function checkUserReports() {
      console.log('check user reports');
      PatientService.isReportCreated(createdReportId).then(
        function(response) {
          if (response !== null) {
            if (response.reportGeneratorStatus.name == 'GenerationFinished') {
              vmreport.showSpinner = false;
              $interval.cancel(interval);
              getNumberOfUnViewedPatientSummaryReports();
              updateUserReports();
            } else if (
              response.reportGeneratorStatus.name == 'GenerationFailed'
            ) {
              vmreport.showSpinner = false;
              $interval.cancel(interval);
            }
          }
        },
        function(reason) {
          $interval.cancel(interval);
          vmreport.showError($translate.instant('report_error'));
        }
      );
    }

    function updateUserReports() {
      if (hasUserId()) {
        vmreport.filters = [];
        pagingParameters = UtilService.preparePagingParameters(
          _tableState,
          vmreport.filters
        );

        PatientService.getUserReports(vmreport.userId, pagingParameters).then(function(
          data
        ) {
          vmreport.reports = data.rows;
          vmreport.totalReports = data.nbrOfRows;
          vmreport.nrOfPages = data.nbrOfPages;

          if (_tableState) {
            _tableState.pagination.numberOfPages = data.nbrOfPages;
          }
        });
      }
    }

    vmreport.fetchReports = function(tableState) {
      if (tableState) {
        _tableState = tableState;
      }

      pagingParameters = UtilService.preparePagingParameters(
        _tableState,
        vmreport.filters
      );
      pagingParameters.pageSize = 20;

      updateUserReports();
    };

    vmreport.reportDescription = function(report) {
      return ReportHelperService.reportDescription(report);
    };

    vmreport.setMarked = function(id) {
      const index = vmreport.markedreports.indexOf(id);

      if (index < 0) {
        vmreport.markedreports.push(id);
      } else {
        vmreport.markedreports.splice(index, 1);
      }
    };

    vmreport.getMarked = function(id) {
      return vmreport.markedreports.indexOf(id) >= 0;
    };

    vmreport.isMarket = function() {
      return vmreport.markedreports.length > 0;
    };

    vmreport.reportFailed = function(report) {
      if (report.reportGeneratorStatus.name == 'GenerationFailed') {
        return true;
      }
      return false;
    };

    vmreport.removeMarkedReports = function() {
      vmreport.showSpinner = true;
      console.log('Delete selected reports');

      const deletePatientSummaryReportRequest = {
        reportIds: vmreport.markedreports
      };

      PatientService.deleteReports(deletePatientSummaryReportRequest).then(
        function(data) {
          updateUserReports();
          vmreport.markedreports = [];
          vmreport.showSpinner = false;
        },
        function(reason) {
          vmreport.showSpinner = false;
          vmreport.showError($translate.instant('report_delete_error'));
        }
      );
    };

    vmreport.getLocalTime = function(utctime) {
      const date = moment(utctime).format('YYYY-MM-DD HH:mm:ss');
      const localTime = moment.utc(date).toDate();
      return localTime;
    };

    vmreport.showDownloadLink = function(report) {
      return ReportHelperService.showDownloadLink(report);
    };

    vmreport.reportIsNotViewed = function(report) {
      return ReportHelperService.reportIsNotViewed(report);
    };

    vmreport.reportIsViewed = function(report) {
      return ReportHelperService.reportIsViewed(report);
    };

    vmreport.setToViewed = function(reportId, storagePath) {
      return ReportHelperService.setToViewed(reportId, storagePath, vmreport.reports);
    };

    vmreport.showMonitoringCenter = function() {
      return NationService.isUS();
    };

    vmreport.getDownloadLink = function(report) {
      return report.storagePath;
    };

    vmreport.goBack = function() {
      $state.go('main.patientdetails', {
        userId: vmreport.userId,
        startItem: vmreport.startItem
      });
    };

    vmreport.showFeedback = function(message) {
      Lobibox.notify('success', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };

    vmreport.showError = function(message) {
      Lobibox.notify('error', {
        msg: message,
        icon: false,
        title: 'Message',
        position: 'top right'
      });
    };
  }
})();
