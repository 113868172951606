import * as angular from 'angular';

(function() {
  'use strict';

  angular
    .module('core')
    .controller('InfoController', InfoController);

  function InfoController($rootScope, $state, $stateParams, $http, $window, envConfig, InfoService) {
    const vm = this;

    vm.portalBuildDate = envConfig.coalaBuildTime;
    vm.portalVersion = envConfig.coalaVersion;
    vm.baseUrl = envConfig.baseUrl;
    vm.autoLogoutMinutes = envConfig.autoLogoutMinutes;
    vm.defaultTableItemsPerPage = envConfig.defaultTableItemsPerPage;
    vm.portalBuild = envConfig.coalaBuild;
    vm.portalBranch = envConfig.coalaBranch;
    vm.showSpinner = true;

    vm.analyserStatus = '';

    InfoService.getServerInfoDetails().then(function(response) {
      vm.apiBuildDate = response.buildDate;
      vm.apiVersion = response.version;
      // vm.apiBuild = response.version.build;
      vm.apiBranch = response.branch;
      vm.showSpinner = false;
    });
  }
})();
